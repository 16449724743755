/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

import { PrintService } from '../../core/8/print.service';

import { DialogSpinnerComponent } from '../../shared/dialog-spinner/dialog-spinner.component';

@Component({
  selector: 'app-dialog-print-text',
  templateUrl: './dialog-print-text.component.html',
  styleUrls: ['./dialog-print-text.component.scss']
})
export class DialogPrintTextComponent implements OnInit, OnDestroy {
  spinner: MatDialogRef<DialogSpinnerComponent, any>;

  constructor(
    public dialogRef: MatDialogRef<DialogPrintTextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private printService: PrintService
  ) { }

  ngOnInit() {
    if (this.data.automatic) {
      this.print(this.data.what);
    }
  }

  ngOnDestroy() {
    if (this.spinner) {
      this.spinner.close();
    }
  }

  onOk() {
    this.dialog.closeAll();
  }

  onCancel() {
    this.dialogRef.close();
  }

  /**
   * 빙글빙글 보여주면서 출력한다.
   */
  async print(what: 'cook' | 'customer' | 'all' | 'default') {
    // 1. 출력 명령을 보낸다.
    await this.printService.print(this.data.order, what);

    // 2. 현재 대화상자를 닫는다.
    this.onOk();
  }
}
