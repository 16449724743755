/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MessagePeer } from '../../schema/2/schema-message';
import { PrinterDoc } from '../../schema/3/schema';
import { LocalStorageService } from '../../core/1/local-storage.service';
import { RoomService } from '../../core/1/room.service';
import { UserService } from '../../core/3/user.service';
import { LogService } from '../../core/4/log.service';
import { FirebaseManagerService } from '../../core/5/firebase-manager.service';
import { PrinterAgentMessageService } from '../../core/6/printer-agent-message.service';

import { DialogSpinnerComponent } from '../dialog-spinner/dialog-spinner.component';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';

@Component({
  selector: 'app-dialog-printer',
  templateUrl: './dialog-printer.component.html',
  styleUrls: ['./dialog-printer.component.scss']
})
export class DialogPrinterComponent implements OnInit, OnDestroy {
  public printer: PrinterDoc;
  public printerAddressForm: FormControl;

  // '주문 취소', '리뷰'등의 인쇄 요청을 수행할지 결정한다.
  public printAlarmSetting = false;
  public isFormEnabled = false;

  private spinnerRef: MatDialogRef<DialogSpinnerComponent>;
  private subscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DialogPrinterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logService: LogService,
    private userService: UserService,
    private roomService: RoomService,
    private localStorageService: LocalStorageService,
    private dialogSpinnerService: DialogSpinnerService,
    private firebaseManagerService: FirebaseManagerService,
    private printerAgentMessageService: PrinterAgentMessageService
  ) { }

  ngOnInit() {
    this.initForm();
    this.subscription = this.userService.latestPrinterSubject.subscribe(printer => {
      if (printer.printerAddress !== this.printer?.printerAddress) {
        // printerAddress에 변경사항이 있으면 UI에 반영해야한다.
        this.printerAddressForm.setValue(printer.printerAddress ?? '');
      }
      this.printer = printer;
    });
    // localStorage의 설정값을 반영한다.
    this.printAlarmSetting = this.localStorageService.getValue('printAlarm') === 'on';
  }

  ngOnDestroy() {
    this.spinnerRef?.close();
    this.dialogRef?.close();
    this.subscription?.unsubscribe();
  }

  public closeDialog() {
    this.spinnerRef?.close();
    this.dialogRef?.close();
  }

  public enableForm() {
    this.isFormEnabled = true;
  }

  public disableForm() {
    this.isFormEnabled = false;
  }

  /**
   * connectionType이 'direct'인 프린터의 IP 설정을 변경한다.
   */
  public async updatePrinterAddress() {
    const { value } = this.printerAddressForm;

    // 변경사항이 없는 경우 반영하지 않는다.
    if (value === this.printer?.printerAddress) {
      return;
    }

    this.spinnerRef = this.dialogSpinnerService.openSpinnerDialog('변경 중');
    try {
      await this.firebaseManagerService.updateDoc('printer', this.printer._id, { printerAddress: value });
    } catch (error) {
      this.logService.logRoomWithToastrCatch(error, `[updatePrinterAddress] 프린터 주소 변경 중 예외발생`);
    }
    this.isFormEnabled = false;

    this.spinnerRef.close();
    this.spinnerRef = undefined;
  }

  /**
   * functions로 부터 전달받는 '주문 취소', '리뷰'등의 인쇄 요청을 수행할지 결정한다.
   * 설정은 localStorage에 저장한다.
   */
  public togglePrintAlarm(setting: boolean) {
    if (this.localStorageService.setItem('printAlarm', setting ? 'on' : 'off')) {
      this.printAlarmSetting = setting;
    } else {
      this.logService.error('localStorage에 프린터 설정을 저장하지 못했습니다.');
    }
  }

  public async printerTest() {
    this.spinnerRef = this.dialogSpinnerService.openSpinnerDialog('인쇄 요청 중');
    const to: MessagePeer = {
      class: this.printer.connectionType === 'direct' ? 'pos' : 'printer-agent',
      instanceNo: this.printer.connectionType === 'direct' ? this.roomService.room._id : this.roomService.room.site
    };

    try {
      await this.printerAgentMessageService.requestPrintTestMessage(this.printer, to);
    } catch (error) {
      this.logService.logRoomWithToastrCatch(error, `[printerTest] 출력 요청 실패`);
    }

    this.spinnerRef.close();
    this.spinnerRef = undefined;
  }

  private initForm() {
    this.printerAddressForm = new FormControl({
      value: '',
      disabled: false
    }, this.formValidator());
  }

  private formValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const { value } = control;

      if (value && value.length > 0) {
        const regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (regex.test(value) === false) {
          return { reason: '주소가 형식에 맞지 않습니다.' };
        }
      } else {
        return { reason: '예) 192.168.200.187' };
      }

      return null;
    };
  }
}
