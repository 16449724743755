<!-- <mat-tab-group [dynamicHeight]="true" animationDuration="0ms"> -->
<mat-tab-group [dynamicHeight]="true" [selectedIndex]="selectedIndex" [disableRipple]="true"
  (selectedIndexChange)="onSelectedIndexChange($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <div><span class="status">접수대기</span><br />({{ newOrdersSum | number}}원/{{ newOrders.length }}건)</div>
    </ng-template>
    <app-operating-table [orders]="newOrders" [tabName]="'new'"></app-operating-table>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div><span class="status">접수</span><br />({{ acceptedOrdersSum | number}}원/{{ acceptedOrders.length }}건)
      </div>
    </ng-template>
    <app-operating-table [orders]="acceptedOrders" [tabName]="'accepted'"></app-operating-table>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div><span class="status">배송중</span><br />({{ pickedupOrdersSum | number}}원/{{ pickedupOrders.length }}건)
      </div>
    </ng-template>
    <app-operating-table [orders]="pickedupOrders" [tabName]="'pickedup'"></app-operating-table>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div><span class="status">완료</span><br />({{ completedOrdersSum | number}}원/{{ completedOrders.length }}건)
      </div>
    </ng-template>
    <app-operating-table [orders]="completedOrders" [tabName]="'completed'"></app-operating-table>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div class="cancel-tab-label"><span class="status">취소</span><br /><span>({{ canceledOrders.length }}건)</span>
        <mat-icon *ngIf="numUnconfirmedCancelOrders > 0">warning</mat-icon>
      </div>
    </ng-template>
    <app-operating-table [orders]="canceledOrders" [tabName]="'canceled'"></app-operating-table>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div [ngClass]="countUnreadNotice > 0 ? 'notice-unread-tab-label' : 'notice-read-tab-label'">
        <span>{{ countUnreadNotice == 0 ? '공지 사항' : '미확인 공지' }}</span>
        <span *ngIf="countUnreadNotice > 0"><br/>({{ countUnreadNotice }}건)</span>
      </div>
    </ng-template>
    <app-notice [notices]="notices"></app-notice>
  </mat-tab>
</mat-tab-group>