/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
/**
 * 노드 패키지 'iNoBounce'의 코드를 가져왔다.
 *
 * 패키지 원본
 * iNoBounce - v0.1.6
 * https://github.com/lazd/iNoBounce/
 */

/** 현재 발생하는 touchevent의 진행방향을 알기위해 사용한다. */
let startY = 0;
let enabled = false;
let supportsPassiveOption = false;

try {
  // addEventListner의 passive옵션 지원여부를 확인한다.
  const options: AddEventListenerOptions = Object.defineProperty({}, 'passive',
    {
      get: () => {
        supportsPassiveOption = true;
      },
    }
  );

  window.addEventListener('test', null, options);
  window.removeEventListener('test', null, options);
} catch (err) {
  supportsPassiveOption = false;
  console.error(err);
}

const handleTouchmove = (evt: TouchEvent) => {
  // 현재 이벤트가 발생한 element
  let el = evt.target as HTMLElement;

  const zoom = window.innerWidth / document.documentElement.clientWidth;
  if (evt.touches.length > 1 || zoom !== 1) {
    // 확대가 된 상태 혹은 핀치줌인 경우에는 touchevent를 막지 않는다.
    return;
  }

  // 이벤트가 발생한 element와 그 조상까지 스크롤 가능여부를 판단한다.
  while (el !== document.body && (el as any) !== document) {
    const style = window.getComputedStyle(el);

    // 적용된 스타일을 알 수 없는 element를 만나면 루프를 종료한다.
    if (!style) {
      break;
    }

    // range input element는 무시한다.
    if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
      return;
    }

    const overflowY = style.getPropertyValue('overflow-y');
    const height = parseInt(style.getPropertyValue('height'), 10);

    // 스크롤이 필요한 element인지 확인한다.
    const isScrollable =
      (overflowY === 'auto' || overflowY === 'scroll') &&
      el.scrollHeight > el.offsetHeight;

    if (isScrollable) {
      const curY = evt.touches[0].screenY;

      // 마지막 영역에 도달한 스크롤 영역을 같은 방향으로 다시 스크롤 하면 bouncing이 발생한다.
      // 이를 막기위해 스크롤이 마지막 영역에 도달했는지 확인한다.
      const isAtTop = startY <= curY && el.scrollTop === 0;
      const isAtBottom = startY >= curY && el.scrollHeight - el.scrollTop === height;

      if (isAtTop || isAtBottom) {
        evt.preventDefault();
      }

      // 여기까지 도달한 경우는 스크롤이 가능한 element로 이벤트를 막지 않는다.
      return;
    }
    el = el.parentNode as HTMLElement;
  }

  // 스크롤이 없는 element로 bouncing을 발생시키지 않게 하기 위해 이벤트를 막는다.
  evt.preventDefault();
};

const handleTouchstart = (evt: TouchEvent) => {
  // 현재 발생하는 touchevent의 진행방향을 알기위해 시작점을 저장한다.
  // ex) startY <= curY: 아래에서 위로 쓸어올리는 터치 이벤트
  startY = evt.touches[0].screenY;
};

const enable = () => {
  window.addEventListener('touchstart', handleTouchstart, supportsPassiveOption ? { passive: false } : false);
  window.addEventListener('touchmove', handleTouchmove, supportsPassiveOption ? { passive: false } : false);
  enabled = true;
};

const disable = () => {
  window.removeEventListener('touchstart', handleTouchstart, false);
  window.removeEventListener('touchmove', handleTouchmove, false);
  enabled = false;
};

const isEnabled = () => enabled;

export const iNoBounce = {
  enable,
  disable,
  isEnabled,
};

// if (typeof module !== 'undefined' && module.exports) {
//   // Node.js Support
//   module.exports = iNoBounce;
// } if (typeof (global as any).define === 'function') {
//   // AMD Support
//   ((define) => {
//     define('iNoBounce', [], () => iNoBounce);
//   })((global as any).define);
// } else {
//   // Browser support
//   global['iNoBounce'] = iNoBounce;
// }
