/*
 * © 2022 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { CallProxyRequest, CallProxyResponse } from '../../schema/1/schema-functions-proxy';
import { BaeminSessionDoc } from '../../schema/3/schema-baemin';

import { LogService } from '../4/log.service';
import { FirebaseManagerService } from '../5/firebase-manager.service';

const host = 'omg-mobile-api.baemin.com';

@Injectable({
  providedIn: 'root'
})
export class BaeminAppApiService {
  private commonHeader = {
    Host: host,
    'X-DeviceModel': 'iPhone11,2',
    Accept: 'application/json',
    // Authorization: '',
    'X-TouchOrder': 'false',
    'X-UserAgent': 'iph4_3.27.0',
    // Accept-Encoding: 'gzip',
    'Accept-Language': 'ko-kr',
    // Connection: 'keep-alive',
    // 'X-DeviceId': deviceId,
    'X-SiteCode': '',
    'User-Agent': '%EB%B0%B0%EB%AF%BC%EC%A3%BC%EB%AC%B8%EC%A0%91%EC%88%98/3.27.0.601 CFNetwork/1331.0.7 Darwin/21.4.0',
    'X-MerchantNo': '',
    'X-OsVersion': '15.4',
    Carrier: '45005'
  };

  constructor(
    private fns: AngularFireFunctions,
    private firebaseManager: FirebaseManagerService,
    private logService: LogService,
  ) { }

  /**
   * 인증에 필요한 다음 4개의 필드는 functions에서 채워준다.
   *
   * - Authorization: `Basic ${authorization}`,
   * - 'X-SiteCode': siteCode,
   * - 'X-MerchantNo': merchantNo,
   * - 'X-DeviceId': deviceId,
   */
  public async get_block(organization: string, site: string, room: string, instanceNo: string) {
    // 1. merchantNo 확보
    const doc = await this.firebaseManager.getDoc<BaeminSessionDoc>(`baeminSession/${instanceNo}`);
    if (!doc) {
      this.logService.logRoomWithToastrError(room, `baeminSession/${instanceNo}가 없어요.`);

      return undefined;
    }
    const merchantNo = doc.appSession?.memberInfo?.merchantNo;

    // 2. callProxy
    const callable = this.fns.httpsCallable<CallProxyRequest, CallProxyResponse>('callProxy');

    const callInput: CallProxyRequest = {
      organization,
      site,
      room,
      auth: {
        collection: 'baeminSession',
        instanceNo
      },
      request: {
        method: 'GET',
        url: `https://${host}/v4/merchant/${merchantNo}/block?merchantNo=${merchantNo}`,
        headers: this.commonHeader,
        timeout: 15000
      }
    };

    return callable(callInput).toPromise();
  }
  public async post_block(organization: string, site: string, room: string, instanceNo: string, temporaryBlockTime: number) {
    return this.blockOrUnblock('block', organization, site, room, instanceNo, temporaryBlockTime);
  }
  public async post_unblock(organization: string, site: string, room: string, instanceNo: string) {
    return this.blockOrUnblock('unblock', organization, site, room, instanceNo);
  }

  /**
   * 지정한 시간만큰 임시영업중지한다.
   *
   * - 2022-01-12 최신 주문접수앱과 동일
   *
   * @param temporaryBlockTime 분
   */
  private async blockOrUnblock(endpoint: 'block' | 'unblock', organization: string, site: string, room: string, instanceNo: string, temporaryBlockTime?: number) {
    // 1. merchantNo 확보
    const doc = await this.firebaseManager.getDoc<BaeminSessionDoc>(`baeminSession/${instanceNo}`);
    if (!doc) {
      throw new Error(`baeminSession/${instanceNo}가 없어요.`);
    }
    const merchantNo = doc.appSession?.memberInfo?.merchantNo;

    // 2. callProxy
    const callable = this.fns.httpsCallable<CallProxyRequest, CallProxyResponse>('callProxy');

    const form: CallProxyRequest['request']['form'] = {
      merchantNo
    };
    if (endpoint === 'block') {
      form.temporaryBlockTime = String(temporaryBlockTime);
    }

    const callInput: CallProxyRequest = {
      organization,
      site,
      room,
      auth: {
        collection: 'baeminSession',
        instanceNo
      },
      request: {
        method: 'POST',
        url: `https://${host}/v4/merchant/${merchantNo}/${endpoint}`,
        headers: {
          ...this.commonHeader,
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
        timeout: 15000,
        form,
      }
    };

    return callable(callInput).toPromise();
  }
}
