/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditDeliveryComponent } from './dialog-edit-delivery.component';

@Injectable({
  providedIn: 'root'
})
export class DialogEditDeliveryService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogEditDelivery(unifiedOrderId: string, unifiedDeliveryId: string) {
    return this.dialog.open(DialogEditDeliveryComponent, {
      disableClose: true,
      maxWidth: '100vw',
      data: {
        unifiedOrderId,
        unifiedDeliveryId
      }
    });
  }
}
