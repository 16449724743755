/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { format } from 'date-fns';

import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

import { UnifiedOrderContextStatusCode, UnifiedOrderDoc, UnifiedOrderStatusCode } from '../../schema/3/schema';
import { CallInputAcceptWithDelivery, CallOutputAcceptWithDelivery } from '../../schema/4/schema-functions-call';
import { OrderProtocol } from '../../schema/5/schema-protocol';

import { lineClampSwalContent } from '../1/common';
import { cancelCodesMap } from '../1/string-map';
import { LogService } from '../4/log.service';
import { UnifiedOrderService } from '../5/unified-order.service';
import { MessageService } from '../7/message.service';

@Injectable({
  providedIn: 'root'
})
export class BaeminOrderService implements OrderProtocol {

  constructor(
    private unifiedOrderService: UnifiedOrderService,
    private messageService: MessageService,
    private logService: LogService,
    private dialogNoticeService: DialogNoticeService,
    private fns: AngularFireFunctions
  ) { }

  /**
   *
   * 배달 : accept callable을 호출한다.
   * 그 외(배민1이나 포장) : 바로 접수 명령을 보낸다
   *
   * @returns 접수 성공 실패 여부
   */
  public async accept(order: UnifiedOrderDoc, withDelivery = false): Promise<boolean> {
    const cookMinutes = order.cookMinutes;

    if (order.createdBy !== undefined) {
      this.logService.logOrder(order, `createdBy(${order.createdBy})에 값이 있습니다. 고객센터에 알려주세요.`, 'error', true);
      return false;
    }

    try {
      // 배민 경우에는 조리 시간을 선택하게 한다.
      if (order.deliveryType === 'DELIVERY') {
        const callInputAcceptWithDelivery: CallInputAcceptWithDelivery = {
          orderId: order._id,
          withDelivery,
          posRequestedPickupMinutes: order.posRequestedPickupMinutes,
          posDeliveryMinutes: order.posDeliveryMinutes,
          recommendedRequestedPickupMinutes: order.recommendedRequestedPickupMinutes,
          recommendedDeliveryMinutes: order.recommendedDeliveryMinutes,
        };
        const callable = this.fns.httpsCallable<CallInputAcceptWithDelivery, CallOutputAcceptWithDelivery>('callAcceptWithDelivery');
        const response = await callable(callInputAcceptWithDelivery).toPromise();

        if (response.result === 'success') {
          this.logService.logOrder(order, '주문 접수 성공', 'info', true);
        } else {
          if (response.acceptSuccess) {
            this.logService.logOrder(order, `주문 접수에 성공했지만 배차에 실패했습니다. ${response.reason} / withDelivery: ${withDelivery}`, 'error');
            lineClampSwalContent('배차 실패', '주문 접수에 성공했지만 배차에 실패했습니다. 접수된 주문 상세화면에서 배차 추가를 다시 진행해 주세요.');
          } else {
            this.logService.logOrder(order, `주문 접수 시도했지만 실패했습니다. ${response.reason} / withDelivery: ${withDelivery}`, 'error');
            lineClampSwalContent('주문을 접수하지 못 했습니다.', response.reason);

            return false;
          }
        }
      } else {
        if (!cookMinutes) {
          this.logService.logOrder(order, 'cookMinutes이 없습니다. 고객센터에 알려주세요.', 'error', true);
          return false;
        }

        // 배민1과 포장인 경우에는 바로 주문 접수한다.
        const response = await this.messageService.requestAcceptBaeminOrder(order.instanceNo, order.orderNo, cookMinutes);
        if (response?.result === 'success') {
          this.logService.logOrder(order, '배민 포장, 배민1 주문을 접수 성공했습니다. (실제로 접수)');
        } else {
          this.logService.logOrder(order, `배민 포장, 배민1 주문을 접수 실패했습니다: ${response?.reason}`);
          return false;
        }
      }
    } catch (error) {
      this.logService.logOrder(order, `배민 접수 시도했지만 실패했습니다. ${error.message} / baeminActionAccept(withDelivery: ${withDelivery})`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  public canCook(order: UnifiedOrderDoc): boolean {
    if (order.contextStatusCode >= UnifiedOrderContextStatusCode.COOKED) {
      return false;
    }

    // 배민1의 경우에 COOK_REQUETED 이전에는 조리완료 API를 보낼 수 없다.
    if (order.unifiedDelivery?.deliveryVendor as any === 'baera') {
      // tslint:disable-next-line: triple-equals
      return !(order.baemin?.riderStatus == undefined || order.baemin?.riderStatus === 'RIDER_ASSIGNED');
    }

    return true;
  }

  public async cook(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 시간을 기록한다.
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        contextStatusCode: UnifiedOrderContextStatusCode.COOKED,
        time: {
          onCOOKED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
        }
      });
      this.logService.logOrder(order, `배민 주문의 주문상태를 '조리완료'로 변경 완료했습니다.`);
    } catch (error) {
      this.logService.logOrder(order, `배민 주문의 주문상태를 '조리완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
    }

    return true;
  }

  public async pickup(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 포장이나 매장식사의 경우에는 픽업이 곧 배송완료이다.
      if (order.deliveryType === 'HERE' || order.deliveryType === 'TAKEOUT') {
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          // TODO: 배민의 경우에는 완료 요청을 보내야 할까?
          contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED,
        });
        this.logService.logOrder(order, `배민 주문(${order.deliveryType})의 주문상태를 '배송완료'로 변경 완료했습니다.`);
      } else {
        // 시간을 기록한다.
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.PICKEDUP,
          time: {
            onPICKEDUP: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          }
        });
        this.logService.logOrder(order, `배민 주문(${order.deliveryType})의 주문상태를 '배송중'으로 변경 완료했습니다.`);
      }
    } catch (error) {
      this.logService.logOrder(order, `배민 주문의 주문상태를 '배송중'(포장이나 매장식사)이나 '배송완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
    }

    return true;
  }

  public async complete(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 배민에 요청 보내기
      await this.messageService.requestCompleteBaeminOrder(order.instanceNo, order.orderNo);
      this.logService.logOrder(order, `주문상태를 '배송완료'으로 변경 완료했습니다.`);
      return true;
    } catch (error) {
      this.logService.logOrder(order, `주문상태를 '배송완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
    }

    return false;
  }

  public async cancel(order: UnifiedOrderDoc, cancelReasonCode): Promise<boolean> {
    try {
      await this.messageService.requestCancelBaeminOrder(
        order.instanceNo,
        order.orderNo,
        cancelReasonCode
      );

      this.logService.logOrder(order, `배민 주문 취소 요청 성공`);

      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        posCancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
      });
      this.logService.logOrder(order, `배민 취소 주문을 취소 확인상태(posCancelConfirmed)로 변경 성공`);
    } catch (error) {
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      this.logService.logOrder(order, `[${error.message}]의 이유로 취소(${cancelReasonCode})를 실패했다`);

      return false;
    }

    return true;
  }

  // 배민은 deliveryMinutes을 여러 가지 의미로 사용해서 최초 설계시 복잡해졌다.
  public cookMinutesOptionsFor(order: UnifiedOrderDoc): string[] {
    if (order.deliveryType === 'TAKEOUT') {
      return ['5', '10', '15', '20', '25', '30', '40', '50', '60', '70', '80', '90'];
    } else {
      // TAKEOUT : 음식 픽업시간
      // BAERA : 조리시간
      // 배민의 기본값을 따랐다.
      // 2019-09-21: 배민1은 최대 시간이 20분으로 줄어 든 것으로 보이지만 그냥 둔다.
      return ['5', '10', '15', '20', '25', '30'];
    }
  }

  public canCancel(order: UnifiedOrderDoc): boolean {
    // 배민 앱 주문의 경우에만 해당
    if (order.createdBy === 'manual') {
      return false;
    }

    // 배민 주문은 완료 이전에는 취소 가능
    return order.contextStatusCode < UnifiedOrderContextStatusCode.COMPLETED;
  }

  public cancelDescs(order: UnifiedOrderDoc) {
    return cancelCodesMap[order.deliveryType]?.[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'];
  }

  public showDeliveryMinutes(order: UnifiedOrderDoc): boolean {
    // 포장, 배민1인 경우에는 '조리시간'에 보여준다.
    return order.deliveryType === 'DELIVERY';
  }

  public showUserTel(order: UnifiedOrderDoc): boolean {
    return order.deliveryType !== 'BAERA';
  }

  public orderNo(order: UnifiedOrderDoc): string {
    const displayNo = order.displayNo ? ` [접수번호: ${order.displayNo}]` : '';
    return `${order.orderNo}${displayNo}`;
  }
}
