<div class="search-address-container">
  <app-full-screen-dialog-header
  [title]="title"
  (closed)="closeDialog()"
  ></app-full-screen-dialog-header>

  <div class="dialog-body">
    <mat-tab-group class="search-address-tab-group" animationDuration="0" [disableRipple]="true" (selectedIndexChange)="onSelectedIndexChange($event)">
      <!-- 번지 주소 입력 탭 -->
      <mat-tab label="번지 주소 입력">
        <main id="bunji-search-tab" class="tab-body">
          <mat-selection-list [multiple]="false">
            <ng-container *ngFor="let sido of dongDB | keyvalue">
              <ng-container *ngFor="let sigungu of sido.value | keyvalue">
                <p class="item-group-name">{{ sigungu.key }}</p>
                <mat-list-option *ngFor="let dong of sigungu.value | keyvalue"
                  class="address-dong"
                  [selected]="bunjiSearchForm.get('address_dong').value === dong.key"
                  [value]="dong.key"
                  (click)="onSelectDong(sido.key, sigungu.key, dong.key)">
                  {{ dong.key }}
                </mat-list-option>
              </ng-container>
            </ng-container>
          </mat-selection-list>

          <form class="bunji-search-form" [formGroup]="bunjiSearchForm">
            <p class="bunji-sequence">{{ bunjiSearchForm.get('address_sido').value }} > {{ bunjiSearchForm.get('address_sigungu').value }} > {{ bunjiSearchForm.get('address_dong').value }}</p>
            <p>번지</p>
            <div class="bunji-input">
              <input [class.invalid]="bunjiSearchForm.get('address_jibun').invalid" readonly="readonly" type="tel" formControlName="address_jibun" placeholder='예) 1321-5'
                pattern="^(산\s*)?([1-9][0-9]{0,3}|[1-9][0-9]{0,3}-[1-9][0-9]{0,2})$">
              <mat-icon class="cancel-icon-button" *ngIf="bunjiSearchForm.get('address_jibun').value.length > 0" (click)="clearBunji()">cancel</mat-icon>
              <mat-error *ngIf="bunjiSearchForm.get('address_jibun').invalid">{{ bunjiSearchForm.get('address_jibun').errors['reason'] }}</mat-error>
            </div>

            <div class="dials">
              <button *ngFor="let num of numbers" (pointerdown)="onChangeDials(num, $event)">{{ num }}</button>
              <button (pointerdown)="onChangeDials('-', $event)">-</button>
              <button (pointerdown)="onChangeDials('0', $event)">0</button>
              <button (pointerdown)="onChangeDials('backspace', $event)">
                <mat-icon color="accent">backspace</mat-icon>
              </button>
            </div>
            
            <div class="buttons">
              <button class="left" (pointerdown)="onChangeDials('산', $event)">산</button>
              <button type="button" [disableRipple]="true" mat-stroked-button color="primary" [disabled]="bunjiSearchForm.get('address_jibun').invalid" (click)="bunjiAugmentAddress()">주소 조회</button>
            </div>
          </form>
        </main>
      </mat-tab>
  
      <!-- 검색으로 찾기 탭 -->
      <mat-tab label="검색으로 찾기">
        <main id="keyword-search-tab" class="tab-body">
          <div class="address-search-form-container">
            <form [formGroup]="keywordSearchForm" action=".">
              <input type="search" style="ime-mode:active;" formControlName="keyword" placeholder="예) 서운로 201" (keyup.enter)="keywordSearch()">
              <mat-icon class="search-icon" (click)="keywordSearch()">search</mat-icon>
              <div class="search-form-border"></div>
              <mat-icon class="cancel-icon-button" *ngIf="keywordSearchForm.get('keyword').value.length > 0" (click)="initKeywordSearchAddressForm()">cancel</mat-icon>
            </form>
            <div class="white-gradient-bar"></div>
          </div>
          

          <div #keywordSearchResult class="address-search-result" *ngIf="documents">
            <ul class="address-list">
              <li class="address-item" [class.selected]="document._selected" *ngFor="let document of documents" (click)="pickAddress(document)">
                <div class="address-name">
                  <b>{{ document.place_name }}</b>
                  <p>{{ document.address_name }}</p>
                  <p><span>도로명</span>{{ document.road_address_name }}</p>
                </div>
                <div class="distance">
                  <p class="distance-label">거리</p>
                  <p>{{ document.distance | number }}m</p>
                </div>
              </li>
            </ul>
  
            <h2 class="address-list-empty" *ngIf="documents?.length === 0">검색된 결과가 없습니다.</h2>
          </div>
          <div class="address-list-help" *ngIf="documents === undefined">
            <p>검색 반경 - {{ radius / 1000 }}km</p><br>
            <p>검색 예시</p>
            <p>- 건물명: 재전빌딩</p>
            <p>- 도로명: 서운로 142-4, 법원로11길 12</p>
            <p>- 번지: 상도1동 501-2, 상도동 900</p>
            <p class="point">* 번지의 경우 '상도동'과 같은 일부 지역은 <b>'상도1동'</b>과 <b>'상도동'</b>을 구분해야합니다. </p>
          </div>
  
          <div class="pagination" *ngIf="(documents && documents.length > 0) && (pagination.pageableCount > pagination.size)">
            <div class="pagenate">
              <mat-icon *ngIf="pagination.pageNumber > 1" (click)="paginate('prev')">navigate_before</mat-icon>
            </div>
            <p class="page">{{ pagination.pageNumber }} / {{ pagination.pageLength }}</p>
            <div class="pagenate">
              <!-- https://apis.map.kakao.com/web/documentation/#Pagination -->
              <mat-icon *ngIf="pagination.pageNumber < pagination.pageLength" (click)="paginate('next')">navigate_next</mat-icon>
            </div>
          </div>
        </main>
      </mat-tab>
    </mat-tab-group>

    <div class="search-address-result">
      <div *ngIf="augmentedAddressJibun">
        <h2 class="bunji-address">{{ augmentedAddressJibun }}</h2>
        <p class="road-address"><span>도로명</span>{{ augmentedAddress ? augmentedAddress.road : '' }}</p>

        <mat-form-field class="address-detail" floatLabel="always">
          <mat-label>상세주소</mat-label>
          <!-- style="ime-mode:active;" iPad 가상 keyboard의 default언어를 한글로 설정한다. -->
          <input matInput [formControl]="addressDetailForm" placeholder="예)재전빌딩 9층 고스트키친" style="ime-mode:active;" (keyup.enter)="submitAugmentedAddress()">
          <button mat-button *ngIf="addressDetailForm.value" matSuffix mat-icon-button aria-label="Clear" (click)="addressDetailForm.setValue('')">
            <mat-icon>cancel</mat-icon>
          </button>
          <mat-error *ngIf="addressDetailForm.invalid">{{ addressDetailForm.errors['reason'] }}</mat-error>
        </mat-form-field>

        <button class="submit-button" type="button" [disableRipple]="true" mat-flat-button color="primary" [disabled]="addressDetailForm.invalid || augmentedAddress === undefined" (click)="submitAugmentedAddress()">주소 입력 완료</button>
      </div>
      <div *ngIf="augmentedAddress" class="address-info">
        <div class="flex-box">
          <p>행정동: {{ augmentedAddress.dongH }}</p>
          <p>위도: {{ augmentedAddress.location.lat | number: '1.0-5' }}, 경도: {{ augmentedAddress.location.lon | number: '1.0-5' }}</p>
          <p class="distance" *ngIf="deliveryDistance">배달거리: {{ deliveryDistance | number }}m</p>
        </div>
        <button [disableRipple]="true" mat-stroked-button color="primary" (click)="showKakaoMap(augmentedAddress.location.lat, augmentedAddress.location.lon)">지도보기</button>
      </div>
    </div>
  </div>
</div>

<div class="kakao-map-popup" [class.show]="showMap">
  <div #kakaoMap class="kakao-map">
    <button mat-flat-button class="close-popup" (click)="closeKaKaoMap()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>