/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import { fromEvent, merge, Subject, Subscription, timer } from 'rxjs';
import { filter, map, pairwise, takeUntil } from 'rxjs/operators';
import { addDays, differenceInMinutes, format, parseISO } from 'date-fns';
import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
// import { animate, state, style, transition, trigger } from '@angular/animations';

import { UnifiedOrderFood } from '../../schema/1/schema-common';
import { UnifiedOrderDoc, UnifiedOrderContextStatusCode } from '../../schema/3/schema';
import { UnifiedOrderDocUI } from '../../schema/4/schema-ui';

import { autoOpenedOrders, canOpenOrder, debugLog, testSound } from '../../core/1/common';
import { openSwalDeliveryFinishCheck } from '../../core/1/swal-util';
import { addressFormatter, currencyFormatter, dateFormatter } from '../../core/1/ag-util';
import { unifiedOrderStatusMappings, unifiedOrderVendorMappings, unifiedOrderDeliveryTypeMappings } from '../../core/1/string-map';
import { NotificationCenterService } from '../../core/1/notification-center.service';
import { SimpleNoticeService } from '../../core/1/simple-notice.service';
import { pickupTime } from '../../core/2/delivery-util';
import { LogService } from '../../core/4/log.service';
import { UnifiedOrderService } from '../../core/5/unified-order.service';
import { DeliveryService } from '../../core/5/delivery.service';
import { OrderService } from '../../core/9/order.service';

import { DialogOrderDetailService } from '../../shared/dialog-order-detail/dialog-order-detail.service';

type Columns =
  'orderDate' |
  'simpleNo' |
  'contextStatusCode' |
  'shopName' |
  'orderVendor' |
  // 'deliveryType' |
  'cookMinutes' |
  'deliveryMinutes' |
  'pickupTime' |
  'riderName' |
  'address_key' |
  'paymentAmount' |
  'foods';

interface ColumnDef {
  field: string;
  /** unifiedOrder field */
  orderField?: Columns;
  headerName: string;
  valueGetter?: any;
  valueFormatter?: any;
  refData?: any;
}

type Row = {
  [field in Columns | 'order']: any;
};

/**
 * ag-grid의 구조와 유사하게 구성하였다.
 *
 * td에 해당하는 테이블의 값이 업데이트되는 과정은 다음과 같다.
 * 1. ngOnChanges()를 통해서 orders의 변경 여부를 전달받는다.
 * 2.   updateRows()
 * 3.     value()
 * 4.       valueGetter()
 */
@Component({
  selector: 'app-operating-table',
  templateUrl: './operating-table.component.html',
  styleUrls: ['./operating-table.component.scss'],
})
export class OperatingTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('preventDoubleTap') private preventDoubleTapRef: ElementRef<HTMLDivElement>;
  @Input() orders: UnifiedOrderDoc[];
  @Input() tabName: string;

  rows: (Row & { order: UnifiedOrderDoc })[] = [];

  public get atDate() {
    return this.notificationCenterService.atDateSubject.value;
  }
  public set atDate(v: number) {
    this.notificationCenterService.atDateSubject.next(v);
  }

  public moreButtonText = '어제';

  private destroySignal = new Subject<boolean>();

  simpleNoticeSubscription: Subscription = null;
  existSimpleNotice = false;

  columnsToDisplay: string[] = [];

  columnDefs: ColumnDef[] = [
    { field: 'orderDate', orderField: 'orderDate', headerName: '주문날짜', valueFormatter: params => dateFormatter(params.value, 'MM-dd') },
    { field: 'orderTime', orderField: 'orderDate', headerName: '주문시각', valueFormatter: params => dateFormatter(params.value, 'HH:mm') },
    { field: 'simpleNo', headerName: '간단번호' },
    { field: 'contextStatusCode', headerName: '주문상태', refData: unifiedOrderStatusMappings },
    { field: 'shopName', headerName: '업소명' },
    {
      field: 'orderVendor', headerName: '주문채널',
      valueGetter: params => {
        const order: UnifiedOrderDoc = params.data;
        let vendorString = unifiedOrderVendorMappings[order.orderVendor];

        if (['baemin', 'ghostkitchen', 'yogiyo', 'coupangeats'].includes(order.orderVendor)) {
          vendorString += `/${unifiedOrderDeliveryTypeMappings[order.deliveryType]}`;
        }

        return vendorString;
      }
    },
    // { field: 'deliveryType',    headerName: '배달유형', refData: unifiedOrderDeliveryTypeMappings },
    {
      field: 'cookMinutes', headerName: '조리시간',
      valueGetter: params => {
        const order: UnifiedOrderDoc = params.data;
        if (['TAKEOUT', 'BAERA'].includes(order.deliveryType)) {
          return order.deliveryMinutes;
        } else {
          return order.cookMinutes ? order.cookMinutes : '-';
        }
      }
    },
    {
      field: 'deliveryMinutes', headerName: '배송시간',
      valueGetter: params => {
        const order = params.data as UnifiedOrderDoc;
        if (['TAKEOUT', 'BAERA'].includes(order.deliveryType)) {
          return '-';
        } else {
          return order.deliveryMinutes;
        }
      }
    },
    {
      field: 'pickupTime', headerName: '픽업까지',
      valueGetter: params => {
        const order = params.data as UnifiedOrderDoc;

        if ((this.tabName === 'accepted' || this.tabName === 'cooked')) {
          return pickupTime(order);
        } else {
          return '--';
        }
      }
    },
    {
      field: 'riderName', headerName: '라이더',
      valueGetter: params => {
        const order = params.data as UnifiedOrderDocUI;

        // 2019-03-27T19:18:16+0900
        if (['DELIVERY', 'BAERA', 'COUPANG', 'YOGIYO'].includes(order.deliveryType)) {
          if (order._ui?.relatedDeliveries?.length > 0) {
            // relatedDeliveries에 취소 배차는 이미 걸렀다.
            // 시간 비교는 문자열 비교로도 충분하다.
            const delivery = order._ui?.relatedDeliveries.sort((a, b) => {
              return (a.timeSubmitted < b.timeSubmitted) ? -1 :
                (a.timeSubmitted > b.timeSubmitted) ? 1 : 0;
            })[0];

            return this.deliveryService.riderName(delivery);
          } else if (order.deliveryCherrypickStatus === 'ghostrider') {
            return order.ghostriderName ? `^${order.ghostriderName}^` : '^_^';
          } else {
            return '--';
          }
        }

        return '';
      }
    },
    {
      field: 'address_key', headerName: '주소',
      valueGetter: params => {
        const order: UnifiedOrderDoc = params.data;
        if (order.orderVendor === 'coupangeats' || order.deliveryType === 'BAERA') {
          return order.address_dongH ?? '';
        } else if (order.address_key == null || order.address_key === '' || order.deliveryType === 'TAKEOUT' || order.deliveryType === 'HERE') {
          return unifiedOrderDeliveryTypeMappings[order.deliveryType];
        } else {
          // agGrid에 사용하는 valueFormatter 형식에 맞추기 위해서 오브젝트로 감싸서 전달한다.
          return addressFormatter({ value: order.address_key } as any);
        }
      }
    },
    {
      field: 'paymentAmount', headerName: '결제액',
      valueGetter: params => {
        const order: UnifiedOrderDoc = params.data;

        const [{ orderAmount = 0, deliveryTip = 0, discount = 0, eventDiscount = 0 } = {}] = [order];

        return orderAmount + deliveryTip - eventDiscount - discount;
      },
      valueFormatter: currencyFormatter
    },
    {
      field: 'foods', headerName: '메뉴요약',
      valueGetter: params => {
        // params.data가 undefined인 경우는 groupRow에 대해서 불리는 경우로 추정
        return params.data && params.data.foods ? params.data.foods.map((food: UnifiedOrderFood) => {
          return (food.foodQty > 1) ? `${food.mergedName} x ${food.foodQty}개` : food.mergedName;
        }).join(' , ') : '';
      }
    },
  ];

  /**
   * 해당 row를 사용할지의 여부
   * multiTemplateDataRows인 경우에는 true인 여러 row가 표시될 수 있고,
   * multiTemplateDataRows가 아닌 경우에는 최초의 true가 사용된다.
   * refer: https://material.angular.io/components/table/api#MatRowDef
   *
   * this가 OperatingTableComponent가 되도록 arrow function으로 정의한다.
   * 상태가 변경이 될 때마다 호출되는 것이 아니기 때문에 expandedElement의 변화 등을 감지하지 못한다.
   *
   * @param index dataSource의 index
   * @param element dataSrouce의 element
   *
   */
  whenSecondaryRow = (index: number, row: Row) => {
    const order = row.order;

    // 접수탭인 경우
    const showCookDoneOrPickup = (this.tabName === 'accepted') &&
      order.contextStatusCode > UnifiedOrderContextStatusCode.NEW && order.contextStatusCode < UnifiedOrderContextStatusCode.PICKEDUP;

    // 요청 메시지가 있는 경우
    const isOrderMsg = order.orderMsg && order.orderMsg.length > 0;

    // 확인하지 않은 취소 주문이 있는 경우
    const showConfirm = (this.tabName === 'canceled') && order.posCancelConfirmed !== true;

    // 배송 완료 버튼
    const showComplete = (this.tabName === 'pickedup') && order.deliveryType === 'DELIVERY';

    // debugLog(`orderDate = ${order.orderDate}`);
    // debugLog(`showCookDone = ${showCookDone}`);

    return showCookDoneOrPickup || isOrderMsg || showConfirm || showComplete;
  }

  /**
   * posMemo 존재에 따라 보여주기
   */
  whenThirdRow = (index: number, row: Row) => {
    const order = row.order;

    const isPosMemo = order.posMemo?.length > 0;

    return isPosMemo;
  }

  constructor(
    private simpleNoticeService: SimpleNoticeService,
    private logService: LogService,
    private dialogOrderDetailService: DialogOrderDetailService,
    public orderService: OrderService,
    private unifiedOrderService: UnifiedOrderService,
    private deliveryService: DeliveryService,
    private notificationCenterService: NotificationCenterService,
  ) { }

  ngOnInit() {
    debugLog(`ngOnInit:${this.tabName}@oprating-table.component`);

    if (this.tabName === 'accepted' || this.tabName === 'cooked') {
      this.columnsToDisplay = [
        'orderTime',
        'simpleNo',
        'contextStatusCode',
        'shopName',
        'orderVendor',
        // 'cookMinutes',
        // 'deliveryMinutes',
        'pickupTime',
        'riderName',
        'address_key',
        'paymentAmount',
        'foods'
      ];
    } else if (this.tabName === 'completed' || this.tabName === 'canceled') {
      this.columnsToDisplay = [
        'orderDate',
        'orderTime',
        'simpleNo',
        // 'contextStatusCode',
        'shopName',
        'orderVendor',
        // 'cookMinutes',
        // 'deliveryMinutes',
        'riderName',
        'address_key',
        'paymentAmount',
        'foods'
      ];
    } else {
      this.columnsToDisplay = [
        'orderTime',
        'simpleNo',
        'contextStatusCode',
        'shopName',
        'orderVendor',
        // 'cookMinutes',
        // 'deliveryMinutes',
        'riderName',
        'address_key',
        'paymentAmount',
        'foods'
      ];
    }
    // debugLog(`ngOnInit. tabName = ${this.tabName}`);

    // 공지 존재 여부를 확인해서 높이를 계산할 때 사용한다.
    this.simpleNoticeSubscription = this.simpleNoticeService.latestSimpleNoticesSubject.subscribe(docs => {
      this.existSimpleNotice = docs.length > 0;
    });

    // atDate가 변경되면 다시 observe() 한다.
    this.notificationCenterService.atDateSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe(atDate => {
        this.moreButtonText = format(addDays(new Date(), atDate - 1), 'MM월 dd일');
      });
  }

  ngAfterViewInit() {
    const merged = merge(
      fromEvent(this.preventDoubleTapRef.nativeElement, 'touchend'),
      fromEvent(this.preventDoubleTapRef.nativeElement, 'touchmove')
    );

    merged.pipe(
      takeUntil(this.destroySignal),
      pairwise(),
      filter(([prevEvent, curEvent]) => (prevEvent.type === 'touchend' && curEvent.type === 'touchend')),
      map(([_, curEvent]) => curEvent)
    ).subscribe(e => {
      e.preventDefault();
      (e.target as HTMLDivElement).click();
    });
  }

  ngOnDestroy() {
    if (this.simpleNoticeSubscription) {
      this.simpleNoticeSubscription.unsubscribe();
    }

    this.destroySignal?.next(true);
    this.destroySignal?.unsubscribe();
    this.destroySignal = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (changes.orders) {
      // debugLog(`OperatingTableComponent::${this.tabName}: orders changes`);
      this.updateRows();

      // 새로운 주문이 나타나면 창을 열고 autoOpendOrders에 추가한다.
      if (this.tabName === 'new') {
        this.orders.forEach((order: UnifiedOrderDoc) => {
          if (!autoOpenedOrders?.has(order._id)) {
            autoOpenedOrders.add(order._id);
            if (this.unifiedOrderService.openedOrder === undefined && testSound.show === false && canOpenOrder.canOpen) {
              this.openDialogOrderDetail(order, true);
            }
          }
        });
      }
    }
  }

  /**
   * orders가 변경되면 columnDefs의 value를 업데이트한다.
   */
  updateRows() {
    const newRows: Row[] = [];
    for (const order of this.orders) {
      const row: Partial<Row> = {
        order
      };
      for (const columnDef of this.columnDefs) {
        const field = columnDef.field;
        if (this.columnsToDisplay.includes(field)) {
          // debugLog(`field = ${field}`);
          row[field] = this.value(columnDef, order);
        }
      }
      newRows.push(row as Row);
    }
    this.rows = newRows;
  }

  /**
   * valuGetter를 거친다.
   *
   * @param column 컬럼 정의할 때 valueGetter, valueFormatter, refData를 설정할 수 있다.
   * @param element dataSource 특정 element
   */
  value(column: ColumnDef, element: any) {
    let v = element[column.orderField ?? column.field];

    if (column.valueGetter) {
      v = column.valueGetter({ data: element });
    }

    if (column.valueFormatter) {
      // agGrid 형식과 맞추기 위해서 value 필드의 값으로 전달한다.
      return column.valueFormatter({ value: v });
    } else if (column.refData) {
      return column?.refData?.[v] ?? '';
    }

    return v;
  }

  /**
   * order._id를 키로 삼았을 경우에는
   * pickupTime이 변경되는 경우에 sub component는 app-timer가 변경이 되지 않은 문제가 있었다.
   * + 취소 탭에서 변경하는 posCancelConfirmed를 감지하기 위해 내용추가
   */
  trackBy(index: number, row: Row) {
    // debugLog(order.orderNo);
    const trackValue = row.order._id + String(row.pickupTime) + String(row.order.posMemo) + (row.order.posCancelConfirmed ? 'confirmed' : 'unconfirmed');
    // debugLog(`trackBy ${trackValue}`);
    return trackValue;
  }

  /**
   * 내부적인 상태
   */
  public async commonAction_COOK(order: UnifiedOrderDocUI, event: Event) {
    if (order._ui?.buttonPressed === true) {
      return;
    }

    this.logService.logOrder(order, `operating-table에서 '조리완료'를 클릭`);
    event.stopPropagation();

    order._ui = { ...order._ui, buttonPressed: true };

    await this.orderService.cook(order);
  }

  /**
   * 픽업 눌렀을 때의 상태 변경
   * 포장이나 매장식사의 경우에는 바로 완료 처리한다.
   */
  public async commonAction_PICKUP(order: UnifiedOrderDocUI, event: Event) {
    if (order._ui?.buttonPressed === true) {
      return;
    }

    this.logService.logOrder(order, `operating-table에서 '픽업확인'를 클릭`);
    event.stopPropagation();

    order._ui = { ...order._ui, buttonPressed: true };

    await this.orderService.pickup(order);
  }

  /**
   * 배송 완료 처리
   */
  public async commonAction_COMPLETE(order: UnifiedOrderDocUI, event: Event) {
    if (order._ui?.buttonPressed === true) {
      return;
    }

    this.logService.logOrder(order, `operating-table에서 '배송완료'를 클릭`);
    event.stopPropagation();

    order._ui = { ...order._ui, buttonPressed: true };

    const difference = differenceInMinutes(new Date(), parseISO(order.orderDate));
    if (difference <= 30) {
      const swalResult = await openSwalDeliveryFinishCheck(difference, order.orderVendor === 'baemin' && order.deliveryType === 'DELIVERY');

      if (!swalResult.isConfirmed) {
        order._ui = { ...order._ui, buttonPressed: false };
        return;
      }
    }

    await this.orderService.complete(order);
  }

  /**
   * 취소된 주문 확인
   */
  public async confirmCanceledOrder(order: UnifiedOrderDoc, event: Event) {
    this.logService.logOrder(order, `operating-table에서 '취소 확인'를 클릭`);
    event.stopPropagation();

    await this.orderService.updatePosCancelConfirmed(order);
  }

  openDialogOrderDetail(order: UnifiedOrderDoc, isAutoOpen = false) {
    this.unifiedOrderService.openedOrder = order._id;
    this.dialogOrderDetailService.openDialogOrderDetail(order._id, isAutoOpen);
  }

  /**
   * 완료 탭에서만 노출된다.
   */
  public moreOrder() {
    if (this.atDate <= -7) {
      return;
    }
    this.atDate -= 1;

    this.logService.logRoom(`내역 더 보기(${this.atDate}) 클릭`);
  }
}
