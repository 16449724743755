<div class="order-detail-container">
  <app-full-screen-dialog-header [title]="'주문 확인'" (closed)="onClose()"></app-full-screen-dialog-header>

  <div class="order-detail-content" *ngIf="order">
    <div class="order-detail">
      <div>
        <div *ngIf="order.contextStatusCode === 80" class="top-order-status">
          취소된 주문입니다! {{ cancelReason }}
        </div>
        <div *ngIf="order.orderVendor === 'yogiyo' && order.yogiyo?.status === 'retry_processing'" class="top-order-status">
          '전화로 주문 전달 중'입니다. 명령을 실행할 수 없습니다.
        </div>
        <div *ngIf="order.contextStatusCode < 80" class="order-status-process">
          <div class="status" *ngFor="let stauts of processStatus">
            <div class="wrapper"
              [ngClass]="order.contextStatusCode > stauts ? 'wrapper-after-active' : 'wrapper-after-none-active'">
              <div class="status-spot"
                [ngClass]="order.contextStatusCode === stauts ? 'spot-highlight' : order.contextStatusCode < stauts ? 'spot-none-active' : 'spot-active'">
              </div>
            </div>
            <div class="status-text" [ngClass]="order.contextStatusCode !== stauts ? 'status-text-blur' : ''">{{
              unifiedOrderStatusMappings[stauts] }}</div>
          </div>
        </div>
      </div>
      <div class="order-summary section">
        <div class="section-title">주문 정보</div>
        <table>
          <tr class="order-status">
            <td colspan="2">
              <span>{{ orderDate}}</span>
              <span>{{ order.simpleNo }}</span>
              <span>{{ orderVendor }}</span>
              <span>{{ order.shopName }}</span>
            </td>
          </tr>
          <tr class="payment ">
            <td class="title">결제 금액</td>
            <td class="content">{{ paymentAmount | number }}원 {{ order.paymentMethod !== 'NA' ? '(' +
              order.paymentMethod + ')' : '' }}</td>
          </tr>
          <tr *ngIf="orderAddress">
            <td class="title">고객 주소</td>
            <td class="content" [class.under-line]="order.preCheckDelivery?.address_location || order.preCheckDelivery?.address_detail">
              <span class="accent-text" [class.under-line]="order.preCheckDelivery?.address_dongH" *ngIf="orderAddressDongH">({{ orderAddressDongH }})</span>
              {{ orderAddress }}
            </td>
          </tr>
          <tr class="order-address-road" *ngIf="orderAddressRoad">
            <td class="title"></td>
            <td class="content"><span class="address-road">도로명</span><span>{{ orderAddressRoad }}</span> <span *ngIf="distance" class="address-road"> 거리</span><span *ngIf="distance">{{ distance | number }}m</span></td>
          </tr>
          <tr class="user-tel" *ngIf="orderService.showUserTel(order)">
            <td class="title">고객 전화</td>
            <td class="content" *ngIf="!orderService.onDemandUserTel(order)">{{ normalizeTel(order.userTel) }}</td>
            <td class="content" *ngIf="orderService.onDemandUserTel(order) && customerSafeNumber.text">{{ customerSafeNumber.text }}</td>
            <td class="content" *ngIf="orderService.onDemandUserTel(order) && !customerSafeNumber.text">
              <button class="get-safe-number" mat-stroked-button (click)="getSafeNumber('customer')">번호확인</button>
            </td>
          </tr>
          <tr *ngIf="order.orderMsg">
            <td class="title">고객 요청 사항</td>
            <td class="content" [class.under-line]="order.preCheckOrder?.orderMsg || order.preCheckDelivery?.orderMsg">{{ order.orderMsg }}</td>
          </tr>
          <tr *ngIf="order.contextStatusCode >= 15 && order.cookMinutes">
            <td class="title">조리 시간</td>
            <td class="content">{{ order.cookMinutes }}분</td>
          </tr>
          <tr
            *ngIf="orderService.showDeliveryMinutes(order) && order.contextStatusCode >= 20 && order.deliveryMinutes">
            <td class="title">예상 배달 시간</td>
            <td class="content">{{ order.deliveryMinutes }}분 (고객에게 알린 배달 시간)</td>
          </tr>
          <tr *ngIf="onPICKEDUP">
            <td class="title">픽업 시간</td>
            <td class="content">{{ onPICKEDUP }}분 (주문 시각[{{ orderDate }}]부터 픽업까지 걸린 시간)</td>
          </tr>
          <tr *ngIf="onCOMPLETED">
            <td class="title">배송 완료 시간</td>
            <td class="content">{{ onCOMPLETED }}분 (주문 시각[{{ orderDate }}]부터 완료까지 걸린 시간)</td>
          </tr>
          <tr>
            <td class="title">주문 이력</td>
            <!-- 주문이력이 있음 -->
            <td class="content" *ngIf="orderHistorySummary.countOrders > 0 || orderHistorySummary.countCanceledOrders > 0">
              <span class="accent-text">{{orderHistorySummary.countOrders | number}}건</span>
              <span *ngIf="orderHistorySummary.countCanceledOrders > 0"> +</span>
              <span *ngIf="orderHistorySummary.countCanceledOrders > 0"> 취소{{orderHistorySummary.countCanceledOrders | number}}건</span>
              <span> ({{orderHistorySummary.totalAmount | number}}원)</span>
              <button class="open-order-history" mat-stroked-button (click)="openDialogOrderHistory()">상세보기</button>
            </td>
            <!-- 주문이력이 없음 -->
            <td class="content" *ngIf="orderHistorySummary.countOrders === 0 && orderHistorySummary.countCanceledOrders === 0">신규</td>
            <!-- 주문이력 확인이 불가능 -->
            <td class="content no-available" *ngIf="orderHistorySummary.countOrders < 0">
              {{ availableOrderHistory ? '조회 중...' : '이력 확인이 불가한 주문입니다.' }}
            </td>
          </tr>
          <tr *ngIf="orderNo">
            <td class="title">주문 번호</td>
            <td class="content">{{ orderNo }}</td>
          </tr>
        </table>
      </div>

      <div class="pos-memo section" *ngIf="order.posMemo">
        <div class="section-title">운영팀 메모</div>
        <div class="pos-memo-content">{{ order.posMemo }}</div>
      </div>

      <!-- 요기요 익스프레스는 조리시간 정보를 전달하지 않기 때문에 보여주지 않는다. -->
      <div class="select-minutes section" *ngIf="order.deliveryType !== 'YOGIYO' && order.contextStatusCode === 10 ">
        <div *ngIf="order.deliveryType === 'DELIVERY'" class="delivery-fee">
          <span class="title">{{ mainDeliveryVendorKR }} 배달비</span>
          <!-- 위치가 살짝 바뀌는 것을 방지하기 위해서 visibility를 이용해서 일단 자리는 차지한다. -->
          <span [class.hide]="deliveryInfo.totalFee === 0" *ngIf="deliveryInfo.extraAvailable" [class.overPrice]="deliveryInfo.distance > 1500">기본 {{ deliveryInfo.baseFee | number }}원 + 할증 {{ (deliveryInfo.totalFee - deliveryInfo.baseFee) | number }}원
          <span class="vat">(VAT 별도)</span> / <span [class.under-line]="order.preCheckDelivery?.address_key" >{{ deliveryInfo.distance | number:'1.0-0' }}m</span></span>
          <span [class.hide]="deliveryInfo.totalFee === 0" *ngIf="!deliveryInfo.extraAvailable" [class.overPrice]="deliveryInfo.distance > 1500">전체 {{ deliveryInfo.totalFee | number }}원
          <span class="vat">(할증 포함, VAT 별도)</span> / <span [class.under-line]="order.preCheckDelivery?.address_key" >{{ deliveryInfo.distance | number:'1.0-0' }}m</span></span>
        </div>
        <!-- [order]는 가장 앞에 위치해야 한다. -->
        <app-select-minute
          [order]="order"
          [showItems]="order.deliveryType !== 'DELIVERY' ? ['cookMinutes'] : ['requestedPickupMinutes', 'deliveryMinutes']"
          [requestDeliveryInformationPickups]="deliveryInfo.pickups"
          (changeCookMinutes)="onChangeCookMinutes($event)"
          (changeRequestedPickupMinutes)="onChangeRequestedPickupMinutes($event)"
          (changeDeliveryMinutes)="onChangeDeliveryMinutes($event)"></app-select-minute>
      </div>

      <div class="section" *ngIf="hasDeliveryVendors && order.orderStatusCode !== 10 && order.deliveryType === 'DELIVERY' && relatedDeliveries?.length === 0">
        현재 접수된 배차가 없습니다. 배차를 추가하려면 '배차 추가' 버튼을 눌러주세요.
      </div>
      <div class="delivery-info section"
        *ngIf="relatedDeliveries?.length > 0">
        <div class="section-title">배차 정보</div>
        <div class='header'>
          <div>상태</div>
          <div>배달대행</div>
          <div>요청/예상픽업</div>
          <div>라이더</div>
          <div>거리</div>
          <div>배달비</div>
          <div>결제</div>
          <div></div>
        </div>
        <div class="delivery-row" *ngFor="let delivery of relatedDeliveries">
          <div class="main-row">
            <div>{{ deliveryStatusCodeMappings[delivery.deliveryStatusCode] }}</div>
            <div>{{ deliveryVendorMappings[delivery.deliveryVendor] }}</div>
            <div>{{ delivery._ui?.pickupTime }}</div>
            <div>{{ delivery._ui?.riderName }}
              <span *ngIf="!deliveryService.onDemandRiderTel(delivery) && delivery._ui?.riderTel"><br />({{ delivery._ui.riderTel }})</span>
              <span *ngIf="deliveryService.onDemandRiderTel(delivery) && !riderSafeNumber.text && delivery.deliveryStatusCode >= 20 && delivery.deliveryStatusCode < 50">
                <br />
                <button class="get-safe-number" mat-stroked-button (click)="getSafeNumber('rider')">번호확인</button>
              </span>
              <span *ngIf="deliveryService.onDemandRiderTel(delivery) && riderSafeNumber.text"><br />({{ riderSafeNumber.text }})</span>
            </div>
            <div *ngIf="delivery.distance === -1">모름</div>
            <div *ngIf="delivery.distance !== -1">{{ delivery.distance ? delivery.distance : distance ? distance : 0 | number }}m</div>
            <div *ngIf="delivery.totalFee === -1">모름</div>
            <div *ngIf="delivery.totalFee !== -1">{{ delivery.totalFee | number }}원</div>
            <div *ngIf="delivery.initialPaymentMethod === 'NA'">{{ (delivery.finalPaymentAmount ? delivery.finalPaymentAmount : delivery.initialPaymentAmount) | number }}원
            </div>
            <div *ngIf="delivery.initialPaymentMethod !== 'NA'">{{ (delivery.finalPaymentAmount ? delivery.finalPaymentAmount : delivery.initialPaymentAmount) | number }}원
              <br />({{ delivery.finalPaymentMethod ? delivery.finalPaymentMethod : delivery.initialPaymentMethod }})
            </div>
            <div>
              <button *ngIf="order.deliveryType === 'DELIVERY'" mat-stroked-button (click)="openDialogEditDelivery(delivery)">배차 변경</button>
            </div>
          </div>
          <!--
            요청/예상픽업에서는 초를 무시한 비교를 하고 있다. 그래서 여기서도 초를 무시한 비교를 한다. pickupTime의 길이가 6이상인 경우는 초도 다르다는 뜻이다. 좋은 방법은 아니지만 코드를 거의 고치지 않는 방법이므로 일단 사용한다.
            바로고의 경우에는 pikcupTime의 길이가 6보가 큰 경우가 barogoPickupExpectedAt 때문인 경우도 있기 때문에 조건에 구멍이 있을 수 있지만,
            이 조건이 들어간 것은 주로 배민1의 경우 때문이므로 바로고의 경우에 문제될 일을 거의 없어 보인다.
          -->
          <div class="sub-row" *ngIf="delivery._ui?.simpleAdjustedPickupTime && delivery._ui?.pickupTime?.length > 6">
            <span class="title">조정픽업시각:</span>
            <span>{{ delivery._ui?.simpleAdjustedPickupTime }} {{ delivery._ui?.adjustedPickupTimeNum > 1 ? '(' +
              delivery._ui?.adjustedPickupTimeNum + '회 변경)' : '' }}</span>
          </div>
          <div class="sub-row" *ngIf="delivery.extraFees?.length > 0">
            <span class="title">할증 요금:</span>
            <span>
              <span *ngFor="let extraFee of delivery.extraFees; let index = index;" class="extraFees">
                {{ extraFee.amount | number }}원{{ extraFee.title ? '(' + (deliveryExtraFeeTitleMappings[extraFee.title] ? deliveryExtraFeeTitleMappings[extraFee.title] : extraFee.title) + ')' : '' }}
                <span *ngIf="index < delivery.extraFees.length - 1"> + </span>
              </span>
              <span class="vat">(배달비에 포함, VAT별도)</span>
            </span>
          </div>
          <div class="sub-row" *ngIf="delivery.cashAmount > 0 && delivery.cardAmount >0">
            <span class="title">복합 결제:</span>
            <span>현금 {{ delivery.cashAmount | number }}원 + 카드 {{ delivery.cardAmount | number }}원</span>
          </div>
          <div class="sub-row" *ngIf="delivery?._ui?.userTel">
            <span class="title">고객 전화:</span>
            <span>{{ delivery?._ui?.userTel }}</span>
          </div>
          <div class="sub-row" *ngIf="delivery?._ui?.orderAddress">
            <span class="title">배달지 주소:</span>
            <span>{{ delivery?._ui?.orderAddress }}</span>
          </div>
          <div class="sub-row" *ngIf="delivery?._ui?.orderMsg">
            <span class="title">고객 요청사항:</span>
            <span>{{ delivery?._ui?.orderMsg }}</span>
          </div>
        </div>
      </div>

      <div class="buttons">
        <button [disableRipple]="true" *ngIf="canCancel()" mat-flat-button [disabled]="buttonPressed.CANCEL"
          class="cancel-button" (click)="onOrderAction('CANCEL')">주문 {{ order.orderStatusCode === 10 ? '거부' : '취소'
          }}...</button>

        <button [disableRipple]="true" mat-flat-button class="order-modify-buttton"
          *ngIf="order.createdBy === 'manual'"
          (click)="openDialogCreateOrder()">주문 변경</button>

        <button [disableRipple]="true" mat-flat-button [disabled]="buttonPressed.REQUEST_DELIVERY"
          *ngIf="order.deliveryType === 'DELIVERY' && hasDeliveryVendors && order.contextStatusCode !== 10"
          (click)="openDialogRequestDelivery()">배차 추가</button>

        <button [disableRipple]="true" *ngIf="order.contextStatusCode === 10" mat-flat-button
          class="order-accept-with-delivery-button" [disabled]="buttonPressed.ACCEPT"
          (click)="onOrderAction('ACCEPT', order.deliveryType === 'DELIVERY' && hasDeliveryVendors)">접수{{
          order.deliveryType === 'DELIVERY' && hasDeliveryVendors ? ' 및 배차' : ''}}</button>

        <button [disableRipple]="true" *ngIf="order.contextStatusCode === 10 && (order.deliveryType === 'DELIVERY' && hasDeliveryVendors)"
          mat-flat-button class="order-accept-button" [disabled]="buttonPressed.ACCEPT"
          (click)="onOrderAction('ACCEPT', false)">접수만</button>

        <ng-container *ngIf="order.contextStatusCode > 15 && order.contextStatusCode < 60">
          <!-- anchor element -->
          <!-- 배차 상태가 이미 픽업 이후라면 '픽업확인' 버튼을 바로 노출한다. -->
          <ng-container *ngIf="relatedDeliveries[0]?.deliveryStatusCode >= 30 || order.contextStatusCode >= 40; then pickUpBlock; else cookBlock"></ng-container>

          <!-- template의 내용이 anchor element 자식으로 들어간다. 그래서 ng-container를 anchor element로 만들었다. -->
          <ng-template #cookBlock>
            <button [disableRipple]="true" mat-flat-button [disabled]="buttonPressed.COOK || !orderService.canCook(order)" class="accent-button"
              (click)="onOrderAction('COOK')">{{ orderService.canCook(order) ? '조리 완료' : '조리요청대기' }}</button>
          </ng-template>

          <ng-template #pickUpBlock>
            <button [disableRipple]="true" mat-flat-button [disabled]="buttonPressed.PICKUP"
              (click)="onOrderAction('PICKUP')">픽업 확인</button>
          </ng-template>
        </ng-container>

        <button [disableRipple]="true" *ngIf="(order.deliveryType === 'DELIVERY' || order.deliveryType === 'YOGIYO') && 20 <= order.contextStatusCode && order.contextStatusCode < 70"
          mat-flat-button [disabled]="buttonPressed.COMPLETE" class="complete-button" (click)="onOrderAction('COMPLETE')">배송 완료</button>

        <!-- 업소에서 취소 주문의 존재를 인지했음을 알릴때 사용한다. -->
        <button [disableRipple]="true" *ngIf="order.contextStatusCode === 80 && order.posCancelConfirmed !== true"
          class="accent-button" mat-flat-button (click)="confirmCanceledOrder()">취소 확인</button>
      </div>
    </div>

    <div class="menu-summary">
      <div class="section">
        <app-menu [order]="order"></app-menu>
      </div>
      <div class="buttons">
        <button [disableRipple]="true" mat-stroked-button (click)="onPrint()">인쇄...</button>
      </div>
    </div>
  </div>

</div>
