<div class="section-title">고객 전화번호</div>
<div class="user-tel">
  <div *ngIf="userTel !== ''">{{ userTel }}</div>
  <div *ngIf="userTel === ''" >
    <span class="no-data-text">'전화번호 입력' 버튼을 눌러 전화번호를 입력해 주세요.</span>
  </div>
  <button mat-stroked-button (click)="openDialogTel()">전화번호 {{ userTel === '' ? '입력' : '변경' }}</button>
</div>
<div class="section-title">고객 주소</div>
<div class="delivery-address">
  <div *ngIf="displayAddress.addressKey !== ''" >
    <span>{{ displayAddress.addressKey }}</span>
    <span><span class="prefixMsg">{{ prefixMsg }}</span> {{ displayAddress.addressDetail}}</span>
  </div>
  <div *ngIf="displayAddress.addressKey === ''" >
    <span class="no-data-text">'주소 입력' 버튼을 눌러 주소를 입력해 주세요.</span>
  </div>
  <button mat-stroked-button (click)="openDialogSearchAddress()">주소 {{ displayAddress.addressKey === '' ? '입력' : '변경' }}</button>
</div>
<div *ngIf="displayAddress.addressRoad !== ''" class="delivery-address-road">
  <span class="address-road">도로명</span>
  <span>{{ displayAddress.addressRoad }}</span>
</div>
<div class="section-title">고객 요청사항</div>
<input class="input" matInput [formControl]="formControlOrdMsg" required>