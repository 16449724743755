/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { RoomDoc, SiteDoc } from '../../schema/3/schema';

import { trimOrganization } from '../../core/1/util';
import { RoomService } from '../../core/1/room.service';
import { SiteService } from '../../core/1/site.service';
import { UserService } from '../../core/3/user.service';
import { FirebaseManagerService } from '../../core/5/firebase-manager.service';

@Component({
  selector: 'app-swal-room-select',
  templateUrl: './swal-room-select.component.html',
  styleUrls: ['./swal-room-select.component.scss']
})
export class SwalRoomSelectComponent implements OnInit, OnDestroy {
  @ViewChild('roomSelect') private roomSelect: SwalComponent;
  @Output() confirm = new EventEmitter<string>();

  public siteControl = new FormControl();
  public roomControl = new FormControl();

  public sites: { siteName: string, siteKey: string }[] = [];
  public rooms: {
    roomKey: string;
    roomName: string;
    shopName: string;
    live: string;
  }[] = [];

  /** 캐시 역할을 한다. */
  private roomDocs: {
    [siteKey: string]: {
      roomKey: string;
      roomName: string;
      shopName: string;
      live: string;
    }[];
  } = {};

  private destroySignal = new Subject<boolean>();

  constructor(
    private siteService: SiteService,
    private roomService: RoomService,
    private userService: UserService,
    private firebaseManagerService: FirebaseManagerService,
  ) { }

  ngOnInit(): void {
    this.observeSite();
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  show() {
    this.roomSelect.fire();
  }

  onConfirm() {
    this.confirm.emit(this.roomControl.value);
  }

  public async loadSites() {
    if (this.sites.length === 0) {
      const sites = await this.firebaseManagerService.getDocsArrayWithWhere<SiteDoc>('site', [
        ['organization', '==', this.userService.user.organization]
      ]);
      this.sites = sites
        .sort((a, b) => a.siteNo - b.siteNo)
        .map(site => ({
          siteKey: site._id,
          siteName: trimOrganization(site.siteName),
        }));
    }

    this.siteControl.setValue(this.siteService.siteDoc._id);
    this.roomControl.setValue(this.roomService.room._id);
  }

  private observeSite() {
    // 변경된 site에 따른 room 목록 받아오기
    this.siteControl.valueChanges.pipe(takeUntil(this.destroySignal)).subscribe(async siteKey => {
      if (siteKey) {
        if (this.roomDocs[siteKey] === undefined) {
          const roomDocs = await this.firebaseManagerService.getDocsArrayWithWhere<RoomDoc>('room', [['site', '==', siteKey]]);
          this.roomDocs[siteKey] = roomDocs
            .filter(roomDoc => roomDoc.room.slice(-2) !== '00')
            .sort((a, b) => a.room < b.room ? -1 : 1)
            .map(roomDoc => ({
              live: roomDoc.live ? '🟢' : '❌',
              roomName: trimOrganization(roomDoc.name),
              roomKey: roomDoc.room,
              shopName: roomDoc.shopName
            }));
        }

        this.rooms = this.roomDocs[siteKey];
        // 현재 사이트인 경우에는 현재 호실을 초기값으로 보여준다.
        this.roomControl.setValue(this.siteService.siteDoc._id === siteKey ? this.roomService.room._id : this.rooms[0].roomKey);
      }
    });
  }
}
