/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
//
// 배민1, 쿠팡이츠, 요기요 익스프레스인 경우
//
import { Injectable } from '@angular/core';

import { UnifiedDeliveryDoc } from '../../schema/3/schema';
import { DeliveryProtocol } from '../../schema/5/schema-protocol';

@Injectable({
  providedIn: 'root'
})
export class IntegratedDeliveryService implements DeliveryProtocol {
  constructor(
  ) { }

  public canCancel(delivery: UnifiedDeliveryDoc): boolean {
    return false;
  }
}
