<h2 mat-h2>주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }}</h2>
<div class="cancel-container">
  <form class="section cancel-reason" [formGroup]="cancelOrderForm">
    <div class="section-title">취소 사유</div>
    <mat-radio-group formControlName="cancelReasonCode" class="cancelReasonCode">
      <ng-container *ngFor="let cancelDesc of cancelDescs">
        <mat-radio-button [disableRipple]="true" [value]="cancelDesc.cancelCode">{{ cancelDesc.cancelReason }}
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>

    <div class="buttons">
      <button [disableRipple]="true" mat-stroked-button (click)="onCancel()">닫기</button>
      <button [disableRipple]="true" mat-flat-button (click)="onSubmit()" type="submit"
        [disabled]="cancelOrderForm.invalid">주문 {{ order.orderStatusCode === 10 ? '거부' : '취소' }}</button>
    </div>
  </form>
</div>