/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UnifiedOrder } from '../../schema/3/schema';
import { CancelDesc } from '../../schema/5/schema-protocol';

import { debugLog } from '../../core/1/common';
import { LogService } from '../../core/4/log.service';
import { OrderService } from '../../core/9/order.service';

import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';

@Component({
  selector: 'app-dialog-cancel-order',
  templateUrl: './dialog-cancel-order.component.html',
  styleUrls: ['./dialog-cancel-order.component.scss']
})
export class DialogCancelOrderComponent implements OnInit {
  public order: UnifiedOrder;
  public cancelOrderForm: FormGroup;
  public cancelDescs: CancelDesc[] = [];

  private isCancelButtonPressed = false;

  constructor(
    public dialogRef: MatDialogRef<DialogCancelOrderComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: { order: UnifiedOrder },
    private fb: FormBuilder,
    private logService: LogService,
    private orderService: OrderService,
    private dialogSpinnerService: DialogSpinnerService
  ) { }

  ngOnInit() {
    this.order = this.data.order;

    const order = this.order;
    this.cancelDescs = this.orderService.cancelDescs(order);

    if (!this.cancelDescs) {
      this.logService.logOrder(this.order, 'cancelDesc가 없습니다.', 'error');
    }

    this.cancelOrderForm = this.fb.group({
      cancelReasonCode: this.cancelDescs[0].cancelCode
    });
  }

  onCancel() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  async onSubmit() {
    const spinnerDailogRef = this.dialogSpinnerService.openSpinnerDialog('취소 중...');
    const cancelReasonCode = this.cancelOrderForm.value.cancelReasonCode;
    debugLog(`cancelReasonCode = ${cancelReasonCode}`);
    this.logService.logOrder(this.data.order, `취소 대화상자에서 취소(${cancelReasonCode})를 클릭했다.`);

    if (this.isCancelButtonPressed === false) {
      this.isCancelButtonPressed = true; // 중복 클릭 방지

      const cancelResult = await this.orderService.cancel(this.order, cancelReasonCode);
      spinnerDailogRef.close();
      if (cancelResult) {
        this.dialogRef.close(true);
      }
    }
  }
}
