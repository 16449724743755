/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPrinterComponent } from './dialog-printer.component';

@Injectable({
  providedIn: 'root'
})
export class DialogPrinterService {
  constructor(
    private dialog: MatDialog
  ) { }

  public openDialog(timeout?: number) {
    const dialogRef = this.dialog.open(DialogPrinterComponent);

    if (timeout) {
      setTimeout(() => {
        dialogRef.close();
      }, timeout);
    }
  }
}
