import { Injectable } from '@angular/core';

import { UnifiedOrderDoc } from '../../schema/3/schema';

import { RoomService } from '../1/room.service';
import { UtilService } from '../2/util.service';
import { UserService } from '../3/user.service';
import { LogService } from '../4/log.service';
import { MessageService } from '../7/message.service';

import { DialogSpinnerService } from '../../shared/dialog-spinner/dialog-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(
    private userService: UserService,
    private logService: LogService,
    private roomService: RoomService,
    private messageService: MessageService,
    private utilService: UtilService,
    private dialogSpinnerService: DialogSpinnerService,
  ) { }

  /**
   * printer-agent에게 출력 메시지를 전송한다.
   */
  public async print(order: UnifiedOrderDoc, what: 'cook' | 'customer' | 'all' | 'default') {
    this.logService.logOrder(order, `주문 인쇄 시작. what=${what}`);

    if (this.userService.user.printers === undefined || this.userService.user.printers.length < 1) {
      this.logService.logOrder(order, `등록된 프린터가 없어서 인쇄 불가`, 'error', true);
      return;
    }
    if (this.userService.user.printers.length > 1) {
      this.logService.logOrder(order, `등록된 ${this.userService.user.printers.length}개 중 최초 1개의 프린터로만 출력합니다.`, 'warn');
    }

    // 1. printDoc을 찾는다.
    // user.printers[0]에 설정된 printDoc에서 찾는다.
    const printer = this.userService.printer;
    if (printer === undefined) {
      this.logService.logOrder(order, `printer에서 프린터 정보(${this.userService.user.printers?.[0]})를 찾을 수 없습니다.`, 'error', true);
      return;
    }
    if (['proxy', 'direct'].includes(printer.connectionType) === false) {
      this.logService.logOrder(order, `잘못된 connectionType(${printer.connectionType})`, 'error', true);
      return;
    }

    // 2. 빙글빙글 보여준다.
    const spinner = this.dialogSpinnerService.openSpinnerDialog('인쇄 중');

    // 3. 프린터에 출력한다.
    try {
      const printCookOption = this.roomService.room.printCookOption ?? 'double';
      const response = await this.messageService.requestPrintOrder(order._id, what, printCookOption);
      if (response.result === 'error') {
        this.logService.logOrder(order, `주문 인쇄 실패 : ${response.reason}\n(좌상단) 메뉴 > '빌프린터 장애 매뉴얼'을 참고하세요.`, 'error', true);
      } else {
        this.logService.logOrder(order, `주문 인쇄 성공(${response.result})`);
      }
    } catch (error) {
      if (error.name === 'TimeoutError') {
        this.logService.logOrder(order, `주문 인쇄 응답 시간 초과\n(좌상단) 메뉴 > '빌프린터 장애 매뉴얼'을 참고하세요.`, 'error', true);
      } else {
        this.logService.logOrder(order, `주문 인쇄 예외: ${error.message}\n(좌상단) 메뉴 > '빌프린터 장애 매뉴얼'을 참고하세요.`, 'error', true);
      }
    }

    // 4. spinner 제거
    spinner?.close(false);
  }

  /**
   * 현재 설정에 따라서 출력 여부 및 내용을 결정한다.
   *
   * - '접수' 및 '접수 및 배차'를 누르면 호출된다.
   */
  public async defaultPrint(order: UnifiedOrderDoc) {
    const printer = this.userService.printer;
    if (printer) {
      if (printer.printFormat === 'text') {
        if (this.roomService.room?.printOption === 'noPrint') {
          this.utilService.toastrInfo('설정에 따라 주문을 인쇄하지 않습니다.');
        } else {
          await this.print(order, 'default');
        }
      } else {
        this.logService.logOrder(order, `지원하지 않는 프린터 포맷 ${printer.printFormat}입니다. 관리자에게 알려주세요.`, 'error', true);
      }
    } else {
      this.logService.logOrder(order, '프린터 설정을 못 찾았습니다. 관리자에게 알려주세요.', 'error', true);
    }
  }

  public async printStat(ignoreZeroOption = false) {
    const option = ignoreZeroOption ? '요약 판매집계 인쇄' : '상세 판매집계 인쇄';
    this.logService.logRoom(`${option} 시작`);

    const spinner = this.dialogSpinnerService.openSpinnerDialog('인쇄 중');

    try {
      const response = await this.messageService.requestPrintStat(ignoreZeroOption);
      if (response.result === 'error') {
        this.logService.logRoomWithToastrError(`${option} 실패 : ${response.reason}`);
      } else {
        this.logService.logRoom(`${option} 성공`);
      }
    } catch (error) {
      this.logService.logRoomWithToastrCatch(`${option} 예외`, error);
    }

    spinner?.close();
  }
}
