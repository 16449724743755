/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { differenceInMinutes, format, parseISO } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { AngularFireFunctions } from '@angular/fire/functions';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode, UnifiedOrderContextStatusCode, UnifiedOrderDoc } from '../../schema/3/schema';
import { CallInputOrderHistory, CallInputRequestDeliveryInformation, CallOutputOrderHistory, CallOutputRequestDeliveryInformation, OrderHistory } from '../../schema/4/schema-functions-call';
import { UnifiedDeliveryDocUI } from '../../schema/4/schema-ui';

import { unifiedOrderStatusMappings, deliveryVendorMappings, deliveryStatusCodeMappings, deliveryExtraFeeTitleMappings } from '../../core/1/string-map';
import { debugLog, lineClampSwalContent } from '../../core/1/common';
import { openSwalDeliveryFinishCheck } from '../../core/1/swal-util';
import { dateFormatter } from '../../core/1/ag-util';
import { diffTime, normalizeTel } from '../../core/1/util';
import { UnifiedDeliveryService } from '../../core/1/unified-delivery.service';
import { RoomService } from '../../core/1/room.service';
import { UtilService } from '../../core/2/util.service';
import { UserService } from '../../core/3/user.service';
import { LogService } from '../../core/4/log.service';
import { UnifiedOrderService } from '../../core/5/unified-order.service';
import { DeliveryUtilService } from '../../core/5/delivery-util.service';
import { DeliveryService } from '../../core/5/delivery.service';
import { PrintService } from '../../core/8/print.service';
import { OrderService } from '../../core/9/order.service';

import { DeliveryMinutes, RequestedPickupMinutes } from '../select-minute/select-minute.component';
import { DialogPrintTextService } from '../dialog-print-text/dialog-print-text.service';
import { DialogCancelOrderService } from '../dialog-cancel-order/dialog-cancel-order.service';
import { DialogEditDeliveryService } from '../dialog-edit-delivery/dialog-edit-delivery.service';
import { DialogRequestDeliveryService } from '../dialog-request-delivery/dialog-request-delivery.service';
import { DialogCreateOrderService } from '../dialog-create-order/dialog-create-order.service';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';
import { DialogOrderHistoryService } from '../dialog-order-history/dialog-order-history.service';
import { DialogOrderHistoryComponent } from '../dialog-order-history/dialog-order-history.component';

@Component({
  selector: 'app-dialog-order-detail',
  templateUrl: './dialog-order-detail.component.html',
  styleUrls: ['./dialog-order-detail.component.scss']
})
export class DialogOrderDetailComponent implements OnInit, OnDestroy {
  public order: UnifiedOrderDoc;
  private destroySignal = new Subject<boolean>();

  public contextStatusCode = '';
  public orderDate = '';
  public shopName = '';
  public orderVendor = '';
  public orderAddress = '';
  public paymentAmount = 0;
  public cancelReason = '';
  public orderAddressDongH = '';
  public orderAddressRoad = '';
  public distance = 0;
  public customerSafeNumber = {
    text: '',
  };
  public riderSafeNumber = {
    text: '',
  };

  public mainDeliveryVendorKR = '';
  public deliveryInfo: CallOutputRequestDeliveryInformation['deliveryInfo'] = {
    extraAvailable: false,
    isPossible: true,
    pickups: [],
    distance: 0,
    baseFee: 0,
    totalFee: 0
  };

  public orderNo = '';
  public normalizeTel = normalizeTel;

  public deliveryVendorMappings = deliveryVendorMappings;
  public deliveryStatusCodeMappings = deliveryStatusCodeMappings;
  public deliveryExtraFeeTitleMappings = deliveryExtraFeeTitleMappings;

  /**
   * roomDoc에 deliveryVendors가 있냐?
   */
  public hasDeliveryVendors = false;
  public orderHistorySummary = {
    countOrders: -1,
    countCanceledOrders: 0,
    totalAmount: 0
  };

  public availableOrderHistory = true;

  public unifiedOrderStatusMappings = unifiedOrderStatusMappings;

  public buttonPressed = {
    REQUEST_DELIVERY: false,
    ACCEPT: false,
    COOK: false,
    PICKUP: false,
    COMPLETE: false,
    CANCEL: false
  };

  /**
   * orderDate부터 픽업까지 걸린 시간(분)
   */
  public onPICKEDUP: number;
  /**
   * orderDate부터 완료까지 걸린 시간(분)
   *
   * time.onCOMPLETED가 있는 경우 혹은 unifiedDelivery.timeDelivered가 있는 경우
   */
  public onCOMPLETED: number;

  // 예상 배달 시간
  private recommendedDeliveryMinutes = 0; // 업주가 변경하기 이전의 값으로 추후 차이를 확인할 때 사용한다.
  public deliveryMinutes = 40;

  // app-select-minute에서 받는 값
  // 조리시간
  public cookMinutes = this.roomService.room.cookMinutes;
  // 요청 픽업 시간
  public recommendedRequestedPickupMinutes = 0; // 업주가 변경하기 이전의 값으로 추후 차이를 확인할 때 사용한다.
  public requestedPickupMinutes = 20;

  public relatedDeliveries: UnifiedDeliveryDocUI[] = [];

  public processStatus = [
    UnifiedOrderContextStatusCode.NEW,
    UnifiedOrderContextStatusCode.CEOACCEPTED,
    UnifiedOrderContextStatusCode.ACCEPTED,
    UnifiedOrderContextStatusCode.COOKED,
    UnifiedOrderContextStatusCode.PICKEDUP,
    UnifiedOrderContextStatusCode.COMPLETED
  ];

  /** 한 번만 수행하도록 한다. */
  private orderHistoriesOneTime = false;
  private orderHistories: OrderHistory[] = [];
  private dialogOrderHistoryRef: MatDialogRef<DialogOrderHistoryComponent>;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: string, isAutoOpen: boolean },
    public userService: UserService,
    public orderService: OrderService,
    public deliveryService: DeliveryService,
    private unifiedOrderService: UnifiedOrderService,
    private logService: LogService,
    private utilService: UtilService,
    private roomService: RoomService,
    private dialogPrintTextService: DialogPrintTextService,
    private unifiedDeliveryService: UnifiedDeliveryService,
    private dialogCancelOrderService: DialogCancelOrderService,
    private dialogEditDeliveryService: DialogEditDeliveryService,
    private dialogRequestDeliveryService: DialogRequestDeliveryService,
    private dialogCreateOrderService: DialogCreateOrderService,
    private dialogSpinnerService: DialogSpinnerService,
    private dialogOrderHistoryService: DialogOrderHistoryService,
    private fns: AngularFireFunctions,
    private deliveryUtilService: DeliveryUtilService,
    private printService: PrintService,
  ) { }

  ngOnInit(): void {
    debugLog(`[DialogOrderDetail] ngOnInit CALLED`);
    /** 자주 변경되는 값은 아니라서 추적을 하지는 않는다. 창을 닫고 다시 열면 된다. */
    this.hasDeliveryVendors = this.roomService.room.deliveryVendors?.length > 0;
    this.observeOrder(this.data.orderId);
  }

  ngOnDestroy() {
    this.destroySignal?.next(true);
    this.destroySignal?.unsubscribe();
    this.destroySignal = undefined;

    this.unifiedOrderService.openedOrder = undefined;
  }

  public canCancel() {
    return this.orderService.canCancel(this.order);
  }

  public async onOrderAction(action: string, withDelivery = false) {
    // 요기요는 retry_processing 상태가 되면 명령을 처리할 방법이 없다.
    if (this.order.orderVendor === 'yogiyo' && this.order.yogiyo?.status === 'retry_processing') {
      this.logService.logOrder(this.order, `주문 상세에서 ${action}(withDelivery: ${withDelivery}) 클릭했지만 '전화로 주문 전달 중' 경고만 표시`);
      Swal.fire('알림', `현재 '전화로 주문 전달 중'입니다.<br>이 상태에서는 명령을 실행할 수 없습니다.<br>전화 접수가 성공하면 '배차 추가'를 이용해서 배차할 수 있습니다.`, 'warning');
      return;
    }

    // 버튼 처리
    if (this.buttonPressed[action] === true) {
      // debugLog('이미 눌렸으니 통과');
      return;
    }
    this.logService.logOrder(this.order, `주문 상세에서 ${action}(withDelivery: ${withDelivery}) 클릭`);

    if (['ACCEPT', 'COOK', 'PICKUP', 'CANCEL', 'COMPLETE'].includes(action)) {
      this.buttonPressed[action] = true;
    }

    // CANCEL의 경우에는 대화상자에서 취소 액션을 안하고 닫거나 실패할 수 있으므로 unsubscribe를 미리 하지 않는다.
    if (['ACCEPT', 'COOK', 'PICKUP', 'COMPLETE'].includes(action)) {
      this.destroySignal?.next(true);
      this.destroySignal?.unsubscribe();
      this.destroySignal = undefined;
    }

    switch (action) {
      case 'ACCEPT':
        // 접수만: preCheckOrder 확인
        // 접수 및 배차: preCheckOrder와 preCheckDelivery 확인
        if (Object.keys(this.order.preCheckOrder ?? {}).length > 0 || (withDelivery && Object.keys(this.order.preCheckDelivery ?? {}).length > 0)) {
          const confirmButtonText = withDelivery ? '접수 및 배차 진행하기' : '접수만 진행하기';
          const preCheckResult = await this.openSwalPreCheckMessage(confirmButtonText);

          if (!preCheckResult.isConfirmed) {
            this.buttonPressed[action] = false;
            break;
          }
        }

        // 1. 접수에 필요한 분 세팅
        if (this.order.deliveryType === 'DELIVERY') {
          this.order.recommendedRequestedPickupMinutes = this.recommendedRequestedPickupMinutes;
          this.order.recommendedDeliveryMinutes = this.recommendedDeliveryMinutes;

          this.order.posRequestedPickupMinutes = this.requestedPickupMinutes;
          this.order.posDeliveryMinutes = this.deliveryMinutes;
        } else {
          // 배민1이나 포장인 경우에는 cookMinutes 값으로 바로 주문접수한다.
          this.order.cookMinutes = this.cookMinutes;
        }

        // 2. 접수
        const dialogSpinnerRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');
        await this.orderService.accept(this.order, withDelivery);
        dialogSpinnerRef.close();

        // 3. 프린트
        if (this.order.orderVendor === 'coupangeats' && this.roomService.room?.autoPrint?.coupangeats) {
          // 쿠팡이츠 자동출력인 경우에는 이미 출력되었을 가능성이 높으므로 출력하지 않는다.
          debugLog('autoPrint.coupangeats인 경우에는 출력하지 않는다.');
        } else {
          if (['ceo', 'ceostat'].includes(this.userService.user.role)) {
            this.printService.defaultPrint(this.order);
          } else {
            this.utilService.toastrInfo(`user.role이 ${this.userService.user.role}인 경우에는 주문서 인쇄를 하지 않습니다.`);
          }
        }
        this.onClose();
        break;
      case 'COOK':
        await this.orderService.cook(this.order);
        this.onClose();
        break;
      case 'PICKUP':
        await this.orderService.pickup(this.order);
        this.onClose();
        break;
      case 'COMPLETE':
        const difference = differenceInMinutes(new Date(), parseISO(this.order.orderDate));
        if (difference <= 30) {
          const swalResult = await openSwalDeliveryFinishCheck(difference, this.order.orderVendor === 'baemin' && this.order.deliveryType === 'DELIVERY');

          if (!swalResult.isConfirmed) {
            this.buttonPressed[action] = false;
            break;
          }
        }

        await this.orderService.complete(this.order);
        this.onClose();
        break;
      case 'CANCEL':
        // manual 주문이면 고객에게 직접 취소 안내를 해야한다.
        const isManual = this.order.orderVendor === 'ghostkitchen' ||
          this.order.createdBy === 'manual' ||
          this.order.createdBy === 'face';

        let cancelHTML = '';

        if (isManual) {
          cancelHTML += '고객에게 직접 주문 취소 안내를 해 주시기 바랍니다.';
        }

        // 주문 취소를 하려면 관련배차가 없거나 취소 상태여야 한다.
        // 일체형 배송의 경우에는 제외한다.
        const validDeliveries = this.relatedDeliveries.filter(delivery =>
          (!['baera', 'coupang', 'yogiyo'].includes(delivery.deliveryVendor)) &&
          delivery.deliveryStatusCode !== UnifiedDeliveryStatusCode.CANCELED
        );
        if (validDeliveries.length > 0) {
          if (cancelHTML !== '') {
            cancelHTML += '<br/></br></br>';
          }
          cancelHTML += `취소하지 않은 배차가 있습니다.</br>필요한 경우에는 배차 변경 후에 '배차 변경' 후에 '배차 취소'하시면 됩니다.`;
        }

        if (cancelHTML !== '') {
          const { isConfirmed } = await Swal.fire({
            html: cancelHTML,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: '되돌아가기',
            confirmButtonText: '취소 진행하기',
            confirmButtonColor: 'hsl(212, 100%, 50%)',
            // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
            reverseButtons: true
          });

          if (!isConfirmed) {
            // 취소 성공이 아니라면 버튼을 다시 누를 수 있어야한다.
            this.buttonPressed[action] = false;
            break;
          }
        }

        const result = await this.dialogCancelOrderService.openCancelOrderDialog(this.order).afterClosed().toPromise();
        // 취소 성공했을 때에만 상세 창을 닫는다.
        if (result === true) {
          this.destroySignal?.next(true);
          this.destroySignal?.unsubscribe();
          this.destroySignal = undefined;
          this.onClose();
        } else {
          // 취소 성공이 아니라면 버튼을 다시 누를 수 있어야한다.
          this.buttonPressed[action] = false;
        }

        break;
      default:
        this.logService.logOrder(this.order, `예기지 않은 액션 : ${action}. 고객센터에 알려주세요. 내부 에러`, 'error', true);
    }
  }

  /**
   * 취소된 주문 확인
   */
  public confirmCanceledOrder() {
    this.logService.logOrder(this.order, `주문 상세에서 '취소 확인' 버튼 클릭`);
    this.orderService.updatePosCancelConfirmed(this.order);
  }

  /**
   * 인쇄용 다이얼로그
   */
  public onPrint() {
    this.logService.logOrder(this.order, `주문 상세에서 '인쇄...' 버튼 클릭`);
    const printer = this.userService.printer;
    if (printer) {
      if (printer.printFormat === 'text') {
        this.dialogPrintTextService.openDialog(this.order);
      } else {
        this.utilService.toastrError(`지원하지 않는 프린터 포맷 ${printer.printFormat}입니다. 관리자에게 알려주세요.`);
      }
    } else {
      this.utilService.toastrError('프린터 설정을 못 찾았습니다. 관리자에게 알려주세요.');
    }
  }

  public onChangeCookMinutes(minutes: string) {
    this.cookMinutes = parseInt(minutes, 10);
  }

  public onChangeRequestedPickupMinutes(minutes: RequestedPickupMinutes) {
    this.recommendedRequestedPickupMinutes = minutes.recommendedRequestedPickupMinutes;
    this.requestedPickupMinutes = minutes.requestedPickupMinutes;
  }

  public onChangeDeliveryMinutes(minutes: DeliveryMinutes) {
    this.recommendedDeliveryMinutes = minutes.recommendedDeliveryMinutes;
    this.deliveryMinutes = minutes.deliveryMinutes;
  }

  public onClose() {
    this.unifiedOrderService.openedOrder = undefined;
    this.dialogRef?.close();
  }

  public openDialogEditDelivery(unifiedDelivery: UnifiedDeliveryDoc) {
    if (unifiedDelivery.deliveryStatusCode === UnifiedDeliveryStatusCode.CANCELED) {
      Swal.fire('취소된 배차는 변경할 수 없습니다.');
      return;
    }

    this.dialogEditDeliveryService.openDialogEditDelivery(this.order._id, unifiedDelivery._id);
  }

  public openDialogRequestDelivery() {
    // 관련 배차가 있다면 제일 첫 배차를 메인 대행사로 잡는다.
    // 관련 배차가 없다면 연동 대행사들 중 첫번째 우선순위를 메인 대행사로 잡는다.
    const unifiedDeliveryDoc: Partial<UnifiedDeliveryDoc> = this.relatedDeliveries.length > 0 ?
      this.relatedDeliveries[0] : { deliveryVendor: this.roomService.room.deliveryVendors[0] };
    this.dialogRequestDeliveryService.openDialogRequestDelivery(this.order, unifiedDeliveryDoc);
  }

  public openDialogCreateOrder() {
    this.dialogCreateOrderService.openDialogCreateOrder(this.order, this.relatedDeliveries);
  }

  public openDialogOrderHistory() {
    this.dialogOrderHistoryRef = this.dialogOrderHistoryService.openDialogOrderHistory(this.orderHistories);
  }

  /**
   * 쿠팡이츠 주문 고객 또는 쿠리어의 안심번호를 요청한다.
   * 쿠팡이츠인 경우에만 사용하기 때문에 order, delivery를 같이 처리한다.
   */
  public async getSafeNumber(target: 'customer' | 'rider') {
    const safeNumberResult = target === 'customer'
      ? this.customerSafeNumber
      : this.riderSafeNumber;

    safeNumberResult.text = '안심번호 요청중';

    const ret = await this.orderService.getSafeNumber(this.order, target);
    safeNumberResult.text = ret.result === 'success' ? normalizeTel(ret.data) : ret.reason;
  }

  private observeOrder(orderId: string) {
    const observableOrder = this.unifiedOrderService.observeOrderById(orderId);

    // orderId를 이용하면 당연히 같은 호실의 주문이겠지만 firebase는 알 수 없다고 처리하는 듯 하다.
    // 규칙에 위배된다고 에러를 발생시키기 때문에 room 정보도 조건에 추가한다.
    const roomKey = this.roomService.room.room;
    const observableDelivery =
      this.unifiedDeliveryService.observeUnifiedDelivery([['relatedOrderId', '==', orderId], ['room', '==', roomKey]]);

    combineLatest([observableOrder, observableDelivery])
      .pipe(takeUntil(this.destroySignal))
      .subscribe(([order, deliveries]: [UnifiedOrderDoc, UnifiedDeliveryDoc[]]) => {
        // 신규 주문이라 자동 오픈 된 창인데 더이상 신규 주문이 아니면 창을 닫는다.
        // 내가 접수한 창이 아닐 때에만 자동으로 닫힌다.
        // 내가 취소로 바꾼 것은 닫지 않는다.
        if (this.data.isAutoOpen && order.contextStatusCode !== UnifiedOrderContextStatusCode.NEW
          && !this.buttonPressed.ACCEPT && !this.buttonPressed.CANCEL
          // 주문이력 상세화면을 보고있는 경우 자동으로 닫지 않는다.
          && this.dialogOrderHistoryRef === undefined) {
          // 내가 한 것이 아니기 때문에 다른 버튼을 더 누를 수 없게 한다.
          this.buttonPressed.ACCEPT = true;
          this.buttonPressed.COOK = true;
          this.buttonPressed.PICKUP = true;
          this.buttonPressed.CANCEL = true;
          this.buttonPressed.COMPLETE = true;
          this.utilService.toastrInfo('다른 곳에서 처리되어 창을 닫습니다.');
          setTimeout(() => {
            this.onClose();
          }, 500);
        }

        this.order = cloneDeep(order);
        // 'BAERA', 'COUPANG', 'YOGIYO'의 경우에 unifiedDelivery가 relatedDeliveries 역할을 한다.
        if (order.unifiedDelivery) {
          // order.unifiedDelivery는 UnifiedDelivery의 일부 필드만 있으므로 섬세한 주의가 필요하다.
          this.relatedDeliveries = [{
            ...order.unifiedDelivery,
            initialPaymentAmount: order.orderAmount + (order.deliveryTip ?? 0) - (order.eventDiscount ?? 0) - (order.discount ?? 0),
            initialPaymentMethod: order.paymentMethod,
            distance: -1,
            totalFee: -1,
          } as any];
        } else {
          this.relatedDeliveries = cloneDeep(deliveries);
        }

        this.initUnifiedOrder();

        // deliveryType인 'DELIVERY'가 아니라고 해도 연관 배차는 있을 수 있다.
        // 예) 배달에서 포장으로 변경한 경우
        this.initUnifiedDelivery();

        if (this.relatedDeliveries?.length > 0) {
          // relatedDeliveries에는 order.unifiedDelivery가 포함되어 있다.
          // relatedDeliveries는 initUnifiedDelivery()에서 정렬되었다.
          const mainDelivery = this.relatedDeliveries[0];
          if (mainDelivery.timePickedUp) {
            this.onPICKEDUP = diffTime(this.order.orderDate, mainDelivery.timePickedUp).m;
          }
        }

        // 배송 완료 시간
        // 배민(배민1 포함)은 메시지에 포함되어 있고,
        // 쿠팡이츠는 완료로 변경되는 시점에 기록하기로 한다.
        if (this.order?.time?.onCOMPLETED) {
          // 배민의 경우 완료로 상태가 변경된 시간
          this.onCOMPLETED = diffTime(this.order.orderDate, this.order.time.onCOMPLETED).m;
        } else if (this.order?.unifiedDelivery?.timeDelivered) {
          // 쿠팡이츠의 경우
          this.onCOMPLETED = diffTime(this.order.orderDate, this.order.unifiedDelivery.timeDelivered).m;
        }

        // 고객 주문 이력
        this.getOrderHistory();
      }, error => {
        console.error(error);
      });
  }

  /**
   * 필요한 경우 unifiedOrder내용을 화면에 보여줄 텍스트로 가공한다.
   */
  private initUnifiedOrder() {
    // 주문 번호
    this.orderNo = this.orderService.orderNo(this.order);

    // 주문 상태
    this.contextStatusCode = unifiedOrderStatusMappings[this.order.contextStatusCode];

    // 주문 시각
    // safari는 0900을 포함하는 문자열로 new Date를 할 수 없어서 parseISO를 사용한다.
    this.orderDate = format(parseISO(this.order.orderDate), 'HH:mm');

    // 주문 채널
    this.orderVendor = this.orderService.venderWithDeliveryType(this.order);

    // 고객 주소
    this.orderAddress = this.orderService.orderAddress(this.order);
    this.orderAddressRoad = this.orderService.roadAddress(this.order);
    this.orderAddressDongH = this.orderService.addressDongH(this.order);

    // 결제 금액 및 수단
    const [{ orderAmount = 0, deliveryTip = 0, discount = 0, eventDiscount = 0 } = {}] = [this.order];
    this.paymentAmount = orderAmount + deliveryTip - eventDiscount - discount;

    // 포장, 배민1의 경우에는 '예상 배달 시간'이 아닌 '조리 시간'으로 보여주는 것이 적합하다.
    // 포장, 배민1의 경우에는 '예상 배달 시간'을 보여주지 않도록 했지만 cookMinutes 필드가 없기 때문에 조리 시간이 표시되지 않는다.
    // 그래서 여기에서 임시로 넣어준다. 마음에 들지 않지만 일단 이렇게 하고 좋은 방법을 찾아보자.
    // TODO: 나중에는 cookMinutes을 여기 말고 baemin-app-proxy에서 넣어주는 것을 고려해 보자.
    if (this.order.createdBy === undefined && this.order.orderVendor === 'baemin' && this.order.deliveryType !== 'DELIVERY') {
      if (this.order.cookMinutes === undefined) {
        this.order.cookMinutes = this.order.deliveryMinutes;
      }
    }

    // 취소된 주문 - 취소사유를 표시
    if (this.order.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED) {
      this.cancelReason = this.order.cancelReason ? `(사유: ${this.order.cancelReason})` : '';
    }

    if (this.order.deliveryType === 'DELIVERY' && this.order.address_location?.lat) {
      this.distance = this.deliveryUtilService.calcDeliveryDistance(this.order.address_location.lat, this.order.address_location.lon);
    } else {
      this.distance = 0;
    }
  }

  private initUnifiedDelivery() {
    //
    // 1. 신규 주문인 경우에 배차 요청 전 정보를 조회한다.
    //
    if (this.order.deliveryType === 'DELIVERY' && this.order.contextStatusCode === UnifiedOrderContextStatusCode.NEW && this.hasDeliveryVendors) {
      const mainDeliveryVendor = this.roomService.room.deliveryVendors[0];
      this.mainDeliveryVendorKR = deliveryVendorMappings[mainDeliveryVendor];

      const instanceNo = mainDeliveryVendor === 'ghokirun' ? 'N/A' : this.roomService.room.account[mainDeliveryVendor]?.[0];

      if (instanceNo) {
        this.requestDeliveryInformation().then(v => {
          debugLog(`응답 =\n${JSON.stringify(v)}`);

          const { result, reason, deliveryInfo } = v;

          if (result !== 'success') {
            this.logService.logOrder(this.order, `[order-detail] 요청 전 정보 조회 에러: ${reason}`, result === 'error' ? 'error' : 'warn');
            // 배달 불가능 혹은 에러
            lineClampSwalContent('요청 전 정보 조회 실패', reason);
            return;
          }

          if (deliveryInfo.isPossible === false) {
            this.logService.logOrder(this.order, `isPossible이 false인 경우가 등장했다. 어떤 경우인가 살펴보라.\n${JSON.stringify(deliveryInfo)}`, 'error');
          }

          this.deliveryInfo = deliveryInfo;
          // 빠른 응답과 에러가 발생할 경우에 대비해서 최근의 픽업 옵션을 캐시한다.
          this.deliveryService.recentRequestedPickupMinutesOptions = deliveryInfo.pickups;
        }, error => {
          // 오류가 나도 UI 정보는 그냥 둔다.
          this.logService.logOrder(this.order, `요청 전 정보 조회 예외: ${error}`, 'warn');
        });
      } else {
        this.logService.logOrder(this.order, `호실 설정에 account.${mainDeliveryVendor}가 없어요.`, 'error');
        Swal.fire('에러', `${mainDeliveryVendor}에 대한 계정 설정이 없습니다. 관리자에게 알려주세요.`, 'error');
      }
    }

    //
    // 2. 관련 배차가 있는 경우에 정렬, 내용 채우기
    //
    if (this.relatedDeliveries?.length > 0) {
      // 시간 비교는 문자열 비교로도 충분하다.
      this.relatedDeliveries.sort((a, b) => {
        return (a.timeSubmitted < b.timeSubmitted) ? -1 : (a.timeSubmitted > b.timeSubmitted) ? 1 : 0;
      });
      this.relatedDeliveries.forEach(delivery => {
        // 요청 픽업 시각 / 도착 예정 시각
        const { _ui = {} } = delivery;
        const requestedPickupTime = delivery.requestedPickupTime ? dateFormatter(delivery.requestedPickupTime, 'HH:mm') : '';

        // 픽업 조정이 발생한 경우
        if (delivery?.adjustedPickupTimes?.length > 0) {
          const latest = delivery.adjustedPickupTimes[delivery.adjustedPickupTimes.length - 1];
          _ui.simpleAdjustedPickupTime = dateFormatter(latest.adjustedTime, 'HH:mm');
          _ui.adjustedPickupTimeNum = delivery.adjustedPickupTimes.length;
        }

        // 바로고라면 예정시간이 있다.
        // 다른 대행사는 마지막 픽업 조정 시각을 표시한다.
        const expectedTime = delivery.deliveryVendor === 'barogo' ? dateFormatter(delivery.barogoPickupExpectedAt, 'HH:mm') :
          _ui.simpleAdjustedPickupTime ?? '';

        // 예정시각이 요청시각과 같거나 값이 없으면 요청시각만 표시한다.
        _ui.pickupTime = (requestedPickupTime === expectedTime) || (expectedTime === '') || (expectedTime === undefined) ? requestedPickupTime : `${requestedPickupTime}/${expectedTime}`;

        // 배달지 주소 - 고객 주소 정보와 다르면 배달지 주소를 표시한다.
        if (delivery.address_key) {
          const deliveryAddress = `${delivery.address_key} ${delivery.address_detail}`;

          if (deliveryAddress !== this.orderAddress) {
            _ui.orderAddress = deliveryAddress;
          }
        }

        // 고객 전화번호 - 고객 전화번호 정보와 다르면 전화번호를 표시한다.
        if (delivery.userTel) {
          if (this.order.userTel !== delivery.userTel) {
            _ui.userTel = normalizeTel(delivery.userTel);
          }
        }

        if (delivery.orderMsg) {
          if (delivery.orderMsg !== this.order.orderMsg) {
            _ui.orderMsg = delivery.orderMsg;
          }
        }

        delivery._ui = _ui;

        // 라이더 정보 업데이트
        _ui.riderName = this.deliveryService.riderName(delivery);
        _ui.riderTel = this.deliveryService.riderTel(delivery);
      });
    }
  }

  private requestDeliveryInformation() {
    const mainDeliveryVendor = this.roomService.room.deliveryVendors[0];

    const callInputRequestDeliveryInformation: CallInputRequestDeliveryInformation = {
      organization: this.order.organization,
      site: this.order.site,
      room: this.order.room,
      deliveryVendor: mainDeliveryVendor,
      // 호출하기 전에 확인했다.
      instanceNo: mainDeliveryVendor === 'ghokirun' ? 'N/A' : this.roomService.room.account[mainDeliveryVendor][0],
      address_key: this.order.address_key,
      address_detail: this.order.address_detail,
      address_location: this.order.address_location,
      address_jibun: this.order.address_jibun,
      address_sido: this.order.address_sido,
      address_sigungu: this.order.address_sigungu,
      address_dong: this.order.address_dong,
      address_dongH: this.order.address_dongH,
      initialPaymentAmount: this.paymentAmount
    };

    if (this.order.address_road) {
      callInputRequestDeliveryInformation.address_road = this.order.address_road;
    }

    return this.deliveryService.requestDeliveryInformation(callInputRequestDeliveryInformation);
  }

  /**
   * 고객의 주문이력을 조회한다.
   */
  private async getOrderHistory() {
    // 쿠팡이츠는 전부 불가
    if (this.order.orderVendor === 'coupangeats') {
      this.availableOrderHistory = false;
      return;
    }

    // 이력조회가 가능한 주문만 조회한다. (쿠팡이츠, 요기요 익스프레스, 제외)
    if (['DELIVERY', 'BAERA', 'TAKEOUT', 'HERE'].includes(this.order.deliveryType) === false) {
      this.availableOrderHistory = false;
      return;
    }

    if (this.orderHistoriesOneTime) {
      return;
    }

    const { _id: orderId, hashUserTel, address_key, address_detail } = this.order;

    // 필수 정보가 없다면 굳이 callable을 호출하지 않는다.
    if (!hashUserTel && (!address_key || !address_detail)) {
      this.logService.logOrder(this.order, `주문이력 조회에 필요한 필수 값이 없습니다. orderId: ${orderId}. hashUserTel: ${hashUserTel}, address_key: ${address_key}, address_detail: ${address_detail}`, 'warn');
      this.availableOrderHistory = false;
      return;
    }

    // 호실에 해당하는 주문이력만을 가져오기 위해서 where값을 채운다.
    const callInput: CallInputOrderHistory = { orderId, where: 'room' };
    // 현재 계약자의 영업일에 해당하는 주문만을 조회한다.
    // 필요한 정보가 없는 경우 요청에서 필드 자체를 제외시킨다.
    if (this.userService.user.businessStartDate) {
      callInput.startAt = this.userService.user.businessStartDate;
    }
    if (this.userService.user.businessEndDate) {
      callInput.endAt = this.userService.user.businessEndDate;
    }

    try {
      const callOrderHistory = this.fns.httpsCallable<CallInputOrderHistory, CallOutputOrderHistory>('callOrderHistory');
      const response = await callOrderHistory(callInput).toPromise();

      if (response.result === 'success') {
        const summary = response.orderHistories.reduce((sum, history) => {
          if (history.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED) {
            sum.countCanceledOrders++;
          } else {
            sum.countOrders++;
            sum.totalAmount += (history.orderAmount + history.deliveryTip);
          }
          return sum;
        }, {
          countOrders: 0,
          countCanceledOrders: 0,
          totalAmount: 0
        });

        // order._ui에 값을 붙여주면 orders의 상태가 변화할 때 새로고침되면서 창이 열리는 이벤트가 발생하지 않은경우(이미 열려있음)
        // orders 하위에 붙여둔 정보가 사라지는 문제가 있었다.
        // 조회하는 동안에도 이전에 열었을 때의 정보를 화면에 유지하기 위해서 private 변수로 가지고 있는다.
        this.orderHistorySummary = summary;
        this.orderHistories = response.orderHistories;
      } else {
        this.availableOrderHistory = false;
        this.orderHistories = [];
        this.logService.logOrder(this.order, `주문이력 조회(${JSON.stringify(callInput)}) 실패: ${response.reason}`, response.result === 'warning' ? 'warn' : 'error');
      }

      this.orderHistoriesOneTime = true;
    } catch (error) {
      this.availableOrderHistory = false;
      this.orderHistories = [];
      this.logService.logOrder(this.order, `주문이력 조회(${JSON.stringify(callInput)}) 예외: ${JSON.stringify(error)}`, 'error');
    }
  }

  private openSwalPreCheckMessage(confirmButtonText: string) {
    let preCheckOrderHTML = '';

    if (Object.keys(this.order.preCheckOrder).length > 0) {
      const orderContentsHTML = Object.values(this.order.preCheckOrder).map((value) => `<li>${value}</li>`).join('');

      preCheckOrderHTML = `
      <div class="swal-title">! 접수하기 전에 확인해 보세요</div>
      <div class="swal-content">
        ${orderContentsHTML}
      </div>`;
    }

    let preCheckDeliveryHTML = '';
    if (confirmButtonText === '접수 및 배차 진행하기' && Object.keys(this.order.preCheckDelivery).length > 0) {
      const orderContentsHTML = Object.values(this.order.preCheckDelivery).map((value) => `<li>${value}</li>`).join('');

      preCheckDeliveryHTML = `
      <div class="swal-title">! 배차 전에 확인해 보세요.</div>
      <div class="swal-content">
        ${orderContentsHTML}
      </div>
      <div class="swal-notice">(주소 변경 등이 필요한 경우에는 '되돌아가기' 후 '접수만' 진행하시고 이후에 '배차 추가'를 하시면 됩니다.)</div>`;
    }

    const html = `${preCheckOrderHTML} ${preCheckDeliveryHTML}`;

    return Swal.fire({
      html,
      cancelButtonText: '되돌아가기',
      showCancelButton: true,
      confirmButtonText,
      confirmButtonColor: 'hsl(212, 100%, 50%)',
      // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
      reverseButtons: true,
      // 스타일의 스코프를 지정하기 위한 target
      target: 'app-dialog-order-detail',
      customClass: {
        popup: 'swal-wide'
      }
    });
  }
}
