/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';

import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode } from '../../schema/3/schema';
import { DeliveryProtocol } from '../../schema/5/schema-protocol';

@Injectable({
  providedIn: 'root'
})
export class CombinenetDeliveryService implements DeliveryProtocol {

  constructor(
  ) { }

  public canCancel(delivery: UnifiedDeliveryDoc): boolean {
    // 런투유는 픽업 이전, 스파이더는 배차 이전까지 취소 가능
    return delivery.deliveryVendor === 'run2u' ? delivery.deliveryStatusCode < UnifiedDeliveryStatusCode.PICKED_UP
    : delivery.deliveryStatusCode < UnifiedDeliveryStatusCode.ASSIGNED;
  }

  modifiableFields(delivery: UnifiedDeliveryDoc) {
    // start부터 변경 가능하여 end 상태가 되기 전까지 변경 가능하다.
    // 배차 상태와 상관없이 변경 불가능한 필드는 start 와 end를 모두 SUBMITTED로 한다.
    const modifiableFields = [
      { fieldname: 'requestedPickupTime', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'initialPaymentMethod', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'initialPaymentAmount', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'orderMsg', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'userTel', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'addressKey', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'addressDetail', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
      { fieldname: 'addressRoad', start: UnifiedDeliveryStatusCode.SUBMITTED, end: UnifiedDeliveryStatusCode.PICKED_UP },
    ];

    return modifiableFields
      .filter(field => field.start <= delivery.deliveryStatusCode && delivery.deliveryStatusCode < field.end)
      .map(field => field.fieldname);
  }
}
