/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';

import { AugmentedAddress, DeliveryVendor } from '../../schema/1/schema-common';
import { UnifiedDeliveryDoc, UnifiedOrderDoc } from '../../schema/3/schema';
import { CallInputRequestDelivery, CallInputRequestDeliveryInformation, CallOutputRequestDelivery, CallOutputRequestDeliveryInformation } from '../../schema/4/schema-functions-call';

import { deliveryVendorMappings } from '../../core/1/string-map';
import { debugLog, lineClampSwalContent } from '../../core/1/common';
import { normalizeCurrency } from '../../core/1/util';
import { RoomService } from '../../core/1/room.service';
import { LogService } from '../../core/4/log.service';
import { DeliveryService } from '../../core/5/delivery.service';

import { RequestedPickupMinutes } from '../select-minute/select-minute.component';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';

@Component({
  selector: 'app-dialog-request-delivery',
  templateUrl: './dialog-request-delivery.component.html',
  styleUrls: ['./dialog-request-delivery.component.scss']
})
export class DialogRequestDeliveryComponent implements OnInit, OnDestroy {
  private destroySignal = new Subject<boolean>();

  public deliveryVendors: DeliveryVendor[] = [];
  public deliveryVendorMappings = deliveryVendorMappings;
  public deliveryVendor: DeliveryVendor | 'none';
  public deliveryInfo: CallOutputRequestDeliveryInformation['deliveryInfo'] = {
    extraAvailable: false,
    isPossible: true,
    pickups: [],
    distance: 0,
    baseFee: 0,
    totalFee: 0
  };

  public paymentMethod: '후불현금' | '후불카드' | '선불' | 'NA' = 'NA';
  public address = {
    address_key: '',
    address_road: '',
    address_detail: '',
    address_location: { lat: 0, lon: 0 },
    address_sido: '',
    address_sigungu: '',
    address_dong: '',
    address_dongH: '',
    address_jibun: '',
    address_building_name: '',
    vroong: {
      dest_sigungu: '',
      dest_legal_eupmyeondong: '',
      dest_admin_eupmyeondong: '',
      dest_ri: '',
      dest_beonji: '',
      dest_road: '',
      dest_building_number: '',
    },
  };

  public userTel = '';
  public orderMsg = '';
  public prefixMsg = '';
  public selectedOptions = [];

  public riderName = '';

  public formControlPaymentAmount = new FormControl();
  public formControlPrefixMsg = new FormControl('');
  public requestedPickupMinutes = 20;

  public buttonPressed = {
    REQUEST: false
  };

  constructor(
    public dialogRef: MatDialogRef<DialogRequestDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order: UnifiedOrderDoc, mainDelivery: Partial<UnifiedDeliveryDoc> },
    private logService: LogService,
    private fns: AngularFireFunctions,
    private dialogSpinnerService: DialogSpinnerService,
    private roomService: RoomService,
    private deliveryService: DeliveryService
  ) { }

  ngOnInit(): void {
    this.observeCurrency(this.formControlPaymentAmount);
    this.observePrefixMsg();

    this.deliveryVendors = this.roomService.room.deliveryVendors;
    this.deliveryVendor = this.data.mainDelivery.deliveryVendor;
    this.paymentMethod = this.data.order.paymentMethod;

    this.riderName = this.data.mainDelivery.riderName ?? '';

    // eventDiscount는 undefined도 될 수 있다고 가정
    this.formControlPaymentAmount.setValue(`${this.data.order.orderAmount + this.data.order.deliveryTip - (this.data.order.eventDiscount ?? 0) - (this.data.order.discount ?? 0)}`);

    this.address = {
      address_key: this.data.order.address_key,
      address_road: this.data.order.address_road,
      address_detail: this.data.order.address_detail,
      address_location: this.data.order.address_location,
      address_sido: this.data.order.address_sido,
      address_sigungu: this.data.order.address_sigungu,
      address_dong: this.data.order.address_dong,
      address_dongH: this.data.order.address_dongH,
      address_jibun: this.data.order.address_jibun,
      address_building_name: this.data.order.address_building_name,
      vroong: this.data.order.vroong,
    };

    this.userTel = this.data.order.userTel;
    this.orderMsg = this.data.order.orderMsg;

    this.updateRequestDeliveryInformation();
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  public onChangePaymentMethod(event) {
    this.paymentMethod = event.value;
  }

  /**
   * 금액에 변화가 있으면 포맷을 자동 적용한다.
   */
  private observeCurrency(formControl: AbstractControl) {
    formControl.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        const normalizedCurrency = normalizeCurrency(String(value));
        if (value !== normalizedCurrency) {
          formControl.setValue(normalizedCurrency);
        }
      });
  }

  private observePrefixMsg() {
    this.formControlPrefixMsg.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        this.updatePrefixMsg();
      });
  }

  public onChangeRequestedPickupMinutes(minutes: RequestedPickupMinutes) {
    this.requestedPickupMinutes = minutes.requestedPickupMinutes;
  }

  public onClose() {
    this.dialogRef.close();
  }

  public onClickPrePaidZero() {
    this.paymentMethod = '선불';
    this.formControlPaymentAmount.setValue('0');
  }

  public onChangeAddress(event: {
    augmentedAddress: AugmentedAddress
    addressDetail: any
  }) {
    this.address = {
      address_key: event.augmentedAddress.key,
      address_road: event.augmentedAddress.road,
      address_detail: event.addressDetail,
      address_location: event.augmentedAddress.location,
      address_sido: event.augmentedAddress.sido,
      address_sigungu: event.augmentedAddress.sigungu,
      address_dong: event.augmentedAddress.dong,
      address_dongH: event.augmentedAddress.dongH,
      address_jibun: event.augmentedAddress.jibun,
      address_building_name: event.augmentedAddress.building_name,
      vroong: event.augmentedAddress.vroong,
    };

    this.updateRequestDeliveryInformation();
  }

  public onChangeUserTel(event) {
    this.userTel = event;
  }

  public onChangeOrderMsg(event) {
    this.orderMsg = event;
  }

  public async submitDelivery() {
    const order = this.data.order;

    if (this.deliveryVendor === 'none') {
      Swal.fire('요청할 배달 대행사를 선택해 주세요');
      return;
    }

    if (!this.userTel) {
      Swal.fire('고객 전화번호가 없어서\n배차 요청을 할 수 없습니다.\n[전화번호 입력]을 해주세요.', '', 'error');
      return;
    }

    const delivery: CallInputRequestDelivery = {
      organization: order.organization,
      site: order.site,
      room: order.room,

      relatedOrderId: order._id,
      deliveryVendor: this.deliveryVendor,
      cookMinutes: this.requestedPickupMinutes,
      userTel: this.userTel.replace(/-/g, ''),

      address_key: this.address.address_key,
      address_road: this.address.address_road,
      address_detail: `${this.prefixMsg}${this.address.address_detail}`,
      address_location: this.address.address_location,
      address_sido: this.address.address_sido,
      address_sigungu: this.address.address_sigungu,
      address_dong: this.address.address_dong,
      address_dongH: this.address.address_dongH,
      address_jibun: this.address.address_jibun,
      address_building_name: this.address.address_building_name,
      vroong: this.address.vroong,

      initialPaymentMethod: this.paymentMethod,
      initialPaymentAmount: parseInt(this.formControlPaymentAmount.value.replace(/,/g, ''), 10),

      orderMsg: this.orderMsg,
    };

    const spinnerDeialogRef = this.dialogSpinnerService.openSpinnerDialog('배차 요청...');
    try {
      const callable = this.fns.httpsCallable<CallInputRequestDelivery, CallOutputRequestDelivery>('callRequestDelivery');
      const callOutput = await callable(delivery).toPromise();
      if (callOutput.result === 'success') {
        if (this.dialogRef) {
          this.dialogRef.close(true);
          this.dialogRef = undefined;
        }
        this.logService.logOrder(this.data.order, `${deliveryVendorMappings[this.deliveryVendor]} 배차 요청 성공했습니다.`, 'info', true);
      } else {
        this.logService.logOrder(this.data.order, `${deliveryVendorMappings[this.deliveryVendor]} 배차 요청에 실패했습니다.\n${JSON.stringify(callOutput)}`, 'error');
        lineClampSwalContent(`${deliveryVendorMappings[this.deliveryVendor]} 배차 요청에 실패했습니다.`, callOutput.reason);
      }
    } catch (error) {
      lineClampSwalContent(`${deliveryVendorMappings[this.deliveryVendor]} 배차 요청 중 예외가 발생했습니다.`, error.message);
      this.logService.logOrder(this.data.order, `${deliveryVendorMappings[this.deliveryVendor]} 배차 요청 처리 중 예외가 발생했습니다.\n${error}`, 'error');
    }

    spinnerDeialogRef?.close();
  }

  public onChangePrefix(event: MatSelectionListChange) {
    this.selectedOptions = event.source.selectedOptions.selected;
    this.updatePrefixMsg();
  }

  private updatePrefixMsg() {
    const selectedOptionValues = this.selectedOptions.map(option => option.value).join('/');
    const customPrefixMsg = this.formControlPrefixMsg.value;
    const prefixMsg = selectedOptionValues.length > 0 && customPrefixMsg !== '' ? `${selectedOptionValues}/${customPrefixMsg}` : `${selectedOptionValues}${customPrefixMsg}`;
    this.prefixMsg = prefixMsg === '' ? '' : `(${prefixMsg})`;
  }

  private updateRequestDeliveryInformation() {
    const deliveryVendor = this.deliveryVendor;

    if (deliveryVendor === 'none') {
      return;
    }

    const instanceNo = deliveryVendor === 'ghokirun' ? 'N/A' : this.roomService.room.account[deliveryVendor]?.[0];

    if (instanceNo) {
      this.requestDeliveryInformation(deliveryVendor).then(v => {
        debugLog(`응답 =\n${JSON.stringify(v)}`);

        const { result, reason, deliveryInfo } = v;

        if (result !== 'success') {
          this.logService.logOrder(this.data.order, `배차 요청 전 정보 조회 에러: ${reason}`, result === 'error' ? 'error' : 'warn');
          // 배달 불가능 혹은 에러
          lineClampSwalContent('대행사 요금 정보 조회 실패', reason);
          return;
        }

        if (deliveryInfo.isPossible === false) {
          this.logService.logOrder(this.data.order, `isPossible이 false인 경우가 등장했다. 어떤 경우인가 살펴보라.\n${JSON.stringify(deliveryInfo)}`, 'error');
        }

        this.deliveryInfo = deliveryInfo;
        this.deliveryService.recentRequestedPickupMinutesOptions = deliveryInfo.pickups;
      }, error => {
        // 오류가 나도 UI 정보는 그냥 둔다.
        this.logService.logOrder(this.data.order, `요청 전 정보 조회 예외: ${error}`, 'warn');
      });
    } else {
      this.logService.logOrder(this.data.order, `호실 설정에 account.${deliveryVendor}가 없어요.`, 'error');
      Swal.fire('대행사 요금 정보 조회 실패', `${deliveryVendor}에 대한 계정 설정이 없습니다. 관리자에게 알려주세요.`, 'error');
    }
  }

  private requestDeliveryInformation(deliveryVendor: DeliveryVendor) {
    const callInputRequestDeliveryInformation: CallInputRequestDeliveryInformation = {
      organization: this.data.order.organization,
      site: this.data.order.site,
      room: this.data.order.room,
      deliveryVendor,
      // 호출하기 전에 확인했다.
      instanceNo: deliveryVendor === 'ghokirun' ? 'N/A' : this.roomService.room.account[deliveryVendor][0],
      address_key: this.address.address_key,
      address_detail: this.address.address_detail,
      address_location: this.address.address_location,
      address_jibun: this.address.address_jibun,
      address_sido: this.address.address_sido,
      address_sigungu: this.address.address_sigungu,
      address_dong: this.address.address_dong,
      address_dongH: this.address.address_dongH,
      initialPaymentAmount: parseInt(this.formControlPaymentAmount.value.replace(/,/g, ''), 10)
    };

    if (this.address.address_road) {
      callInputRequestDeliveryInformation.address_road = this.address.address_road;
    }

    return this.deliveryService.requestDeliveryInformation(callInputRequestDeliveryInformation);
  }

  public onDeliveryVendorChanged(event) {
    this.deliveryVendor = event.value;
    this.updateRequestDeliveryInformation();
  }
}
