<swal 
 #roomSelect 
 title="호실 변경" 
 [showCancelButton]="true"
 cancelButtonText="취소"
 confirmButtonText="변경"
 (willOpen)="loadSites()" 
 (confirm)="onConfirm()"
>
  <div *swalPortal>
    <select class="swal2-input" [formControl]="siteControl">
      <option *ngFor="let site of sites" [value]="site.siteKey">{{ site.siteName }}</option>
    </select>
    <select class="swal2-input" [formControl]="roomControl">
      <option *ngFor="let room of rooms" [value]="room.roomKey">{{ room.live }} {{ room.roomName }} {{ room.shopName }}</option>
    </select>
  </div>
</swal>
