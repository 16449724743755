/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore } from '@angular/fire/firestore';

import { LogLevel } from '../../schema/1/schema-common';
import { Log } from '../../schema/2/schema-message';
import { LogOrderDoc } from '../../schema/3/schema';

import { debugLog } from '../1/common';
import { IpService } from '../1/ip.service';
import { RoomService } from '../1/room.service';
import { UtilService } from '../2/util.service';
import { UserService } from '../3/user.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  // ignore이면 toastrError 메시지를 보여주지 않는다.
  // Logout 시에 일시적인 에러 표시를 막기 위함
  public ignore = false;
  public instanceId: string;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService,
    private ipService: IpService,
    private roomService: RoomService,
  ) {
    this.instanceId = this.utilService.getInstanceId();
    debugLog(`log instance ID = ${this.instanceId}`);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  private async send(level: LogLevel, message: string, email?: string) {
    const collectionPath = 'log';

    if (this.ignore) {
      return;
    }

    try {
      // message Id는 firestore가 제공하는 Id를 이용한다.
      const docRef = this.db.firestore.collection(collectionPath).doc();
      const docId = docRef.id;

      const doc: Log = {
        _id: docId,
        _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
        from: {
          class: 'pos',
          instanceNo: this.instanceId,
          account: email ? email : (this.userService?.user?.email ?? ''),
        },
        level,
        message
      };

      return await this.db.doc<Log>(docRef).set(doc);
    } catch (error) {
      console.error('send에서 예외 발생:', error);
    }
  }

  public async debug(message: string) {
    return this.logRoom(message, 'debug');
  }
  public async info(message: string) {
    return this.logRoom(message, 'info');
  }
  public async warn(message: string) {
    return this.logRoom(message, 'warn');
  }
  public async error(message: string) {
    return this.logRoom(message, 'error');
  }

  public async withToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    const msg = `${title ? title + ': ' : ''} ${message}`;
    return this.logOrder({ _id: 'room', site: this.roomService.room.site, room: this.roomService.room.room }, msg, 'error');
  }

  public withToastrCatch(error: Error | string, reason?: string) {
    this.utilService.toastrCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    return this.logOrder({ _id: 'room', site: this.roomService.room.site, room: this.roomService.room.room }, msg, 'error');
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  public async logOrder(order: { _id: string; site?: string; room?: string; }, message: string, level?: LogLevel, withToastr?: boolean) {
    if (withToastr === true) {
      if (level === 'error') {
        this.utilService.toastrError(message);
      } else if (level === 'warn') {
        this.utilService.toastrWarning(message);
      } else {
        this.utilService.toastrInfo(message);
      }
    }

    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection('logOrder').doc();
    const docId = docRef.id;

    const organization = this.userService.organization;

    const doc: LogOrderDoc = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,

      organization,
      site: order.site ?? this.roomService.room.site,
      room: order.room ?? this.roomService.room.room ?? this.userService?.user?.email,
      orderId: order._id ?? 'ERROR',

      instanceType: 'pos',
      instanceNo: this.instanceId,
      account: this.userService?.user?.email ?? '',

      publicIP: this.ipService.publicAddress ?? '',

      level: level ?? 'info',
      message
    };

    try {
      if (doc.room === undefined) {
        return this.send('error', `logOrder를 남기는데 room이 없습니다. ${JSON.stringify(doc)}`);
      }

      return await this.db.doc<LogOrderDoc>(docRef).set(doc);
    } catch (error) {
      console.error('logOrder에서 예외 발생:', error);
    }
  }

  /**
   * logRoom은 logOrder와 같은 컬렉션을 사용한다.
   */
  public async logRoom(message: string, level: LogLevel = 'info') {
    return this.logOrder({ _id: 'room', site: this.roomService.room.site, room: this.roomService.room.room }, message, level);
  }

  public logRoomWithToastrWarn(message: string, title?: string, timeout?: number) {
    this.utilService.toastrWarning(message, title, timeout);
    return this.logRoom(`${title ? title + ': ' : ''} ${message}`, 'warn');
  }

  public logRoomWithToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastrError(message, title, timeout);
    return this.logRoom(`${title ? title + ': ' : ''} ${message}`, 'error');
  }

  public logRoomWithToastrCatch(error: Error | string, reason?: string) {
    this.utilService.toastrCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    return this.logRoom(msg, 'error');
  }
}
