/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogBaeminBlockComponent } from './dialog-baemin-block.component';

@Injectable({
  providedIn: 'root'
})
export class DialogBaeminBlockService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(roomKey: string): MatDialogRef<DialogBaeminBlockComponent, boolean> {
    // 다른 다일로그는 닫는다.
    // this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogBaeminBlockComponent, {
      maxWidth: 600,
      // 다이얼로그를 열 대 data를 공급해 준다.
      data: {
        roomKey
      }
    });

    return dialogRef;
  }
}
