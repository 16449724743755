/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { WHERE } from '../../schema/1/schema-common';
import { SimpleNoticeDoc } from '../../schema/3/schema';

const collectionPath = 'simpleNotice';

@Injectable({
  providedIn: 'root'
})
export class SimpleNoticeService {
  simpleNotices: SimpleNoticeDoc[] = [];
  // 변경이 있을 때만 알고 싶어서 Subject를 이용한다.
  latestSimpleNoticesSubject = new Subject<SimpleNoticeDoc[]>();

  constructor(
    private db: AngularFirestore
  ) { }

  startObservingSimpleNotice(wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, orderBy: 'asc' | 'desc' = 'asc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 공지부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }
    // _timeUpdate는 Timestamp형식이다.
    const atDateTimestamp = new Date(now.getTime() + atDate * 24 * 3600 * 1000);

    const queryFn: QueryFn = ref => {
      let query = wheres.reduce((query0, where: WHERE) => query0.where(where[0], where[1] as firestore.WhereFilterOp, where[2]), ref);
      query = query.orderBy('_timeUpdate', orderBy);
      query = orderBy === 'asc' ? query.startAt(atDateTimestamp) : query.endAt(atDateTimestamp);
      return query;
    };

    const simpleNoticeCollection = this.db.collection<SimpleNoticeDoc>(collectionPath, queryFn);

    simpleNoticeCollection.valueChanges().subscribe(docs => {
      this.simpleNotices = docs;
      this.latestSimpleNoticesSubject.next(docs);
    });
  }
}
