/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription } from 'rxjs';

import { PrinterDoc, UserDoc } from '../../schema/3/schema';

import { debugLog } from '../1/common';
import { UtilService } from '../2/util.service';

const collectionPath = 'user';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: UserDoc;
  public latestUserSubject = new BehaviorSubject<UserDoc>(null);

  public printer: PrinterDoc;
  public latestPrinterSubject = new BehaviorSubject<PrinterDoc>(null);
  private printerSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
  }

  public get organization() {
    if (this.user?.organization === undefined) {
      alert('필수정보(organization)가 없습니다. 고객센터에 알려주세요.');
    }
    return this.user.organization;
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observe(email: string) {
    debugLog(`email = ${email}`);
    const docRef = this.db.doc<UserDoc>(`${collectionPath}/${email}`);

    docRef.valueChanges().subscribe(doc => {
      this.user = doc;

      this.latestUserSubject.next(doc);
      // user의 대표 프린터 설정을 구독한다.
      if (this.user.printers?.[0] !== this.printer?._id) {
        this.observePrinter(this.user.printers?.[0] ?? undefined);
      }
    }, error => {
      this.utilService.toastrError(`UserService:observe에서 에러 발생 : ${error}`);
    });
  }

  /**
   * 사용자에게 할당된 프린터의 설정을 모니터링한다.
   */
  private observePrinter(printerKey: string | undefined) {
    // 사용자의 프린터 설정이 제거된 경우
    if (printerKey === undefined) {
      this.printer = undefined;
      this.latestPrinterSubject.next(undefined);
      this.printerSubscription?.unsubscribe();
      return;
    }

    const printerDocRef = this.db.doc<PrinterDoc>(`printer/${printerKey}`);

    this.printerSubscription?.unsubscribe();
    this.printerSubscription = printerDocRef.valueChanges().subscribe(printerDoc => {
      this.printer = printerDoc;
      this.latestPrinterSubject.next(printerDoc);
    }, error => {
      this.utilService.toastrError(`UserService:observePrinter에서 에러 발생 : ${error}`);
    });
  }
}
