/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogSearchAddressComponent, DialogSearchAddressData } from './dialog-search-address.component';

@Injectable({
  providedIn: 'root'
})
export class DialogSearchAddressService {

  constructor(
    private dialog: MatDialog
  ) { }

  /** 주소 입력을 위한 다이얼로그 */
  openDialog(addressData?: DialogSearchAddressData) {
    const dialogConfig: MatDialogConfig = {
      data: {
        addressData
      },
      panelClass: 'full-screen-dialog',
      disableClose: true
    };

    return this.dialog.open(DialogSearchAddressComponent, dialogConfig);
  }
}
