<h1>배차 변경</h1>

<div class="container">
  <div class="guide-area section" *ngIf="guide !== ''">
    <div class="section-title">사용 안내</div>
    <div class="guide-content">{{ guide }}</div>
  </div>

  <div class="delivery-info">
    <div class="text-info section">
      <div class="section-title">배송 정보</div> 
      <table>
        <tr>
          <td class="title">배차 접수시각</td>
          <td class="content">{{ timeSubmitted }}</td>
        </tr>
        <tr>
          <td class="title">배송 상태</td>
          <td class="content">{{ deliveryStatus }}</td>
        </tr>
        <tr>
          <td class="title">배달 대행사</td>
          <td class="content">{{ deliveryVendor }}</td>
        </tr>
        <tr>
          <td class="title">배달비</td>
          <td *ngIf="delivery" class="content">{{ delivery.totalFee | number }}원</td>
        </tr>
        <tr *ngIf="delivery?.riderName">
          <td class="title">라이더</td>
          <td class="content">{{ delivery.riderName }} <span *ngIf="delivery.riderTel">({{ delivery.riderTel }})</span></td>
        </tr>
      </table>
    </div>
    <div class="editable-info section">
      <div class="section-title">요청 픽업 시각</div>
      <div class="counter">
        <span [class.changed]="changedField('deliveryTimeRequestedPickup').changed">{{ timeRequestedPickup }}</span>
        <button mat-button [disabled]="disableRequestedPickupTime" (click)="changeMinute(-5)">-</button>
        <input matInput [formControl]="formControlRequestedPickupTimeMinute">
        <button mat-button [disabled]="disableRequestedPickupTime" (click)="changeMinute(5)">+</button>
      </div>
      
      <div class="section-title">결제 방법</div>
      <mat-button-toggle-group [disabled]="disablePaymentMethod" (change)="onChangePaymentMethod($event)" [value]="paymentMethod" [class.changedToggle]="changedField('paymentMethod').changed">
        <mat-button-toggle value="선불">선불</mat-button-toggle>
        <mat-button-toggle value="후불현금">후불현금</mat-button-toggle>
        <mat-button-toggle value="후불카드">후불카드</mat-button-toggle>
      </mat-button-toggle-group>

      <div class="section-title">결제 금액</div>
      <input matInput class="input" [class.input-disabled]="formControlPaymentAmount.disabled" inputmode="decimal" [formControl]="formControlPaymentAmount" [class.changed]="changedField('paymentAmount').changed" required>
    </div>
  </div>
  <div class="customer-info section">
      <div class="section-title">고객 주소</div>
      <div class="delivery-address">
        <span [class.changed]="changedField('addressKey').changed">{{ addressKey }}</span>
        <span [class.changed]="changedField('addressDetail').changed">{{ addressDetail}}</span>
        <button mat-stroked-button [disabled]="disableAddress" (click)="openDialogSearchAddress()">주소 변경</button>
      </div>
      <div class="delivery-address-road">
        <span class="address-road">도로명</span>
        <span [class.changed]="changedField('addressRoad').changed">{{ addressRoad }}</span>
      </div>
      <div class="section-title">고객 전화번호</div>
      <div class="user-tel">
        <span [class.changed]="changedField('userTel').changed">{{ userTel }}</span>
        <button mat-stroked-button (click)="openDialogTel()" [disabled]="disableUserTel">전화번호 변경</button>
      </div> 
      <div class="section-title">고객 요청사항</div>
      <input class="input" [class.changed]="changedField('orderMsg').changed" [class.input-disabled]="formControlOrdMsg.disabled" matInput [formControl]="formControlOrdMsg" required>
  </div>
</div>

<div class="buttons">
  <button class="button-close" mat-stroked-button (click)="onClose()">닫기</button>
  <button class="button-cancel" mat-flat-button (click)="onClickCancel()" [disabled]="buttonPressed.CANCEL || !buttonEnable.CANCEL">배차 취소...</button>
  <button class="button-modify" mat-flat-button (click)="onClickModify()" [disabled]="buttonPressed.MODIFY || !buttonEnable.MODIFY">배차 변경</button>
</div>