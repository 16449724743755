/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UnifiedOrderDoc } from '../../schema/3/schema';

import { DialogPrintTextComponent } from './dialog-print-text.component';

@Injectable({
  providedIn: 'root'
})
export class DialogPrintTextService {
  constructor(
    private dialog: MatDialog
  ) { }

  openDialog(order: UnifiedOrderDoc, timeout?: number) {
    const dialogRef = this.dialog.open(DialogPrintTextComponent, {
      data: {
        order,
        automatic: false
      }
    });

    if (timeout !== undefined) {
      setTimeout(() => {
        dialogRef.close();
      }, timeout);
    }
  }

  printAutomatically(order: UnifiedOrderDoc, what: 'cook' | 'customer' | 'all' | 'default') {
    this.dialog.open(DialogPrintTextComponent, {
      data: {
        order,
        automatic: true,
        what
      }
    });
  }
}
