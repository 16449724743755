/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
export const roleMappings = {
  admin: '관리자',
  operator: '접수자',
  ceo: '사장님',
};

export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문',
  15: '업소접수',
  17: '배차대기',
  20: '주문접수',
  30: '조리시작',
  40: '조리완료',
  50: '포장완료',
  60: '배송중',
  70: '배송완료',
  80: '취소',
  90: '삭제',
  95: '결제대기',
  96: '결제중단'
};

export const unifiedOrderVendorMappings = {
  baemin: '배민',
  foodfly: '푸플',
  yogiyo: '요기요',
  ubereats: '우버이츠',
  ddingdong: '띵동',
  coupangeats: '쿠팡이츠',
  shuttle: '셔틀',
  ghostkitchen: '고스트키친',
  ddangyo: '땡겨요',
  other: '기타',
};

export const unifiedOrderChannelMappings = {
  app: '앱',
  tel: '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY: '배달',
  TAKEOUT: '포장',
  FOODFLY: '푸드플라이',
  BAERA: '배민1',
  DDINGDONG: '띵동',
  UBER: '우버이츠',
  COUPANG: '쿠팡',
  SHUTTLE: '셔틀',
  HERE: '매장식사',
  YOGIYO: '익스프레스'
};

export const deliveryVendorMappings = {
  vroong: '부릉',
  run2u: '런투유',
  spidor: '스파이더',
  barogo: '바로고',
  logiall: '생각대로',
  ghokirun: '고키런',
  manna: '만나플래닛',
  baera: '배민1',
  coupang: '쿠팡',
  yogiyo: '요기요',
  zendeli: '젠딜리',
  shero: '슈퍼히어로',
  dalgo: '달리고',
  iudream: '이어드림',
  baedalyo: '배달요',
  baedalhero: '배달히어로'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED: '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED: '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금'
};

export const coupangeatsOrderStatusMappings = {
  PAYMENT_APPROVED: '신규',
  ACCEPTED: '주문접수',
  PICKED_UP: '배송중',
  COMPLETED: '배송완료',
  CANCELLED: '취소'
};

export const coupangeatsDeliveryStatusMappings = {
  MERCHANT_ACCEPTED: '배차대기',
  COURIER_ACCEPTED: '픽업중',
  MERCHANT_READY: '조리완료',
  COURIER_PICKEDUP: '배송중',
  DELIVERED: '배송완료',
  CANCELLED: '취소'
};

export const combinenetStateCodeMappings = {
  1: '접수',
  2: '배차',
  3: '픽업',
  5: '배달완료',
  44: '취소'
};

export const combinenetPayMethodMappings = {
  0: '후불현금',
  1: '후불카드',
  2: '선불'
};

export const simpleNoticeMappings = {
  hide: '비활성화',
  show: '활성화',
  deleted: '삭제',
};

export const deliveryStatusCodeMappings = {
  10: '접수',
  20: '배차',
  30: '픽업',
  40: '배송완료',
  50: '취소'
};

export const barogoOrderCancelTypeMapping = {
  OTHER_DELIVERY_AGENCY: '다른 배달대행사로 요청',
  DUPLICATE_ORDER: '중복된 주문 접수',
  CANCEL_BY_STORE: '상점 요청',
  CANCEL_BY_CUSTOMER: '고객 요청',
  CANCEL_BY_ADMIN: '관리자 요청',
  CANCEL_BY_TIMEOUT: '수락 시간 초과',
  COMPLAIN_BY_CUSTOMER: '고객의 불만 요청',
  DELAY_DELIVERY: '배달 지연',
  UNNECESSARY_ORDER: '불필요한 주문 접수',
  ETC: '기타'
};

export const deliveryExtraFeeTitleMappings = {
  WEATHER: '기상',
  AREA: '구역',
  OVERLOAD: '과적',
  DISTANCE: '거리',
  HOLIDAY: '휴일',
  TIME: '시간(피크,야간)',
  VALUABLE: '귀중',
  ETC: '기타'
};

export const deliveryGuideText = {
  barogo: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태에서는 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 이후에는 변경이 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  spidor: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 이후에는 변경 및 취소가 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  run2u: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 이후에는 변경 및 취소가 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  logiall: {
    10: '배송완료/취소 전까지 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배송완료/취소 전까지 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '배송완료/취소 전까지 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  manna: {
    10: '취소 전까지 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '취소 전까지 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '취소 전까지 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    40: '배송완료 이후에는 변경이 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  zendeli: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 이후에는 변경 및 취소가 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  dalgo: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태 이후에는 변경 및 취소가 불가능합니다.',
    30: '픽업 이후에는 변경 및 취소가 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  shero: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태 이후에는 변경 및 취소가 불가능합니다.',
    30: '픽업 이후에는 변경 및 취소가 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  iudream: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배치 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 이후에는 변경 및 취소가 불가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  baedalyo: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  },
  baedalhero: {
    10: '접수 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    20: '배차 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    30: '픽업 상태에서는 요청 픽업 시각, 결제 방법/결제 금액, 고객 주소/전화번호/요청사항만 변경 가능합니다.',
    40: '배송완료 이후에는 변경 및 취소가 불가능합니다.',
    50: '취소된 배차는 변경할 수 없습니다.'
  }
};

export const yogiyoOrderCancelTypeMapping = {
  1: '배달 불가지역',
  2: '배달원 부재',
  3: '주문량 폭주',
  4: '메뉴 품절(재료소진)',
  5: '요청사항 적용불가',
  6: '메뉴/가격변동',
  7: '영업시간아님(휴무/조기마감/늦게오픈)',
  8: '기상악화'
};

/** 배민 기준 취소 사유 */
export const cancelCodesMap = {
  DELIVERY: {
    REJECT: [
      { cancelCode: '01', cancelReason: '배달불가지역' },
      { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
      { cancelCode: '20', cancelReason: '재료소진' },
      { cancelCode: '04', cancelReason: '배달지연' },
      { cancelCode: '05', cancelReason: '고객정보부정확' },
      { cancelCode: '06', cancelReason: '기타' },
    ],
    CANCEL: [
      { cancelCode: '07', cancelReason: '고객요청' },
      { cancelCode: '24', cancelReason: '가게사정' },
      { cancelCode: '25', cancelReason: '배달불가' },
      { cancelCode: '26', cancelReason: '재료소진' },
    ]
  },
  TAKEOUT: {
    REJECT: [
      { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
      { cancelCode: '20', cancelReason: '재료소진' },
      { cancelCode: '27', cancelReason: '조리지연' },
      { cancelCode: '28', cancelReason: '가게사정' },
      { cancelCode: '29', cancelReason: '요청사항불가' },
      { cancelCode: '06', cancelReason: '기타' },
    ],
    CANCEL: [
      { cancelCode: '07', cancelReason: '고객요청' },
      { cancelCode: '24', cancelReason: '가게사정' },
      { cancelCode: '26', cancelReason: '재료소진' },
    ]
  },
  /** EAT_IN */
  HERE: {
    REJECT: [
      { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
      { cancelCode: '20', cancelReason: '재료소진' },
      { cancelCode: '27', cancelReason: '조리지연' },
      { cancelCode: '28', cancelReason: '가게사정' },
      { cancelCode: '29', cancelReason: '요청사항불가' },
      { cancelCode: '06', cancelReason: '기타' },
    ],
    CANCEL: [
      { cancelCode: '07', cancelReason: '고객요청' },
      { cancelCode: '24', cancelReason: '가게사정' },
      { cancelCode: '26', cancelReason: '재료소진' },
    ]
  },
  BAERA: {
    REJECT: [
      { cancelCode: '19', cancelReason: '메뉴및가게정보다름' },
      { cancelCode: '20', cancelReason: '재료소진' },
      { cancelCode: '27', cancelReason: '조리지연' },
      { cancelCode: '28', cancelReason: '가게사정' },
      { cancelCode: '29', cancelReason: '요청사항불가' },
      { cancelCode: '06', cancelReason: '기타' },
    ],
    CANCEL: [
      { cancelCode: '07', cancelReason: '고객요청' },
      { cancelCode: '24', cancelReason: '가게사정' },
      { cancelCode: '26', cancelReason: '재료소진' },
    ]
  }
};
