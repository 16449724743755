/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { WHERE } from '../../schema/1/schema-common';
import { NoticeDoc } from '../../schema/3/schema';

import { IpService } from '../1/ip.service';
import { RoomService } from '../1/room.service';
import { UserService } from '../3/user.service';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  private collectionPath = 'notice';

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private roomService: RoomService,
    private ipService: IpService
  ) { }

  observeNotice(wheres: WHERE[]) {
    const queryFn: QueryFn = ref => wheres.reduce((q, where: WHERE) => q.where(where[0], where[1] as firestore.WhereFilterOp, where[2]),
      ref.orderBy('_timeCreate', 'desc')
    );

    const noticeCollection = this.db.collection<NoticeDoc>(this.collectionPath, queryFn);
    return noticeCollection.valueChanges();
  }

  /** 공지를 읽을 때에는 최신 기록으로 업데이트 한다.  */
  readNotice(id: string) {
    const time = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;
    const path = `readBy.${this.userService.user.room}`;
    const doc = {
      _timeUpdate: time,
      [path]: {
        time,
        siteKey: this.roomService.room.site,
        account: this.userService.user.email,
        ipAddress: this.ipService.publicAddress
      }
    };

    return this.db.firestore.doc(`${this.collectionPath}/${id}`).update(doc);
  }
}
