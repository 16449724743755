/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UnifiedOrderContextStatusCode } from '../../schema/3/schema';
import { OrderHistory } from '../../schema/4/schema-functions-call';

import { currencyFormatter, dateFormatter } from '../../core/1/ag-util';
import { unifiedOrderDeliveryTypeMappings, unifiedOrderVendorMappings } from '../../core/1/string-map';

interface OrderHistoriesDialogData {
  orderHistories: OrderHistory[];
}

type Columns =
  | 'orderHistory'
  | 'orderDate'
  | 'shopName'
  | 'foods.mergedName'
  | 'orderAmount'
  | 'orderMsg'
  | 'orderChannel'
  | 'orderVendor'
  | 'deliveryType'
  | 'createdBy'
  | 'paymentMethod';

interface ColumnDef {
  field: Columns;
  headerName: string;
  valueGetter?: (param: { data: any }) => any;
  valueFormatter?: (param: { value: any }) => any;
  refData?: any;
}

type Row = {
  [field in Columns]: any;
};

@Component({
  selector: 'app-dialog-order-history',
  templateUrl: './dialog-order-history.component.html',
  styleUrls: ['./dialog-order-history.component.scss'],
})
export class DialogOrderHistoryComponent implements OnInit {
  public columnsToDisplay: Columns[] = [
    'orderDate',
    'shopName',
    'foods.mergedName',
    'orderAmount',
    'orderMsg',
    // 'orderChannel',
    'orderVendor',
    // 'createdBy',
    'paymentMethod',
  ];
  public rows: Row[] = [];

  public columnDefs: ColumnDef[] = [
    {
      field: 'orderDate',
      headerName: '주문일',
      valueFormatter: (params) =>
        params.value ? dateFormatter(params.value, 'yyyy-MM-dd ccc') : '',
    },
    { field: 'shopName', headerName: '업소명' },
    {
      field: 'foods.mergedName',
      headerName: '메뉴요약',
      valueGetter: (params) => {
        const orderHistory: OrderHistory = params.data;

        return (
          orderHistory?.foods
            ?.map((food) =>
              food.foodQty > 1
                ? `${food.mergedName} x ${food.foodQty}개`
                : food.mergedName
            )
            .join(', ') ?? ''
        );
      },
    },
    {
      field: 'orderAmount',
      headerName: '결제액',
      valueGetter: (params) => {
        const orderHistory: OrderHistory = params.data;
        if (orderHistory) {
          const [{ orderAmount = 0, deliveryTip = 0 } = {}] = [orderHistory];
          return orderAmount + deliveryTip;
        }
        return undefined;
      },
      valueFormatter: currencyFormatter
    },
    { field: 'orderMsg', headerName: '요청사항' },
    {
      field: 'orderVendor',
      headerName: '주문채널',
      valueGetter: (params) => {
        const orderHistory: OrderHistory = params.data;
        let vendorString = unifiedOrderVendorMappings[orderHistory.orderVendor];

        if (
          ['baemin', 'ghostkitchen', 'yogiyo'].includes(
            orderHistory.orderVendor
          )
        ) {
          vendorString += `/${
            unifiedOrderDeliveryTypeMappings[orderHistory.deliveryType]
          }`;
        }
        // 쿠팡이츠는 모두 COUPANGEATS
        return vendorString;
      },
    },
    {
      field: 'paymentMethod', headerName: '결제방식',
      valueGetter: params => {
        const orderHistory: OrderHistory = params.data;
        if (orderHistory.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED) {
          return `${orderHistory.paymentMethod}\n(취소)`;
        } else {
          return orderHistory.paymentMethod;
        }
      }
    },
  ];

  public summary = {
    countOrders: -1,
    countCanceledOrders: 0,
    totalAmount: 0,
  };

  public showCancelOrderOnly = false;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderHistoryComponent, OrderHistoriesDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: OrderHistoriesDialogData,
  ) {}

  ngOnInit(): void {
    this.updateRows();
    this.summary = this.getSummary();
  }

  public onClose() {
    this.dialogRef?.close();
  }

  public showCancelOrderOnlyChange(checked: boolean) {
    this.showCancelOrderOnly = checked;
    this.updateRows();
  }

  private updateRows() {
    const newRows: Row[] = [];

    const orderHistories = this.showCancelOrderOnly === true
      // '취소 주문만 보기' 체크박스에 따른 필터링
      ? this.data.orderHistories.filter(history => history.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED)
      : this.data.orderHistories;

    // 주문시각을 기준으로 정렬한다.
    orderHistories.sort((a, b) => Date.parse(b.orderDate) - Date.parse(a.orderDate));

    for (const orderHistory of orderHistories) {
      const row: Partial<Row> = {
        orderHistory
      };
      for (const columnDef of this.columnDefs) {
        const field = columnDef.field;
        if (this.columnsToDisplay.includes(field)) {
          row[field] = this.value(columnDef, orderHistory);
        }
      }
      newRows.push(row as Row);
    }
    this.rows = newRows;
  }

  private getSummary() {
    return this.data.orderHistories.reduce(
      (sum, history) => {
        if (history.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED) {
          sum.countCanceledOrders++;
        } else {
          sum.countOrders++;
          sum.totalAmount += history.orderAmount + history.deliveryTip;
        }
        return sum;
      },
      {
        countOrders: 0,
        countCanceledOrders: 0,
        totalAmount: 0,
      }
    );
  }

  /**
   * valuGetter를 거친다.
   *
   * @param column 컬럼 정의할 때 valueGetter, valueFormatter, refData를 설정할 수 있다.
   * @param element dataSource 특정 element
   */
  private value(column: ColumnDef, element: any) {
    let v = element[column.field];

    if (column.valueGetter) {
      v = column.valueGetter({ data: element });
    }

    if (column.valueFormatter) {
      // agGrid 형식과 맞추기 위해서 value 필드의 값으로 전달한다.
      return column.valueFormatter({ value: v });
    } else if (column.refData) {
      return column?.refData?.[v] ?? '';
    }

    return v;
  }
}
