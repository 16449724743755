/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

import { debugLog } from '../../core/1/common';
import { AuthService } from '../../core/2/auth.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-dialog-login',
  templateUrl: './dialog-login.component.html',
  styleUrls: ['./dialog-login.component.scss']
})
export class DialogLoginComponent implements OnInit {
  authForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogLoginComponent, boolean>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialogNoticeService: DialogNoticeService
  ) { }

  ngOnInit() {
    this.authForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onOk(): void {
    this.dialogRef.close(true);
  }

  onSubmit() {
    const promise = this.authService.signinWithEmailAndPassword(this.authForm.value.email, this.authForm.value.password);

    promise.then(value => {
      this.dialogRef.close(true);
      debugLog('navigate');
      this.router.navigate(['/']);
    }, error => {
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
    });
  }
}
