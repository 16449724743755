<div *ngIf="showItems.includes('cookMinutes')">
  <div class="section-title">조리 시간</div>
  <mat-button-toggle-group [value]="cookMinutes" (change)="onChangeCookMinutes($event)">
    <mat-button-toggle *ngFor="let option of cookMinutesOptions" [value]="option"
      [checked]="option === cookMinutes">{{ option + '분' }}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div *ngIf="showItems.includes('requestedPickupMinutes')">
  <div class="section-title">요청 픽업 시간<span class="accent-text"> (라이더에게 전달되는 값이다.)</span></div>
  <mat-button-toggle-group [value]="requestedPickupMinutes" (change)="onChangeRequestedPickupMinutes($event)">
    <mat-button-toggle *ngFor="let option of requestedPickupMinutesOptions" [value]="option"
      [checked]="option === requestedPickupMinutes">{{ option + '분' }}</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div *ngIf="showItems.includes('deliveryMinutes')">
  <div class="section-title">예상 배달 시간<span class="accent-text"> (고객에게 전달되는 값이다. 요청 픽업 시간을 변경하면 거리를 고려해서 자동 계산된다.)</span></div>
  <mat-button-toggle-group [value]="deliveryMinutes" (change)="onChangeDeliveryMinutes($event)">
    <mat-button-toggle *ngFor="let option of deliveryMinutesOptions" [value]="option"
      [checked]="option === deliveryMinutes" [disabled]="option < requestedPickupMinutes">{{ option + '분' }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>