/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Howl, Howler } from 'howler';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { debugLog } from '../1/common';
import { textToSpeech } from '../1/util';
import { LogService } from '../4/log.service';

// 개발 환경에서는 볼류 작게
const isDev = environment.firebase?.projectId !== 'toe-prod';
const volume = isDev ? 0.1 : 1.0;

// 2022-04-05: Web Audio는 Safari에서 재생이 되지 않는 경우가 많아서 HTML5 Audio를 이용하도록 한다.
const forceHtml5 = true;

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  // 아이패드가 최초 사용자 터치가 없어서 재생 불가능한 경우에는 재생하지 않도록 할 때 사용한다.
  public soundDisabledSubject = new BehaviorSubject<boolean>(false);
  public get disabled() {
    return this.soundDisabledSubject.getValue();
  }
  public set disabled(newValue: boolean) {
    this.soundDisabledSubject.next(newValue);
  }

  readonly soundOrder = new Howl({
    html5: forceHtml5,
    src: ['/assets/noti.mp3'],
    loop: true,
    preload: true,
    volume,
    // onend: () => {
    //   console.log('onend!');
    // },
    // onload: () => {
    //   console.log('onload');
    // },
    // onloaderror: () => {
    //   console.log('onloaderror');
    // },
    // onpause: () => {
    //   console.log('onpause');
    // },
    // onstop: () => {
    //   console.log('onstop');
    // },
    // onmute: () => {
    //   console.log('onmute');
    // },
    // onseek: () => {
    //   console.log('onseek');
    // },
    // onplay: () => {
    //   console.log('onplay');
    // },
    // onplayerror: () => {
    //   console.log('onplayerror');
    // },
    // onunlock: () => {
    //   console.log('onunlock');
    // },
    // onfade: () => {
    //   console.log('onfade');
    // },
    // onrate: () => {
    //   console.log('onrate');
    // },
    // onvolume: () => {
    //   console.log('onvolume');
    // },
  });
  // 개별 play가 아니라 반복 play가 진행중이라는 뜻
  isPlayingOrder = false;

  readonly soundNotice = new Howl({
    html5: forceHtml5,
    src: ['/assets/notice.mp3'],
    loop: false,
    preload: true,
    volume
  });

  readonly MaxNumPlayCanceled = 3;
  readonly soundCanceled = new Howl({
    html5: forceHtml5,
    src: ['/assets/canceled2.mp3'],
    loop: true,
    preload: true,
    volume,
  });
  remaingNumPlayCanceled = 0;
  isPlayingCanceled = false;

  readonly soundAssigned = new Howl({
    html5: forceHtml5,
    src: ['/assets/assigned2.mp3'],
    loop: false,
    preload: true,
    volume,
  });

  readonly soundTest = new Howl({
    html5: forceHtml5,
    src: ['/assets/onetwothree.mp3'],
    loop: false,
    preload: true,
    volume,
  });

  readonly soundRestart = new Howl({
    html5: forceHtml5,
    src: ['/assets/restart.mp3'],
    loop: false,
    preload: true,
    volume,
  });

  constructor(
    private logService: LogService
  ) {
    debugLog('SoundService::constructor() called');

    this.soundCanceled.on('end', () => {
      this.remaingNumPlayCanceled--;
      // console.log(`this.remainingNumPlayCanceled = ${this.remaingNumPlayCanceled}`);
      if (this.remaingNumPlayCanceled <= 0) {
        this.soundCanceled.stop();
        this.isPlayingCanceled = false;
      }
    });
  }

  playNewOrder() {
    if (this.disabled) {
      return;
    }

    if (!this.isPlayingOrder) {
      this.isPlayingOrder = true;
      // 혹시 재생중인 것이 있으면 stop()
      if (this.soundOrder.playing()) {
        this.soundOrder.stop();
      }
      const soundId = this.soundOrder.play();

      // 예외가 발생하는 경우가 있어서 감싼다.
      const message = '주문! 주문! 주문!';
      try {
        textToSpeech(message);
      } catch (error) {
        this.logService.logRoom(`textToSpeech(${message}) 예외: ${error}`, 'error');
      }

      debugLog(`soundNewOrder.play() => ${soundId}`);
      this.logService.logRoom(`soundNewOrder.play() => ${soundId}, Howler.ctx.state => ${Howler.ctx.state}`, 'debug');

      if (Howler.ctx.state !== 'running') {
        Howler.ctx.resume();
        this.logService.logRoom(`ctx.state가 'running'이 아니라서 'resume' 합니다.`, 'warn');
      }
    }
  }

  stopNewOrder() {
    this.isPlayingOrder = false;
    this.soundOrder.stop();
  }

  playNotice() {
    if (this.disabled) {
      return;
    }

    const soundId = this.soundNotice.play();
    debugLog(`soundNotice.play() => ${soundId}`);
    this.logService.logRoom(`soundNotice.play() => ${soundId}`, 'debug');
  }

  playCanceled() {
    if (this.disabled) {
      return;
    }

    this.remaingNumPlayCanceled = this.MaxNumPlayCanceled;
    if (!this.isPlayingCanceled) {
      this.isPlayingCanceled = true;
      const soundId = this.soundCanceled.play();
      debugLog(`soundCanceled.play() => ${soundId}`);
      this.logService.logRoom(`soundCanceled.play() => ${soundId}`, 'debug');
    }
  }

  playAssigned() {
    if (this.disabled) {
      return;
    }

    const soundId = this.soundAssigned.play();
    debugLog(`soundAssigned.play() => ${soundId}`);
    this.logService.logRoom(`soundAssigned.play() => ${soundId}`, 'debug');
  }

  playRestart() {
    const soundId = this.soundRestart.play();
    debugLog(`soundRestart.play() => ${soundId}`);
    this.logService.logRoom(`soundRestart.play() => ${soundId}`, 'debug');
  }

  playTest() {
    const soundId = this.soundTest.play();
    setTimeout(() => {
      debugLog(`soundTest.play() => ${soundId} Howler.ctx.state => ${Howler.ctx.state}`);
      if (Howler.ctx.state !== 'running') {
        Howler.ctx.resume();
        this.logService.logRoom(`ctx.state가 'running'이 아니라서 'resume' 합니다.`, 'warn');
      }
    }, 100);
    // 시점상 room을 모를 때 로그를 찍는 경우가 있어서 제외한다.
    // this.logService.logRoom(`soundTest.play() => ${soundId}`, 'debug');
  }

  /**
   * 메시지 요청에 의해 재생할 때 사용한다.
   */
  playOnetime(src: string[]) {
    const sound = new Howl({
      html5: forceHtml5,
      src,
      loop: false,
      preload: true,
      volume,
    });

    const soundId = sound.play();
    this.logService.logRoom(`soundOnetime.play(${src}) => ${soundId}`, 'debug');
  }
}
