<div class="create-order-container">
  <app-full-screen-dialog-header [title]="data.order ? '주문 변경' : '주문 입력'" (closed)="onClose()"></app-full-screen-dialog-header>

  <div class="create-order-content">
    <div class="order-detail">
      <div class="section order-info">
        <div class="left">
          <div class="section-title">주문 채널</div>
          <mat-button-toggle-group (change)="onChangeOrderChannel($event)" [value]="orderChannel">
            <mat-button-toggle [disableRipple]="true" value="tel">{{ unifiedOrderChannelMappings['tel'] }}</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="face">{{ unifiedOrderChannelMappings['face'] }}</mat-button-toggle>
          </mat-button-toggle-group>
          
          <div class="section-title">결제 방법</div>
          <mat-button-toggle-group (change)="onChangePaymentMethod($event)" [value]="paymentMethod">
            <mat-button-toggle [disableRipple]="true" value="선불">선불</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="후불현금">후불현금</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="후불카드">후불카드</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="right">
          <div class="section-title">배달 유형</div>
          <mat-button-toggle-group (change)="onChangeDeliveryType($event)" [value]="deliveryType">
            <mat-button-toggle [disableRipple]="true" value="DELIVERY">{{ unifiedOrderDeliveryTypeMappings['DELIVERY'] }}</mat-button-toggle>
            <mat-button-toggle [disableRipple]="true" value="TAKEOUT">{{ unifiedOrderDeliveryTypeMappings['TAKEOUT'] }}</mat-button-toggle>
          </mat-button-toggle-group> 
          
          <div class="section-title">배달팁<span class="baemin-delivery-tip-text">{{ baeminDeliveryTipText }}</span></div>
          <div class="delivery-tip">
            <input class="input" inputmode="decimal" matInput [formControl]="formControlDeliveryTip">
            <button  *ngIf="!data.order" mat-stroked-button (click)="applyBaeminDeliveryTip()" [disabled]="deliveryType !== 'DELIVERY' || deliveryTip?.finalDeliveryTips == undefined || deliveryTip.finalDeliveryTips.length === 0">배민 기준 적용</button>
            <button mat-stroked-button (click)="openDialogDeliveryTip()" [disabled]="deliveryType !== 'DELIVERY'">배민 배달팁 상세</button>
          </div>
        </div>
      </div>

      <div class="section customer-info">
        <app-customer [address]="address" [userTel]="userTel" [orderMsg]="orderMsg"
        (addressEmitter)="onChangeAddress($event)" (userTelEmitter)="onChangeUserTel($event)" (orderMsgEmitter)="onChangeOrderMsg($event)"
        ></app-customer>
      </div>

      <div *ngIf="data.order === undefined"  class="section select-minutes">
        <div *ngIf="deliveryType === 'DELIVERY'" class="delivery-info">
          <div class="left">
            <div class="section-title">배달 대행사</div>
            <div>
              <mat-form-field>
                <mat-select disabled="true" [(value)]="deliveryVendor" (selectionChange)="onDeliveryVendorChanged($event)" >
                  <mat-option *ngIf="deliveryVendors.length === 0" [value]="'none'">---</mat-option>
                  <mat-option [value]="deliveryVendor" *ngFor="let deliveryVendor of deliveryVendors">{{ deliveryVendorMappings[deliveryVendor] }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="right">
            <div class="section-title">배달비</div>
            <div *ngIf="isLoading" class="loading">
              --
            </div> 
            <div *ngIf="!isLoading" class="delivery-fee">
              <!-- 위치가 살짝 바뀌는 것을 방지하기 위해서 visibility를 이용해서 일단 자리는 차지한다. -->
              <span [class.hide]="deliveryInfo.totalFee === 0" *ngIf="deliveryInfo.extraAvailable">기본 {{ deliveryInfo.baseFee | number }}원 + 할증 {{ (deliveryInfo.totalFee - deliveryInfo.baseFee) | number }}원 
              <span class="vat">(VAT 별도)</span> / {{ deliveryInfo.distance | number:'1.0-0' }}m</span>
              <span [class.hide]="deliveryInfo.totalFee === 0" *ngIf="!deliveryInfo.extraAvailable">전체 {{ deliveryInfo.totalFee | number }}원 
              <span class="vat">(할증 포함, VAT 별도)</span> / {{ deliveryInfo.distance | number:'1.0-0' }}m</span>
            </div>
          </div>
        </div>
        <!-- [order]는 가장 앞에 위치해야 한다. -->
        <app-select-minute [order]="order"
          [showItems]="deliveryType !== 'DELIVERY' ? ['cookMinutes'] : ['requestedPickupMinutes', 'deliveryMinutes']"                 
          [requestDeliveryInformationPickups]="deliveryInfo.pickups"
          (changeCookMinutes)="onChangeCookMinutes($event)"
          (changeRequestedPickupMinutes)="onChangeRequestedPickupMinutes($event)"
          (changeDeliveryMinutes)="onChangeDeliveryMinutes($event)"></app-select-minute>
      </div>
      <div class="buttons">
        <button *ngIf="data.order === undefined" [disableRipple]="true" mat-flat-button
          class="order-accept-with-delivery-button" [disabled]="buttonPressed.ACCEPT"
          (click)="onOrderAction('accept', deliveryType === 'DELIVERY' && hasDeliveryVendors)">접수{{
          deliveryType === 'DELIVERY' && hasDeliveryVendors ? ' 및 배차' : ''}}</button>

        <button [disableRipple]="true" *ngIf="deliveryType === 'DELIVERY' && hasDeliveryVendors && data.order === undefined"
          mat-flat-button class="order-accept-button" [disabled]="buttonPressed.ACCEPT"
          (click)="onOrderAction('accept', false)">접수만</button>

        <button [disableRipple]="true" *ngIf="data.order"
        mat-flat-button class="order-accept-button" [disabled]="buttonPressed.ACCEPT" (click)="onOrderAction('modify', false)">
        주문 변경
        </button>
      </div>
    </div>

    <div class="menu-summary">
      <div class="section">
        <div *ngIf="order.foods.length > 0" class="exist-menu">
          <app-menu [order]="order"></app-menu>
          <div>
            <button class="order-menu-button" mat-stroked-button (click)="openDialogOrderMenu()">메뉴 변경</button>
          </div>
        </div>
        
        <div *ngIf="order.foods.length === 0" class="empty-menu">
          <div>'메뉴 입력'을 눌러</div>
          <div>메뉴를 입력해 주세요.</div>
          <button class="order-menu-button" mat-stroked-button (click)="openDialogOrderMenu()">메뉴 입력</button>
        </div>
      </div>
    </div>
  </div>
</div>