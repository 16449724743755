/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { environment } from '../../../environments/environment';

export const logger = {
  info: (msg: string) => {
    if (environment.production === false) {
      console.log(msg);
    }
  },
  warn: (msg: string) => {
    if (environment.production === false) {
      console.error(msg);
    }
  }
};
