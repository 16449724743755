<form [formGroup]="orderForm" class="formGroup">
  <mat-form-field [hideRequiredMarker]="true" floatLabel="always">
    <mat-label>메뉴명</mat-label>
    <input matInput formControlName="foodName" required>
  </mat-form-field>

  <mat-form-field [hideRequiredMarker]="true" floatLabel="always">
    <mat-label>가격</mat-label>
    <!-- 숫자를 키보드 초기 세팅으로 불러오기 위해 inputmode="decimal" -->
    <input matInput formControlName="foodPrice" inputmode="decimal">
    <mat-error *ngIf="orderForm.get('foodPrice').invalid">{{ orderForm.get('foodPrice').errors['reason'] }}</mat-error>
  </mat-form-field>

  <div class="buttons">
    <button mat-flat-button class="button cancel-submit-button" (click)="onCancel()" type="button">취소</button>
    <button mat-flat-button class="button submit-button-with-cancel" [disabled]="orderForm.invalid" (click)="onSubmit()"
      type="button">메뉴 직접 추가</button>
  </div>
</form>