/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { race, NEVER, Subscription } from 'rxjs';
import { map, timeout, filter, take } from 'rxjs/operators';

import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

import { BaeminCancelReasonCode } from '../../schema/1/schema-baemin-common';
import { Message, MessagePeer, MessageBodyMap, MessageResult } from '../../schema/2/schema-message';

import { IpService } from '../1/ip.service';
import { debugLog } from '../1/common';
import { LocalStorageService } from '../1/local-storage.service';
import { textToSpeech } from '../1/util';
import { UtilService } from '../2/util.service';
import { UserService } from '../3/user.service';
import { LogService } from '../4/log.service';
import { SoundService } from '../5/sound.service';
import { InAppBrowserMessageService } from '../5/in-app-browser-message.service';
import { PrinterAgentMessageService } from '../6/printer-agent-message.service';
import { environment } from '../../../environments/environment';

const collectionPath = 'message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private messageSubscription: Subscription;
  private receivedMessageIds = new Set<string>();

  constructor(
    private db: AngularFirestore,
    private ipService: IpService,
    private utilService: UtilService,
    private logService: LogService,
    private userService: UserService,
    private soundService: SoundService,
    private printerAgentMessageService: PrinterAgentMessageService,
    public inAppBrowserMessageService: InAppBrowserMessageService,
    private localStorageService: LocalStorageService,
  ) {
    debugLog(`message session ID = ${this.logService.instanceId}`);
  }

  /**
   * 지정 시각 이후의 메시지 전체를 받는다.
   *
   * @param room user가 속한 room을 instanceId로 사용한다.
   *
   */
  public observeMessage(room: string) {
    const now = new Date();
    // const from = now.getTime() - 10 * 24 * 3600 * 1000;

    debugLog(`${this.constructor.name}::observeMessage from ${now}`);
    const queryFn: QueryFn = ref => {
      return ref
        .where('channel', '==', 'message')
        // .where('type', '==', 'response')
        .where('to.class', '==', 'pos')
        // .where('to.instanceNo', '==', this.instanceId)
        .where('to.instanceNo', '==', room)
        .where('_timeCreate', '>', now);
    };

    const messageCollection = this.db.collection<Message<any, any>>(collectionPath, queryFn);

    if (this.messageSubscription) {
      this.logService.logRoom(`observeMessage(${room})를 다시 시작합니다.`, 'error');
      this.messageSubscription.unsubscribe();
    }
    this.messageSubscription = messageCollection
      .stateChanges()
      .pipe(
        map(actions =>
          actions.map(action => {
            // _type 필드 추가
            return { _type: action.type, ...action.payload.doc.data() };
          })
        )
      )
      .subscribe(messages => {
        for (const message of messages) {
          if (message._type === 'added') {
            // 2021-02-27 내부 코드의 버그 등으로 중복 메시지가 어떤 이유로 발생하는 경우가 있는 듯하여 제거하는 코드를 추가해 본다.
            const messageId = message._id;
            if (this.receivedMessageIds.has(messageId)) {
              this.logService.logRoom(`중복 메시지(${messageId}-${message.type}-${message.name}-${message.from?.class}) 수신 감지 => 무시`, 'error');
            } else {
              this.receivedMessageIds.add(messageId);
              debugLog(`message received : ${message.name}`);
              this.handleMessage(message);
            }
          } else {
            debugLog(`${message.name} ${message._type}`);
          }
        }
      }, error => {
        this.logService.logRoomWithToastrError(`observeMessage에서 에러 발생 : ${error}`);
        setTimeout(() => {
          this.logService.logRoom(`에러 발생후 5초 지나서 observeMessage(${room}) 재시작`);
          this.observeMessage(room);
        }, 5000);
      });
  }

  /**
   * requestId로 요청한 메시지의 응답을 지정 시간까지 기다린다.
   *
   * @param requestId request document의 ID
   * @param msec 밀리초
   */
  private async observeResponseWithTimeout(requestId: string, msec = 12000) {
    // 복합 색인을 피하기 위해서 requestId에 대해서만 조건을 주었다.
    const queryFn: QueryFn = ref => {
      return ref
        .where('requestId', '==', requestId);
    };

    const messageCollection = this.db.collection<Message<'response', any>>(collectionPath, queryFn);

    return new Promise<Message<'response', any>>((resolve, reject) => {
      // refer: https://stackoverflow.com/questions/46886073/rxjs-timeout-to-first-value
      // race는 2개의 observable 중에 1개의 observable을 선택하는 것이지 한 개의 값을 취하는 것이 아니다.
      // 그렇기 때문에 complete이 되는 것도 아니다.
      // complete이 되게 하기 위해서
      // 1. filter로 원치 않는 응답은 거르고
      // 2. take()로 1개만 취했다.
      const messageOb = messageCollection
        .stateChanges()
        .pipe(
          map(actions =>
            actions.map(action => {
              // _type 필드 추가
              return { _type: action.type, ...action.payload.doc.data() };
            })
          ),
          filter(messages => messages.length > 0),  // 최초의 빈 배열은 거른다.
          take(1)
        );
      const timeoutOb = NEVER.pipe(timeout(msec));

      race(messageOb, timeoutOb).subscribe(messages => {
        debugLog('next :');
        // debugDir(messages.toString());

        for (const message of messages) {
          if (message._type === 'added') {
            debugLog(`message response for ${requestId}/${message.name} received`);
            resolve(message);
          } else {
            debugLog(`${message.name} ${message._type}`);
          }
        }
      }, error => {
        // timeout인 경우에는 다음의 형식을 리턴
        // {
        //   message: "Timeout has occurred"
        //   name: "TimeoutError"
        //   stack: "
        // }
        if (error.name === 'TimeoutError') {
          error.message = `응답 대기 시간이 ${msec / 1000}초를 초과했습니다.`;
          debugLog(`observeResponse(${requestId}) Tiemout`);
        } else {
          this.logService.logRoomWithToastrError(`observeMessage에서 에러 발생 : ${error}`);
        }
        reject(error);
      }, () => {
        debugLog(`observeResponse(${requestId}) complete`);
      });
    });
  }

  private handleMessage(message: Message<any, any>) {
    try {
      if (message.type === 'request') {
        this.handleRequest(message);
      } else if (message.type === 'response') {
        this.handleResponse(message);
      } else {
        this.logService.logRoom(`처리할 수 없는 메시지: ${JSON.stringify(message)}`, 'error');
      }
    } catch (error) {
      this.logService.logRoom(`메시지 처리 중에 예외 발생: ${message.type}/${message.name}: ${error}`);
    }
  }

  private handleRequest(message: Message<'request', keyof MessageBodyMap['request']>) {
    switch (message.name) {
      case 'playSound':
        {
          const request = message as Message<'request', 'playSound'>;
          const src = request.body.src;
          const from = request.from;

          this.soundService.playOnetime(src);
          this.response(message.name, from, message._id, 'success', '', { src });
        }
        break;

      case 'reload':
        {
          const request = message as Message<'request', 'reload'>;
          const from = request.from;
          const viewportDesc = window.visualViewport ?
            ('해상도 = ' + window.visualViewport.width + 'x' + window.visualViewport.height + ' (확대비율 ' + Math.round(window.visualViewport.scale * 100) + '%)') : '해상도 모름 ';

          let platform = 'web';
          let version = environment.version;
          const instanceNo = this.utilService.getInstanceId();

          if (this.inAppBrowserMessageService.webkit !== undefined) {
            platform = 'app';
            const ionicInfo = this.inAppBrowserMessageService.IonicInfo;
            const appVersion = ionicInfo ? `${ionicInfo.binaryVersionName}/${ionicInfo.binaryVersionCode}` : '알 수 없음';
            version += `(${appVersion})`;
          }

          const body: MessageBodyMap['response']['reload'] = {
            platform,
            version,
            instanceNo,
            userAgent: navigator?.userAgent ?? 'NA',
            uptime: performance ? Math.floor(performance.now() / 1000) : 0,
            account: this.userService.user.email ?? '',
            publicIP: this.ipService.publicAddress ?? '',
            viewportDesc,
            printAlarm: this.localStorageService.getValue('printAlarm') ?? 'NA'
          };

          this.response(message.name, from, message._id, 'success', '', body);

          // message 응답만 보고 싶은 경우 (message 정상 동작 확인)
          if (request.body.dryRun !== false) {
            this.logService.logRoom('[dryRun] 원격 재시작');
            return;
          }

          const text = '3초 후, 원격 재시작 됩니다.';
          // 예외가 발생하는 경우가 있어서 감싼다.
          try {
            textToSpeech(text);
          } catch (error) {
            this.logService.logRoom(`textToSpeech(${text}) 예외: ${error}`, 'error');
          }

          Swal.fire({
            icon: 'success',
            title: text,
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            didDestroy: () => {
              window.location.reload();
            }
          });
          this.logService.logRoom('원격 재시작');
        }
        break;
      case 'printOrder':
      case 'printStat':
        {
          const from = message.from;
          // embedded printer agent는 자기가 보낸 printOrder, printStat만 처리한다.
          if (from.session !== this.logService.instanceId) {
            debugLog(`${message.name} 요청이 내(${this.logService.instanceId})가 아닌 ${from.session}에서 왔다 => 무시`);
          } else {
            this.printerAgentMessageService.handlePrinterMessageRequest(message);
          }
        }
        break;
      case 'printReview':
      case 'printMessage':
        this.printerAgentMessageService.handlePrinterMessageRequest(message);
        break;
      default:
        this.logService.logRoom(`처리할 수 없는 request 메시지: ${JSON.stringify(message)}`, 'error');
        break;
    }
  }

  private handleResponse(message: Message<'response', keyof MessageBodyMap['response']>) {
    let toastrMessage: string;

    switch (message.name) {
      case 'acceptCoupangeatsOrder':
        // toastrMessage = '쿠팡이츠 주문 접수';
        // break;
        return;

      case 'readyCoupangeatsOrder':
        // toastrMessage = '쿠팡이츠 준비 완료';
        // break;
        return;

      case 'cancelCoupangeatsOrder':
        toastrMessage = '쿠팡이츠 주문 취소';
        break;

      case 'getSafeNumberCoupangeatsOrder':
        return;

      case 'getBaeminBlock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
      case 'postBaeminBlock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
      case 'postBaeminUnblock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;

      case 'printOrder':
        // toastrMessage = `출력 성공 : ${message.body.orderId}`;
        return;
      case 'printStat':
        // toastrMessage = `통계 인쇄`;
        return;
      case 'printMessage':
        return;

      case 'acceptBaeminOrder':
        toastrMessage = `배민 주문 접수 : ${(message as Message<'response', 'acceptBaeminOrder'>).body.orderNo}`;
        break;
      case 'completeBaeminOrder':
        toastrMessage = `배민 배송 완료 : ${(message as Message<'response', 'completeBaeminOrder'>).body.orderNo}`;
        break;
      case 'cancelBaeminOrder':
        toastrMessage = `배민 주문 취소 : ${(message as Message<'response', 'cancelBaeminOrder'>).body.orderNo}`;
        break;
      case 'acceptYogiyoOrder':
        toastrMessage = `요기요 주문 접수 : ${(message as Message<'response', 'acceptYogiyoOrder'>).body.orderNo}`;
        break;
      case 'createVroongDelivery':
        toastrMessage = `배차 요청 : ${(message as Message<'response', 'createVroongDelivery'>).body.client_order_no}`;
        break;
      case 'preparedCargoVroongDelivery':
        toastrMessage = `조리 완료 : ${(message as Message<'response', 'preparedCargoVroongDelivery'>).body.deliveryId}`;
        break;
      default:
        toastrMessage = `알 수 없는 메시지 : ${message.name}`;
        break;
    }

    if (message.result === 'success') {
      this.utilService.toastrInfo(toastrMessage, '성공', 5000);
    } else if (message.result === 'error') {
      this.logService.logRoomWithToastrError(`${toastrMessage}\n${message.reason}`, '실패', 30000);
    } else {
      this.logService.logRoomWithToastrError(`이런 result : ${message.result}`, '실패', 600000);
    }
  }

  /**
   * timeout이 발생한 경우에는 예외가 발생한다.
   * error.name === 'TimeoutError'
   */
  private async request<N extends keyof MessageBodyMap['request']>(
    name: N,
    to: MessagePeer,
    body: MessageBodyMap['request'][N]
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;
    const room = this.userService.user.room;

    const organization = this.userService.organization;
    const cmd: Message<'request', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'pos',
        instanceNo: room,
        account: this.userService.user.email,
        session: this.logService.instanceId
      },
      to,
      type: 'request',
      name,
      body,
    };

    await this.db.doc<Message<'request', N>>(docRef).set(cmd);
    const message = await this.observeResponseWithTimeout(docId);

    return message;
  }

  private response<N extends keyof MessageBodyMap['response']>(
    name: N,
    to: MessagePeer,
    requestId: string,
    result: MessageResult,
    reason: string,
    body: MessageBodyMap['response'][N]
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;
    const organization = this.userService.organization;
    const room = this.userService.user.room;

    const cmd: Message<'response', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'pos',
        instanceNo: room,
        account: this.userService.user.email
      },
      to,
      type: 'response',
      name,
      body,
      requestId,
      result,
      reason
    };

    return this.db.doc<Message<'response', N>>(docRef).set(cmd);
  }

  private notification<N extends keyof MessageBodyMap['notification']>(
    name: N,
    body: MessageBodyMap['notification'][N],
    email?: string
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const organization = this.userService.organization;
    const cmd: Message<'notification', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization,
      channel: 'message',
      from: {
        class: 'pos',
        instanceNo: this.logService.instanceId,
        account: email ? email : this.userService.user.email
      },
      // to,
      type: 'notification',
      name,
      body,
    };

    return this.db.doc<Message<'notification', N>>(docRef).set(cmd);
  }

  public requestAcceptBaeminOrder(instanceNo: string, orderNo: string, deliveryMinutes: number) {
    return this.request('acceptBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  public requestCancelBaeminOrder(instanceNo: string, orderNo: string, cancelReasonCode: BaeminCancelReasonCode) {
    return this.request('cancelBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      cancelReasonCode
    });
  }

  public requestCompleteBaeminOrder(instanceNo: string, orderNo: string) {
    return this.request('completeBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo
    });
  }

  /**
   * 배민의 영업운영중지 상태를 조회환다.
   */
  public requestGetBaeminBlock(instanceNo: string) {
    return this.request('getBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }
  /**
   * 배민 영업운영중지 설정
   * @param temporaryBlockTime 분
   */
  public requestPostBaeminBlock(instanceNo: string, temporaryBlockTime: number) {
    return this.request('postBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      temporaryBlockTime
    });
  }
  /**
   * 배민 영업운영중지 해제
   */
  public requestPostBaeminUnblock(instanceNo: string) {
    return this.request('postBaeminUnblock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }

  /**
   * 쿠팡이츠
   */
  public requestAcceptCoupangeatsOrder(instanceNo: string, orderId: string, duration: string) {
    return this.request('acceptCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      duration
    });
  }

  public requestReadyCoupangeatsOrder(instanceNo: string, orderId: string) {
    return this.request('readyCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId
    });
  }

  public requestCancelCoupangeatsOrder(instanceNo: string, orderId: string, cancelReasonId: string, cancelType: 'DECLINE' | 'CANCEL') {
    return this.request('cancelCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      cancelReasonId,
      cancelType
    });
  }

  /**
   * 요기요
   */
  public requestAcceptYogiyoOrder(orderNo: string, deliveryMinutes: string) {
    const organization = this.userService.organization;

    return this.request('acceptYogiyoOrder', {
      class: 'yogiyo-app-proxy',
      instanceNo: organization === 'ghostkitchen' ? 'default' : organization
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  /**
   * timeout이 발생한 경우에는 예외가 발생한다.
   * error.name === 'TimeoutError'
   */
  public requestPrintOrder(orderId: string, what: 'customer' | 'cook' | 'all' | 'default', printCookOption: 'normal' | 'double') {
    const printer = this.userService.printer;
    const to = this.makePrintTo();

    return this.request('printOrder', to, {
      orderId,
      printerKey: printer._id,
      what,
      printCookOption,
      beep: false,
      autoPrint: false
    });
  }

  public requestPrintStat(ignoreZeroOption: boolean) {
    const printer = this.userService.printer;
    const to = this.makePrintTo();

    return this.request('printStat', to, {
      roomKey: this.userService.user.room,
      printerKey: printer._id,
      ignoreZeroOption
    });
  }

  /**
   * UserDoc, PrintDoc 설정에 따른 MessagePeer를 결정한다.
   */
  private makePrintTo() {
    const printer = this.userService.printer;

    // 1. 설정 확인
    if (printer) {
      if (!['proxy', 'direct'].includes(printer.connectionType)) {
        throw new Error(`지원하지 않는 프린터 타입 ${printer.connectionType}입니다. 관리자에게 알려주세요.`);
      }
      if (printer.connectionType === 'proxy' && !printer.proxyInstanceNo) {
        throw new Error('proxyInstanceNo가 없습니다. 관리자에게 알려주세요.');
      }
    } else {
      throw new Error('프린터 설정을 못 찾았습니다. 관리자에게 알려주세요.');
    }

    // 2. to
    const to: MessagePeer = (printer.connectionType === 'direct') ? {
      class: 'pos',
      instanceNo: this.userService.user.room
    } : {
      class: 'printer-agent',
      instanceNo: printer.proxyInstanceNo
    };

    return to;
  }

  public requestGetSafeNumberCoupangeatsOrder(instanceNo: string, coupangeatsOrderId: string, target: 'customer' | 'courier'): Promise<Message<'response', 'getSafeNumberCoupangeatsOrder'>> {
    return this.request('getSafeNumberCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId: coupangeatsOrderId,
      target
    });
  }

  public notificationLogin(email: string, body: { version: string; localIPs: string; publicIP: string; }) {
    return this.notification('login', body, email);
  }

  public notificationLogout() {
    return this.notification('logout', null);
  }

  public notificationLog(msg: string, context: any = null) {
    return this.notification('log', {
      msg,
      context
    });
  }
}
