/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UnifiedMenuDoc, UnifiedOrderDoc } from '../../schema/3/schema';

import { DialogOrderMenuComponent } from './dialog-order-menu.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOrderMenuService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogOrderMenu(unifiedMenu: Omit<UnifiedMenuDoc, '_timeUpdate' | 'instanceNo'>, unifiedOrder?: UnifiedOrderDoc) {
    return this.dialog.open(DialogOrderMenuComponent, {
      disableClose: true,
      panelClass: 'full-screen-dialog',
      data: {
        unifiedMenu,
        unifiedOrder
      }
    });
  }
}
