/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NoticeDoc } from '../../schema/3/schema';
import { NoticeService } from '../../core/4/notice.service';

@Component({
  selector: 'app-dialog-notice-detail',
  templateUrl: './dialog-notice-detail.component.html',
  styleUrls: ['./dialog-notice-detail.component.scss']
})
export class DialogNoticeDetailComponent implements OnInit {
  public isButtonPressed = false;

  constructor(
    public dialogRef: MatDialogRef<DialogNoticeDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notice: NoticeDoc },
    private noticeService: NoticeService,
  ) { }

  ngOnInit(): void {
  }

  onOk() {
    this.isButtonPressed = true;
    this.noticeService.readNotice(this.data.notice._id);
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }


}
