/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UnifiedMenuFood } from '../../../schema/3/schema';

import { DialogOptionMenuComponent } from './dialog-option-menu.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOptionMenuService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogOptionMenu(unifiedMenuFood: UnifiedMenuFood) {
    return this.dialog.open(DialogOptionMenuComponent, {
      maxHeight: '95vh',
      data: {
        unifiedMenuFood
      }
    });
  }
}
