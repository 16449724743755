<div class="container" [class.existSimpleNotice]="existSimpleNotice" #preventDoubleTap>
  <table mat-table [dataSource]="rows" multiTemplateDataRows [trackBy]="trackBy">
    <!--
        열(matColumnDef)을 정의한다. 여기서 정의한 열을 조합해서 행을 구성한다.
        여기에 정의한
        mat-header-cell은 헤더에 표시할 템플릿, mat-cell은 row에 표시할 템플릿

        1. columnDefs의 열을 정의
        2. empty 열을 정의
        3. quickOne 열을 정의
        4. orderMsg 열을 정의
        -->
    <ng-container matColumnDef="{{ column.field }}" *ngFor="let column of columnDefs">
      <th mat-header-cell *matHeaderCellDef>{{ column.headerName }}</th>
      <!-- agGrid와 호환성을 유지하기 위해서 valueFormatter의 인자를 value 필드에 담는다 -->
      <!-- element는 dataSource의 하나의 element가 된다. row 개별 필드와 manColumnDef와의 관계는 없다. 알아서 사용하는 것이다. -->
      <td mat-cell *matCellDef="let row">
        <!-- 보통은 내용만 표시하면 된다. -->
        <span *ngIf="column.field !== 'pickupTime'">{{ row[column.field] }}</span>

        <!--
            pickupTime은 예외 처리
            미배차 등의 사유로 pickupTime이 아직 없는 경우는 app-timer 대신에 지정한 문자로 출력한다.
            '--', '배차대기' 의 길이는 10을 넘지 못한다.
            TODO: 깨지기 쉬운 코드지만 일단 간단하기 때문에 이용한다.
        -->
        <!-- failToDelivery: 접수 및 배차를 했지만 배차가 없는 경우 
             noDelivery: 배달 주문인데 배차를 하지 않은 경우, 처음에는 배차를 하지 않았다가 나중에 배차가 되었다면 pickupTime.length 조건이 달라져서 여기로 오지 않는다. 뭔가 아슬아슬해서 좋지 않다. -->
        <span *ngIf="column.field === 'pickupTime' && row.pickupTime?.length <= 5"
          [class.failToDelivery]="row.order.withDelivery === true && !(row.order._ui?.relatedDeliveries?.length > 0)"
          [class.noDelivery]="(row.order.deliveryType === 'DELIVERY' && row.order.withDelivery !== true)">{{ row.pickupTime }}</span>
        <!-- 런투유, 스파이더, 부릉, 배민1, 쿠팡이츠인 경우 -->
        <app-timer
          *ngIf="column.field === 'pickupTime' && row.pickupTime?.length > 5 && (row.order.deliveryType === 'DELIVERY' || row.order.deliveryType === 'BAERA' || row.order.deliveryType === 'COUPANG' || row.order.deliveryType === 'YOGIYO')"
          [class.adjusted]="row.order._ui?.isPickupAdjusted"
          [class.riderPickedUp]="row.order._ui?.isRiderPickedUp" [baseDate0]="row.pickupTime"
          [onlyMinute]="true" [isFuture]="true" [suffix]="'분'">
        </app-timer>
      </td>
    </ng-container>

    <!-- 첫 번째 column을 차지할 때 사용한다. -->
    <ng-container matColumnDef="empty">
      <td mat-cell *matCellDef="let row"></td>
    </ng-container>

    <!-- 2,3 번째 column을 차지한다. -->
    <ng-container matColumnDef="posMemoTitle">
      <td mat-cell *matCellDef="let row" [attr.colspan]="2">
        <div class="posMemoTitle">
          운영팀 메모:
        </div>
      </td>
    </ng-container>

    <!-- 2,3 번째 column을 차지하고 orderMsg와 함께 사용한다. -->
    <ng-container matColumnDef="quickOne">
      <td mat-cell *matCellDef="let row" [attr.colspan]="2">
        <div *ngIf="tabName === 'accepted'" class="quickOne">
          <ng-container *ngIf="row.order.contextStatusCode > 15 && row.order.contextStatusCode < 60">
            <!-- anchor element -->
            <!-- 배차 상태가 이미 픽업 이후라면 '픽업확인' 버튼을 바로 노출한다. -->
            <ng-container *ngIf="(row.order._ui?.relatedDeliveries && row.order._ui?.relatedDeliveries[0]?.deliveryStatusCode >= 30) || row.order.contextStatusCode >= 40; then pickUpBlock; else cookBlock"></ng-container>

            <!-- template의 내용이 anchor element 자식으로 들어간다. 그래서 ng-container를 anchor element로 만들었다. -->
            <ng-template #cookBlock>
              <button [disableRipple]="true" mat-stroked-button
                class="accent-button" [disabled]="row.order._ui?.buttonPressed === true || !orderService.canCook(row.order)"
                (click)="commonAction_COOK(row.order, $event)">{{ orderService.canCook(row.order) ? '조리 완료' : '조리요청대기' }}</button>
            </ng-template>

            <ng-template #pickUpBlock>
              <button [disableRipple]="true" mat-stroked-button
                class="primary-button" [disabled]="row.order._ui?.buttonPressed === true"
                (click)="commonAction_PICKUP(row.order, $event)">픽업 확인</button>         
            </ng-template>
          </ng-container>
        </div>
        <div *ngIf="tabName === 'pickedup' && (row.order.deliveryType === 'DELIVERY' || row.order.deliveryType === 'YOGIYO') && row.order.contextStatusCode >= 60 && row.order.contextStatusCode < 70" class="quickOne">
          <button [disableRipple]="true" *ngIf="row.order" mat-stroked-button
          class="complete-button" [disabled]="row.order._ui?.buttonPressed === true"
          (click)="commonAction_COMPLETE(row.order, $event)">배송 완료</button>   
        </div>
        <!-- 업소에서 취소 주문의 존재를 인지했음을 알릴때 사용한다. -->
        <div *ngIf="tabName === 'canceled'" class="quickOne">
          <button [disableRipple]="true" *ngIf="row.order.posCancelConfirmed !== true" mat-stroked-button class="accent-button"
            (click)="confirmCanceledOrder(row.order, $event)">취소 확인</button>
        </div>
      </td>
    </ng-container>

    <!-- 첫 번째 column을 제외하고 나머지 column을 차지한다. 어디까지나 columnDef.-->
    <ng-container matColumnDef="orderMsg">
      <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length - 3">
        <div class="orderMsg">
          <span *ngIf="row.order.orderMsg && row.order.orderMsg.length > 0">{{ row.order.orderMsg }}</span>
        </div>
      </td>
    </ng-container>

    <!-- 첫 번째 column을 제외하고 나머지 column을 차지한다. 어디까지나 columnDef.-->
    <ng-container matColumnDef="posMemo">
      <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length - 3">
        <div class="posMemo">
          <span *ngIf="row.order.posMemo?.length > 0">{{ row.order.posMemo }}</span>
        </div>
      </td>
    </ng-container>

    <!-- 자리차지용이다 -->
    <ng-container matColumnDef="fullEmpty">
      <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length"></td>
    </ng-container>

    <!-- columns 배열의 요소 => matColumnDef -->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

    <!--
        하나의 주문은 아래의 3개의 행을 구성한다.
        각 행을 구성하는 열은 colums 설정을 따른다.
        1행 : columsToDisplay
        2행 : empty(1), quickOne(2), orderMsg(columsToDisplay - 3)
        3행 : empty(1), posMemoTitle(2), posMemo(columnsToDisplay - 3)
        4행 : fullEmpty(columnsToDisplay)
    -->
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="fundamental-row"
      (click)="openDialogOrderDetail(row.order)">
    </tr>

    <!-- quickOne이나 orderMsg가 있는 경우 -->
    <tr mat-row *matRowDef="let row; columns: ['empty', 'quickOne', 'orderMsg']; when: whenSecondaryRow"
      class="secondary-row"
      [class.none]="!row.order.orderMsg || row.order.orderMsg.length === 0"
      (click)="openDialogOrderDetail(row.order)">
    </tr>

    <!-- quickOne이나 orderMsg가 있는 경우 -->
    <tr mat-row *matRowDef="let row; columns: ['empty', 'posMemoTitle', 'posMemo']; when: whenThirdRow"
      class="third-row"
      [class.none]="!row.order.posMemo || row.order.posMemo.length === 0"
      (click)="openDialogOrderDetail(row.order)">
    </tr>

    <!-- 수평선을 긋기 위한 용도이다. 높이는 0 -->
    <tr mat-row *matRowDef="let row; columns: ['fullEmpty'];" class="bottom-row"></tr>
  </table>
  <!-- 더보기 관련 내용은 완료 탭에서만 보이도록 한다. -->
  <div class="more" *ngIf="tabName === 'completed'">
    <button mat-stroked-button color="primary" (click)="moreOrder()" *ngIf="atDate > -7; else noMore">{{ moreButtonText }}까지 내역 보기</button>
    <ng-template #noMore>이후 주문은 발가락 사장님 사이트에서 확인 가능합니다.</ng-template>
  </div>
</div>
