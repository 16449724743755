<form [formGroup]="authForm" (ngSubmit)="onSubmit()">
  <div class="form-container">
    <mat-form-field class="email" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>이메일</mat-label>
      <input matInput formControlName="email" type="email" placeholder="id@example.com" required>
      <mat-error *ngIf="authForm.get('email').invalid">비었거나 형식이 맞지 않습니다.</mat-error>
    </mat-form-field>

    <mat-form-field class="password" [hideRequiredMarker]="true" floatLabel="always">
      <mat-label>password</mat-label>
      <input matInput formControlName="password" type="password" placeholder="" required>
      <mat-error *ngIf="authForm.get('password').invalid">비밀번호가 형식에 맞지 않습니다.</mat-error>
    </mat-form-field>

    <button [disableRipple]="true" mat-raised-button type="submit" color="accent" [disabled]="authForm.invalid">로그인</button>
  </div>
</form>
