/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogOrderDetailComponent } from './dialog-order-detail.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOrderDetailService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogOrderDetail(orderId: string, isAutoOpen = false) {
    return this.dialog.open(DialogOrderDetailComponent, {
      disableClose: true,
      panelClass: 'full-screen-dialog',
      data: {
        orderId,
        isAutoOpen
      }
    });
  }
}
