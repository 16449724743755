/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';
import { OperatingComponent } from './operating/operating/operating.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService, InitGuardService],
    canActivateChild: [AuthGuardService, InitGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'operating',
      },
      {
        path: 'operating',
        pathMatch: 'full',
        component: OperatingComponent
      },
    ]
  },
  // {
  //   path: 'dev',
  //   loadChildren: './dev/dev.module#DevModule',
  //   canLoad: [AuthGuardService],
  //   canActivate: [AuthGuardService]
  // },
  {
    path: '**',
    redirectTo: 'operating'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
