/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// https://www.blazemeter.com/blog/the-correct-way-to-import-lodash-libraries-a-benchmark
import cloneDeep from 'lodash-es/cloneDeep';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addMinutes, format } from 'date-fns';
import Swal from 'sweetalert2';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormControl } from '@angular/forms';

import { DialogTelService } from '../dialog-tel/dialog-tel.service';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';
import { DialogSearchAddressService } from '../dialog-search-address/dialog-search-address.service';
import { DialogSearchAddressData } from '../dialog-search-address/dialog-search-address.component';

import { AugmentedAddress } from '../../schema/1/schema-common';
import { UnifiedDeliveryDoc } from '../../schema/3/schema';
import { CallInputCancelDelivery, CallInputModifyDelivery } from '../../schema/4/schema-functions-call';
import { UnifiedDeliveryService } from '../../core/1/unified-delivery.service';
import { normalizeCurrency, normalizeTel } from '../../core/1/util';
import { deliveryStatusCodeMappings, deliveryVendorMappings } from '../../core/1/string-map';
import { UtilService } from '../../core/2/util.service';
import { LogService } from '../../core/4/log.service';
import { DeliveryService } from '../../core/5/delivery.service';

// 변경사항
interface Change {
  key: string;
  changed: boolean;
  oldValue?: any;
  newValue?: any;
}

type ChangeKeys = 'deliveryTimeRequestedPickup' | 'paymentMethod' | 'paymentAmount' | 'addressKey' | 'addressDetail' | 'addressRoad' | 'userTel' | 'orderMsg';
type Changes = {
  [key in ChangeKeys]?: Change;
};

const labelMapping = {
  deliveryTimeRequestedPickup: '요청 픽업 시각',
  addressKey: '번지 주소',
  addressDetail: '상세 주소',
  addressRoad: '도로명 주소',
  paymentMethod: '결제 방법',
  paymentAmount: '결제 금액',
  userTel: '고객 전화번호',
  orderMsg: '고객 요청사항'
};

@Component({
  selector: 'app-dialog-edit-delivery',
  templateUrl: './dialog-edit-delivery.component.html',
  styleUrls: ['./dialog-edit-delivery.component.scss']
})
export class DialogEditDeliveryComponent implements OnInit, OnDestroy {
  private destroySignal = new Subject<boolean>();
  public delivery: UnifiedDeliveryDoc;

  public orderStatus = '';
  public addressKey = '';
  public addressDetail = '';
  public addressRoad = '';
  public timeSubmitted = '';
  public timeRequestedPickup = '';
  public deliveryStatus = '';
  public deliveryVendor = '';
  public paymentMethod: '후불현금' | '후불카드' | '선불' | 'NA' = 'NA';
  public userTel = '';
  public guide = '';

  public disableRequestedPickupTime = true;
  public disableUserTel = true;
  public disablePaymentMethod = true;
  public disableAddress = true;

  public formControlRequestedPickupTimeMinute = new FormControl(0);
  public formControlPaymentAmount = new FormControl();
  public formControlOrdMsg = new FormControl();

  private relatedOrder: { _id: string; site: string; room: string; } = {
    _id: '',
    site: '',
    room: '',
  };

  public buttonPressed = {
    CANCEL: false,
    MODIFY: false
  };

  public buttonEnable = {
    CANCEL: true,
    MODIFY: true
  };

  constructor(
    public dialogRef: MatDialogRef<DialogEditDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { unifiedOrderId: string, unifiedDeliveryId: string },
    private unifiedDeliveryService: UnifiedDeliveryService,
    private utilService: UtilService,
    private dialogTelService: DialogTelService,
    private logService: LogService,
    private dialogSpinnerService: DialogSpinnerService,
    private deliveryService: DeliveryService,
    private dialogSearchAddressService: DialogSearchAddressService
  ) { }

  ngOnInit(): void {
    this.observeDelivery();
    this.observeCurrency(this.formControlPaymentAmount);

    // 직접 변경할 수 없는 필드
    this.formControlRequestedPickupTimeMinute.disable();
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  observeDelivery() {
    this.unifiedDeliveryService.observeDeliveryById(this.data.unifiedDeliveryId)
      .pipe(takeUntil(this.destroySignal))
      .subscribe((delivery: UnifiedDeliveryDoc) => {
        this.delivery = cloneDeep(delivery);
        this.relatedOrder = { _id: this.delivery.relatedOrderId, site: this.delivery.site, room: this.delivery.room };

        this.setModifiableField();
        this.initUnifiedDelivery();
        this.initButtonEnable();
      });
  }

  /**
   * 벤더, 상태 별로 form에 변경이 가능한 필드를 enable 한다.
   */
  private setModifiableField() {
    const modifiableFeilds = this.deliveryService.modifiableFields(this.delivery);

    this.disableRequestedPickupTime = !modifiableFeilds.includes('requestedPickupTime');
    this.disablePaymentMethod = !modifiableFeilds.includes('initialPaymentMethod');
    this.disableUserTel = !modifiableFeilds.includes('userTel');
    this.disableAddress = !modifiableFeilds.includes('addressKey');

    this.formControlPaymentAmount[modifiableFeilds.includes('initialPaymentAmount') ? 'enable' : 'disable']();
    this.formControlOrdMsg[modifiableFeilds.includes('orderMsg') ? 'enable' : 'disable']();
  }

  initUnifiedDelivery() {
    // 배차 접수 시각
    this.timeSubmitted = format(new Date(this.delivery.timeSubmitted), 'HH:mm:ss');
    // 배송 상태
    this.deliveryStatus = deliveryStatusCodeMappings[this.delivery.deliveryStatusCode];
    // 배달 대행사
    this.deliveryVendor = deliveryVendorMappings[this.delivery.deliveryVendor];

    // 라이더 정보 업데이트
    this.delivery.riderName = this.deliveryService.riderName(this.delivery);
    this.delivery.riderTel = this.deliveryService.riderTel(this.delivery);

    // 고객 주소
    this.addressKey = this.delivery.address_key;
    this.addressDetail = this.delivery.address_detail;
    this.addressRoad = this.delivery.address_road;
    // 고객 전화 번호
    this.userTel = normalizeTel(this.delivery.userTel);
    // 고객 요청사항
    this.formControlOrdMsg.setValue(this.delivery.orderMsg);

    // 요청 픽업 시각
    this.timeRequestedPickup = format(new Date(this.delivery.requestedPickupTime), 'HH:mm');
    // 결제 방법 - finalPaymentMethod가 채워질 때는 변경할 수 없어서 initialPaymentMethod만 사용
    this.paymentMethod = this.delivery.initialPaymentMethod;
    this.formControlPaymentAmount.setValue(new Intl.NumberFormat().format(this.delivery.initialPaymentAmount));

    // 대행사별 안내사항
    this.guide = this.deliveryService.guide(this.delivery);
  }

  initButtonEnable() {
    this.buttonEnable.MODIFY = this.delivery !== undefined ? this.deliveryService.modifiableFields(this.delivery).length > 0 : false;
    this.buttonEnable.CANCEL = this.delivery !== undefined ? this.deliveryService.canCancel(this.delivery) : false;
  }

  /**
   * 요청 픽업 시각 조정
   * 최소 0분 최대 30분
   */
  changeMinute(offset: -5 | 5) {
    const min = 0;
    const max = 30;
    const requestedPickupTimeMinute = parseInt(this.formControlRequestedPickupTimeMinute.value, 10);
    if (requestedPickupTimeMinute + offset < min) {
      this.utilService.toastrInfo('요청시각보다 빠르게 조정할 수 없습니다.');
      return;
    }

    if (requestedPickupTimeMinute + offset > max) {
      this.utilService.toastrInfo(`최대 ${max}분까지 조정이 가능합니다.`);
      return;
    }

    this.formControlRequestedPickupTimeMinute.setValue(requestedPickupTimeMinute + offset);
    const newRequestedPickupTime = addMinutes(new Date(this.delivery.requestedPickupTime), this.formControlRequestedPickupTimeMinute.value);
    this.timeRequestedPickup = format(newRequestedPickupTime, 'HH:mm');
  }

  /**
   * 금액에 변화가 있으면 포맷을 자동 적용한다.
   */
  private observeCurrency(formControl: AbstractControl) {
    formControl.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        const normalizedCurrency = normalizeCurrency(String(value));
        if (value !== normalizedCurrency) {
          formControl.setValue(normalizedCurrency);
        }
      });
  }

  public openDialogTel() {
    this.dialogTelService.openNumberPadDialog(this.userTel).afterClosed().subscribe(telNo => {
      if (telNo === undefined) {
        // 취소
      } else if (telNo === '') {
        this.utilService.toastrWarning('전화번호가 올바르지 않습니다.');
      } else {
        this.userTel = normalizeTel(telNo);
      }
    });
  }

  /**
   * 필드 변경 여부를 반환
   */
  public changedField(key: string): Change {
    if (!this.delivery) {
      return {
        key,
        changed: false
      };
    }

    let oldValue;
    let newValue;

    switch (key) {
      case 'deliveryTimeRequestedPickup':
        const requestedPickupTimeMinute = parseInt(this.formControlRequestedPickupTimeMinute.value, 10);
        const oldRequestedPickupTime = new Date(this.delivery.requestedPickupTime);

        oldValue = format(oldRequestedPickupTime, `yyyy-MM-dd'T'HH:mm:ss+09:00`);
        const newRequestedPickupTime = addMinutes(oldRequestedPickupTime, requestedPickupTimeMinute);
        newValue = format(newRequestedPickupTime, `yyyy-MM-dd'T'HH:mm:ss+09:00`);
        break;
      case 'paymentMethod':
        oldValue = this.delivery.initialPaymentMethod;
        newValue = this.paymentMethod;
        break;
      case 'paymentAmount':
        oldValue = this.delivery.initialPaymentAmount + '';
        newValue = this.formControlPaymentAmount.value.replace(/,/g, '');
        break;
      case 'addressKey':
        oldValue = this.delivery.address_key;
        newValue = this.addressKey;
        break;
      case 'addressDetail':
        oldValue = this.delivery.address_detail;
        newValue = this.addressDetail;
        break;
      case 'addressRoad':
        oldValue = this.delivery.address_road;
        newValue = this.addressRoad;
        break;
      case 'userTel':
        oldValue = this.delivery.userTel;
        newValue = this.userTel.replace(/-/g, '');
        break;
      case 'orderMsg':
        oldValue = this.delivery.orderMsg;
        newValue = this.formControlOrdMsg.value;
        break;
      default:
        this.logService.logRoom(`새로운 변경 가능한 key가 등장했다. ${key}`, 'error');
        // 같은 값을 넣어서 changed가 false가 되게한다.
        oldValue = key;
        newValue = key;
    }

    return {
      key,
      changed: oldValue !== newValue,
      oldValue,
      newValue
    };
  }

  public displayFormatter(key: string, value: any) {
    switch (key) {
      case 'deliveryTimeRequestedPickup':
        return format(new Date(value), `HH:mm`);
      case 'paymentAmount':
        return Intl.NumberFormat().format(value);
      case 'userTel':
        return normalizeTel(value);
    }

    return value;
  }

  /**
   * 기존 값과 달라진(변경된) 값을 반환한다.
   */
  public checkFormValue(): Changes {
    const keys = ['deliveryTimeRequestedPickup', 'paymentMethod', 'paymentAmount', 'addressKey', 'addressDetail', 'addressRoad', 'userTel', 'orderMsg'];
    const changes: Changes = {};

    // filter 조건에서 Change를 사용하기 때문에 mapping을 먼저 한다.
    keys.map(key => this.changedField(key))
      .filter((changed: Change) => changed.changed)
      .forEach((changed: Change) => {
        changes[changed.key] = changed;
      });

    return changes;
  }

  public onChangePaymentMethod(event) {
    this.paymentMethod = event.value;
  }

  public openDialogSearchAddress() {
    const searchAddressData: DialogSearchAddressData = {
      address_key: this.addressKey,
      address_detail: this.addressDetail
    };

    this.dialogSearchAddressService.openDialog(searchAddressData).afterClosed()
      .subscribe((result: {
        augmentedAddress: AugmentedAddress
        addressDetail: any
      }) => {
        if (result) {
          this.addressKey = result.augmentedAddress.key;
          this.addressDetail = result.addressDetail;
          this.addressRoad = result.augmentedAddress.road ?? '';
        }
      });
  }

  onClose() {
    this.dialogRef.close();
  }

  /**
   * 배차 취소
   */
  public async onClickCancel() {
    // 버튼 처리
    if (this.buttonPressed.CANCEL === true) {
      return;
    }

    this.buttonPressed.CANCEL = true;

    // 취소 불가
    if (!['barogo', 'run2u', 'spidor', 'logiall', 'manna', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'].includes(this.delivery.deliveryVendor)) {
      Swal.fire('해당 배달 대행사는 배차 취소가 불가합니다.');
      this.buttonPressed.CANCEL = false;
      return;
    }

    // 1. 취소 사유 확인 / 사유 목록이 없으면 취소 요청 확인 다이얼로그
    const cancelReasons = this.deliveryService.cancelReasonsFor(this.delivery);
    const cancelReason = cancelReasons ? await Swal.fire({
      title: '취소 사유를 선택해 주세요',
      input: 'radio',
      inputOptions: cancelReasons,
      inputPlaceholder: '취소 사유 선택',
      showCancelButton: true,
      cancelButtonText: '닫기',
      confirmButtonText: '취소 요청',
      confirmButtonColor: 'hsl(212, 100%, 50%)',
      // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
      reverseButtons: true,
      // 스타일의 스코프를 지정하기 위한 target
      target: 'app-dialog-edit-delivery',
      inputValidator: value => value ? null : '취소 사유를 하나 선택해야합니다.'
    }) : await Swal.fire({
      titleText: '배차를 취소하시겠습니까?',
      showCancelButton: true,
      cancelButtonText: '닫기',
      confirmButtonText: '취소 요청',
      confirmButtonColor: 'hsl(212, 100%, 50%)',
      // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
      reverseButtons: true
    });

    if (!cancelReason.isConfirmed) {
      this.buttonPressed.CANCEL = false;
      return;
    }

    // 2. 취소 요청 보내고 응답 처리
    const spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('취소 요청...');
    try {
      // 취소 버튼 로그 기록
      this.logService.logOrder(this.relatedOrder, `'취소' 버튼을 눌러 배차 취소 요청\ndeliveryVendor: ${this.delivery.deliveryVendor}, deliveryNo: ${this.delivery.deliveryNo}}`);

      const callInputCancelDelivery: CallInputCancelDelivery = {
        organization: this.delivery.organization,
        site: this.delivery.site,
        room: this.delivery.room,
        deliveryVendor: this.delivery.deliveryVendor,
        deliveryNo: this.delivery.deliveryNo,
        cancelReason: cancelReason.value
      };

      const cancelResult = await this.deliveryService.cancel(this.delivery, callInputCancelDelivery);
      if (cancelResult) {
        this.dialogRef?.close(false);
        this.dialogRef = undefined;
      }
    } catch (error) {
      this.logService.logOrder(this.relatedOrder, `배차 취소 중 예외 발생 ${error}`, 'error', true);
    }

    spinnerDialogRef.close();
    this.buttonPressed.CANCEL = false;
  }

  /**
   * 배차 변경
   */
  public async onClickModify() {
    // 버튼 처리
    if (this.buttonPressed.MODIFY === true) {
      return;
    }

    this.buttonPressed.MODIFY = true;

    // 변경 불가
    if (!['barogo', 'logiall', 'run2u', 'spidor', 'manna', 'zendeli', 'shero', 'dalgo', 'iudream', 'baedalyo', 'baedalhero'].includes(this.delivery.deliveryVendor)) {
      Swal.fire('해당 배달 대행사는 배차 변경이 불가합니다.');
      this.buttonPressed.MODIFY = false;
      return;
    }

    const changes: Changes = this.checkFormValue();

    if (Object.keys(changes).length === 0) {
      Swal.fire('변경한 값이 없습니다.');
      this.buttonPressed.MODIFY = false;
      return;
    }

    // 1. 변경 사항 확인
    const { isConfirmed } = await this.openSwalConfirmChanges(Object.values(changes));

    if (!isConfirmed) {
      this.buttonPressed.MODIFY = false;
      return;
    }

    // 2. 변경 요청 보내고 응답 처리
    const spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('변경 요청...');
    try {
      // 1. 변경 버튼 누름 로그 기록
      this.logService.logOrder(this.relatedOrder, `'변경' 버튼을 눌러 배차 정보 변경 요청\ndeliveryVendor: ${this.delivery.deliveryVendor}, deliveryNo: ${this.delivery.deliveryNo}, changeValues: ${JSON.stringify(changes)}`);

      // 2. 변경사항으로 전달할 객체 만들기
      const callInputModifyDelivery = this.callInputModifyDelivery(changes);

      // 3. 변경 요청
      const modifyResult = await this.deliveryService.modify(this.delivery, callInputModifyDelivery);
      if (modifyResult) {
        this.dialogRef?.close(false);
        this.dialogRef = undefined;
      }
    } catch (error) {
      this.logService.logOrder(this.relatedOrder, `배차 변경 중 예외 발생 ${error}`, 'error', true);
    }

    spinnerDialogRef.close();
    this.buttonPressed.MODIFY = false;
  }

  /**
   * 변경 요청에 보낼 callInputModifyDelivery 객체를 만든다.
   */
  private callInputModifyDelivery(changes: Changes): CallInputModifyDelivery {
    const delivery: Partial<UnifiedDeliveryDoc> = {
      // logOrder에 사용하기 위해 정보를 추가한다.
      relatedOrderId: this.delivery.relatedOrderId,
      organization: this.delivery.organization,
      site: this.delivery.site,
      room: this.delivery.room,

      deliveryVendor: this.delivery.deliveryVendor,
      deliveryStatusCode: this.delivery.deliveryStatusCode,
      deliveryNo: this.delivery.deliveryNo,
    };

    /**
     * 변경된 값을 요청 값에 추가해준다.
     * address, payment는 한 가지라도 변경된 것이 있으면 연관된 값을 다 채워서 보내준다.
     * 화면 표시용 형식을 원래의 저장 형식으로 다시 바꿔준다.
     */
    if (changes.addressKey || changes.addressDetail || changes.addressRoad) {
      // 시도 영역을 복구 - 주소를 변경해도 시도가 변경되는 경우는 없다고 가정한다.
      delivery.address_key = this.addressKey;
      delivery.address_detail = this.addressDetail;
      delivery.address_road = this.addressRoad;
    }

    if (changes.deliveryTimeRequestedPickup) {
      const newRequestedPickupTime = addMinutes(new Date(this.delivery.requestedPickupTime), this.formControlRequestedPickupTimeMinute.value);
      delivery.requestedPickupTime = format(newRequestedPickupTime, `yyyy-MM-dd'T'HH:mm:ss+09:00`);
    }

    if (changes.orderMsg) {
      delivery.orderMsg = this.formControlOrdMsg.value;
    }

    if (changes.paymentMethod || changes.paymentAmount) {
      delivery.initialPaymentMethod = this.paymentMethod;
      delivery.initialPaymentAmount = parseInt(this.formControlPaymentAmount.value.replace(/,/g, ''), 10);
    }

    if (changes.userTel) {
      delivery.userTel = this.userTel.replace(/-/g, '');
    }

    const callInput: CallInputModifyDelivery = {
      delivery
    };

    return callInput;
  }

  private async openSwalConfirmChanges(changes: Change[]) {
    const changeMessage = changes.map(change => `<tr><td>${labelMapping[change.key]}</td><td>${this.displayFormatter(change.key, change.oldValue)}</td><td>${this.displayFormatter(change.key, change.newValue)}</td></tr>`).join('');

    return await Swal.fire({
      title: '배차 정보를 변경하시겠습니까?',
      html: `<table class="swal-table">
        <tr>
        <th>항목</th>
        <th>기존</th>
        <th>변경</th>
        </tr>
      ${changeMessage}
      </table>`,
      cancelButtonText: '취소',
      cancelButtonColor: 'hsl(4.1, 89.6%, 58.4%)',
      showCancelButton: true,
      confirmButtonText: '변경',
      confirmButtonColor: 'hsl(212, 100%, 50%)',
      // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
      reverseButtons: true,
      // 스타일의 스코프를 지정하기 위한 target
      target: 'app-dialog-edit-delivery',
      customClass: {
        popup: 'swal-wide'
      }
    });
  }

}
