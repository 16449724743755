<div class="order-menu-container">
  <app-full-screen-dialog-header
  [title]="title"
  (closed)="closeDialog()"
  ></app-full-screen-dialog-header>

  <div class="content">
    <div class="menus">
      <ng-container *ngFor="let unifiedMenuFoodGrp of data.unifiedMenu.menus" class="unifiedMenuFoodGrp">
        <div class="menuGroup">{{unifiedMenuFoodGrp.foodGrpName}}</div>
        <div *ngFor="let unifiedMenuFood of unifiedMenuFoodGrp.foods" class="unifiedMenuFood"
          [class.soldout]="unifiedMenuFood.foodSoldOut" (click)="openDialogOption(unifiedMenuFood)">
          <span class="menuName">{{unifiedMenuFood.foodName}}</span>
          <span class="displaySoldOut">(품절)</span>
          <span class="menuPrice">{{ unifiedMenuFood.foodOptGroups[0].foodOpts[0].optPrice | number }}</span>
        </div>
      </ng-container>
    </div>

    <div class="orderSummary">
      <div class="orderSummaryHeader">
        <span class="headerMenuName">메뉴</span>
        <span class="headerMenuQty">수량</span>
        <span class="headerMenuPrice">금액</span>
      </div>

      <div class="orderSummaryMenuItem">
        <app-order-summary-menu-item
        *ngFor="let unifiedOrderFood of unifiedOrderFoods; let i = index;"
        [unifiedOrderFood]="unifiedOrderFood"
        [menuIndex]="i"
        (removeFood)="removeFood($event)"
        (increaseFoodQty)="addFoodQty($event, 1)"
        (decreaseFoodQty)="addFoodQty($event, -1)"
        ></app-order-summary-menu-item>
      </div>

      <div class="total">
        <span>합계:</span>
        <span class="totalPrice"><span>{{totalPrice | number}}</span>원</span>
      </div>
      <div class="buttons">
        <button mat-stroked-button class="button addOrderForm" (click)="openDialogOrderForm()">메뉴 직접 추가</button>
        <button mat-flat-button class="button finishUnifiedMenu" (click)="finishUnifiedMenu()">메뉴 입력 완료</button>
      </div>
    </div>
  </div>
</div>