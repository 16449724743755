/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NoticeDoc } from 'src/app/schema/3/schema';

import { DialogNoticeDetailComponent } from './dialog-notice-detail.component';

@Injectable({
  providedIn: 'root'
})
export class DialogNoticeDetailService {

  constructor(
    private dialog: MatDialog
  ) { }

  openSimpleNoticeDialog(notice: NoticeDoc) {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    this.dialog.open(DialogNoticeDetailComponent, {
      disableClose: true,
      data: {
        notice
      }
    });
  }

}
