/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { PrintService } from '../../core/8/print.service';

import { DialogSpinnerComponent } from '../dialog-spinner/dialog-spinner.component';


@Component({
  selector: 'app-dialog-order-stats',
  templateUrl: './dialog-order-stats.component.html',
  styleUrls: ['./dialog-order-stats.component.scss']
})
export class DialogOrderStatsComponent implements OnDestroy {
  private spinnerDialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderStatsComponent>,
    private printService: PrintService,
  ) { }

  ngOnDestroy() {
    this.spinnerDialogRef?.close();
    this.spinnerDialogRef = undefined;
  }

  public async printStat(ignoreZeroOption = false) {
    await this.printService.printStat(ignoreZeroOption);
  }

  public close() {
    this.dialogRef.close();
  }
}
