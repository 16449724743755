/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import cloneDeep from 'lodash-es/cloneDeep';
import { combineLatest, Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { WHERE } from '../../schema/1/schema-common';
import { NoticeDoc, UnifiedDeliveryStatusCode, UnifiedOrderContextStatusCode, UnifiedOrderDoc } from '../../schema/3/schema';

import { debugLog } from '../../core/1/common';
import { unifiedOrderVendorMappings } from '../../core/1/string-map';
import { dateFormatter } from '../../core/1/ag-util';
import { NotificationCenterService } from '../../core/1/notification-center.service';
import { UnifiedDeliveryService } from '../../core/1/unified-delivery.service';
import { RoomService } from '../../core/1/room.service';
import { isiPad, sleep, textToSpeech } from '../../core/1/util';
import { UtilService } from '../../core/2/util.service';
import { UserService } from '../../core/3/user.service';
import { LogService } from '../../core/4/log.service';
import { NoticeService } from '../../core/4/notice.service';
import { UnifiedOrderDocUI } from '../../schema/4/schema-ui';
import { UnifiedOrderService } from '../../core/5/unified-order.service';
import { SoundService } from '../../core/5/sound.service';
import { FirebaseManagerService } from '../../core/5/firebase-manager.service';

// lib.dom.d.ts 선언이 아닌 @types/node가 인식되어 추가한다.
declare function setInterval(handler: TimerHandler, timeout?: number, ...arguments: any[]): number;

@Component({
  selector: 'app-operating',
  templateUrl: './operating.component.html',
  styleUrls: ['./operating.component.scss']
})
export class OperatingComponent implements OnInit, OnDestroy {
  private destroySignal = new Subject<boolean>();
  private subscription: Subscription;
  private syncSubscription: Subscription;

  /** 상태 변화를 비교할 때 사용 */
  private allOrders: UnifiedOrderDoc[] = [];

  /** 신규이면서 조리시간 미정 */
  public newOrders: UnifiedOrderDoc[] = [];
  public newOrdersSum = 0;
  /** 조리시간 정해지고 완료 되기 전 */
  public acceptedOrders: UnifiedOrderDoc[] = [];
  public acceptedOrdersSum = 0;

  /** 배송중 */
  public pickedupOrders: UnifiedOrderDoc[] = [];
  public pickedupOrdersSum = 0;

  /** 완료 */
  public completedOrders: UnifiedOrderDoc[] = [];
  public completedOrdersSum = 0;
  /** 취소 */
  public canceledOrders: UnifiedOrderDoc[] = [];
  public numUnconfirmedCancelOrders = 0;

  /** 취소를 제외한 주문 */
  private validOrders: UnifiedOrderDoc[] = [];

  public selectedIndex = 0;

  private intervalTimer: number;
  private unconfirmedCancelSubscription: Subscription;

  /** 이전 visibilitychange 와의 시간차 */
  private lastVisibilityUptime = 0;

  /** 공지사항 */
  public notices: NoticeDoc[] = [];
  public countUnreadNotice = 0;

  private get atDate() {
    return this.notificationCenterService.atDateSubject.value;
  }
  private set atDate(v: number) {
    this.notificationCenterService.atDateSubject.next(v);
  }

  constructor(
    private unifiedOrderService: UnifiedOrderService,
    private unifiedDeliveryService: UnifiedDeliveryService,
    private userService: UserService,
    private roomService: RoomService,
    private soundService: SoundService,
    private logService: LogService,
    private noticeService: NoticeService,
    private firebaseManagerService: FirebaseManagerService,
    private utilService: UtilService,
    private notificationCenterService: NotificationCenterService,
  ) { }

  ngOnInit() {
    document.onvisibilitychange = () => {
      const now = performance ? performance.now() : 0;

      const nowSeconds = Math.floor(now / 1000);
      const diffSeconds = Math.floor((now - this.lastVisibilityUptime) / 1000);
      this.lastVisibilityUptime = now;

      this.logService.logRoom(`[${diffSeconds}/${nowSeconds}] document.visibilityState: ${document.visibilityState}`, 'debug');
      if (document.visibilityState !== 'visible') {
        this.soundService.stopNewOrder();
      }
    };

    const start = () => {
      // atDate가 변경되면 다시 observe() 한다.
      // behaviorSubject()이기 때문에 최초에도 실행된다.
      this.notificationCenterService.atDateSubject
        .pipe(takeUntil(this.destroySignal))
        .subscribe(v => {
          this.observe();
        });

      // 10초 뒤에 10초 마다 신규 알림 재생 여부를 확인한다.
      timer(10 * 1000, 10 * 1000).subscribe(n => {
        if (document.visibilityState === 'visible') {
          // debugLog(`[${n}] timer run playNewOrderConditionally`);
          this.playNewOrderConditionally();
        } else {
          this.soundService.stopNewOrder();
        }
      });

      this.observeNotices();
    };

    // 아이패드에서 소리 알림 터치를 하지 않아서 소리를 발생할 수 없는 상황에서는
    // 주문을 가져오지 않는다.
    // 터치 후에 신규 주문이 있어도 소리가 나지 않는 문제를 해결하기 위함이다.
    if (this.soundService.disabled) {
      const subscription = this.soundService.soundDisabledSubject.subscribe(disabled => {
        // 소리 재생 가능한 상태가 되었으니 주문을 받기 시작한다.
        if (disabled === false) {
          subscription.unsubscribe();
          start();
        }
      });
    } else {
      start();
    }

    this.intervalTimer = setInterval(() => {
      debugLog('3시간마다 다시 시작하기');
      this.logService.logRoom('3시간이 지나서 OperatingComponent::observe 재시작하기');
      this.observe();
    }, 3600 * 3000);
  }

  ngOnDestroy() {
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer);
    }

    this.subscription?.unsubscribe();
    this.subscription = undefined;

    this.unconfirmedCancelSubscription?.unsubscribe();
    this.unconfirmedCancelSubscription = undefined;

    this.syncSubscription?.unsubscribe();
    this.syncSubscription = undefined;

    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  public onSelectedIndexChange(index: number) {
    // 탭을 이동하고, atDate가 0이 아닐 때(이전 기록 조회)만 데이터를 다시 불러온다.
    if (this.selectedIndex !== index && this.atDate !== 0) {
      this.atDate = 0;
    }
    this.selectedIndex = index;
  }

  public observe() {
    const atDate = this.atDate;

    this.subscription?.unsubscribe();
    this.subscription = undefined;

    this.observeUnconfirmedCancel();

    // 이전 내역을 조회할 때에는 0시 기준으로 조회한다.
    const searchHour = atDate === 0 ? 6 : 0;
    // 조회 일자를 기준으로 +2일 추가 조회
    // atDate => duration
    //  0 => 2,
    // -1 => 3,
    // -2 => 4,
    const duration = 2 - atDate;
    const roomKey = this.userService.user.room;

    const observableOrder = this.unifiedOrderService.observeOrder(roomKey, searchHour, 0, atDate, duration, 'desc');
    const observableDelivery = this.unifiedDeliveryService.observe([['room', '==', roomKey]], searchHour, 0, atDate, duration, 'desc');

    // 당일 주문 건을 필터링할 기준 날짜를 뽑는다.
    const { atDateStringFrom } = this.unifiedOrderService.makeAtDateString(6, 0, 0, 2);

    this.subscription = combineLatest([
      observableOrder,
      observableDelivery,
    ]).subscribe(([orders0, deliveries0]) => {
      // debugLog('OperatingComponent::subscribe called');

      // tslint:disable-next-line: max-line-length
      // console.log(`orders = ${orders.length}, vroong = ${vroongDeliveries.length}, coupangeates = ${coupangeatsOrders0.length}, combinenet = ${combinenetDeliveries0.length}`);

      // refer: https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
      // orders가 아닌 다른 입력의 변화에 의해서 불린 경우에 orders는 과거의 orders를 그대로 사용하게 된다.
      // _ui 등이 누적되어 변경될 수 있는 문제가 있어서 복사해서 사용하다.
      // _ui만 제거해서 사용하는 것도 고려할 수 있으나 잠재적인 문제를 야기할 수 있어서 깔끔하게 복사한다.
      const orders1 = cloneDeep(orders0);
      const deliveries = cloneDeep(deliveries0);

      const orders: UnifiedOrderDocUI[] = orders1.filter(order => {
        // 완료 이외의 주문은 당일 건만 보이도록 필터링한다.
        if (order.contextStatusCode !== UnifiedOrderContextStatusCode.COMPLETED) {
          return order.orderDate >= atDateStringFrom;
        } else {
          return true;
        }
      });

      // debugLog(`orders = ${orders.length}`);
      // debugLog(`orderContexts = ${orderContexts.length}`);

      // 기존의 코드가 _ui.relatedDeliveries에 의존적이기 때문에
      // 'BAERA', 'COUPANG'의 경우에 unifiedDelivery를 _ui.relatedDeliveries에 추가한다.
      orders.forEach(order => {
        if (order.unifiedDelivery) {
          if (!order._ui) {
            order._ui = {};
          }
          // order.unifiedDelivery는 UnifiedDelivery의 일부 필드만 있으므로 섬세한 주의가 필요하다.
          order._ui.relatedDeliveries = [order.unifiedDelivery as any];
        }
      });

      deliveries
        .filter(delivery => delivery.deliveryStatusCode !== UnifiedDeliveryStatusCode.CANCELED)
        .forEach(delivery => {
          const orderId = delivery.relatedOrderId;

          const matchingOrder = orders.find(order => order._id === orderId);
          if (matchingOrder) {
            const { _ui = {} } = matchingOrder;
            const { relatedDeliveries = [] } = _ui;

            relatedDeliveries.push(delivery);

            _ui.relatedDeliveries = relatedDeliveries;
            matchingOrder._ui = _ui;
          }
        });


      // 분류하기
      /** 10 (NEW) */
      const newOrders: UnifiedOrderDoc[] = []; let newOrdersSum = 0;
      /** 20 (ACCEPTED) */
      const acceptedOrders: UnifiedOrderDoc[] = []; let acceptedOrdersSum = 0;
      /** 60 (PICKEDUP) */
      const pickedupOrders: UnifiedOrderDoc[] = []; let pickedupOrdersSum = 0;
      /** 70 (COMPLETED) */
      const completedOrders: UnifiedOrderDoc[] = []; let completedOrdersSum = 0;
      /** 80 (CANCELED) */
      const canceledOrders: UnifiedOrderDoc[] = [];
      /** 새로 수신한 취소가 아닌 주문 */
      const validOrders: UnifiedOrderDoc[] = [];

      let numUnconfirmedCancelOrders = 0;

      for (const order of orders) {
        /** 업소 매출이므로 eventDiscount는 제하지 않는다. */
        const paymentAmount = order.orderAmount + order.deliveryTip - (order.discount ?? 0);

        //
        // 2020-05-05 중복 신규가 발생하는 경우를 막기 위한 궁여지책 코드
        // CEOACCEPTED가 ACCEPTED를 한 경우라면 NEW 다시 오면 상태를 변경한다.
        if (order.contextStatusCode === UnifiedOrderContextStatusCode.NEW) {
          if (this.unifiedOrderService.acceptedOrders.has(order._id)) {
            order.contextStatusCode = UnifiedOrderContextStatusCode.ACCEPTED;
            this.logService.logOrder(order, `ACCEPTED로 변경했는데 NEW라고 다시 온다. 임시로 상태 조정`, 'error');
          } else if (this.unifiedOrderService.ceoacceptedOrders.has(order._id)) {
            order.contextStatusCode = UnifiedOrderContextStatusCode.CEOACCEPTED;
            this.logService.logOrder(order, `CEOACCEPTED로 변경했는데 NEW라고 다시 온다. 임시로 상태 조정`, 'error');
          }
        }

        if (order.contextStatusCode === UnifiedOrderContextStatusCode.NEW) {
          newOrders.push(order);
          newOrdersSum += paymentAmount;
        } else if (order.contextStatusCode === UnifiedOrderContextStatusCode.PICKEDUP) {
          pickedupOrders.push(order);
          pickedupOrdersSum += paymentAmount;
        } else if (order.contextStatusCode > UnifiedOrderContextStatusCode.NEW && order.contextStatusCode < UnifiedOrderContextStatusCode.COMPLETED) {
          acceptedOrders.push(order);
          acceptedOrdersSum += paymentAmount;
        } else if (order.contextStatusCode === UnifiedOrderContextStatusCode.COMPLETED) {
          completedOrders.push(order);
          completedOrdersSum += paymentAmount;
        } else if (order.contextStatusCode === UnifiedOrderContextStatusCode.CANCELED) {
          canceledOrders.push(order);
          // 미확인 취소 주문만 따로 카운트한다.
          if (order.posCancelConfirmed !== true) {
            numUnconfirmedCancelOrders++;
          }
        }

        if (order.contextStatusCode < UnifiedOrderContextStatusCode.CANCELED) {
          validOrders.push(order);
        }
      }

      if (this.numStatusChangedOrders(UnifiedOrderContextStatusCode.CANCELED, this.allOrders, orders) > 0) {
        this.soundService.playCanceled();
        this.logService.logRoom('취소 알림 사운드 play');
        this.observeUnconfirmedCancel();
      }

      // 취소 주문이 아닌 경우에 대해서 배차 상태 감시
      this.triggerNewlyAssignedOrders(this.validOrders, validOrders);
      this.triggerReSubmittedOrders(this.validOrders, validOrders);

      this.allOrders = orders;
      this.newOrders = newOrders; this.newOrdersSum = newOrdersSum;
      this.acceptedOrders = acceptedOrders; this.acceptedOrdersSum = acceptedOrdersSum;
      this.pickedupOrders = pickedupOrders; this.pickedupOrdersSum = pickedupOrdersSum;
      this.completedOrders = completedOrders; this.completedOrdersSum = completedOrdersSum;
      this.canceledOrders = canceledOrders;
      this.validOrders = validOrders;

      this.numUnconfirmedCancelOrders = numUnconfirmedCancelOrders;

      // 신규 주문이 있으면 '접수대기' 탭으로 자동 이동
      if (newOrders.length > 0) {
        this.selectedIndex = 0;
      } else {
        // 현재 탭에 내용이 없으면 '접수대기' > '접수' > '배송중' > '완료' > '취소' 순으로 자동 이동
        if ((this.selectedIndex === 0 && newOrders.length === 0)
          || (this.selectedIndex === 1 && this.acceptedOrders.length === 0)
          || (this.selectedIndex === 2 && this.pickedupOrders.length === 0)
          || (this.selectedIndex === 3 && this.completedOrders.length === 0)
          || (this.selectedIndex === 4 && this.canceledOrders.length === 0)
        ) {
          if (this.acceptedOrders.length > 0) {
            this.selectedIndex = 1;
          } else if (this.pickedupOrders.length > 0) {
            this.selectedIndex = 2;
          } else if (this.completedOrders.length > 0) {
            this.selectedIndex = 3;
          }
        }
      }

      if (document.visibilityState === 'visible') {
        this.playNewOrderConditionally();
      }
    }, error => {
      this.logService.logRoomWithToastrWarn(`OperatingComponent::subscribe에서 에러 발생 => 10초 후 재접속\n${error}`);
      // 아이패드는 재시작 후 사용자입력이 필요하기 때문(소리 알림받기)에
      // 사용자가 인지하여 재시작 후 소리알림 테스트를 할 수 있도록 소리알림을 재생한다
      if (isiPad()) {
        const message = '통신 이상이 감지되어 잠시 후 재시작합니다. 재시작 후에는 소리 알림을 확인해 주세요.';
        // 예외가 발생하는 경우가 있어서 감싼다.
        try {
          textToSpeech(message);
        } catch (error) {
          this.logService.logRoom(`textToSpeech(${message}) 예외: ${error}`, 'error');
        }
      }

      // 10초 뒤에 자동 리로드
      setTimeout(() => {
        window.location.reload();
      }, 10000);
    });

    this.syncUnifiedOrder();
  }

  /**
   * 현재 상태에 따라서 신규 주문 알림 재생하거나 멈춘다.
   *
   * unifiedOrder가 변경이 되거나 10초 간격 timer에 의해서 호출된다.
   */
  private playNewOrderConditionally() {
    // debugLog(`> playNewOrderConditionally`);

    const now = Date.now();

    const newOrdersNoAccepting = this.newOrders
      .filter(newOrder => {
        // 접수 시도 후 30초가 지나지 않았으면 소리내지 않는다.
        if (newOrder.time?.onTryACCEPT) {
          return ((now - parseISO(newOrder.time.onTryACCEPT).getTime()) > 30 * 1000);
        }
        return true;
      });

    /** retry_processing 상태에서는 접수, 취소 API가 동작하지 않기 때문에 계속 신규 주문 알림은 짜증을 유발할 수 있다. */
    const numYogiyoRetryProcessingNewOrders = newOrdersNoAccepting
      .filter(newOrder => {
        if (newOrder.orderVendor === 'yogiyo' && newOrder.yogiyo?.status === 'retry_processing') {
          // retry_processing이고 3분이 자닌 경우에만 인정한다.
          if ((now - parseISO(newOrder.orderDate).getTime()) > 180 * 1000) {
            return true;
          }
        }
        return false;
      }).length;

    const numCoupangeatsNewOrders = newOrdersNoAccepting
      .filter(newOrder => newOrder.orderVendor === 'coupangeats').length;

    if (newOrdersNoAccepting.length - numYogiyoRetryProcessingNewOrders > 0) {
      // room.autoPrint 설정은 변경될 수 있으므로 this.room을 참고하지 않고 매번 다시 확인한다.
      // 다음 주문의 변화가 있어야지 room의 변화가 반영된다.
      // 소리가 나고 있을 때 autoPrint 설정을 변경한다고 바로 반영되지 않는다는 뜻이다.
      // 쿠팡이츠 자동 출력인 경우에는 쿠팡이츠 신규 주문만 있는 경우에는 신규 주문 알림 소리를 발생시키지 않는다.
      if (this.roomService.room.autoPrint?.coupangeats === true) {
        if (newOrdersNoAccepting.length - numYogiyoRetryProcessingNewOrders - numCoupangeatsNewOrders > 0) {
          this.soundService.playNewOrder();
        } else {
          this.soundService.stopNewOrder();
        }
      } else {
        // this.logService.info('신규 알림 사운드 play');
        this.soundService.playNewOrder();
      }
    } else {
      // this.logService.info('신규 알림 사운드 stop');
      this.soundService.stopNewOrder();
    }
  }

  private observeNotices() {
    const organization = this.userService.organization;
    const noticeWheres: WHERE[] = [
      // 전체이거나 속한 지점의 공지만 불러온다.
      ['whereIn', 'in', [organization, this.roomService.room.site]],
      // 노출중인 공지만 불러온다.
      ['state', '==', 'show']
    ];

    this.noticeService.observeNotice(noticeWheres)
      .pipe(takeUntil(this.destroySignal))
      .subscribe(notices => {
        this.notices = Object.values(notices);

        // 미확인 공지가 있는지 확인한다.
        const countUnreadNotice = this.notices.filter(notice => !notice.readBy[this.userService.user.room]).length;

        /**
         * 미확인 공지 소리알림 기준
         * 1. 로그인 후 새로운 공지가 있으면 알림(미확인 공지 갯 수 초기값은 0이므로 미확인 공지가 있다면 0보다 크다.)
         * 2. subscribe를 통해 새로운 미확인 공지가 있으면 알림(확인 이력 변경에 대해 알림이 발생하지 않도록 미확인 공지가 늘어나면 알린다.)
         */
        if (this.countUnreadNotice < countUnreadNotice) {
          this.logService.logRoom(`현재 미확인 공지: ${this.countUnreadNotice}, 새로운 미확인 공지: ${countUnreadNotice}: 공지 알림이 울림`);
          this.soundService.playNotice();
        }

        this.countUnreadNotice = countUnreadNotice;
      });
  }

  /**
   * 특정 상태로 변경된 주문 수를 리턴한다.
   */
  private numStatusChangedOrders(status: UnifiedOrderContextStatusCode, oldOrders: UnifiedOrderDoc[], newOrders: UnifiedOrderDoc[]) {
    if (oldOrders === undefined) {
      return 0;
    }

    // 1. status 상태(예, 취소) 주문을 확인한다.
    const newFilteredOrders = newOrders.filter(order => order.contextStatusCode === status);

    let numFound = 0;
    // 2. status 상태(취소) 주문 중에 이전에는 status(취소)가 아닌 주문을 찾는다.
    for (const order of newFilteredOrders) {
      if (oldOrders.find(oldOrder => oldOrder._id === order._id && oldOrder.contextStatusCode !== status)) {
        debugLog(`취소된 주문: ${order.orderDate}:${order.orderStatusCode}:${order.contextStatusCode}:${order._id}`);
        numFound++;
      }
    }

    return numFound;
  }

  /**
   * 배차된 주문에 대해서 알린다.
   */
  private triggerNewlyAssignedOrders(oldOrders: UnifiedOrderDoc[], newOrders: UnifiedOrderDoc[]) {
    if (oldOrders === undefined) {
      return 0;
    }

    // 1. 배차된 주문을 확인한다.
    const newAssignedOrders = newOrders.filter(order => this.getDeliveryStatus(order) === UnifiedDeliveryStatusCode.ASSIGNED);

    let numFound = 0;
    // 2. 배차된 주문 중에 이전에는 배차가 아닌 주문을 찾는다.
    for (const newAssignedOrder of newAssignedOrders) {
      // 리로드인 경우에는 이전 주문이 없기 때문에 알림을 발생시키지 않는다.
      if (oldOrders.find(oldOrder => oldOrder._id === newAssignedOrder._id && this.getDeliveryStatus(oldOrder) !== UnifiedDeliveryStatusCode.ASSIGNED)) {
        // debugLog(`배차된 주문: ${newAssignedOrder.orderDate}:${newAssignedOrder.orderStatusCode}:${newAssignedOrder.contextStatusCode}:${newAssignedOrder._id}`);
        this.utilService.toastrInfo(`${dateFormatter(newAssignedOrder.orderDate, 'HH:mm')} ${newAssignedOrder.simpleNo} ${unifiedOrderVendorMappings[newAssignedOrder.orderVendor]}`, '배차 알림', 20000);
        this.logService.logOrder(newAssignedOrder, '배차 알림 표시');
        numFound++;
      }
    }

    if (numFound > 0) {
      this.soundService.playAssigned();
    }
    return numFound;
  }

  /**
   * '배차'에서 다시 '접수'로 변경된 주문에 대한 알림을 보낸다.
   */
  private triggerReSubmittedOrders(oldOrders: UnifiedOrderDoc[], newOrders: UnifiedOrderDoc[]) {
    if (oldOrders === undefined) {
      return 0;
    }

    // 1. '접수'된 주문을 확인한다.
    const submittedOrders = newOrders.filter(order => this.getDeliveryStatus(order) === UnifiedDeliveryStatusCode.SUBMITTED);

    let numFound = 0;
    // 2. '접수'된 주문 중에 이전에는 '배차'인 주문을 찾는다.
    for (const submittedOrder of submittedOrders) {
      // 리로드인 경우에는 이전 주문이 없기 때문에 알림을 발생시키지 않는다.
      if (oldOrders.find(oldOrder => oldOrder._id === submittedOrder._id && this.getDeliveryStatus(oldOrder) === UnifiedDeliveryStatusCode.ASSIGNED)) {
        this.utilService.toastrInfo(`${dateFormatter(submittedOrder.orderDate, 'HH:mm')} ${submittedOrder.simpleNo} ${unifiedOrderVendorMappings[submittedOrder.orderVendor]}`, '배차 할당 취소', 20000);
        this.logService.logOrder(submittedOrder, '배차 할당 취소 표시');
        numFound++;
      }
    }

    if (numFound > 0) {
      const message = '배차가 취소되어 재배차를 기다립니다.';
      // 예외가 발생하는 경우가 있어서 감싼다.
      try {
        textToSpeech(message);
      } catch (error) {
        this.logService.logRoom(`textToSpeech(${message}) 예외: ${error}`, 'error');
      }
    }
    return numFound;
  }

  /**
   * 주문에 딸린 배차 상태를 얻는다.
   * 부릉 상태 기준으로 맞춘다.
   */
  private getDeliveryStatus(order: UnifiedOrderDocUI): UnifiedDeliveryStatusCode | undefined {
    if (order._ui === undefined) {
      return undefined;
    }

    if (order._ui.relatedDeliveries?.length > 0) {
      // relatedDeliveries에 취소 배차는 이미 걸렀다.
      // 시간 비교는 문자열 비교로도 충분하다.
      const delivery = order._ui.relatedDeliveries.sort((a, b) => {
        return (a.timeSubmitted < b.timeSubmitted) ? -1 :
          (a.timeSubmitted > b.timeSubmitted) ? 1 : 0;
      })[0];

      return delivery.deliveryStatusCode;
    } else if (order.deliveryCherrypickStatus === 'ghostrider') {
      // 자세한 상태를 알 수 없으므로 2가지 상태만 응답한다.
      return order.ghostriderName ? UnifiedDeliveryStatusCode.ASSIGNED : UnifiedDeliveryStatusCode.SUBMITTED;
    }

    return undefined;
  }

  /**
   * 1분 마다 '미확인한 취소 주문'이 있는지를 체크한다.
   * 업소에서 취소 주문을 모두 확인할 때까지 반복해서 취소 알림 사운드를 재생한다.
   */
  private observeUnconfirmedCancel() {
    if (this.unconfirmedCancelSubscription) {
      this.unconfirmedCancelSubscription.unsubscribe();
      this.unconfirmedCancelSubscription = null;
    }

    const interval = 60 * 1000;
    // 최초 알림 사운드와 중복되지 않도록 dueTime도 함께 설정한다.
    this.unconfirmedCancelSubscription = timer(interval, interval).subscribe(() => {
      if (this.numUnconfirmedCancelOrders > 0) {
        this.soundService.playOnetime(['/assets/canceled2.mp3']);
        this.logService.logRoom('미확인 취소 알림 사운드 play');
      }
    });
  }

  /**
   * 현재 local에서 관리중인 주문이 DB의 최신 주문 목록과 일치하는지 확인한다.
   */
  private syncUnifiedOrder() {
    // if (this.userService.user?.role !== 'ceo') {
    //   this.logService.logRoom(`계정의 역할이 '${this.userService.user?.role}'인 경우에는 최신주문동기화 확인을 하지 않습니다.`, 'info');
    //   return;
    // }

    /** 검증될 때까지는 일단 재시작은 하지 않는다. */
    const dryRun = false;

    // POS시작 5분 후 부터 1분 마다 확인한다.
    const startAfter = dryRun ? 60 * 1000 : 5 * 60 * 1000;
    const period = 1 * 60 * 1000;
    const syncTimer = timer(startAfter, period);

    // DB의 최신 주문을 가져오는 도중 에러 발생 시 다시 시도하는 최대 횟수
    const maxTry = 3;
    let countTry = 0;

    this.syncSubscription?.unsubscribe();
    this.syncSubscription = syncTimer.subscribe(async () => {
      if (document.visibilityState === 'hidden') {
        this.logService.logRoom(`화면이 Active가 아니라서 최신주문동기화 확인하지 않습니다.`, 'info');
        return;
      }

      const now = new Date();
      // 1시간 이내의 최신 주문을 가져온다. (영업시간 기준으로 하루가 바뀔때 최대 3시간 이내의 주문을 가져오기 때문에 이를 넘어서는 안된다.)
      const startAt = format(now.getTime() - 1 * 3600 * 1000, `yyyy-MM-dd'T'HH:mm:ss+0900`);
      const room = this.roomService.room;
      const wheres: WHERE[] = [
        ['room', '==', room._id],
        ['orderDate', '>', startAt]
      ];

      try {
        const latestOrders = await this.firebaseManagerService.getDocsArrayWithWhere<UnifiedOrderDoc>(
          'unifiedOrder', wheres, { sortKey: 'orderDate', orderBy: 'desc', limit: 1 });

        // 기존 목록 동기화에도 시간이 필요할 수 있으니 최신 주문을 가져오고 10초 후 비교를 한다.
        await sleep(10 * 1000);

        // 현재 관리중인 목록(Local)이 getDoc으로 가져온 목록(DB)을 포함하고 있는지 확인한다.(id를 기준으로 비교)
        // DB ⊂ Local
        const latestOrderId = latestOrders[0]?._id;
        if (latestOrderId === undefined) {
          debugLog('최근 1시간 이내의 주문이 없습니다.');
          return;
        }

        const allOrdersKeys = this.allOrders.map(order => order._id);
        const isSynchronized = allOrdersKeys.includes(latestOrderId);

        if (isSynchronized) {
          debugLog('주문 목록 동기화 정상');
          countTry = 0;
        } else {
          if (dryRun) {
            this.logService.logRoom(`[dryRun] 최신 주문이 존재하지 않습니다. 3초 후 재시작 합니다. 최근 주문 = ${latestOrderId}. allOrdersKeys.length = ${allOrdersKeys.length}`, 'error');
          } else {
            this.logService.logRoom(`최신 주문이 존재하지 않습니다. 3초 후 재시작 합니다. 최근 주문 = ${latestOrderId}. allOrdersKeys.length = ${allOrdersKeys.length}`, 'error');
            this.reloadAfter();
          }
        }
      } catch (error) {
        countTry++;
        this.logService.logRoom(`최신 주문을 가져오는데 ${countTry}회 실패했습니다. error: ${error}`, 'error');
        if (countTry >= maxTry) {
          if (dryRun) {
            this.logService.logRoom(`[dryRun] 최신 주문을 가져오는데 ${countTry}회 연속 실패하여 3초 후 재시작 합니다.`, 'error');
            countTry = 0;
          } else {
            this.logService.logRoom(`최신 주문을 가져오는데 ${countTry}회 연속 실패하여 3초 후 재시작 합니다.`, 'error');
            this.reloadAfter();
          }
        }
      }
    });
  }

  private reloadAfter(seconds = 3) {
    const message = '통신 이상이 감지되어 3초 후 재시작 합니다.';

    // 예외가 발생하는 경우가 있어서 감싼다.
    try {
      textToSpeech(message);
    } catch (error) {
      this.logService.logRoom(`textToSpeech(${message}) 예외: ${error}`, 'error');
    }

    Swal.fire({
      icon: 'success',
      title: message,
      timer: seconds * 1000,
      timerProgressBar: true,
      showConfirmButton: false,
      didDestroy: () => {
        window.location.reload();
      }
    });
  }
}
