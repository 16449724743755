/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { VroongPosOptionsPickupItem } from '../../schema/1/schema-vroong-api';
import { VroongPosOptionsPickupDoc, VroongPosOptionsPickupDocs } from '../../schema/2/schema-vroong';

import { debugLog } from '../1/common';

const collectionPath = 'vroongPosOptionsPickup';

/**
 * vroongPosOptionsPickup collection과 동기화한다.
 *
 * TODO: 나중에 상점이 많아지면 필터를 걸어서 필요한 정보만 가져오는 것도 가능하다.
 */
@Injectable({
  providedIn: 'root'
})
export class VroongPosOptionsPickupService {
  vroongPosOptionsPickupDocs: VroongPosOptionsPickupDocs = {};
  latestVroongPosOptionsPickupDocsSubject = new BehaviorSubject<VroongPosOptionsPickupDocs>({});
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  observeVroongPosOptionsPickup(room: string) {
    const queryFn: QueryFn = ref => {
      const query = ref.where('_room', '==', room);
      return query;
    };

    const collection = this.db.collection<VroongPosOptionsPickupDoc>(collectionPath, queryFn);

    this.subscription = collection.valueChanges().subscribe(docs => {
      this.vroongPosOptionsPickupDocs = docs.reduce((acc: VroongPosOptionsPickupDocs, doc) => {
        // 원래의 ID는 store_id이지만 _bizNo로 변경한다.
        if (acc[doc._bizNo]) {
          // this.logService.withToastrError(`${doc._bizNo}에 대한 서로 다른 store_id가 감지되었습니다. 픽업 옵션 컬렉션에서 오래된 store_id를 삭제하라고 고객센터에 알려주세요.`);
        }
        acc[doc._bizNo] = doc;
        return acc;
      }, {});

      this.latestVroongPosOptionsPickupDocsSubject.next(this.vroongPosOptionsPickupDocs);
    });
  }

  /**
   *
   * @param instanceNo bizNo
   */
  vroongPosOptionsPickupItemsForInstanceNo(instanceNo: string): VroongPosOptionsPickupItem[] {
    if (this.vroongPosOptionsPickupDocs[instanceNo]) {
      // SET, UNSET은 부릉 POS UI에 임시로 제한이 걸려있음을 표시하는 용도이다.
      return this.vroongPosOptionsPickupDocs[instanceNo].options;
    } else {
      debugLog(`Not exist vroongPosOptionsPickup for ${instanceNo}`);
      return [15, 20, 25, 30, 35, 40, 50].map(time => ({
        // enabled: time <= 20 ? false : true,
        enabled: true,
        time,
        visible: true
      }));
    }
  }
}
