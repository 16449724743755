/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { UnifiedDeliveryStatusCode } from '../../schema/3/schema';
import { UnifiedOrderDocUI } from '../../schema/4/schema-ui';

import { debugLog } from '../1/common';

/**
 * 요청 픽업 시각을 응답하고 다음의 속성도 변경한다.
 * 1. order._ui.isRiderPickedUp
 * 2. order._ui.isPickupAdjusted
 *
 * 가정: 관련 배차 정보(._ui.relatedVroongDeliveries, _ui.relatedCombinenetDeliveries)가 order에 딸려 있어야 한다.
 *
 * 가능한 출력은 다음과 같다.
 * - yyyy-MM-dd'T'HH:mm:ss+09:00
 * - '비배차'
 * - '배차확인'
 * - '--'
 *
 */
export function pickupTime(order: UnifiedOrderDocUI) {
  // 2019-03-27T19:18:16+0900
  if (['DELIVERY', 'BAERA', 'COUPANG', 'YOGIYO'].includes(order.deliveryType)) {
    // 부릉, 런투유, 스파이더, 바로고, 배민1, 쿠팡이츠인 경우
    if (order._ui?.relatedDeliveries) {
      // 라이더가 픽업으로 상태를 바꾼 경우. 실제로 픽업하지 않은 경우도 있다.
      order._ui.isRiderPickedUp = order._ui?.relatedDeliveries.find(delivery => delivery.deliveryStatusCode === UnifiedDeliveryStatusCode.PICKED_UP) !== undefined;

      // relatedDeliveries에 취소 배차는 이미 걸렀다.
      // 시간 비교는 문자열 비교로도 충분하다.
      const mainDelivery = order._ui.relatedDeliveries.sort((a, b) => {
        return (a.timeSubmitted < b.timeSubmitted) ? -1 :
          (a.timeSubmitted > b.timeSubmitted) ? 1 : 0;
      })[0];

      // barogoPickupExpectedAt 때문에 기준 시간은 제공 못 해도 픽업 조정이 발생했음은 알려야 한다.
      if (mainDelivery?.adjustedPickupTimes?.length > 0) {
        // HH:mm 까지만 비교해서 다른 경우에만 조정을 인정한다.
        // 주문 상세에서 비교 기준이 분까만 하고 있다.
        if (mainDelivery.requestedPickupTime.substring(0, 16) !== mainDelivery.adjustedPickupTimes[mainDelivery.adjustedPickupTimes.length - 1].adjustedTime.substring(0, 16)) {
          // UI를 달리 표현하기 위해 사용한다.
          order._ui.isPickupAdjusted = true;
        }
      }
      // 픽업 조정이 있다면 가장 최근의 픽업 조정 시각을 기준으로 하고
      // 그렇지 않다면 최초 요청 픽업 시각을 기준으로 한다.
      // 바로고의 경우에는 barogoPickupExpectedAt을 기준으로 한다.
      if (mainDelivery?.barogoPickupExpectedAt) {
        return mainDelivery.barogoPickupExpectedAt;
      } else if (mainDelivery?.adjustedPickupTimes?.length > 0) {
        debugLog(`${order.simpleNo} 주문의 픽업 시간은 조정이 발생해서 adjustedPickupTimes에서 가져온다.`);
        return mainDelivery.adjustedPickupTimes[mainDelivery.adjustedPickupTimes.length - 1].adjustedTime;
      } else {
        order._ui.isPickupAdjusted = false;
        // 템플릿(HTML)에서는 app-timer를 이용해서 표시한다.
        // debugLog(`${order.simpleNo} 주문의 픽업 시간은 조정이 없어서 requestedPickupTime에서 가져온다.`);
        return mainDelivery.requestedPickupTime;
      }
    } else {
      if (order.deliveryType === 'DELIVERY') {
        // --- 여기서부터는 relatedDeliveries가 없는 경우
        if (order.deliveryCherrypickStatus === 'ghostrider') {
          return order.ghostriderPickupAt;
        } else if (order.withDelivery !== true) {
          // withDelivery인 경우에도 나중에 배차를 요청할 수도 있으므로 우선 순위는 낮게 한다.
          // POS로 접수하지 않은 경우에는 withDelivery가 없으므로 비배차로 인식한다.
          return '비배차';
        } else {
          // 접수 및 배차 했다.
          return '배차확인';
        }
      }
      // 배민1이나 쿠팡이츠인 경우에 아직 접수를 하지 않은 상태
      else {
        return '--';
      }
    }
  } else {
    return '--';
  }
}
