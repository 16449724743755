/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { UnifiedOrderDoc } from '../../schema/3/schema';

import { debugLog } from '../../core/1/common';
import { RoomService } from '../../core/1/room.service';
import { LogService } from '../../core/4/log.service';
import { DeliveryVendorParams, DeliveryUtilService } from '../../core/5/delivery-util.service';
import { DeliveryService } from '../../core/5/delivery.service';
import { OrderService } from '../../core/9/order.service';

export interface RequestedPickupMinutes {
  // 최초의 추천 시간을 함께 기록하기 때문에 비록 값이 변하지는 않지만 함께 emit한다.
  recommendedRequestedPickupMinutes: number;
  requestedPickupMinutes: number;
}

export interface DeliveryMinutes {
  // 최초의 추천 시간을 함께 기록하기 때문에 비록 값이 변하지는 않지만 함께 emit한다.
  recommendedDeliveryMinutes: number;
  deliveryMinutes: number;
}

@Component({
  selector: 'app-select-minute',
  templateUrl: './select-minute.component.html',
  styleUrls: ['./select-minute.component.scss']
})
export class SelectMinuteComponent implements OnInit {
  /** 컴포넌트 사용시 가장 앞에 위치해야 한다. */
  public order: UnifiedOrderDoc;
  @Input('order') set _order(value: UnifiedOrderDoc) {
    debugLog(`[SelectMinuteComponent] set order(${value ? value._id : 'undefined'}) CALLED`);
    // ngOnInit보다 먼저 불린다.
    this.order = value;
    this.deliveryParams = this.deliveryUtilService.calculateDeliveryVendorParams(this.order, this.mainDeliveryVendor);
  }

  /**
   * 노출 시킬 항목
   * 'cookMinutes' | 'requestedPickupMinutes' | 'deliveryMinutes'의 조합이 된다.
   */
  public showItems: string[] = [];
  @Input('showItems') set _showItems(value: string[]) {
    debugLog(`[SelectMinuteComponent] set showItems(${value}) CALLED`);
    this.showItems = value;

    if (this.order.deliveryType === 'DELIVERY') {
      if (this.showItems.includes('requestedPickupMinutes')) {
        // 내부에서 deliveryMinutes 관련 변수도 설정한다.
        this.initPickupMinutes();
      } else if (this.showItems.includes('deliveryMinutes')) {
        this.initDeliveryMinutes();
      }
    } else {
      this.cookMinutesOptions = this.orderService.cookMinutesOptionsFor(this.order);
      // defaultCookMinutes 값이 cookMinutesOptions에 없을 때 기본 값과 가장 가까운 큰 값이 선택되도록 설정
      const foundOption = this.cookMinutesOptions.find(option => this.roomService.room.cookMinutes <= parseInt(option, 10));
      // 못 찾았다면 가장 큰 값을 선택한다.
      this.cookMinutes = foundOption ?? this.cookMinutesOptions[this.cookMinutesOptions.length - 1];
      // 선택된 cookMinutes 값을 emit 해준다.
      this.changeCookMinutes.emit(this.cookMinutes);
    }
  }

  // 요청전 정보가 갱신되면 요청 전 정보를 이용
  @Input('requestDeliveryInformationPickups') set _pickups(value: number[]) {
    debugLog(`[SelectMinuteComponent] set requestDeliveryInformationPickups(${value}) called`);
    if (value.length > 0) {
      // 실제 정보를 요청해서 다르면 덮어쓴다.
      if (JSON.stringify(this.requestedPickupMinutesOptions) !== JSON.stringify(value)) {
        this.requestedPickupMinutesOptions = value;
        this.compensateRequestPickupMinutes();
      }
    }
  }

  /**
   * 조리 시간
   *
   * 비대달인 경우의 deliveryMinutes이다. 분리한 이유는 선택 가능한 옵션이 배달과 차이가 있기 때문이다.
   */
  public cookMinutesOptions: string[];
  public cookMinutes = this.roomService.room.cookMinutes.toString();
  @Output() changeCookMinutes = new EventEmitter<string>();

  /** 요청 픽업 시간 */
  public requestedPickupMinutes = this.roomService.room.cookMinutes;
  /**
   * initPickupMinutes()에서 초기화한다.
   *
   * 1. set _order에서 this.deliveryParams 초기화
   * 2. initPickupMinutes()에서 초기화
   * 3. set _pickups에서 변경
   */
  public requestedPickupMinutesOptions: number[];
  /** 추천 요청 픽업 시간 : 업주가 변경하기 이전의 값으로 추후 차이를 확인할 때 사용한다. */
  private recommendedRequestedPickupMinutes = 20;
  @Output() changeRequestedPickupMinutes = new EventEmitter<RequestedPickupMinutes>();

  public deliveryMinutesOptions: number[];
  /** 예상 배달 시간 */
  public deliveryMinutes = 40;
  /** 추천 예상 배달 시간: 업주가 변경하기 이전의 값으로 추후 차이를 확인할 때 사용한다. */
  private recommendedDeliveryMinutes = 0;
  @Output() changeDeliveryMinutes = new EventEmitter<DeliveryMinutes>();

  /**
   * order에 의해서 최초에 결정된 값을 저장한다.
   * this.deliveryUtilService.calculateDeliveryVendorParams(order)를 통해서 결정한다.
   */
  private deliveryParams: DeliveryVendorParams;

  private get mainDeliveryVendor() {
    const deliveryVendor = this.roomService.room.deliveryVendors[0];
    debugLog(`[SelectMinuteComponent] get mainDeliveryVendor = ${deliveryVendor}`);

    return deliveryVendor;
  }

  constructor(
    private roomService: RoomService,
    private logService: LogService,
    private deliveryUtilService: DeliveryUtilService,
    private orderService: OrderService,
    private deliveryService: DeliveryService
  ) {
    debugLog(`[SelectMinuteComponent] constructor CALLED`);
  }

  ngOnInit(): void {
    debugLog(`[SelectMinuteComponent] ngOnInit() CALLED`);
  }

  public onChangeCookMinutes(event) {
    this.cookMinutes = event.value;
    this.changeCookMinutes.emit(this.cookMinutes);
    this.logService.logOrder(this.order, `버튼을 눌러 조리시간을 ${this.cookMinutes}분으로 변경.`);
  }

  /**
   * 요청 픽업 시간이 변경되면 예상 배달 시간도 함께 변경되기 때문에 2개의 이벤트가 발생한다.
   */
  public onChangeRequestedPickupMinutes(event) {
    this.updateRequestedPickupMinutes(event.value);
    this.logService.logOrder(this.order, `버튼을 눌러 요청 픽업 시간을 ${this.requestedPickupMinutes}분으로 변경.`);
  }

  public onChangeDeliveryMinutes(event) {
    this.deliveryMinutes = event.value;
    this.changeDeliveryMinutes.emit({
      recommendedDeliveryMinutes: this.recommendedDeliveryMinutes,
      deliveryMinutes: this.deliveryMinutes
    });

    this.logService.logOrder(this.order, `버튼을 눌러 예상 배달 시간을 ${this.deliveryMinutes}분으로 변경.`);
  }

  /**
   * 한번이라도 요청전 정보를 가져온게 있으면 deliveryService에 가지고 있다가 이걸 먼저 보여준다.
   * deliveryParams(order에 의존적)와 room/deliveryVendors 설정을 따라 다음의 2가지 변수를 결정한다.
   * - requestedPikcupMinutes
   * - requestedPickupMinutesOptions
   */
  private initPickupMinutes() {
    this.recommendedRequestedPickupMinutes = this.deliveryParams.pickupMinutes;
    // 최초에는 최근 기록을 확인한다. 최근 기록이 없으면 지점 초기정보
    this.requestedPickupMinutesOptions = this.deliveryService.recentRequestedPickupMinutesOptions.length > 0 ?
      this.deliveryService.recentRequestedPickupMinutesOptions : this.deliveryParams.pickups;

    this.requestedPickupMinutes = this.roomService.room.cookMinutes ?? this.recommendedRequestedPickupMinutes;

    // 목록에서 유효한 값으로 변경
    this.compensateRequestPickupMinutes();
  }

  private initDeliveryMinutes() {
    this.recommendedDeliveryMinutes = this.deliveryParams.deliveryMinutes;
    this.deliveryMinutes = this.recommendedDeliveryMinutes;

    this.changeDeliveryMinutes.emit({
      recommendedDeliveryMinutes: this.recommendedDeliveryMinutes,
      deliveryMinutes: this.deliveryMinutes
    });

    this.deliveryMinutesOptions = this.deliveryParams.deliveryMinutesOptions;
  }

  /**
   * 선택 가능한 범위의 변화로 픽업 요청 시간을 재조정한다.
   * 그에 따라 관련 변수도 변경한다.
   * 보정 여부와 관계없이 이벤트도 발송한다.
   */
  private compensateRequestPickupMinutes() {
    // 기존보다 큰 값을 못 찾으면 목록중에 제일 큰 값
    // [20, 30, 40, 50]: 50 => [20, 30, 40]: 40
    const largestOption = this.requestedPickupMinutesOptions[this.requestedPickupMinutesOptions.length - 1];
    if (largestOption < this.requestedPickupMinutes) {
      this.updateRequestedPickupMinutes(largestOption);
      return;
    }

    // 같은 값이 있으면 유지
    // [20, 30, 40]: 20 => [20, 30, 40, 50]: 20
    // 목록에 없으면 큰 수 중에 제일 작은 값
    // [25, 30, 40]: 25 => [20, 30, 40, 50]: 30
    // [20, 30, 40, 50]: 20 => [30, 40]: 30

    // 중간에 탈출하기 위해서 every 사용
    const result = this.requestedPickupMinutesOptions.every(option => {
      if (this.requestedPickupMinutes <= option) {
        this.updateRequestedPickupMinutes(option);
        return false;
      }

      return true;
    });

    if (result === true) {
      this.logService.logOrder(this.order, '여기는 오면 버그다', 'error');
    }
  }

  /**
   * 요청 픽업 시간이 변경되면 그에 맞추어 다음 값도 변경하고 알려준다.
   *
   * - requestedPickupMinutes
   * - deliveryMinutes
   * - deliveryMinutesOptions
   * - recommendedDeliveryMinutes
   */
  private updateRequestedPickupMinutes(requestedPickupMinutes: number) {
    this.requestedPickupMinutes = requestedPickupMinutes;
    this.changeRequestedPickupMinutes.emit({
      recommendedRequestedPickupMinutes: this.recommendedRequestedPickupMinutes,
      requestedPickupMinutes: this.requestedPickupMinutes
    });

    ({
      deliveryMinutes: this.deliveryMinutes,
      deliveryMinutesOptions: this.deliveryMinutesOptions
    } = this.deliveryUtilService.calculateDeliveryVendorParams(this.order, this.mainDeliveryVendor, this.requestedPickupMinutes));

    // 조리시간을 변경한 것과 같은 의미이기 때문에 함께 변경해 주어야 한다.
    this.recommendedDeliveryMinutes = this.deliveryMinutes;
    this.changeDeliveryMinutes.emit({
      recommendedDeliveryMinutes: this.recommendedDeliveryMinutes,
      deliveryMinutes: this.deliveryMinutes
    });
  }
}
