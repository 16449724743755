/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { instanceId } from '../1/common';
import { LocalStorageService } from '../1/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
  ) { }

  // ignore이면 toastrError 메시지를 보여주지 않는다.
  // Logout 시에 일시적인 에러 표시를 막기 위함
  public ignore = false;

  public toastrInfo(message: string, title?: string, timeout?: number) {
    const now = format(new Date(), `HH:mm:ss`);
    this.toastr.info(`[${now}] ${message}`, title, {
      timeOut: timeout ? timeout : 10000
      // disableTimeOut: true
    });
  }

  public toastrWarning(message: string, title?: string, timeout?: number) {
    const now = format(new Date(), `HH:mm:ss`);
    this.toastr.warning(`[${now}] ${message}`, title, {
      timeOut: timeout ? timeout : 30000
      // disableTimeOut: true
    });
  }

  public toastrError(message: string, title?: string, timeout?: number) {
    if (this.ignore) {
      return;
    }

    console.error(message);

    const now = format(new Date(), `HH:mm:ss`);
    this.toastr.error(`[${now}] ${message}`, title, {
      timeOut: timeout ? timeout : 30000
      // disableTimeOut: true
    });
  }

  public toastrCatch(error: Error | string, reason?: string) {
    const now = format(new Date(), `HH:mm:ss`);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    console.error(msg, error);

    this.toastr.error(`[${now}] ${msg}`, '예외 발생', {
      timeOut: 30000,
      disableTimeOut: false
    });
  }

  /**
   * 리로드와 상관없이 동일한 사용자를 확인하기 위해 localStorage에 instanceId를 저장한다.
   */
  public getInstanceId() {
    // localStorage사용이 불가능한 경우
    if (this.localStorageService.available !== true) {
      return instanceId;
    }

    if (this.localStorageService.isExist('instanceId')) {
      return this.localStorageService.getValue('instanceId');
    } else {
      this.localStorageService.setItem('instanceId', instanceId);
      return instanceId;
    }
  }
}
