/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogIframeComponent } from './dialog-iframe.component';

@Injectable({
  providedIn: 'root'
})
export class DialogIframeService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialog() {
    return this.dialog.open(DialogIframeComponent, {
      disableClose: true,
      panelClass: 'none-padding-dialog',
      width: '95vw',
      height: '95vh',
    });
  }
}
