import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AugmentedAddress } from '../../schema/1/schema-common';
import { CallInputAugmentAddress, CallOutputAugmentAddress } from '../../schema/4/schema-functions-call';

import { RoomService } from '../1/room.service';
import { UserService } from '../3/user.service';
import { LogService } from '../4/log.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private addressCache: {
    [rawAddress: string]: AugmentedAddress;
  } = {};

  constructor(
    private fns: AngularFireFunctions,
    private roomService: RoomService,
    private userService: UserService,
    private logService: LogService,
  ) { }

  /**
   * functions에게 augmentedAddress를 요청한다.
   * rawAddressRoad로 먼저 조회하고 실패하면 rawAddressJibun으로 조회한다.
   */
  public async augmentAddress(rawAddressJibun: string, rawAddressRoad?: string, bQuiet = false) {
    let resMessage: CallOutputAugmentAddress = {
      result: 'error',
      reason: '',
      augmentedAddress: undefined
    };

    const callInput: CallInputAugmentAddress = {
      organization: this.roomService.room.organization,
      site: this.roomService.room.site,
      room: this.roomService.room.room,
      rawAddress: 'Not Initialized',
      from: `pos/${this.userService.user?.email}`,
    };

    //
    // 1. cache 확인
    if (rawAddressRoad?.length > 0) {
      callInput.rawAddress = rawAddressRoad;
      if (this.addressCache[callInput.rawAddress]) {
        resMessage = { result: 'success', reason: 'cache HIT', augmentedAddress: this.addressCache[callInput.rawAddress] };
        if (!bQuiet) {
          this.logService.logRoom(`도로명주소 '${callInput.rawAddress}'로 검색 => '성공(cache HIT)'`);
        }
      }
    }
    if (resMessage?.result !== 'success') {
      callInput.rawAddress = rawAddressJibun;
      if (this.addressCache[callInput.rawAddress]) {
        resMessage = { result: 'success', reason: 'cache HIT', augmentedAddress: this.addressCache[callInput.rawAddress] };
        if (!bQuiet) {
          this.logService.logRoom(`지번주소 '${callInput.rawAddress}'로 검색 => '성공(cache HIT)'`);
        }
      }
    }
    if (resMessage?.result === 'success') {
      return { ...resMessage, usedRawAddress: callInput.rawAddress };
    }

    //
    // 2. functions에 조회
    try {
      const requestAugmentAddress = this.fns.httpsCallable<CallInputAugmentAddress, CallOutputAugmentAddress>('callAugmentAddress');

      // [검색으로 찾기 탭]에서 augmentAddress를 하는 경우 최초 도로명 주소로 시도한다.
      if (rawAddressRoad?.length > 0) {
        callInput.rawAddress = rawAddressRoad;
        resMessage = await requestAugmentAddress(callInput).toPromise();
        this.logService.logRoom(`도로명주소 '${callInput.rawAddress}'로 검색 => ${resMessage?.result === 'success' ? '성공' : '실패(지번주소로 재시도)'}`);
      }

      // [번지 주소 탭]에서 augmentAddress를 하는 경우 또는 [검색으로 찾기 탭]에서 도로명 주소 검색이 실패한 경우
      if (resMessage?.result !== 'success') {
        // 도로명 검색으로 모호한 응답(복수의 응답)이 오는 경우 지번 주소로 한 번 더 시도한다.
        callInput.rawAddress = rawAddressJibun;
        resMessage = await requestAugmentAddress(callInput).toPromise();
        this.logService.logRoom(`지번주소 '${callInput.rawAddress}'로 검색 => ${resMessage?.result === 'success' ? '성공' : '실패'}`, resMessage?.result === 'success' ? 'info' : 'error');
      }

      //
      // 3. cache에 저장하기
      if (resMessage?.result === 'success') {
        this.addressCache[callInput.rawAddress] = resMessage.augmentedAddress;
      }

      return { ...resMessage, usedRawAddress: callInput.rawAddress };
    } catch (error) {
      resMessage.reason = (error.name === 'TimeoutError') ? '시간 초과 : 명령에 응답을 하지 않습니다.' : error.message;

      return { ...resMessage, usedRawAddress: callInput.rawAddress };
    }
  }
}
