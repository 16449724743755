<div class="container">
  <div class="foodMenu">
    <div class="foodName">{{unifiedOrderFood.foodName}}</div>

    <div class="qty" [style.gridRowEnd]="gridRowEnd">
      <button (click)="decreaseMenuQty()" [disabled]="unifiedOrderFood.foodQty < 2">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <span class="qtyText">{{unifiedOrderFood.foodQty}}</span>
      <button (click)="increaseMenuQty()" [disabled]="unifiedOrderFood.foodQty >= 999">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>

    <!-- unifiedOrderFood.foodOpts[0].optPrice은 기본 메뉴 가격 -->
    <div class="foodPrice">{{ unifiedOrderFood.foodOpts[0].optPrice * unifiedOrderFood.foodQty | number}}</div>

    <ng-container *ngFor="let foodOpt of unifiedOrderFood.foodOpts; let firstOpt = first;">
      <!-- unifiedOrderFood.foodOpts[0]는 기본 메뉴이므로 옵션에 표시하지 않는다 -->
      <div class="optName" *ngIf="!firstOpt">+ {{foodOpt.optName}}</div>
      <div class="optPrice" *ngIf="!firstOpt">{{foodOpt.optPrice * unifiedOrderFood.foodQty | number}}</div>
    </ng-container>
  </div>

  <div class="removeButton" (click)="removeMenu()">
    <mat-icon>delete_forever</mat-icon>
  </div>
</div>