<div class="title">{{unifiedMenuFood.foodName}}</div>
<form class="optionList" [formGroup]="unifiedMenuFoodForm">
  <div class="optionListContainer">
    <mat-list *ngFor="let foodOptGroup of unifiedMenuFood.foodOptGroups; let foodOptGroupIndex = index;">
      <div class="optGrpName">{{foodOptGroup.optGrpName}}
        <span *ngIf="foodOptGroup.optGrpMinSel === 0 && 0 < foodOptGroup.optGrpMaxSel">(최대
          {{foodOptGroup.optGrpMaxSel}}개)</span>
        <span
          *ngIf="foodOptGroup.optGrpMinSel === foodOptGroup.optGrpMaxSel && 1 < foodOptGroup.optGrpMinSel">({{foodOptGroup.optGrpMinSel}}개 선택 필수)</span>
      </div>
      <!-- 
    특정 갯수 이상 선택되면 더이상 선택을 할 수 없다.
    mat-selection-list에 max-size 효과 주기 ref: https://stackblitz.com/edit/material2-beta12-ehlsjg?file=app%2Fapp.component.ts 
  -->
      <div *ngIf="foodOptGroup._controlType === 'checkbox'">
        <mat-selection-list #list formControlName="foodOptGroup{{foodOptGroupIndex}}">
          <mat-list-option *ngFor="let foodOpt of foodOptGroup.foodOpts; let i = index;" #option
            [class.soldout]="foodOpt.optSoldOut"
            [disabled]="(list.selectedOptions.selected.length >= foodOptGroup.optGrpMaxSel && !option.selected) || foodOpt.optSoldOut"
            [value]="foodOpt">
            <span class="optName">{{foodOpt.optName}}</span>
            <span class="displaySoldOut">(품절)</span>
            <span class="optPrice">{{foodOpt.optPrice | number}}</span>
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div *ngIf="foodOptGroup._controlType === 'radio'">
        <mat-radio-group formControlName="foodOptGroup{{foodOptGroupIndex}}">
          <mat-radio-button *ngFor="let foodOpt of foodOptGroup.foodOpts; let foodOptsIndex = index;"
            [value]="foodOpt">
            <span class="optName">{{foodOpt.optName}}</span>
            <span class="optSoldOut" *ngIf="foodOpt.optSoldOut">{{foodOpt.optSoldOut}}</span>
            <span class="optPrice">{{foodOpt.optPrice | number}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-list>
  </div>
  <div class="warning" *ngIf="!validUnifiedMenuFoodForm" >{{ unifiedMenuFoodFormErrMessage }}</div>
  <div class="buttons">
    <button mat-flat-button class="button cancel-submit-button" (click)="onCancel()">취소</button>
    <button mat-flat-button [disabled]="!validUnifiedMenuFoodForm" class="button submit-button-with-cancel" (click)="addFood()">추가</button>
  </div>
</form>