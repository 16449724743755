<form [formGroup]="configForm">
  <h2>설정 변경</h2>

  <div class="container">
    <div class="telNo">
      <h3>주문서에 출력되는 전화번호 변경</h3>
      <div>
        <span>{{normalizedTel}}</span>
        <button mat-flat-button color="primary" (click)="openTelNoDialog()">변경</button>
        <input matInput formControlName="telNo">
      </div>
    </div>

    <div>
      <h3>기본 조리 시간(요청 픽업 시간)</h3>
      <div class="explanation">기본으로 선택할 조리 시간을 설정합니다. 선택한 시간이 목록에 없을 경우 가장 가까운 시간으로 선택됩니다.</div>
      <mat-radio-group formControlName="cookMinutes">
        <mat-radio-button [disableRipple]="true" [value]="5">5분</mat-radio-button>
        <mat-radio-button [disableRipple]="true" [value]="10">10분</mat-radio-button>
        <mat-radio-button [disableRipple]="true" [value]="15">15분</mat-radio-button>
        <mat-radio-button [disableRipple]="true" [value]="20">20분</mat-radio-button>
        <mat-radio-button [disableRipple]="true" [value]="25">25분</mat-radio-button>
        <mat-radio-button [disableRipple]="true" [value]="30">30분</mat-radio-button>
      </mat-radio-group>
    </div>

    <div required>
      <h3>주문 자동 출력 방식</h3>
      <mat-radio-group formControlName="printOption">
        <mat-radio-button [disableRipple]="true" value="cookFirst">주방용 먼저 출력</mat-radio-button>
        <mat-radio-button [disableRipple]="true" value="customerFirst">고객용 먼저 출력</mat-radio-button>
        <mat-radio-button [disableRipple]="true" value="cookOnly">주방용만 출력</mat-radio-button>
        <mat-radio-button [disableRipple]="true" value="customerOnly">고객용만 출력</mat-radio-button>
        <mat-radio-button [disableRipple]="true" value="noPrint">자동 출력하지 않음</mat-radio-button>
      </mat-radio-group>
    </div>

    <div required>
      <h3>주방용 주문서 인쇄 형식</h3>
      <div class="explanation">주방용 주문서의 글자 크기를 조절합니다.</div>
      <mat-radio-group formControlName="printCookOption">
        <mat-radio-button value="normal">기본</mat-radio-button>
        <mat-radio-button value="double">2배</mat-radio-button>
      </mat-radio-group>
    </div>

    <div required>
      <h3>배민 리뷰 자동 인쇄</h3>
      <div class="explanation">새로 등록되거나 변경된 배민 리뷰 내용을 프린터로 자동 인쇄할 수 있습니다.</div>
      <mat-radio-group formControlName="printReview">
        <mat-radio-button [value]="true">인쇄</mat-radio-button>
        <mat-radio-button [value]="false">인쇄 안함</mat-radio-button>
      </mat-radio-group>
    </div>

    <div required>
      <h3>취소 주문 자동 인쇄</h3>
      <div class="explanation">취소 주문이 감지되면 자동으로 취소 주문을 인쇄합니다.</div>
      <mat-radio-group formControlName="canceledOrderAutoPrint">
        <mat-radio-button [value]="true">인쇄</mat-radio-button>
        <mat-radio-button [value]="false">인쇄 안함</mat-radio-button>
      </mat-radio-group>
    </div>

    <div required>
      <h3>쿠팡 자동 인쇄 (및 알림 끄기)</h3>
      <div class="explanation">'자동 인쇄'를 선택하면 1. 자동으로 주문을 먼저 인쇄하고 2. 쿠팡이츠 주문에 대해서 소리 알림을 하지 않습니다.</div>
      <mat-radio-group formControlName="coupangeatsAutoPrint">
        <mat-radio-button [value]="false">접수하면 인쇄</mat-radio-button>
        <mat-radio-button [value]="true">자동 인쇄</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <mat-error *ngIf="configForm.invalid">{{ configForm.errors['reason'] }}</mat-error>

  <div class="buttons">
    <button cdkFocusInitial [disableRipple]="true" mat-flat-button class="left cancel" (click)="closeDialog()">닫기</button>
    <button [disableRipple]="true" mat-flat-button color="accent" (click)="update()" [disabled]="configForm.invalid">변경</button>
  </div>
</form>
