/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import cloneDeep from 'lodash-es/cloneDeep';

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UnifiedOrderFood, UnifiedOrderFoodOpt } from '../../schema/1/schema-common';
import { UnifiedMenuDoc, UnifiedMenuFood, UnifiedOrderDoc } from '../../schema/3/schema';

import { postProcessFoods } from '../../core/1/util';

import { DialogOptionMenuComponent } from './dialog-option-menu/dialog-option-menu.component';
import { DialogOptionMenuService } from './dialog-option-menu/dialog-option-menu.service';
import { DialogOrderFormComponent } from './dialog-order-form/dialog-order-form.component';
import { DialogOrderFormService } from './dialog-order-form/dialog-order-form.service';

@Component({
  selector: 'app-dialog-order-menu',
  templateUrl: './dialog-order-menu.component.html',
  styleUrls: ['./dialog-order-menu.component.scss']
})
export class DialogOrderMenuComponent implements OnInit {
  public unifiedOrderFoods: UnifiedOrderFood[] = [];
  public totalPrice = 0;
  public title = '';

  constructor(
    public dialogRef: MatDialogRef<DialogOrderMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { unifiedMenu: UnifiedMenuDoc, unifiedOrder?: UnifiedOrderDoc },
    private dialogOptionMenuService: DialogOptionMenuService,
    private dialogOrderFormService: DialogOrderFormService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.title = this.data.unifiedMenu.shopName ? `[${this.data.unifiedMenu.shopName}] 메뉴 입력` : '메뉴 입력';
    this.unifiedOrderFoods = cloneDeep(this.data.unifiedOrder?.foods) ?? [];
    this.calcTotal();
  }

  openDialogOption(unifiedMenuFood: UnifiedMenuFood) {
    // 품절된 메뉴는 동작 없음
    if (unifiedMenuFood.foodSoldOut) {
      this.snackBar.open('품절된 메뉴입니다', '닫기', {
        duration: 1000
      });
      return;
    }

    // 옵션이 있으면 옵션 선택 다이얼로그를 띄운다.
    if (unifiedMenuFood.foodOptGroups.length > 1) {
      const dialogRef: MatDialogRef<DialogOptionMenuComponent> = this.dialogOptionMenuService.openDialogOptionMenu(unifiedMenuFood);

      // 닫히면 내용을 받아서 요약에 추가
      dialogRef.afterClosed().subscribe((unifiedOrderFood) => {
        if (unifiedOrderFood !== false && unifiedOrderFood !== undefined) {
          this.addMenu(unifiedOrderFood);
        }
      });
    } else {
      // 옵션이 없으면 다이얼로그를 띄우지 않고 바로 요약에 추가
      // 기본 가격은 첫번째 옵션 형태로 들어간다
      const foodOpt: UnifiedOrderFoodOpt = {
        optName: unifiedMenuFood.foodOptGroups[0].foodOpts[0].optName,
        optPrice: unifiedMenuFood.foodOptGroups[0].foodOpts[0].optPrice,
        optQty: 1
      };

      const unifiedOrderFood: UnifiedOrderFood = {
        foodName: unifiedMenuFood.foodName,
        foodOrdPrice: unifiedMenuFood.foodOptGroups[0].foodOpts[0].optPrice,
        foodQty: 1,
        foodOpts: [foodOpt],
        mergedName: unifiedMenuFood.foodName    // 나중에 채워질 것이다
      };

      this.addMenu(unifiedOrderFood);
    }
  }

  /**
   * unifiedMenuFood를 UnifiedOrderFood형태로 바꿔서 저장한다.
   */
  private addMenu(unifiedOrderFood: UnifiedOrderFood) {
    this.unifiedOrderFoods.push(unifiedOrderFood);
    this.unifiedOrderFoods = postProcessFoods(this.unifiedOrderFoods);
    this.calcTotal();
  }

  public openDialogOrderForm() {
    const dialogOrderFormRef: MatDialogRef<DialogOrderFormComponent> = this.dialogOrderFormService.openDialogOptionMenu();

    // 결과를 받으면 자동으로 완료된다.
    dialogOrderFormRef.afterClosed().subscribe(food => {
      if (food !== false && food !== undefined) {
        // 기본 가격은 첫번째 옵션 형태로 들어간다
        const foodOpt: UnifiedOrderFoodOpt = {
          optName: '',
          optPrice: food.foodPrice,
          optQty: 1
        };

        const unifiedOrderFood: UnifiedOrderFood = {
          foodName: food.foodName,
          foodOrdPrice: food.foodPrice * food.qty,
          foodQty: food.qty,
          foodOpts: [foodOpt],
          mergedName: food.foodName    // 나중에 채워질 것이다
        };

        this.addMenu(unifiedOrderFood);
      }
    });
  }

  public async closeDialog() {
    const close = await Swal.fire({
      html: '현재 내용은 저장되지 않습니다.</br>메뉴 입력 화면을 닫으시겠습니까?',
      icon: 'warning',
      confirmButtonText: '닫기',
      confirmButtonColor: 'hsl(212, 100%, 50%)',
      cancelButtonText: '되돌아가기',
      showCancelButton: true,
      // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
      reverseButtons: true
    });

    if (close.isConfirmed) {
      this.dialogRef?.close();
    }
  }

  public removeFood(menuIndex: number) {
    this.unifiedOrderFoods = this.unifiedOrderFoods.filter(($menu, index) => index !== menuIndex);
    this.calcTotal();
  }

  public addFoodQty(menuIndex: number, qty: number) {
    this.unifiedOrderFoods[menuIndex].foodQty += qty;
    this.unifiedOrderFoods[menuIndex].foodOrdPrice = this.unifiedOrderFoods[menuIndex].foodOpts.reduce((optTotal, opt) => {
      return optTotal + (opt.optPrice * opt.optQty);
    }, 0) * this.unifiedOrderFoods[menuIndex].foodQty;
    this.calcTotal();
  }

  /**
   * orderUnifiedOrderFoods가 변경되는 모든 상황에서 호출되어야한다.
   */
  calcTotal() {
    this.totalPrice = this.unifiedOrderFoods.reduce((total, menu) => total + menu.foodOrdPrice, 0);
  }

  finishUnifiedMenu() {
    // 최종적으로 넘길 때에는 가격순으로 정렬한다.
    this.dialogRef.close({
      foods: postProcessFoods(this.unifiedOrderFoods, true),
      shopNo: this.data.unifiedMenu.shopNo
    });
  }
}
