/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { RoomDoc } from '../../schema/3/schema';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class RoomService {
  room: RoomDoc = {} as any;
  latestSubject = new BehaviorSubject<RoomDoc>({} as any);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe(roomKey: string) {
    this.db.doc<RoomDoc>(`room/${roomKey}`).valueChanges().subscribe(doc => {
      this.room = doc;

      this.latestSubject.next(doc);
    });
  }

  updateRoomField(roomKey: string, fieldPath: string, value: any) {
    return this.db.doc(`room/${roomKey}`).update({
      [fieldPath] : value
    });
  }

  mergeRoomDoc(roomKey: string, updateData: any) {
    return this.db.doc(`room/${roomKey}`).set(updateData, { merge: true });
  }
}
