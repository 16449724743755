/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { UnifiedMenuDoc } from '../../schema/3/schema';

import { UnifiedMenuService } from '../../core/3/unified-menu.service';

@Component({
  selector: 'app-dialog-select-shop',
  templateUrl: './dialog-select-shop.component.html',
  styleUrls: ['./dialog-select-shop.component.scss']
})
export class DialogSelectShopComponent implements OnInit {
  menuDocs: UnifiedMenuDoc[] = [];

  constructor(
    private dialogRef: MatDialogRef<DialogSelectShopComponent>,
    private unifiedMenuService: UnifiedMenuService
  ) { }

  ngOnInit(): void {
    this.initMenuDocs();
  }

  private initMenuDocs() {
    const menuDocs = this.unifiedMenuService.menuDocs;
    this.menuDocs = menuDocs.filter(menuDoc => menuDoc.baeminShopInfo?.Ad_Yn !== 'N')
    .sort((a, b) => {
      const typeA = a.baeminShopInfo?.Ct_Ty_Cd;
      const typeB = b.baeminShopInfo?.Ct_Ty_Cd;

      const weightA = typeA === '1' ? 100 : typeA === '4' ? 50 : 0;
      const weightB = typeB === '1' ? 100 : typeB === '4' ? 50 : 0;

      // 큰 값이 앞에 위치
      return weightB - weightA;
    });
  }

  public selectShop(menuDoc: UnifiedMenuDoc) {
    this.dialogRef.close(menuDoc);
  }

  public onClose() {
    this.dialogRef.close('close');
  }

}
