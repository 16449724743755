/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { cloneDeep } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UnifiedOrderFood, UnifiedOrderFoodOpt } from '../../../schema/1/schema-common';
import { UnifiedMenuFood, UnifiedMenuFoodOpt } from '../../../schema/3/schema';
import { UnifiedMenuFoodOptGrpUI } from '../../../schema/4/schema-ui';

@Component({
  selector: 'app-dialog-option-menu',
  templateUrl: './dialog-option-menu.component.html',
  styleUrls: ['./dialog-option-menu.component.scss']
})
export class DialogOptionMenuComponent implements OnInit, OnDestroy {
  public unifiedMenuFood: UnifiedMenuFood;
  public unifiedMenuFoodForm: FormGroup;
  public validUnifiedMenuFoodForm = true;
  public unifiedMenuFoodFormErrMessage = '';

  private destroySignal = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogOptionMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { unifiedMenuFood: UnifiedMenuFood }
  ) { }

  ngOnInit(): void {
    this.unifiedMenuFood = cloneDeep(this.data.unifiedMenuFood);
    const initModel = {};
    this.unifiedMenuFood.foodOptGroups.forEach((foodOptGroup: UnifiedMenuFoodOptGrpUI, i) => {
      // radio 정하기
      foodOptGroup._controlType = (foodOptGroup.optGrpMinSel === 1 && foodOptGroup.optGrpMaxSel === 1) ? 'radio' : 'checkbox';

      // 라디오 타입이면 처음 것을 선택
      if (foodOptGroup._controlType === 'radio') {
        initModel[`foodOptGroup${i}`] = foodOptGroup.foodOpts[0];
      } else {
        // 최소 1개 이상 선택해야 하는 경우에 앞에서부터 선택해 놓는다.
        const value: UnifiedMenuFoodOpt[] = foodOptGroup.foodOpts
          .filter((foodOpt, foodOptIndex) => (foodOptGroup.optGrpMinSel === foodOptGroup.foodOpts.length) ||
            (foodOptGroup.optGrpMinSel > 0 && foodOptIndex < foodOptGroup.optGrpMinSel)
          );

        // value가 배열이기 때문에 튜플형태 ([..., ... , ...])와 구별하기 위해 []로 한번 감싸주어야한다.
        initModel[`foodOptGroup${i}`] = [value];
      }
    });

    this.unifiedMenuFoodForm = this.fb.group(initModel);

    this.observeForm();
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  public addFood() {
    const unifiedMenuFoodFormValue = this.unifiedMenuFoodForm.value;

    const foodOpts: UnifiedOrderFoodOpt[] = [];
    Object.values(unifiedMenuFoodFormValue).forEach((foodOptsGroup: any) => {
      if (Array.isArray(foodOptsGroup)) {
        foodOptsGroup.forEach((foodOpt: UnifiedMenuFoodOpt) => {
          foodOpts.push({
            optName: foodOpt.optName,
            optPrice: foodOpt.optPrice,
            optQty: 1
          });
        });
      } else {
        foodOpts.push({
          optName: foodOptsGroup.optName,
          optPrice: foodOptsGroup.optPrice,
          optQty: 1
        });
      }
    });

    const unifiedOrderFood: UnifiedOrderFood = {
      foodName: this.unifiedMenuFood.foodName,
      foodOpts,
      foodQty: 1,
      foodOrdPrice: foodOpts.reduce((acc, foodOpt) => acc + foodOpt.optPrice * foodOpt.optQty, 0),
      mergedName: this.unifiedMenuFood.foodName // 나중에 변경한다.
    };

    this.dialogRef.close(unifiedOrderFood);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  observeForm() {
    this.unifiedMenuFoodForm.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(formValue => {
        // 초기화
        this.validUnifiedMenuFoodForm = true;
        this.unifiedMenuFoodFormErrMessage = '';

        this.unifiedMenuFood.foodOptGroups.forEach((foodOptGroup: UnifiedMenuFoodOptGrpUI, index) => {
          if (foodOptGroup._controlType === 'checkbox' && formValue[`foodOptGroup${index}`].length < foodOptGroup.optGrpMinSel) {
            this.validUnifiedMenuFoodForm = false;
            this.unifiedMenuFoodFormErrMessage = `! ${foodOptGroup.optGrpName}에서 ${foodOptGroup.optGrpMinSel}이상 선택해야합니다. `;
          }
        });
      });
  }
}
