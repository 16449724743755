/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { OrderHistory } from '../../schema/4/schema-functions-call';

import { DialogOrderHistoryComponent } from './dialog-order-history.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOrderHistoryService {

  constructor(
    private dialog: MatDialog
  ) { }

  public openDialogOrderHistory(orderHistories: OrderHistory[]) {
    return this.dialog.open(DialogOrderHistoryComponent, {
      width: '80vw',
      data: {
        orderHistories
      },
      autoFocus: false
    });
  }
}
