/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { debugLog } from '../../core/1/common';
import { AuthService } from '../../core/2/auth.service';

import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  authForm: FormGroup;
  authSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  ngOnInit() {
    this.authForm = this.fb.group({
      email: '',
      password: ''
    });

    this.authSubscription = this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      })
    ).subscribe(async value => {
      if (value === true) {
        debugLog(`로그인된 상태이므로 바로 이동한다.`);
        this.router.navigate(['/operating']);
      }
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  onSubmit() {
    const promise = this.authService.signinWithEmailAndPassword(this.authForm.value.email, this.authForm.value.password);

    promise.then(value => {
      debugLog('navigate');
      this.router.navigate(['/']);
    }, err => {
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
    });
  }
}
