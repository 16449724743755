/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from '../my-material/my-material.module';
import { OperatingComponent } from './operating/operating.component';
import { OperatingTableComponent } from './operating-table/operating-table.component';
import { SharedModule } from '../shared/shared.module';
import { NoticeComponent } from './notice/notice.component';

@NgModule({
  declarations: [
    OperatingComponent,
    OperatingTableComponent,
    NoticeComponent
  ],
  imports: [
    CommonModule,
    MyMaterialModule,
    SharedModule
  ],
})
export class OperatingModule { }
