/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode } from '../../schema/3/schema';
import {
  CallInputCancelDelivery, CallInputModifyDelivery, CallInputRequestDeliveryInformation,
  CallOutputCancelDelivery, CallOutputModifyDelivery, CallOutputRequestDeliveryInformation
} from '../../schema/4/schema-functions-call';
import { DeliveryProtocol } from '../../schema/5/schema-protocol';

import { deliveryGuideText } from '../1/string-map';
import { lineClampSwalContent } from '../1/common';
import { normalizeTel } from '../1/util';
import { VroongDeliveryService } from '../1/vroong-delivery.service';
import { IntegratedDeliveryService } from '../1/integrated-delivery.service';
import { GhokirunDeliveryService } from '../1/ghokirun-delivery.service';
import { SiteService } from '../1/site.service';
import { CombinenetDeliveryService } from '../1/combinenet-delivery.service';
import { LogiallDeliveryService } from '../1/logiall-delivery.service';
import { ZendeliDeliveryService } from '../1/zendeli-delivery.service';
import { MoaplanetDeliveryService } from '../1/moaplanet-delivery.service';
import { IudreamDeliveryService } from '../1/iudream-delivery.service';
import { BaedalyoDeliveryService } from '../1/baedalyo-delivery.service';
import { BarogoDeliveryService } from '../2/barogo-delivery.service';
import { MannaDeliveryService } from '../2/manna-delivery.service';
import { LogService } from '../4/log.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService implements DeliveryProtocol {
  /** 최근 조회한 메인 배달 대행사의 픽업요청시간 목록 */
  public recentRequestedPickupMinutesOptions: number[] = [];

  constructor(
    private integratedDeliveryService: IntegratedDeliveryService,
    private barogoDeliveryService: BarogoDeliveryService,
    private combinenetDeliveryService: CombinenetDeliveryService,
    private logiallDeliveryService: LogiallDeliveryService,
    private ghokirunDeliveryService: GhokirunDeliveryService,
    private vroongDeliveryService: VroongDeliveryService,
    private mannaDeliveryService: MannaDeliveryService,
    private zendeliDeliveryService: ZendeliDeliveryService,
    private moaplanetDeliveryService: MoaplanetDeliveryService,
    private iudreamDeliveryService: IudreamDeliveryService,
    private baedalyoDeliveryService: BaedalyoDeliveryService,
    private logService: LogService,
    private siteService: SiteService,
    private fns: AngularFireFunctions
  ) { }

  private typeOfDeliveryService(delivery: UnifiedDeliveryDoc): DeliveryProtocol {
    if (['baera', 'coupang', 'yogiyo'].includes(delivery.deliveryVendor)) {
      return this.integratedDeliveryService;
    } else if (delivery.deliveryVendor === 'barogo') {
      return this.barogoDeliveryService;
    } else if (['run2u', 'spidor'].includes(delivery.deliveryVendor)) {
      return this.combinenetDeliveryService;
    } else if (delivery.deliveryVendor === 'logiall') {
      return this.logiallDeliveryService;
    } else if (delivery.deliveryVendor === 'ghokirun') {
      return this.ghokirunDeliveryService;
    } else if (delivery.deliveryVendor === 'vroong') {
      return this.vroongDeliveryService;
    } else if (delivery.deliveryVendor === 'manna') {
      return this.mannaDeliveryService;
    } else if (delivery.deliveryVendor === 'zendeli') {
      return this.zendeliDeliveryService;
    } else if (['shero', 'dalgo'].includes(delivery.deliveryVendor)) {
      return this.moaplanetDeliveryService;
    } else if (delivery.deliveryVendor === 'iudream') {
      return this.iudreamDeliveryService;
    } else if (['baedalyo', 'baedalhero'].includes(delivery.deliveryVendor)) {
      return this.baedalyoDeliveryService;
    }

    throw new TypeError('Unsupported deliveryVendor');
  }

  public async modify(delivery: UnifiedDeliveryDoc, callInputModifyDelivery: CallInputModifyDelivery): Promise<boolean> {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.modify) {
      return deliveryService.modify(delivery, callInputModifyDelivery);
    }

    const relatedOrder = { _id: delivery.relatedOrderId, site: delivery.site, room: delivery.room };
    try {
      const callable = this.fns.httpsCallable<CallInputModifyDelivery, CallOutputModifyDelivery>('callModifyDelivery');

      const response = await callable(callInputModifyDelivery).toPromise();

      if (response.result === 'success') {
        this.logService.logOrder(relatedOrder, '배차 변경 성공', 'info', true);
      } else {
        this.logService.logOrder(relatedOrder, `배차 변경 실패 ${JSON.stringify(response)}`, 'error');
        lineClampSwalContent('배차를 변경하지 못 했습니다.', response.reason);
        return false;
      }
    } catch (error) {
      this.logService.logOrder(relatedOrder, `배차 변경 중 예외 발생 ${error}`, 'error');
      lineClampSwalContent('배차를 변경하지 못 했습니다.', error.message);
      return false;
    }

    return true;
  }

  public async cancel(delivery: UnifiedDeliveryDoc, callInputCancelDelivery: CallInputCancelDelivery): Promise<boolean> {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (!this.canCancel(delivery)) {
      this.logService.logRoomWithToastrError(`취소할 수 없는 배차인데 취소하려고 했습니다. ${delivery._id} ${delivery.deliveryVendor}`);
      return false;
    }

    if (deliveryService?.cancel) {
      return deliveryService.cancel(delivery, callInputCancelDelivery);
    }

    const relatedOrder = { _id: delivery.relatedOrderId, site: delivery.site, room: delivery.room };
    try {
      const callable = this.fns.httpsCallable<CallInputCancelDelivery, CallOutputCancelDelivery>('callCancelDelivery');
      const response = await callable(callInputCancelDelivery).toPromise();

      if (response.result === 'success') {
        this.logService.logOrder(relatedOrder, '배차 취소 성공', 'info', true);
      } else {
        this.logService.logOrder(relatedOrder, `배차 취소 실패 ${JSON.stringify(response)}`, 'error');
        lineClampSwalContent('배차를 취소하지 못 했습니다', response.reason);
        return false;
      }
    } catch (error) {
      this.logService.logOrder(relatedOrder, `배차 취소 중 예외 발생 ${error}`, 'error');
      lineClampSwalContent('배차를 취소하지 못 했습니다', error.message);
      return false;
    }

    return true;
  }

  /**
   * 요청 전 배달 대행 정보
   */
  public async requestDeliveryInformation(callInputRequestDeliveryInformation: CallInputRequestDeliveryInformation): Promise<CallOutputRequestDeliveryInformation> {
    const callable = this.fns.httpsCallable<CallInputRequestDeliveryInformation, CallOutputRequestDeliveryInformation>('callRequestDeliveryInformation');
    return callable(callInputRequestDeliveryInformation).toPromise();
  }

  public canCancel(delivery: UnifiedDeliveryDoc): boolean {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.canCancel) {
      return deliveryService?.canCancel(delivery);
    }

    // Default
    return false;
  }

  /**
   * 취소 사유 선택
   */
  public cancelReasonsFor(delivery: UnifiedDeliveryDoc) {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.cancelReasonsFor) {
      return deliveryService?.cancelReasonsFor(delivery);
    }

    return null;
  }

  /**
   * 라이더 이름
   * 고키런은 ghokirunDeliveryService로 분리해야하나 다른 기능이 없어서 deliveryService에서 처리하게 하고 있다.
   */
  public riderName(delivery: UnifiedDeliveryDoc): string {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.riderName) {
      return deliveryService?.riderName(delivery);
    }

    // default
    return delivery.deliveryStatusCode === UnifiedDeliveryStatusCode.SUBMITTED ? '배차대기' : delivery.riderName?.length > 0 ? delivery.riderName : '<아무개>';
  }

  /**
   * 라이더 전화번호
   * 고키런은 ghokirunDeliveryService로 분리해야하나 다른 기능이 없어서 deliveryService에서 처리하게 하고 있다.
   */
  public riderTel(delivery: UnifiedDeliveryDoc): string {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.riderTel) {
      return deliveryService?.riderTel(delivery);
    }

    // default
    return delivery.deliveryStatusCode === UnifiedDeliveryStatusCode.SUBMITTED ? '' : normalizeTel(delivery.riderTel);
  }

  /**
   * 사용 안내사항
   */
  public guide(delivery: UnifiedDeliveryDoc): string {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.guide) {
      return deliveryService?.guide(delivery);
    }

    const deliveryVendorParams = this.siteService.siteDoc.deliveryParams.deliveryVendorParams[delivery.deliveryVendor];
    const contactName = deliveryVendorParams?.contactName ?? '';
    const contactTel = deliveryVendorParams?.contactTel ? normalizeTel(deliveryVendorParams.contactTel) : '';
    const contactGuideText = contactName && contactTel ? ` (${contactName}: ${contactTel})` : '';
    return `${deliveryGuideText[delivery.deliveryVendor][delivery.deliveryStatusCode]}${contactGuideText}`;
  }

  public modifiableFields(delivery: UnifiedDeliveryDoc): string[] {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.modifiableFields) {
      return deliveryService?.modifiableFields(delivery);
    }

    return [];
  }

  public onDemandRiderTel(delivery: UnifiedDeliveryDoc): boolean {
    const deliveryService = this.typeOfDeliveryService(delivery);

    if (deliveryService?.onDemandRiderTel) {
      return deliveryService?.onDemandRiderTel(delivery);
    }

    if (delivery.deliveryVendor === 'coupang') {
      return true;
    }

    return false;
  }
}
