/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import firestore = firebase.firestore;

import { WHERE } from '../../schema/1/schema-common';
import { UnifiedDeliveryDoc } from '../../schema/3/schema';

const collectionPath = 'unifiedDelivery';

@Injectable({
  providedIn: 'root'
})
export class UnifiedDeliveryService {

  constructor(
    private db: AngularFirestore
  ) { }

  observe(wheres: WHERE[], openHours: number, openMinutes: number, atDate = 0, duration = 1, orderBy: 'asc' | 'desc' = 'desc') {
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }

    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');
    const atDateStringFrom = format(now.getTime() + atDate * 24 * 3600 * 1000, `yyyy-MM-dd'T'${openHHMM}:00+09:00`);
    const atDateStringTo = format(now.getTime() + (atDate + duration) * 24 * 3600 * 1000, `yyyy-MM-dd'T'${openHHMM}:00+09:00`);

    if (orderBy === 'asc') {
      console.log(`${this.constructor.name}::observe ${collectionPath} from ${atDateStringFrom} to ${atDateStringTo}`);
    } else {
      console.log(`${this.constructor.name}::observe ${collectionPath} from ${atDateStringTo} to ${atDateStringFrom}`);
    }

    const queryFn: QueryFn = ref => wheres.reduce((q, where: WHERE) => q.where(where[0], where[1] as firestore.WhereFilterOp, where[2]),
      ref.orderBy('timeSubmitted', orderBy)[orderBy === 'asc' ? 'startAt' : 'endAt'](atDateStringFrom)[orderBy === 'asc' ? 'endAt' : 'startAt'](atDateStringTo)
    );

    const deliveryCollection = this.db.collection<UnifiedDeliveryDoc>(collectionPath, queryFn);

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = deliveryCollection.valueChanges();

    return observable;
  }

  public observeUnifiedDelivery(wheres: WHERE[]) {
    const queryFn: QueryFn = ref => wheres.reduce((q, where: WHERE) => q.where(where[0], where[1] as firestore.WhereFilterOp, where[2]),
      ref.orderBy('timeSubmitted', 'desc')
    );

    const deliveryCollection = this.db.collection<UnifiedDeliveryDoc>(collectionPath, queryFn);

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    return deliveryCollection.valueChanges();
  }

  public observeDeliveryById(deliveryId: string) {
    return this.db.doc(`${collectionPath}/${deliveryId}`).valueChanges();
  }
}
