/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogSelectShopComponent } from './dialog-select-shop.component';

@Injectable({
  providedIn: 'root'
})
export class DialogSelectShopService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogSelectShop() {
    return this.dialog.open(DialogSelectShopComponent, {
      disableClose: true
    });
  }
}
