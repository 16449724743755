<table class="menu" [class.print]="isPrint" [class.noPrice]="!showPrice">
  <thead>
    <tr>
      <th>메뉴/상품명</th>
      <th>수량</th>
      <th *ngIf="showPrice">금액</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let food of order.foods; let i = index">
      <tr [class.notfirst]="i > 0" class="mainopt">
        <td>{{ postProcessFoodName(food.foodName + (food.foodOpts[0].optName ? ('_' + food.foodOpts[0].optName) : '')) }}</td>
        <td>{{ food.foodQty }}</td>
        <td *ngIf="showPrice">{{ food.foodOpts[0].optPrice * food.foodOpts[0].optQty * food.foodQty | number }}</td>
      </tr>
      <tr *ngFor="let foodOpt of food.foodOpts.slice(1); let last = last" class="subopt">
        <td>{{ '+ ' + postProcessFoodName(foodOpt.optName) }}</td>
        <td>{{ food.foodQty * foodOpt.optQty }}</td>
        <td  *ngIf="showPrice" style="text-align: right;">{{ foodOpt.optPrice * foodOpt.optQty * food.foodQty | number }}</td>
      </tr>
    </ng-container>
    <tr *ngIf="showPrice && order.deliveryTip" class="notfirst mainopt">
      <td>배달팁</td>
      <td></td>
      <td>{{ order.deliveryTip | number }}</td>
    </tr>
    <tr *ngIf="showPrice && (order.eventDiscount || order.discount)" class="notfirst mainopt">
      <td>할인액</td>
      <td></td>
      <td>-{{ (order.eventDiscount ? order.eventDiscount : 0) + (order.discount ? order.discount : 0)| number }}</td>
    </tr>
  </tbody>
  <tfoot *ngIf="showPrice">
    <tr>
      <th>합계</th>
      <th colspan="2">{{ paymentAmount | number }}</th>
    </tr>
  </tfoot>
</table>
