/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { format, isAfter } from 'date-fns';
import { ko } from 'date-fns/locale';

import { NoticeDoc } from '../../schema/3/schema';
import { UserService } from '../../core/3/user.service';

import { SimpleNoticeService } from '../../core/1/simple-notice.service';

import { DialogNoticeDetailService } from '../../shared/dialog-notice-detail/dialog-notice-detail.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit, OnDestroy {
  public notices: (NoticeDoc & {
    date: string;
    isRead: boolean;
  })[] = [];

  @Input('notices') set _notices(notices: NoticeDoc[]) {
    if (notices.length < 1) {
      // 공지가 없으면 groupByPinned가 undefined되기 때문에 아무것도 하지 않는다.
      return;
    }

    // 화면에 표시할 내용으로 변환
    this.notices = notices
      .sort((a, b) => a.pinned && !b.pinned ? -1 : !a.pinned && b.pinned ? 1 : isAfter(a._timeCreate.toDate(), b._timeCreate.toDate()) ? -1 : 1)
      .map(notice => ({
        ...notice,
        date: format(notice._timeCreate.toDate(), 'MM-dd ccc HH:mm', { locale: ko }),
        isRead: !!notice.readBy[this.userService.user.room],
      }));
  }

  public existSimpleNotice = false;
  private simpleNoticeSubscription: Subscription;

  constructor(
    private simpleNoticeService: SimpleNoticeService,
    private dialogNoticeDetailService: DialogNoticeDetailService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    // 공지 존재 여부를 확인해서 높이를 계산할 때 사용한다.
    this.simpleNoticeSubscription = this.simpleNoticeService.latestSimpleNoticesSubject.subscribe(docs => {
      this.existSimpleNotice = docs.length > 0;
    });
  }

  ngOnDestroy() {
    this.simpleNoticeSubscription?.unsubscribe();
  }

  async openDialogNoticeDetail(notice: NoticeDoc) {
    this.dialogNoticeDetailService.openSimpleNoticeDialog(notice);
  }

}
