/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogOrderStatsComponent } from './dialog-order-stats.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOrderStatsService {

  constructor(
    private dialog: MatDialog
  ) { }

  openOrderStatsDialog() {
    this.dialog.open(DialogOrderStatsComponent);
  }
}
