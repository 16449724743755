/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */

import { parseISO, parse } from 'date-fns';
import { UnifiedOrderFood } from '../../schema/1/schema-common';

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo: string) {
  if (telNo == null) {
    return '번호없음';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17]0)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 1522-6485
  matches = telNo.match(/^(1[568][0-9][0-9]?)([0-9]{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}`;
  }

  return telNo;
}

/**
 * '고스트키친 삼성점 04호'를 '삼성점 04호'로 변환한다.
 */
export function trimOrganization(text: string) {
  return text?.replace(/^\S+\s+/, '');
}

/**
 * 여러 형태의 시간을 ISO 형식의 문자열로 변환한다.
 * date2iso.test.ts에 사용예 확인
 *
 * Date
 * number
 * Case 0.   '2019-05-03T12:08:38+0900'
 * Case 1-1. '2019-05-03 12:08:38'
 * Case 1-2. '2019-05-03 12:08:38.0'
 * Case 2.   '20190503T120830Z'
 * Case 3.   '1559097490'
 * Case 3.   '1559097490123'
 *
 */
export function toDate(date: string | number | Date): Date {
  if (date === undefined) {
    throw TypeError(`undefined date format @ toDate()`);
  }

  if (date instanceof Date) {
    return date;
  } else if (typeof date === 'number') {
    if (date > 9999999999) {
      // 밀리초라면
      return new Date(date);
    } else {
      // 초라면
      return new Date(date * 1000);
    }
  } else {
    // Case 0. '2019-05-03T12:08:38+0900'
    let match = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}):?(\d{2})$/);
    if (match) {
      return parseISO(date);
    }

    // Case 1-1. '2019-05-03 12:08:38'
    // Case 1-2. '2019-05-03 12:08:38.0'
    match = date.match(/^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.0)?$/);

    if (match) {
      return parseISO(`${match[1]}T${match[2]}+09:00`);
    }

    // Case 2.
    match = date.match(/^(\d{4}\d{2}\d{2})T(\d{2}\d{2}\d{2})Z/);

    if (match) {
      return parse(`${match[1]}T${match[2]}+00:00`, `yyyyMMdd'T'HHmmssXXX`, new Date());
    }

    // Case 3. 1559097490
    // 단위가 초라면 10자가 될 것이다.
    match = date.match(/^\d{10}$/);
    if (match) {
      return new Date(parseInt(date, 10) * 1000);
    }

    // 단위가 밀리초라면 13자가 될 것이다.
    match = date.match(/^\d{13}$/);
    if (match) {
      return new Date(parseInt(date, 10));
    }
  }

  throw TypeError(`Unexpected date format : ${date}`);
}

/**
 * 두 시각 차이를 계산해서 M:ss 형식으로 변환한다.
 * timestamp2 - timestamp1
 */
export function diffTime(timestamp1: string | number | Date, timestamp2: string | number | Date, round?: boolean) {
  if (round == null) {
    round = false;
  }

  const ret = {
    m: 0,
    s: 0,
    sStr: '00'
  };

  if (timestamp1 == null || timestamp2 == null) {
    return ret;
  }

  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  const date1 = toDate(timestamp1).getTime();
  const date2 = toDate(timestamp2).getTime();

  const diffSec = Math.floor((date2 - date1) / 1000);

  let m = Math.floor(diffSec / 60);
  let s = diffSec % 60;

  if (round && s > 50) {
    s = 0;
    m += 1;
  }

  const sStr = s < 10 ? `0${s}` : `${s}`;

  ret.m = m;
  ret.s = s;
  ret.sStr = sStr;

  return ret;
}

/**
 * 1 -> 'A', 2 -> 'B', ....
 */
export function numberToAlphabet(num: number) {
  return String.fromCharCode(num + 64);
}

/**
 *
 * WGS84 좌표계의 두 점 사이의 거리를 구한다.
 * refer: https://blog.asamaru.net/2015/09/14/calculate-distance-between-two-wgs84-points/
 *
 * @param lat1 좌표1의 위도
 * @param lng1 좌표1의 경도
 * @param lat2 좌표2의 위도
 * @param lng2 좌표2의 경도
 * @returns 단위는 미터
 */
export function calcGeoDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  // const R = 6371000; // Radius from wikipedia (https://en.wikipedia.org/wiki/Earth_radius)
  const R = 6372000;  // 부릉의 값과 비교해서 약간 조정한 값
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in meter

  // meter
  return Math.floor(d);
}

/**
 * iOS와 안드로이드를 구분할 때 사용한다.
 */
export function isiPad() {
  // 아이패드에서 재시작 후 사용자의 입력을 한번은 받아야 소리가 날 수 있어서 사용자의 입력을 유도한다.
  const userAgent = navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i) == null ? false : true;
  // iOS 13, iPadOS 13은 userAgent에 위 문구가 없다.
  // https://littleshark.tistory.com/56
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
  return (userAgent && 'ontouchend' in document);
}

/**
 * iPad를 구분한다.
 */
export function isiPadDevice() {
  const userAgent = navigator.userAgent.match(/iPad|Macintosh/i) == null ? false : true;
  // https://minemanemo.tistory.com/101
  return (userAgent && navigator.maxTouchPoints > 0);
}

/**
 * - 를 삽입한다.(입력 이벤트가 계속해서 발생하는 상황에 사용한다.)
 */
export function normalizingTel(telNo: string) {
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // if (telNo[0] !== '0') {
  //   return '';
  // }
  // 2번째 숫자가 허용되지 않는 숫자라면 거부
  // if (telNo[1] !== '1' && telNo[1] !== '2'  && telNo[1] !== '5' && telNo[1] !== '7') {
  //   return telNo[0];
  // }

  if (telNo.match(/^010|050|070/)) {
    // 국번이 0이나 1로 시작하지 않는다.
    if (telNo[3] === '0' || telNo[3] === '1') {
      return telNo.substr(0, 3);
    }

    if (telNo.length === 12) {
      return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
    } else if (telNo.length > 7) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
    } else if (telNo.length > 3) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
    } else {
      return telNo;
    }
  } else if (telNo.match(/^15|16|18/)) {
    // 15YY·16YY·18YY 	기간통신사업자 공통부가서비스 및 자율부가서비스 (전국대표번호 등)
    if (telNo.length > 4) {
      return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}`;
    } else {
      return telNo;
    }
  } else { // 02
    // 국번이 0이나 1로 시작하지 않는다.
    if (telNo[2] === '0' || telNo[2] === '1') {
      return telNo.substr(0, 2);
    }

    if (telNo.length > 9) {
      return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
    } else if (telNo.length > 5) {
      return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
    } else if (telNo.length > 2) {
      return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
    } else {
      return telNo;
    }
  }
}

/**
 * 금액 형식으로 만든다.
 */
export function normalizeCurrency(value: string) {
  if (typeof value !== 'string') {
    return '';
  }

  // 아직 숫자가 들어오기 전 부호만 있는 상태
  if (value === '-') {
    return value;
  }

  // 숫자 이외에는 모두 제외한다.
  // 음수일 때 맨 앞 부호만 유지
  const isPositive = value.startsWith('-') ? false : true;
  value = `${isPositive ? '' : '-'}${value.replace(/[^0-9]/g, '')}`;

  // 빈칸 무효
  if (value === '') {
    return '';
  }

  return new Intl.NumberFormat().format(parseInt(value, 10));
}


/**
 * toe-order-hub에서 가져왔다.
 *
 * foods가 1차 완성된 후에 다음의 2가지를 수행한다.
 * 1. 옵션을 포함해서 한 줄로 이름을 정리한 mergedName을 추가한다.
 * 2. (선택)비싼 음식 먼저 앞에 위치하도록 정렬한다.
 */
export function postProcessFoods(foods: UnifiedOrderFood[], sort = false) {
  // 1. mergedName 생성
  for (const food of foods) {
    if (food.foodOpts.length > 0) {
      const opt = food.foodOpts[0];
      let mergedName = opt.optName === '' ? food.foodName.trim() : `${food.foodName.trim()}_${opt.optName.trim()}`;
      // (사이드)잡채_사이드 같은 경우는 _사이드를 제거한다.
      mergedName = mergedName.replace(/(\(사이드\).+)_사이드$/, '$1');

      if (food.foodOpts.length > 1) {
        mergedName += ' [' + food.foodOpts.slice(1).map(foodOpt => foodOpt.optName.trim()).join(' + ') + ']';
      }
      food.mergedName = mergedName;
    } else {
      food.mergedName = food.foodName;
    }
  }

  // 2. 동일한 옵션 구성은 하나로 merge 한다.
  const keyedFoods: {
    [longkey: string]: UnifiedOrderFood
  } = {};

  for (const food of foods) {
    // 옵션 가격까지 포함해서 비교한다.
    // optQty는 1로 가정하고 비교하지 않는다.
    const longkey = `${food.mergedName}${food.foodOpts.map(opt => String(opt.optPrice)).join('-')}`;
    const keyedFood = keyedFoods[longkey];

    // 중복 메뉴
    if (keyedFood) {
      keyedFood.foodQty += food.foodQty;
      keyedFood.foodOrdPrice = keyedFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * keyedFood.foodQty;

      continue;
    }

    keyedFoods[longkey] = food;
  }

  foods = Object.values(keyedFoods);

  // 3. 가격이 비싼 메뉴가 앞에 위치하도록
  if (sort) {
    const sorted = foods.sort((food1: UnifiedOrderFood, food2: UnifiedOrderFood) => {
      const price1 = food1.foodOrdPrice; // foodQty가 포함된 가격
      const price2 = food2.foodOrdPrice;

      if (price1 > price2) {
        return -1;
      } else if (price1 < price2) {
        return 1;
      } else {
        if (food1.mergedName < food2.mergedName) {
          return -1;
        } else if (food1.mergedName > food2.mergedName) {
          return 1;
        }
        return 0;
      }
    });

    return sorted;
  }

  return foods;
}


const diffTimestampInstances: {
  [instanceKey: string]: [number, number];
} = {};
/**
 * 실행하는 시점 간의 시간 차이를 구할 때 사용한다.
 */
export function diffTimestamp(instanceKey: string) {
  const nowMilli = Date.now();
  if (diffTimestampInstances[instanceKey] === undefined) {
    diffTimestampInstances[instanceKey] = [nowMilli, 0];
  }

  // 1. get old time
  const [oldTimestamp, oldCount] = diffTimestampInstances[instanceKey];
  // 2. calculate diffTIme
  const diffMilli = nowMilli - oldTimestamp;

  const sec = Math.floor(diffMilli / 1000);
  const milli = String(diffMilli % 1000);

  // 3. update oldTIme
  diffTimestampInstances[instanceKey] = [nowMilli, oldCount + 1];

  return `[${oldCount + 1}] ${sec}.${milli.padStart(3, '0')}`;
}

/**
 * 아래와 같은 문자열에서 태그를 제거하기 위해 사용한다.
 * <font color='#000000'>10,000원 ~ </font>
 * 일단 간단하게 <.*?>를 태그의 시작과 끝으로 가정한다. *?는 non-greedy
 *
 * @bSplit true이면 배열을 리턴한다. 그렇지 않은 경우에는 문자열을 리턴한다.
 */
export function removeTags(str: string, bSplit = false) {
  if (bSplit) {
    return str.split(/<.*?>/).filter(s => s.trim().length > 0);
  } else {
    return str.split(/<.*?>/).filter(s => s.trim().length > 0).join('').trim();
  }
}

/**
 * TTS 지원여부
 */
export function isTTSSupported() {
  if (speechSynthesis?.speak) {
    return true;
  } else {
    return false;
  }
}

/**
 * <br>로 1차 나누고
 * <font>로 2차 나눈다.
 *
 * ex.
 * <font color='#000000'>2,000원</font> <font color='#888888'>논현1동, 서초1동, 서초2동, 서초3동, 서초4동, 역삼1동, 역삼2동</font>
 * <br>
 * <font color='#000000'>3,000원</font> <font color='#888888'>논현2동, 도곡1동</font>
 * <br>
 * <font color='#000000'>4,000원</font> <font color='#888888'>반포1동, 반포4동</font>
 */
export function splitDeliveryTipGroupPhrase(phrase: string) {
  const rows = phrase.split(/<br>/).filter(s => s.trim().length > 0);

  // <font>로 분리
  const splitedRows = rows.map(row => removeTags(row, true) as string[]);

  return splitedRows;
}

/**
 * iOS에서는 최초 1회 음성 출력이 작동하지 않고, 두 번째 음성 출력부터 정상적으로 출력되는 이슈가 있어
 * 해당 문제를 해결하기 위해 빈 텍스트 음성을 최초 1회 출력한다.
 * issue: https://developer.apple.com/forums/thread/118077
 */
export function enableTTS() {
  try {
    if (isTTSSupported()) {
      const utterance = new SpeechSynthesisUtterance('');
      speechSynthesis.speak(utterance);
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * 메시지 음성 출력
 *
 * 특정 상황 (혹은 일부 브라우저)에서 예외가 발생하는 경우가 있다.
 *
 * error: ReferenceError: speechSynthesis is not defined
 *
 * 알림을 보내기에 적합하지 않아서 여기서 try..catch를 하지 않고 사용하는 곳에서 한다.
 *
 * @param text 음성으로 출력할 메시지
 */
export function textToSpeech(text: string) {
  if (isTTSSupported()) {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  }
}
