/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { WHERE } from '../../schema/1/schema-common';
import { UnifiedMenuDoc } from '../../schema/3/schema';

import { UtilService } from '../2/util.service';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMenuService {
  public menuDocs: UnifiedMenuDoc[] = [];

  constructor(
    private utilService: UtilService,
    private db: AngularFirestore
  ) { }

  // 호실에 연동된 배민 메뉴판 목록을 가져온다.
  public observeMenuDocs(wheres: WHERE[]) {
    const queryFn: QueryFn = ref => wheres.reduce((q, where: WHERE) => q.where(where[0], where[1] as firestore.WhereFilterOp, where[2]),
      ref
    );

    const unifiedMenuCollection = this.db.collection<UnifiedMenuDoc>('unifiedMenu', queryFn);
    unifiedMenuCollection.valueChanges().subscribe(menuDocs => {
      // console.log(menuDocs);
      this.menuDocs = menuDocs;
    }, error => {
      this.utilService.toastrError(`UnifiedMenuService:observeMenuDocs 에러 발생 : ${error.message}`);
    });
  }
}
