/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';

import { UnifiedDeliveryDoc } from '../../schema/3/schema';
import { DeliveryProtocol } from '../../schema/5/schema-protocol';

@Injectable({
  providedIn: 'root'
})
export class VroongDeliveryService implements DeliveryProtocol {

  constructor(
  ) { }

  public guide(delivery: UnifiedDeliveryDoc): string {
    return '';
  }
}
