/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';

/**
 * @param warn 배민 배달과 같은 경우에는 고객에게 완료 알림이 간다.
 */
export function openSwalDeliveryFinishCheck(diffMinutes: number, warn: boolean) {
  return Swal.fire({
    icon: 'warning',
    html: `주문 후 ${diffMinutes}분이 지났습니다.<br/>${warn ? '배송 완료 상태로 변경을 하면 고객에게 메시지가 전달될 수 있습니다.<br/>' : ''}계속 진행하시겠습니까?`,
    showCancelButton: true,
    cancelButtonText: '아니오',
    confirmButtonText: '배송 완료 진행하기',
    confirmButtonColor: 'hsl(212, 100%, 50%)',
    // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
    reverseButtons: true,
    customClass: {
      popup: 'swal-wide-60vw'
    }
  });
}
