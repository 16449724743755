/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaeminAppBlockResponse } from '../../schema/1/schema-baemin-app-api';
import { Message } from '../../schema/2/schema-message';
import { BaeminSessionDoc } from '../../schema/3/schema-baemin';

import { RoomService } from '../../core/1/room.service';
import { LogService } from '../../core/4/log.service';
import { FirebaseManagerService } from '../../core/5/firebase-manager.service';
import { BaeminAppApiService } from '../../core/6/baemin-app-api.service';
import { MessageService } from '../../core/7/message.service';

import { DialogSpinnerService } from '../../shared/dialog-spinner/dialog-spinner.service';
import { DialogSpinnerComponent } from '../../shared/dialog-spinner/dialog-spinner.component';
import { DialogNoticeService } from '../dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-dialog-baemin-block',
  templateUrl: './dialog-baemin-block.component.html',
  styleUrls: ['./dialog-baemin-block.component.scss']
})
export class DialogBaeminBlockComponent implements OnInit, OnDestroy {
  private spinnerDialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  public roomKey: string;

  public blocked: boolean | null = null;
  public blockedInfo: string | null = null;
  /** 연동된 배민 계정이 2개 이상인 경우에는 적용되는 배민 계정을 알 필요가 있다. */
  public memId: string;

  constructor(
    public dialogRef: MatDialogRef<DialogBaeminBlockComponent, boolean>,
    private roomService: RoomService,
    private dialogSpinnerService: DialogSpinnerService,
    private dialogNoticeService: DialogNoticeService,
    @Inject(MAT_DIALOG_DATA) public data: { roomKey: string },
    private messageService: MessageService,
    private logService: LogService,
    private firebaseManager: FirebaseManagerService,
    private baeminAppApi: BaeminAppApiService
  ) {
    this.roomKey = data.roomKey;
  }

  ngOnInit() {
    const roomDoc = this.roomService.room;
    const instanceNo = roomDoc?.account.baemin[0];
    this.firebaseManager.getDoc<BaeminSessionDoc>(`baeminSession/${instanceNo}`).then(doc => {
      if (doc) {
        this.memId = doc.appSession?.memberInfo?.memId;
      }
    });

    this.getBlock();
  }

  ngOnDestroy() {
    this.dialogRef?.close();
    this.spinnerDialogRef?.close();
  }

  onSubmit() {
    if (this.dialogRef) {
      this.dialogRef.close(false);
      this.dialogRef = undefined;
    }
  }

  /**
   * 3가지 API에 대해서 응답 형식을 공통이다.
   */
  handleBlockResponse(response: BaeminAppBlockResponse) {
    if (response.statusCode === '200') {
      if (response.data) {
        this.blocked = response.data.blocked;
        this.blockedInfo = response.data.temporaryBlockedInfo;
      } else {
        // unblock()의 응답에서 data = null이기 때문에 null이면 unblock인 것으로
        this.blocked = false;
        this.blockedInfo = null;
      }
    } else {
      // data는 항상 존재한다.
      this.dialogNoticeService.openSimpleNoticeDialog('영업임시중지 상태 조회 에러', response.statusMessage);
    }
  }

  /**
   * message collection을 이용한 방법
   */
  async getBlock0() {
    // 현재까지 배민 계정을 1개이므로 0 선택
    const instanceNo = this.roomService.room?.account.baemin[0];

    try {
      // tslint:disable-next-line: max-line-length
      const messageResponse = await this.messageService.requestGetBaeminBlock(instanceNo) as Message<'response', 'getBaeminBlock'>;

      if (messageResponse.result === 'success') {
        this.handleBlockResponse(messageResponse.body as BaeminAppBlockResponse);
      } else {
        this.dialogNoticeService.openSimpleNoticeDialog('영업임시중지 상태 조회 에러', messageResponse.reason);
      }
    } catch (err) {
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
    }
  }

  async getBlock() {
    // TODO: 배민 계정을 2개 이상 연동했을 때의 처리
    const roomDoc = this.roomService.room;
    const instanceNo = roomDoc?.account.baemin[0];

    try {
      const callOutput = await this.baeminAppApi.get_block(roomDoc.organization, roomDoc.site, roomDoc.room, instanceNo);
      if (callOutput.result === 'success' && callOutput.response.status === 200) {
        const body = JSON.parse(callOutput.response.body);
        this.handleBlockResponse(body as BaeminAppBlockResponse);
      } else {
        this.dialogNoticeService.openSimpleNoticeDialog('영업임시중지 상태 조회 에러', callOutput.reason);
      }
    } catch (err) {
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
    }
  }

  async block(temporaryBlockTime: number) {
    this.logService.logRoom(`임시영업중지 ${temporaryBlockTime}분 클릭`);
    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('요청 보내는 중');

    // TODO: 배민 계정을 2개 이상 연동했을 때의 처리
    const roomDoc = this.roomService.room;
    const instanceNo = roomDoc?.account.baemin[0];

    try {
      const callOutput = await this.baeminAppApi.post_block(roomDoc.organization, roomDoc.site, roomDoc.room, instanceNo, temporaryBlockTime);

      if (this.spinnerDialogRef) {
        this.spinnerDialogRef.close(true);
        this.spinnerDialogRef = undefined;
      }

      if (callOutput.result === 'success' && callOutput.response.status === 200) {
        const body = JSON.parse(callOutput.response.body);
        this.handleBlockResponse(body as BaeminAppBlockResponse);
      } else {
        this.dialogNoticeService.openSimpleNoticeDialog('영업임시중지 에러', callOutput.reason);
      }
    } catch (err) {
      if (this.spinnerDialogRef) {
        this.spinnerDialogRef.close(true);
        this.spinnerDialogRef = undefined;
      }
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
    }
  }

  async unblock() {
    this.logService.logRoom('임시영업중지 해제 클릭');
    this.spinnerDialogRef = this.dialogSpinnerService.openSpinnerDialog('요청 보내는 중');

    // TODO: 배민 계정을 2개 이상 연동했을 때의 처리
    const roomDoc = this.roomService.room;
    const instanceNo = roomDoc?.account.baemin[0];

    try {
      const callOutput = await this.baeminAppApi.post_unblock(roomDoc.organization, roomDoc.site, roomDoc.room, instanceNo);

      if (this.spinnerDialogRef) {
        this.spinnerDialogRef.close(true);
        this.spinnerDialogRef = undefined;
      }

      if (callOutput.result === 'success' && callOutput.response.status === 200) {
        const body = JSON.parse(callOutput.response.body);
        this.handleBlockResponse(body as BaeminAppBlockResponse);
        // 해제시에만 다이얼로그를 닫는다.
        this.onSubmit();
      } else {
        this.dialogNoticeService.openSimpleNoticeDialog('영업임시중지 해제 에러', callOutput.reason);
      }
    } catch (err) {
      if (this.spinnerDialogRef) {
        this.spinnerDialogRef.close(true);
        this.spinnerDialogRef = undefined;
      }
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
    }
  }
}
