/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { UnifiedOrder, UnifiedOrderDoc, UnifiedOrderContextStatusCode } from '../../schema/3/schema';
import { UnifiedOrderDocUI } from '../../schema/4/schema-ui';

import { debugLog } from '../1/common';
import { UtilService } from '../2/util.service';
import { LogService } from '../4/log.service';
import { environment } from '../../../environments/environment';

const unifiedOrderCollectionPath = 'unifiedOrder';

@Injectable({
  providedIn: 'root'
})
export class UnifiedOrderService {
  // 접수를 한 주문의 ID를 기록한다.
  // 접수 후에 일시적으로 신규로 다시 노출되는 경우를 막기 위한 용도이다.
  // NEW가 아닌 주문들
  public acceptedOrders = new Set<string>();
  public ceoacceptedOrders = new Set<string>();

  /**
   * 주문 확인창은 전체에서 단 하나만 열려있다.
   * 열린 창이 없다면 undefined
   */
  public openedOrder = undefined;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private logService: LogService
  ) { }

  /**
   * @param openHours 영업 시작 시간
   * @param openMinutes 영업 시작 분
   * @param [atDate=0] 0: 오늘(0시 기준이 아니라 영업시간기준), -1: 어제
   * @param [duration=1] 1: 하루, 2: 이틀, ...
   */
  // tslint:disable-next-line: max-line-length
  private observe(collectionPath: string, room: string, openHours: number, openMinutes: number, atDate = 0, duration = 1, orderBy: 'asc' | 'desc' = 'asc') {
    const { atDateStringFrom, atDateStringTo } = this.makeAtDateString(openHours, openMinutes, atDate, duration);

    debugLog(`${this.constructor.name}::observe ${collectionPath} from ${atDateStringFrom} to ${atDateStringTo}`);
    const queryFn: QueryFn = ref => {
      const query1 = ref
        .where('room', '==', room)
        .orderBy('orderDate', orderBy);
      const query = orderBy === 'asc' ? query1.startAt(atDateStringFrom).endAt(atDateStringTo) : query1.startAt(atDateStringTo).endAt(atDateStringFrom);
      return query;
    };

    const orderCollection = this.db.collection<UnifiedOrderDoc>(collectionPath, queryFn);

    // 디버깅용
    if (environment.production === false) {
      orderCollection.stateChanges().pipe(
        map(actions => actions.map(action => {
          return { _type: action.type, ...action.payload.doc.data() };
        }))
      ).subscribe(orders => {
        // for (const orderBy of orders) {
        //   debugLog(`[${scooter.vendor}] ${scooter.no} '${scooter._type}'`);
        // }
      }, error => {
        this.logService.logRoomWithToastrError(`UnifiedOrderService::observe ${collectionPath}에서 에러 발생 : ${error}`);
      });
    }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    const observable = orderCollection.valueChanges();

    return observable;
  }

  /**
   * 오픈 시각을 기준으로 날짜 범위를 계산한다.
   *
   * @param openHours 하루 시작의 기준 시간
   * @param openMinutes 하루 시작의 기준 분
   * @param atDate 0: 오늘, -1: 어제
   * @param duration 1: 24시간, 2: 48시간 ...
   */
  public makeAtDateString(openHours: number, openMinutes: number, atDate: number, duration: number) {
    // debugLog(`makeAtDateString(openHours: ${openHours}, openMinutes: ${openMinutes}, atDate: ${atDate}, duration: ${duration})`);

    const now = new Date();
    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');
    let openDate = new Date(format(now, `yyyy-MM-dd'T'${openHHMM}:00+09:00`));

    //
    // 1. 오늘 오픈 시각 계산하기 (오늘의 기준은 openHours)
    //
    // 현재 시각보다 오늘의 오픈 시각이 크다면 24시간 이전이 오픈시각이어야 한다.
    //
    // openHours가 6:00인 경우
    // now      < todayOpenDate
    // 29T05:00 < 29T06:00 => true  => 28T06:00
    // 29T12:00 < 29T06:00 => false => 29T06:00
    // 30T01:00 < 30T06:00 => true  => 29T06:00
    //
    // openHours가 0:00인 경우
    // now      < todayOpenDate
    // 29T05:00 < 29T00:00 => false => 29T00:00
    // 29T12:00 < 29T00:00 => false => 29T00:00
    // 30T00:00 < 30T00:00 => false => 30T00:00
    // 30T01:00 < 30T00:00 => false => 30T00:00
    if (now < openDate) {
      openDate = new Date(openDate.getTime() - 24 * 3600 * 1000);
    }

    //
    // 2. atDate 적용하기
    //
    openDate = new Date(openDate.getTime() + atDate * 24 * 3600 * 1000);

    //
    // 3. 최소 3시간 이전부터 시작하게 보정하기 (06시 조금 지났을 때 바로 이전 주문이 사라지지 않도록 하기 위함)
    //
    if ((now.getTime() - openDate.getTime()) < 3 * 3600 * 1000) {
      openDate = new Date(now.getTime() - 3 * 3600 * 1000);
    }

    const atDateStringFrom = format(openDate, `yyyy-MM-dd'T'HH:mm:ss+0900`);
    const atDateStringTo = format(openDate.getTime() + duration * 24 * 3600 * 1000, `yyyy-MM-dd'T'HH:mm:ss+0900`);

    return { atDateStringFrom, atDateStringTo };
  }

  public observeOrder(room: string, openHours: number, openMinutes: number, atDate = 0, duration = 1, orderBy: 'asc' | 'desc' = 'asc') {
    return this.observe(unifiedOrderCollectionPath, room, openHours, openMinutes, atDate, duration, orderBy);
  }

  public observeOrderById(orderId: string) {
    return this.db.doc<UnifiedOrderDoc>(`${unifiedOrderCollectionPath}/${orderId}`).valueChanges();
  }

  /**
   * unifiedOrder의 읿부 필드를 직접 변경한다.
   * 주로 manual 주문에 대해서 처리할 때 사용한다.
   */
  public async mergeOrder(order: Partial<UnifiedOrder>) {
    const id = order._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderDoc> = {
      ...order,
    };

    try {
      const orderQuerySnapshot = await docRef.get();

      // doc이 존재하지 않는 경우에도 mergeOrder()를 사용하기 위해서 에러가 발생하지 않도록 한다.
      if (orderQuerySnapshot.exists) {
        const orderDoc = orderQuerySnapshot.data() as UnifiedOrderDoc;
        const { contextStatusCode: currentContextStatusCode } = orderDoc;
        if (order.contextStatusCode < currentContextStatusCode) {
          // 낮은 상태로의 변경은 허용하지 않는다.
          this.utilService.toastrError(`주문 상태를 변경할 수 없습니다. '메뉴 -> 재시작' 후에 다시 시도해 보세요.`);
          this.logService.error(`${id}\n주문 상태 '${currentContextStatusCode}'에서 '${order.contextStatusCode}'로 변경하려는 시도가 있었습니다.`);
          return;
        }
      }

      await this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc, {
        merge: true
      });

      // 에러가 발생하지 않은 경우에만 추가한다.
      // CEOACCEPTED, ACCEPTED 2가지 경우를 분리해서 기억한다.
      if (order.contextStatusCode === UnifiedOrderContextStatusCode.CEOACCEPTED) {
        this.ceoacceptedOrders.add(id);
        debugLog(`ceoacceptedOrders에 ${id} 추가: status = ${order.contextStatusCode}`);
      } else if (order.contextStatusCode === UnifiedOrderContextStatusCode.ACCEPTED) {
        this.acceptedOrders.add(id);
        debugLog(`acceptedOrders에 ${id} 추가: status = ${order.contextStatusCode}`);
      }
    } catch (err) {
      this.logService.logOrder({ _id: order._id }, `mergeOrder(${id})에서 에러 발생: ${err.message}\n${err.stack}`, 'error');
    }
  }

  public setPosOrder(order: Partial<UnifiedOrder>, isUpdate = false) {
    const doc: Partial<UnifiedOrderDocUI> = order;

    if (isUpdate) {
      doc._id = order._id;
    } else {
      doc._timeCreate = firestore.FieldValue.serverTimestamp() as firestore.Timestamp;
    }

    // contextStautsCode가 없거나 orderStatusCode가 앞선다면 추가한다.
    if (order.contextStatusCode === undefined || order.orderStatusCode > order.contextStatusCode) {
      doc.contextStatusCode = order.orderStatusCode as unknown as UnifiedOrderContextStatusCode;
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(doc._id);

    delete doc._ui;

    return this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc);
  }
}
