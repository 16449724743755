/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { UnifiedOrderFood } from '../../../schema/1/schema-common';

@Component({
  selector: 'app-order-summary-menu-item',
  templateUrl: './order-summary-menu-item.component.html',
  styleUrls: ['./order-summary-menu-item.component.scss']
})
export class OrderSummaryMenuItemComponent implements OnInit {
  @Input() unifiedOrderFood: UnifiedOrderFood;
  @Input() menuIndex: number;
  @Output() removeFood = new EventEmitter<number>();
  @Output() increaseFoodQty = new EventEmitter<number>();
  @Output() decreaseFoodQty = new EventEmitter<number>();

  public gridRowEnd = '';
  constructor() { }

  ngOnInit(): void {
    // grid-auto-rows으로 동적으로 row 수가 정해지지만 css로 암시적 라인에 대한 정보를 얻을 수 없다.
    // 마지막 라인에 대한 정보를 얻어 직접 설정해준다.
    const len = this.unifiedOrderFood.foodOpts.length;
    this.gridRowEnd = `span ${len}`;
  }

  // 버튼은 이벤트만 발생시킨다.
  // 실제 증감 동작은 부모에서 변경한다.

  public removeMenu() {
    this.removeFood.emit(this.menuIndex);
  }

  public increaseMenuQty() {
    this.increaseFoodQty.emit(this.menuIndex);
  }

  public decreaseMenuQty() {
    this.decreaseFoodQty.emit(this.menuIndex);
  }

}
