/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';

import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

import { UnifiedOrderContextStatusCode, UnifiedOrderDoc, UnifiedOrderStatusCode } from '../../schema/3/schema';
import { CallInputAcceptWithDelivery, CallOutputAcceptWithDelivery } from '../../schema/4/schema-functions-call';
import { OrderProtocol, CancelDesc } from '../../schema/5/schema-protocol';

import { lineClampSwalContent } from '../1/common';
import { cancelCodesMap } from '../1/string-map';
import { LogService } from '../4/log.service';
import { UnifiedOrderService } from '../5/unified-order.service';

@Injectable({
  providedIn: 'root'
})
export class ManualOrderService implements OrderProtocol {

  constructor(
    private unifiedOrderService: UnifiedOrderService,
    private logService: LogService,
    private dialogNoticeService: DialogNoticeService,
    private fns: AngularFireFunctions
  ) { }

  /**
   * 외부 서비스와 동기화되지 않는 주문에 대한 접수
   * orderVendor === 'ghostkitchen' || createdBy === 'manual' | 'face' | 'fingerFace'
   */
  public async accept(order: UnifiedOrderDoc, withDelivery: boolean, create?: boolean): Promise<boolean> {
    const cookMinutes = order.cookMinutes;

    try {
      // 1. 신규 생성 주문이면 document부터 만든다.
      if (create) {
        await this.unifiedOrderService.mergeOrder({
          ...order,
          contextStatusCode: UnifiedOrderContextStatusCode.NEW,
          // cookMinutes는 설정하지 않아도 되지만 일부 의존적인 코드가 있을 수 있어서 유지한다.
          cookMinutes: cookMinutes ?? order.posRequestedPickupMinutes,
          posRequestedPickupMinutes: order.posRequestedPickupMinutes,
          posDeliveryMinutes: order.posDeliveryMinutes,
          recommendedRequestedPickupMinutes: order.recommendedRequestedPickupMinutes,
          recommendedDeliveryMinutes: order.recommendedDeliveryMinutes,
          withDelivery,
        });

        this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})을 생성했습니다.`);
      }

      // 2. 존재하는 주문에 대해 접수처리를 한다.
      if (order.deliveryType === 'TAKEOUT' || order.deliveryType === 'HERE') {
        if (!cookMinutes) {
          this.logService.logOrder(order, `cookMinutes이 없습니다. 고객센터에 알려주세요. (withDelivery: ${withDelivery})`, 'error', true);
          return false;
        }

        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          orderStatusCode: UnifiedOrderStatusCode.ACCEPTED,
          contextStatusCode: UnifiedOrderContextStatusCode.ACCEPTED,
          cookMinutes,
          deliveryMinutes: cookMinutes,
          withDelivery: false,
          time: {
            onCEOACCEPTED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          },
        });

        this.logService.logOrder(order, '주문 접수 성공', 'info', true);

      } else if (order.deliveryType === 'DELIVERY') {
        // 접수
        const callInputAcceptWithDelivery: CallInputAcceptWithDelivery = {
          orderId: order._id,
          withDelivery,
          posRequestedPickupMinutes: order.posRequestedPickupMinutes,
          posDeliveryMinutes: order.posDeliveryMinutes,
          recommendedRequestedPickupMinutes: order.recommendedRequestedPickupMinutes,
          recommendedDeliveryMinutes: order.recommendedDeliveryMinutes,
        };

        const callable = this.fns.httpsCallable<CallInputAcceptWithDelivery, CallOutputAcceptWithDelivery>('callAcceptWithDelivery');
        const response = await callable(callInputAcceptWithDelivery).toPromise();

        if (response.result === 'success') {
          this.logService.logOrder(order, '주문 접수 성공', 'info', true);
        } else {
          if (response.acceptSuccess) {
            this.logService.logOrder(order, `주문 접수에 성공했지만 배차에 실패했습니다. ${response.reason} / withDelivery: ${withDelivery}`, 'error');
            lineClampSwalContent('배차 실패', '주문 접수에 성공했지만 배차에 실패했습니다. 접수된 주문 상세화면에서 배차 추가를 다시 진행해 주세요.');
          } else {
            this.logService.logOrder(order, `주문 접수 시도했지만 실패했습니다. ${response.result} / withDelivery: ${withDelivery})`, 'error');
            lineClampSwalContent('주문을 접수하지 못 했습니다.', response.reason);
          }
        }
      } else {
        // 2020-11-30: DDINGDONG(셧다운), COUPANG(수동 입력하던 시절도 있었다.)...
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.CEOACCEPTED,
          // cookMinutes는 설정하지 않아도 되지만 일부 의존적인 코드가 있을 수 있어서 유지한다.
          cookMinutes: cookMinutes ?? 20,
          withDelivery,
          time: {
            onCEOACCEPTED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          },
        });
      }

      this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문 접수 완료했습니다.`);
    } catch (error) {
      this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문 접수를 시도했지만 실패했습니다. manualActionAccept(withDelivery: ${withDelivery}) ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }
    return true;
  }

  /**
   * 외부 서비스와 동기화되지 않는 주문에 대한 조리완료
   * 추가적인 연동이 없이 내부 상태만 변경한다.
   *
   * orderVendor === 'ghostkitchen' || createdBy === 'manual' | 'face' | 'fingerFace'
   */
  public async cook(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 시간을 기록한다.
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        contextStatusCode: UnifiedOrderContextStatusCode.COOKED,
        time: {
          onCOOKED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
        }
      });
      this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문상태를 '조리완료'으로 변경 완료했습니다.`);
    } catch (error) {
      this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문상태를 '조리완료'으로 변경 시도했지만 실패했습니다. ${error.message}, 'error'`);
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  /**
   * 외부 서비스와 동기화되지 않는 주문에 대한 픽업확인
   * 추가적인 연동이 없이 내부 상태만 변경한다.
   *
   * orderVendor === 'ghostkitchen' || createdBy === 'manual' | 'face' | 'fingerFace'
   */
  public async pickup(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 포장이나 매장식사의 경우에는 픽업이 곧 배송완료이다.
      if (order.deliveryType === 'HERE' || order.deliveryType === 'TAKEOUT') {
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          orderStatusCode: UnifiedOrderStatusCode.COMPLETED,
          contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED,
        });
        this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문상태를 '배송완료'로 변경 완료했습니다.`);
      } else {
        // 시간을 기록한다.
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.PICKEDUP,
          time: {
            onPICKEDUP: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          }
        });
        this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문상태를 '배송중'으로 변경 완료했습니다.`);
      }
    } catch (error) {
      this.logService.logOrder(order, `직접입력 주문의 주문상태를 '배송중'(포장이나 매장식사)이나 '배송완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }
    return true;
  }

  public async complete(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 배송완료로 상태를 바꾸고 시간 기록
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED,
        orderStatusCode: UnifiedOrderStatusCode.COMPLETED,
        time: {
          onCOMPLETED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
        }
      });

      this.logService.logOrder(order, `주문상태를 '배송완료'로 변경 완료했습니다.`);
      return true;
    } catch (error) {
      this.logService.logOrder(order, `주문상태를 '배송완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
    }

    return false;
  }

  public async modify(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      await this.unifiedOrderService.mergeOrder(order);
      this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문 상태를 변경 완료했습니다.`);
    } catch (error) {
      this.logService.logOrder(order, `직접입력 주문(${order.deliveryType})의 주문 상태를 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);

      return false;
    }

    return true;
  }

  public showDeliveryMinutes(order: UnifiedOrderDoc): boolean {
    return order.deliveryType === 'DELIVERY';
  }

  public canCancel(order: UnifiedOrderDoc): boolean {
    return order.contextStatusCode < UnifiedOrderContextStatusCode.COMPLETED;
  }

  public async cancel(order: UnifiedOrderDoc, cancelReasonCode): Promise<boolean> {
    const cancelDescs = this.cancelDescs(order).find($desc => $desc.cancelCode === cancelReasonCode);
    let cancelReason = '';
    if (cancelDescs) {
      cancelReason =  cancelDescs.cancelReason;
    } else {
      // 못찾으면 로그를 남기고 빈칸으로 둔다.
      this.logService.logOrder(order, `매칭하는 cancelReason을 찾지 못 했습니다. ${cancelReasonCode}`, 'error');
    }

    try {
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        orderStatusCode: UnifiedOrderStatusCode.CANCELED,
        contextStatusCode: UnifiedOrderContextStatusCode.CANCELED,
        cancelCode: cancelReasonCode,
        cancelReason,
        posCancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
      });
    } catch (error) {
      this.logService.logOrder(order, `주문 취소 실패 ${ error.message }`, 'error');
      lineClampSwalContent('주문 취소 실패', `주문을 취소하지 못 했습니다. ${ error.message}`);
      return false;
    }

    this.logService.logOrder(order, `'주문 ${order.simpleNo}' 취소 성공`, 'info', true);
    return true;
  }

  // manual 주문은 배민 주문을 따른다.
  public cancelDescs(order: UnifiedOrderDoc): CancelDesc[] {
    // 미리 준비된 경우가 아니면 에러가 발생하지 않도록 '기타' 한 가지만 보여준다.
    return cancelCodesMap[order.deliveryType]?.[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'] ?? [
      { cancelCode: '06', cancelReason: '기타' }
    ];
  }
}
