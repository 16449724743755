/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SETTINGS } from '@angular/fire/firestore';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from '../my-material/my-material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { MenuComponent } from './menu/menu.component';
import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';

import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';
import { DialogPrintTextComponent } from './dialog-print-text/dialog-print-text.component';
import { DialogCancelOrderComponent } from './dialog-cancel-order/dialog-cancel-order.component';
import { DialogTelComponent } from './dialog-tel/dialog-tel.component';
import { TimerComponent } from './timer/timer.component';
import { DialogBaeminBlockComponent } from './dialog-baemin-block/dialog-baemin-block.component';
import { SimpleNoticeComponent } from './simple-notice/simple-notice.component';
import { JustTouchComponent } from './just-touch/just-touch.component';
import { DialogSearchAddressComponent } from './dialog-search-address/dialog-search-address.component';
import { DialogNoticeDetailComponent } from './dialog-notice-detail/dialog-notice-detail.component';
import { DialogConfigComponent } from './dialog-config/dialog-config.component';
import { DialogOrderStatsComponent } from './dialog-order-stats/dialog-order-stats.component';
import { DialogOrderMenuComponent } from './dialog-order-menu/dialog-order-menu.component';
import { OrderSummaryMenuItemComponent } from './dialog-order-menu/order-summary-menu-item/order-summary-menu-item.component';
import { DialogOptionMenuComponent } from './dialog-order-menu/dialog-option-menu/dialog-option-menu.component';
import { DialogOrderFormComponent } from './dialog-order-menu/dialog-order-form/dialog-order-form.component';
import { DialogSelectShopComponent } from './dialog-select-shop/dialog-select-shop.component';
import { DialogOrderDetailComponent } from './dialog-order-detail/dialog-order-detail.component';
import { DialogEditDeliveryComponent } from './dialog-edit-delivery/dialog-edit-delivery.component';
import { FullScreenDialogHeaderComponent } from './full-screen-dialog-header/full-screen-dialog-header.component';
import { SelectMinuteComponent } from './select-minute/select-minute.component';
import { DialogRequestDeliveryComponent } from './dialog-request-delivery/dialog-request-delivery.component';
import { CustomerComponent } from './customer/customer.component';
import { DialogCreateOrderComponent } from './dialog-create-order/dialog-create-order.component';
import { SwalRoomSelectComponent } from './swal-room-select/swal-room-select.component';
import { DialogIframeComponent } from './dialog-iframe/dialog-iframe.component';
import { DialogOrderHistoryComponent } from './dialog-order-history/dialog-order-history.component';
import { DialogPrinterComponent } from './dialog-printer/dialog-printer.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MyMaterialModule,
    SweetAlert2Module.forRoot(),
  ],
  declarations: [
    DialogNoticeComponent,
    SimpleNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DialogPrintTextComponent,
    DialogCancelOrderComponent,
    MenuComponent,
    TimerComponent,
    DialogBaeminBlockComponent,
    SimpleNoticeComponent,
    JustTouchComponent,
    DialogTelComponent,
    DialogNoticeDetailComponent,
    DialogConfigComponent,
    DialogOrderStatsComponent,
    DialogOrderMenuComponent,
    OrderSummaryMenuItemComponent,
    DialogOptionMenuComponent,
    DialogOrderFormComponent,
    DialogSelectShopComponent,
    DialogSearchAddressComponent,
    DialogNoticeDetailComponent,
    DialogOrderDetailComponent,
    FullScreenDialogHeaderComponent,
    SelectMinuteComponent,
    DialogEditDeliveryComponent,
    DialogRequestDeliveryComponent,
    CustomerComponent,
    DialogCreateOrderComponent,
    SwalRoomSelectComponent,
    DialogIframeComponent,
    DialogOrderHistoryComponent,
    DialogPrinterComponent,
  ],
  exports: [
    MenuComponent,
    TimerComponent,
    SimpleNoticeComponent,
    JustTouchComponent,
    DialogNoticeDetailComponent,
    SwalRoomSelectComponent
  ],
  entryComponents: [
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
    DialogPrintTextComponent,
    DialogCancelOrderComponent,
    DialogBaeminBlockComponent,
    DialogTelComponent,
    DialogSearchAddressComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // SETTINGS DI Token은 angularfire 소스 코드에서 찾았다.
    // https://firebase.google.com/docs/reference/js/firebase.firestore.Settings#optional-ignoreundefinedproperties
    { provide: SETTINGS, useValue: { ignoreUndefinedProperties: true } }
  ]
  // MAT_DIALOG 관련 Injection 샘플
  // 아래처럼 하면 동작한다.
  // refer: https://material.angular.io/components/dialog/api#MAT_DIALOG_DATA
  // providers: [
  //   {
  //     provide: MAT_DIALOG_DEFAULT_OPTIONS,
  //     useValue: {
  //       hasBackdrop: false  // default true
  //     }
  //   },
  // ]
})
export class SharedModule { }
