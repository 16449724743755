/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, DialogTelComponent } from './dialog-tel.component';

@Injectable({
  providedIn: 'root'
})
export class DialogTelService {

  constructor(
    private dialog: MatDialog
  ) { }

  /** 전화번호 입력을 위한 다이얼로그 */
  openNumberPadDialog(telNo: string, subTitle?: string) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        telNo,
        subTitle
      },
      panelClass: 'number-pad-dialog',
      maxWidth: '440px',
      disableClose: true
    };

    return this.dialog.open(DialogTelComponent, dialogConfig);
  }
}
