/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';
import cloneDeep from 'lodash-es/cloneDeep';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AugmentedAddress, DeliveryVendor, UnifiedOrderChannel, UnifiedOrderDeliveryType, UnifiedOrderFood } from '../../schema/1/schema-common';
import { UnifiedDeliveryDoc, UnifiedDeliveryStatusCode, UnifiedMenuDoc, UnifiedOrderContextStatusCode, UnifiedOrderDoc, UnifiedOrderStatusCode } from '../../schema/3/schema';
import { CallInputDeliveryTip, CallOutputDeliveryTip, CallInputRequestDeliveryInformation, CallOutputRequestDeliveryInformation } from '../../schema/4/schema-functions-call';

import { canOpenOrder, debugLog, lineClampSwalContent } from '../../core/1/common';
import { deliveryVendorMappings, unifiedOrderChannelMappings, unifiedOrderDeliveryTypeMappings } from '../../core/1/string-map';
import { normalizeCurrency } from '../../core/1/util';
import { RoomService } from '../../core/1/room.service';
import { UtilService } from '../../core/2/util.service';
import { UserService } from '../../core/3/user.service';
import { UnifiedMenuService } from '../../core/3/unified-menu.service';
import { LogService } from '../../core/4/log.service';
import { DeliveryService } from '../../core/5/delivery.service';
import { PrintService } from '../../core/8/print.service';
import { OrderService } from '../../core/9/order.service';

import { DeliveryMinutes, RequestedPickupMinutes } from '../select-minute/select-minute.component';
import { DialogOrderMenuService } from '../dialog-order-menu/dialog-order-menu.service';
import { DialogSelectShopService } from '../dialog-select-shop/dialog-select-shop.service';

@Component({
  selector: 'app-dialog-create-order',
  templateUrl: './dialog-create-order.component.html',
  styleUrls: ['./dialog-create-order.component.scss']
})
export class DialogCreateOrderComponent implements OnInit, OnDestroy {
  public order: UnifiedOrderDoc;
  public isLoading = true;

  private destroySignal = new Subject<boolean>();

  public unifiedOrderChannelMappings = unifiedOrderChannelMappings;
  public unifiedOrderDeliveryTypeMappings = unifiedOrderDeliveryTypeMappings;

  /**
   * roomDoc에 deliveryVendors가 있냐?
   */
  public hasDeliveryVendors = false;
  public deliveryVendors: DeliveryVendor[] = [];
  public deliveryVendorMappings = deliveryVendorMappings;
  public deliveryVendor: DeliveryVendor | 'none';
  public deliveryType: UnifiedOrderDeliveryType = 'DELIVERY';
  public deliveryInfo: CallOutputRequestDeliveryInformation['deliveryInfo'] = {
    extraAvailable: false,
    isPossible: true,
    pickups: [],
    distance: 0,
    baseFee: 0,
    totalFee: 0
  };

  /** 다른 컴포넌트에서 변경된 주소를 담고 있는다. */
  public address: {
    address_key: string;
    address_road: string;
    address_detail: string;
    address_location: { lat: number, lon: number };
    address_sido: string;
    address_sigungu: string;
    address_dong: string;
    address_dongH: string;
    address_jibun: string;
    vroong: UnifiedOrderDoc['vroong'];
  };

  public userTel = '';
  public orderMsg = '';

  // app-select-minute에서 받는 값
  // 조리시간
  public cookMinutes = '20';
  // 요청 픽업 시간
  public recommendedRequestedPickupMinutes = 0; // 업주가 변경하기 이전의 값으로 추후 차이를 확인할 때 사용한다.
  public requestedPickupMinutes = 20;

  // 예상 배달 시간
  private recommendedDeliveryMinutes = 0; // 업주가 변경하기 이전의 값으로 추후 차이를 확인할 때 사용한다.
  public deliveryMinutes = 40;

  public orderChannel: UnifiedOrderChannel = 'tel';
  public paymentMethod: '후불현금' | '후불카드' | '선불' | 'NA' = '선불';
  public formControlDeliveryTip = new FormControl();

  public buttonPressed = {
    ACCEPT: false
  };

  private menuDocs: UnifiedMenuDoc[] = [];

  // 반복적인 요청을 거를때 사용한다.
  private lastCallInputDeliveryTip: CallInputDeliveryTip;
  public deliveryTip: CallOutputDeliveryTip['deliveryTip'];
  public baeminDeliveryTip = 0;
  public baeminDeliveryTipText = '';

  constructor(
    private dialogRef: MatDialogRef<DialogCreateOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { order?: UnifiedOrderDoc, deliveries?: UnifiedDeliveryDoc[] },
    private fns: AngularFireFunctions,
    private db: AngularFirestore,
    private logService: LogService,
    private roomService: RoomService,
    private orderService: OrderService,
    private deliveryService: DeliveryService,
    private dialogOrderMenuService: DialogOrderMenuService,
    private dialogSelectShopService: DialogSelectShopService,
    private unifiedMenuService: UnifiedMenuService,
    private userService: UserService,
    private utilService: UtilService,
    private printService: PrintService,
  ) {
    // 신규 주문이 열리지 않게 block
    canOpenOrder.canOpen = false;
    this.menuDocs = this.unifiedMenuService.menuDocs.filter(menuDoc => menuDoc.baeminShopInfo?.Ad_Yn !== 'N');
    // 주문을 초기화 한다.
    this.initOrderModel(this.data.order);
    this.initView();
  }

  ngOnInit(): void {
    this.deliveryVendors = this.roomService.room.deliveryVendors;
    this.deliveryVendor = this.deliveryVendors?.length > 0 ? this.deliveryVendors[0] : 'none';
    this.hasDeliveryVendors = this.deliveryVendors?.length > 0;
    // await 하지 않는다.
    this.updateShopDetail();
    this.observeDeliveryTip();
  }

  ngOnDestroy() {
    this.destroySignal?.next(true);
    this.destroySignal?.unsubscribe();
    this.destroySignal = undefined;

    canOpenOrder.canOpen = true;
  }

  public async onClose() {
    const close = await Swal.fire({
      html: '현재 내용은 저장되지 않습니다.</br>주문 입력 화면을 닫으시겠습니까?',
      icon: 'warning',
      confirmButtonText: '닫기',
      confirmButtonColor: 'hsl(212, 100%, 50%)',
      cancelButtonText: '되돌아가기',
      showCancelButton: true,
      // 버튼의 기본 순서는 confirm, cancel 버튼 순이다. 이 순서를 뒤집는다.
      reverseButtons: true
    });

    if (close.isConfirmed) {
      this.dialogRef?.close();
    }
  }

  private initOrderModel(existingOrder?: UnifiedOrderDoc) {
    if (existingOrder) {
      // cloneDeep를 레퍼런스 참조에 의한 side effect를 차단한다.
      const orderMerge = cloneDeep(existingOrder);
      delete orderMerge._timeCreate;
      this.order = orderMerge;
    } else {
      const docId = this.db.firestore.collection('unifiedOrder').doc().id;

      // Data model
      this.order = {
        _id: `manual-${docId}`,
        organization: this.roomService.room.organization,
        site: this.roomService.room.site,
        room: this.roomService.room.room,
        shopName: this.roomService.room.shopName,
        orderChannel: 'tel',
        orderVendor: 'ghostkitchen',
        deliveryType: 'DELIVERY',
        instanceNo: '',
        orderNo: docId,
        shopNo: '', // 기록하지 않는다.
        orderDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`), // 나중에 최종 적으로 업데이트한다.
        orderStatusCode: UnifiedOrderStatusCode.NEW,   // 신규 접수로 만든다. document 생성후 이어서 바로 accept하도록 한다.
        contextStatusCode: UnifiedOrderContextStatusCode.NEW,   // 신규 접수로 만든다. document 생성후 이어서 바로 accept하도록 한다.
        orderAmount: 0,
        deliveryTip: 0,
        deliveryMinutes: 0,
        paymentMethod: '후불카드',
        userTel: '',
        orderMsg: '',

        // foodForm 초기화에 사용한다.
        foods: [],

        // addressForm 초기화에 사용한다.
        address_key: '',
        address_detail: '',
        address_sido: '',
        address_sigungu: '',
        address_dong: '',
        address_jibun: '',
        address_dongH: '',
        address_road: '',
        address_building_name: '',
        address_location: {
          lat: 0,
          lon: 0
        },

        vroong: {
          dest_sigungu: '',
          dest_legal_eupmyeondong: '',
          dest_admin_eupmyeondong: '',
          dest_ri: '',
          dest_beonji: '',
          dest_road: '',
          dest_building_number: ''
        },

        createdBy: 'manual',
        eventDiscount: 0,
      };
    }
  }

  /**
   * 최초에 한 번만 실행한다.
   * this.order가 초기화된 후에 호출해야 한다.
   */
  private initView() {
    this.address = {
      address_key: this.order.address_key,
      address_road: this.order.address_road,
      address_detail: this.order.address_detail,
      address_location: this.order.address_location,
      address_sido: this.order.address_sido,
      address_sigungu: this.order.address_sigungu,
      address_dong: this.order.address_dong,
      address_dongH: this.order.address_dongH,
      address_jibun: this.order.address_jibun,
      vroong: this.order.vroong
    };

    this.deliveryType = this.order.deliveryType;

    this.userTel = this.order.userTel;
    this.orderMsg = this.order.orderMsg;

    this.orderChannel = this.order.orderChannel;
    this.paymentMethod = this.order.paymentMethod;
    this.formControlDeliveryTip.setValue(this.order.deliveryTip ? Intl.NumberFormat().format(this.order.deliveryTip) : '0');
  }

  public onChangeCookMinutes(minutes: string) {
    this.cookMinutes = minutes;
  }

  public onChangeRequestedPickupMinutes(minutes: RequestedPickupMinutes) {
    this.recommendedRequestedPickupMinutes = minutes.recommendedRequestedPickupMinutes;
    this.requestedPickupMinutes = minutes.requestedPickupMinutes;
  }

  public onChangeDeliveryMinutes(minutes: DeliveryMinutes) {
    this.recommendedDeliveryMinutes = minutes.recommendedDeliveryMinutes;
    this.deliveryMinutes = minutes.deliveryMinutes;
  }

  /**
   * 추가, 변경 모두 여기에서 unifiedOrder를 구성한다.
   */
  private createOrderData(): UnifiedOrderDoc | null {
    if (this.deliveryType === 'DELIVERY') {
      return {
        ...this.order,
        orderChannel: this.orderChannel,
        deliveryType: this.deliveryType,
        orderDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`), // 나중에 최종 적으로 업데이트한다.
        deliveryTip: this.formControlDeliveryTip.value ? parseInt(this.formControlDeliveryTip.value.replace(/,/g, ''), 10) : 0,
        paymentMethod: this.paymentMethod,
        userTel: this.userTel.replace(/-/g, ''),
        orderMsg: this.orderMsg,

        address_key: this.address.address_key,
        address_detail: this.address.address_detail,
        address_sido: this.address.address_sido,
        address_sigungu: this.address.address_sigungu,
        address_dong: this.address.address_dong,
        address_jibun: this.address.address_jibun,
        address_dongH: this.address.address_dongH,
        address_road: this.address.address_road,
        // address_building_name: this.address.address_building_name,
        address_location: this.address.address_location,

        vroong: this.address.vroong,

        createdBy: 'manual',
        eventDiscount: 0,

        recommendedRequestedPickupMinutes: this.recommendedRequestedPickupMinutes,
        recommendedDeliveryMinutes: this.recommendedDeliveryMinutes,
        posRequestedPickupMinutes: this.requestedPickupMinutes,
        posDeliveryMinutes: this.deliveryMinutes
      };
    } else if (this.deliveryType === 'TAKEOUT') {
      return {
        ...this.order,
        orderChannel: this.orderChannel,
        deliveryType: this.deliveryType,
        orderDate: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`), // 나중에 최종 적으로 업데이트한다.
        deliveryTip: 0,
        paymentMethod: this.paymentMethod,
        userTel: this.userTel.replace(/-/g, ''),
        orderMsg: this.orderMsg,

        createdBy: 'manual',
        eventDiscount: 0,

        // 포장인 경우에는 cookMinutes 값으로 바로 주문접수한다.
        cookMinutes: parseInt(this.cookMinutes, 10)
      };
    }

    this.logService.logRoom(`주문 입력시 알 수 없는 배달 유형: ${this.deliveryType}`, 'error');
    return null;
  }

  private checkAcceptValidation(): boolean {
    if (this.order.foods.length === 0) {
      Swal.fire('알림', '메뉴를 선택해 주세요.', 'warning');
      return false;
    }

    if (this.userTel === '') {
      Swal.fire('알림', '고객 전화번호를 입력해 주세요.', 'warning');
      return false;
    }

    if (this.deliveryType === 'DELIVERY') {
      if (this.address.address_key === '') {
        Swal.fire('알림', '주소를 입력해 주세요.', 'warning');
        return false;
      }
    }

    return true;
  }

  async onOrderAction(action: 'accept' | 'modify', withDelivery = false) {
    if (!this.checkAcceptValidation()) {
      return;
    }

    // 버튼 처리
    if (this.buttonPressed.ACCEPT === true) {
      // debugLog('이미 눌렸으니 통과');
      return;
    }

    this.buttonPressed.ACCEPT = true;
    const order: UnifiedOrderDoc = this.createOrderData();

    this.logService.logOrder(order, `create-order 주문 ${action === 'accept' ? '접수' : '변경'} 버튼 (withDelivery: ${withDelivery}) 클릭`);

    // 2. 접수
    if (action === 'accept') {
      await this.orderService.accept(order, withDelivery, true);
    } else {
      await this.orderService.modify(order);
    }

    // 3. 프린트
    if (['ceo', 'ceostat'].includes(this.userService.user.role)) {
      this.printService.defaultPrint(order);
    } else {
      this.utilService.toastrInfo(`user.role이 ${this.userService.user.role}인 경우에는 주문서 인쇄를 하지 않습니다.`);
    }

    this.dialogRef?.close();
  }

  /**
   * 금액에 변화가 있으면 포맷을 자동 적용한다.
   * this.order를 갱신한다.
   */
  private observeDeliveryTip() {
    this.formControlDeliveryTip.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        const normalizedCurrency = normalizeCurrency(String(value));
        if (value !== normalizedCurrency) {
          this.formControlDeliveryTip.setValue(normalizedCurrency);
        }

        this.order.deliveryTip = parseInt(value.replace(/,/g, ''), 10);
        // 이미 deliveryTip을 변경했지만 this.order의 변화를 알려주기 위해 아래 함수를 호출한다.
        this.updateDeliveryTipAndOrderAmount();
      });
  }

  public onDeliveryVendorChanged(event) {
    this.deliveryVendor = event.value;

    if (this.address.address_key) {
      this.updateRequestDeliveryInformation();
    }
  }

  /**
   * 다른 컴포넌트에서 주소가 변경이 되면 호출된다.
   */
  public onChangeAddress(event: {
    augmentedAddress: AugmentedAddress
    addressDetail: any
  }) {
    this.address = {
      address_key: event.augmentedAddress.key,
      address_road: event.augmentedAddress.road,
      address_detail: event.addressDetail,
      address_location: event.augmentedAddress.location,
      address_sido: event.augmentedAddress.sido,
      address_sigungu: event.augmentedAddress.sigungu,
      address_dong: event.augmentedAddress.dong,
      address_dongH: event.augmentedAddress.dongH,
      address_jibun: event.augmentedAddress.jibun,
      vroong: event.augmentedAddress.vroong,
    };

    // 주소가 변경되었으니 배달팁을 다시 읽어보자.
    // await을 붙이지 않는다.
    this.updateShopDetail(this.deliveryTip?.shopNo);

    this.updateRequestDeliveryInformation();
  }

  public onChangeUserTel(event) {
    this.userTel = event;
  }

  public onChangeOrderMsg(event) {
    this.orderMsg = event;
  }

  public onChangeOrderChannel(event) {
    this.orderChannel = event.value;
    this.order.orderChannel = event.value;
  }

  public onChangePaymentMethod(event) {
    this.paymentMethod = event.value;
    this.order.paymentMethod = event.value;
  }

  public onChangeDeliveryType(event) {
    // 배달 -> 포장으로 변경 시도: 취소되지 않은 배차가 있다면 변경을 할 수 없다.
    if (this.deliveryType === 'DELIVERY' && event.value === 'TAKEOUT' && this.data?.deliveries) {
      const validDeliveries = this.data.deliveries.filter(delivery => delivery.deliveryStatusCode !== UnifiedDeliveryStatusCode.CANCELED);
      if (validDeliveries.length > 0) {
        Swal.fire('배달 주문을 포장 주문으로 변경하려면 배차를 먼저 취소해 주세요.');
        // 원래 값 유지
        event.source.buttonToggleGroup.value = 'DELIVERY';

        return;
      }
    }

    this.deliveryType = event.value;
    this.order.deliveryType = event.value;

    this.recalculateAndUpdateDeliveryTip();
  }

  private updateRequestDeliveryInformation() {
    const deliveryVendor = this.deliveryVendor;

    if (deliveryVendor === 'none') {
      return;
    }

    const instanceNo = deliveryVendor === 'ghokirun' ? 'N/A' : this.roomService.room.account[deliveryVendor]?.[0];

    if (instanceNo) {
      this.isLoading = true;
      this.requestDeliveryInformation(deliveryVendor).then(v => {
        debugLog(`응답 =\n${JSON.stringify(v)}`);

        const { result, reason, deliveryInfo } = v;

        if (result !== 'success') {
          this.logService.logOrder(this.data.order, `[create-order] 요청 전 정보 조회 에러: ${reason}`, result === 'error' ? 'error' : 'warn');
          // 배달 불가능 혹은 에러
          lineClampSwalContent('대행사 요금 정보 조회 실패', reason);
          return;
        }

        if (deliveryInfo.isPossible === false) {
          this.logService.logOrder(this.data.order, `isPossible이 false인 경우가 등장했다. 어떤 경우인가 살펴보라.\n${JSON.stringify(deliveryInfo)}`, 'error');
        }

        this.isLoading = false;
        this.deliveryInfo = deliveryInfo;
        this.deliveryService.recentRequestedPickupMinutesOptions = deliveryInfo.pickups;
      }, error => {
        // 오류가 나도 UI 정보는 그냥 둔다.
        this.logService.logOrder(this.data.order, `요청 전 정보 조회 예외: ${error}`, 'warn');
      });
    } else {
      this.logService.logOrder(this.data.order, `호실 설정에 account.${deliveryVendor}가 없어요.`, 'error');
      Swal.fire('대행사 요금 정보 조회 실패', `${deliveryVendor}에 대한 계정 설정이 없습니다. 관리자에게 알려주세요.`, 'error');
    }
  }

  private requestDeliveryInformation(deliveryVendor: DeliveryVendor) {
    const callInputRequestDeliveryInformation: CallInputRequestDeliveryInformation = {
      organization: this.roomService.room.organization,
      site: this.roomService.room.site,
      room: this.roomService.room.room,
      deliveryVendor,
      // 호출하기 전에 확인했다.
      instanceNo: deliveryVendor === 'ghokirun' ? 'N/A' : this.roomService.room.account[deliveryVendor][0],
      address_key: this.address.address_key,
      address_detail: this.address.address_detail,
      address_location: this.address.address_location,
      address_jibun: this.address.address_jibun,
      address_sido: this.address.address_sido,
      address_sigungu: this.address.address_sigungu,
      address_dongH: this.address.address_dongH,
      address_dong: this.address.address_dong,
      initialPaymentAmount: this.order.orderAmount + this.order.deliveryTip - (this.order.eventDiscount ?? 0) - (this.order.discount ?? 0)
    };

    if (this.address.address_road) {
      callInputRequestDeliveryInformation.address_road = this.address.address_road;
    }

    return this.deliveryService.requestDeliveryInformation(callInputRequestDeliveryInformation);
  }

  public async openDialogOrderMenu() {
    const menuDocs = this.unifiedMenuService.menuDocs.filter(menuDoc => menuDoc.baeminShopInfo?.Ad_Yn !== 'N');

    let unifiedMenu: Omit<UnifiedMenuDoc, '_timeUpdate' | 'instanceNo'> | 'close';
    if (menuDocs.length === 0) {
      // 직접 입력 메뉴를 이용할 수 있게 하기 위해서 unifiedMenu를 임의로 만들어 준다.
      unifiedMenu = {
        _id: '',
        shopNo: '',
        organization: this.roomService.room.organization,
        site: this.roomService.room.site,
        room: this.roomService.room.room,
        orderVendor: 'ghostkitchen',
        menus: []
      };
    } else if (menuDocs.length === 1) {
      // 업소가 하나이면 다음 단계로 바로 넘어간다.
      unifiedMenu = menuDocs[0];
    } else {
      unifiedMenu = await this.dialogSelectShopService.openDialogSelectShop().afterClosed().toPromise();
    }

    if (unifiedMenu === 'close') {
      return;
    }

    const unifiedOrderFood: { foods: UnifiedOrderFood[], shopNo: string } =
      await this.dialogOrderMenuService.openDialogOrderMenu(unifiedMenu, this.order).afterClosed().toPromise();
    if (unifiedOrderFood) {
      this.order.foods = unifiedOrderFood.foods;
      const shopNo = unifiedOrderFood.shopNo;

      this.updateDeliveryTipAndOrderAmount();
      if (shopNo && this.deliveryType === 'DELIVERY') {
        this.updateShopDetail(shopNo);
      }
    }
  }

  /**
   * order의 orderAmount와 deliveryTip을 업데이트한다.
   */
  private updateDeliveryTipAndOrderAmount() {
    const orderAmount = this.order.foods ? this.order.foods.reduce((sum, food) => sum + food.foodOrdPrice, 0) : 0;
    const deliveryTip = this.order.deliveryTip ? this.order.deliveryTip : 0;
    // MenuComponent에서 order를 추적하고 있기 때문에 객체 안의 필드 값이 변경되었음을 알려주기 위해서 order를 다시 대입한다.
    this.order = {
      ...this.order,
      deliveryTip,
      orderAmount
    };
  }

  /**
   * 배달팁이 최신 정보로 변경이 되거나 배달 유형이 바뀌었을 때 관련 뷰를 변경한다.
   *
   * - formControlDeliveryTip
   * - baeminDeliveryTip
   * - baeminDeliveryTipText
   */
  private recalculateAndUpdateDeliveryTip() {
    //
    // 1. 배달 유형에 따른 control 상태 및 값 설정
    if (this.deliveryType === 'DELIVERY') {
      this.formControlDeliveryTip.enable();
    } else {
      this.formControlDeliveryTip.setValue('0');
      this.formControlDeliveryTip.disable();
      return;
    }

    if (this.deliveryTip === undefined) {
      this.baeminDeliveryTipText = '';
      return;
    }

    const foods = this.order.foods;
    const orderAmount = foods ? foods.reduce((sum, food) => sum + food.foodOrdPrice, 0) : 0;

    // 0: Object { orderPriceRangeBegin: 16000, deliveryTip: 1500 }
    // 1: Object { orderPriceRangeBegin: 9900, deliveryTip: 3000 }
    // 큰 액수부터 비교한다.
    const foundTip = this.deliveryTip.finalDeliveryTips.find(tip => orderAmount >= tip.orderPriceRangeBegin);

    if (this.deliveryTip.finalDeliveryTips?.length > 0) {
      // 찾지 못 했다는 것은 가장 낮은 가격에다 못 미쳤다는 뜻이므로 가장 높은 배달팁을 일단 적용한다.
      this.baeminDeliveryTip = foundTip ? foundTip.deliveryTip : this.deliveryTip.finalDeliveryTips[this.deliveryTip.finalDeliveryTips.length - 1].deliveryTip;
      // 0원이 되는 경우에도 표시한다.
      this.baeminDeliveryTipText = `(배민 기준: ${Intl.NumberFormat().format(this.baeminDeliveryTip)}원)`;
    }
  }

  /**
   * 적용 버튼을 눌렀을 때에만 배달팁을 적용한다.
   */
  public applyBaeminDeliveryTip() {
    this.formControlDeliveryTip.setValue(this.baeminDeliveryTip + '');
  }

  /**
   * 배달팁 상세 내용을 보여주는 대화 상자를 보여준다.
   * 현재 갖고 있는 deliveryTip을 보여줄 뿐 새로 받아오지는 않는다.
   */
  public async openDialogDeliveryTip() {
    if (this.deliveryType !== 'DELIVERY') {
      Swal.fire('알림', '배달 주문만 배달팁 정보를 조회 할 수 있습니다!', 'warning');
      return;
    }

    if (this.menuDocs.length === 0) {
      // 연동된 배민 샵이 없다.
      Swal.fire('알림', '연동된 배민 업소가 없습니다.', 'warning');
      return;
    }

    if (this.deliveryTip?.finalDeliveryTips === undefined || this.deliveryTip?.finalDeliveryTips.length === 0) {
      Swal.fire('알림', '연동된 배민 업소가 없거나 배달팁 정보를 가져오는 중입니다. 잠시 후 다시 시도해 보세요.', 'warning');
      return;
    }

    //
    // 1. 할증 요금이 포함된 주문 금액별 배달팁 테이블 구성
    const orderPriceRangeDeliveryTips = this.deliveryTip.finalDeliveryTips.map(tip =>
      `<tr>
        <td>${Intl.NumberFormat().format(tip.orderPriceRangeBegin)}원 ~</td>
        <td>${Intl.NumberFormat().format(tip.deliveryTip)}원</td>
       </tr>
      `).join('');

    const orderPriceRangeDeliveryTipsTable = `
    <div class="caption">주문 금액별 배달팁 (할증이 포함된 금액입니다)</div>
    <table class="swal-table">
    <tr>
      <td class="header">주문 금액</td>
      <td class="header">배달팁</td>
    </tr>
      ${orderPriceRangeDeliveryTips}
    </table>
    `;

    const deliveryTipChargePhrase = this.deliveryTip.chargePhrase ? `<div class="phrase">${this.deliveryTip.chargePhrase}</div>` : '';

    //
    // 2. 할증 정보 테이블을 구성한다. '지역', '요일/시간' 할증이 있다.
    const deliveryTipCharges = this.deliveryTip.extraCharges?.map(charge => {
      const length = charge.charges.length;
      const td = `<td class="header" style="width: 76px;" rowspan="${length}">${charge.groupName}</td>`;
      return charge.charges.map((phrase, index) => `
      <tr>
        ${index === 0 ? td : ''}
        <td style="text-align: right; width: 76px;">+${Intl.NumberFormat().format(phrase.amount)}원</td>
        <td style="text-align: left;">${phrase.subject}</td>
      </tr>
     `).join('');
    }).join('');

    const deliveryTipChargesTable = deliveryTipCharges ? `
      <div class="caption">할증 정보</div>
      <table class="swal-table">
        ${deliveryTipCharges}
      </table>
    ` : '';

    const content = `<div> ${orderPriceRangeDeliveryTipsTable} ${deliveryTipChargePhrase} ${deliveryTipChargesTable}</div>`;
    const shopName = this.deliveryTip.shopName;
    const shopNo = this.deliveryTip.shopNo;

    Swal.fire({
      title: `${shopName}(${shopNo}) 기준 배달요금`,
      html: content,
      // 스타일의 스코프를 지정하기 위한 target
      target: 'app-dialog-create-order',
      customClass: {
        popup: 'swal-wide'
      },
      confirmButtonText: '확인',
      confirmButtonColor: 'hsl(212, 100%, 50%)',
    });
  }

  /**
   * callDeliveryTip을 호출해서 배달팁 정보를 다시 받아온다.
   *
   * 다음의 경우에 호출한다.
   * - 최초에 업소 번호 없이
   * - 주소가 변경될 경우에
   * - 메뉴 선택을 하고 나서
   */
  private async updateShopDetail(shopNo?: string) {
    debugLog(`> updateShopDetail(${shopNo})`);

    const callInput: CallInputDeliveryTip = {
      orderVendor: 'baemin',
      room: this.roomService.room.room,
    };

    // shopNo가 없으면 functions가 호실에 딸린 최근 shopNo에 대한 배달팁을 응답한다.
    if (shopNo) {
      callInput.shopNo = shopNo;
    }

    // 위경도가 없으면 functions에서 지점 위치를 기준으로 응답한다.
    if (this.address?.address_location?.lat) {
      callInput.address_location = this.address.address_location;
    }

    // 이전 callInput과 비교해서 변경이 있을 경우에만 호출한다.
    if (this.lastCallInputDeliveryTip &&
      this.lastCallInputDeliveryTip.address_location?.lat === callInput.address_location?.lat &&
      this.lastCallInputDeliveryTip.address_location?.lon === callInput.address_location?.lon &&
      this.lastCallInputDeliveryTip.shopNo === callInput.shopNo) {
      debugLog('변화가 없어서 callInputDeliveryTip을 호출하지 않아요.');

      // 변화는 없어도 계산은 다시 해야 할 수도 있을까봐 추가한다.
      this.recalculateAndUpdateDeliveryTip();
      return;
    }

    debugLog(`callInput = ${JSON.stringify(callInput, undefined, 2)}`);

    const callDeliveryTip = this.fns.httpsCallable<CallInputDeliveryTip, CallOutputDeliveryTip>('callDeliveryTip');
    const response = await callDeliveryTip(callInput).toPromise();
    if (response.result === 'success') {
      debugLog(`NEW deliveryTip =\n${JSON.stringify(this.deliveryTip)}`);
      // '배민1'은 빈 배열이다.
      if (response.deliveryTip?.finalDeliveryTips?.length > 0) {
        this.deliveryTip = response.deliveryTip;
        this.lastCallInputDeliveryTip = callInput;
        this.recalculateAndUpdateDeliveryTip();
      }
    } else {
      console.error(response);
      // 에러 났어도 기존값을 유지하는 것이 좋지 않을까?
      // this.deliveryTip = {
      //   finalDeliveryTips: []
      // };
    }
  }
}
