/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { Injectable } from '@angular/core';

import { UnifiedOrderContextStatusCode, UnifiedOrderDoc } from '../../schema/3/schema';
import { OrderProtocol } from '../../schema/5/schema-protocol';

import { unifiedOrderChannelMappings, unifiedOrderDeliveryTypeMappings, unifiedOrderVendorMappings } from '../1/string-map';
import { LogService } from '../4/log.service';
import { UnifiedOrderService } from '../5/unified-order.service';
import { ManualOrderService } from '../6/manual-order.service';
import { BaeminOrderService } from '../8/baemin-order.service';
import { CoupangeatsOrderService } from '../8/coupangeats-order.service';
import { YogiyoOrderService } from '../8/yogiyo-order.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService implements OrderProtocol {
  constructor(
    private baeminOrderService: BaeminOrderService,
    private coupangeatsOrderService: CoupangeatsOrderService,
    private yogiyoOrderService: YogiyoOrderService,
    private manualOrderService: ManualOrderService,
    private unifiedOrderService: UnifiedOrderService,
    private logService: LogService,
  ) { }

  private typeOfOrderService(order: UnifiedOrderDoc): OrderProtocol {
    const isManual = order.orderVendor === 'ghostkitchen' ||
      order.createdBy === 'manual' ||
      order.createdBy === 'face';

    if (isManual) {
      return this.manualOrderService;
    } else if (order.orderVendor === 'baemin') {
      return this.baeminOrderService;
    } else if (order.orderVendor === 'coupangeats') {
      return this.coupangeatsOrderService;
    } else if (order.orderVendor === 'yogiyo') {
      return this.yogiyoOrderService;
    }

    throw new TypeError('Unsupported orderVendor');
  }

  // 주문 명령
  public async accept(order: UnifiedOrderDoc, withDelivery = false, create = false): Promise<boolean> {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.accept) {
      if (create === false) {
        // coupangeats의 경우에는 coupangeats-order.service.ts 내부에서 처리한다.
        if (['baemin', 'yogiyo'].includes(order.orderVendor)) {
          // 먼저 onTryACCEPT를 기록한다.
          const nowDateString = format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`);
          await this.unifiedOrderService.mergeOrder({
            _id: order._id,
            time: {
              onTryACCEPT: nowDateString
            }
          });
          this.logService.logOrder(order, `접수 시도 시각 ${nowDateString}을 기록합니다.`);
        }
      }

      const result = await orderService.accept(order, withDelivery, create);

      if (create === false) {
        // 접수가 실패했다면 onTryACCEPT를 제거해서 다시 알림이 울리도록 한다.
        if (result === false) {
          await this.unifiedOrderService.mergeOrder({
            _id: order._id,
            time: {
              onTryACCEPT: firestore.FieldValue.delete() as any
            }
          });
          this.logService.logOrder(order, `접수 시도 실패해서 onTryACCEPT 제거합니다.`);
        }
      }

      return result;
    }

    this.logService.logOrder(order, '접수 처리 불가한 주문입니다.', 'error', true);
    return false;
  }

  /**
   * '조리 완료'를 눌러도 되는가?
   * 배민1의 경우에 '조리시작' 알림 이전에는 누를 수 없다.
   */
  public canCook(order: UnifiedOrderDoc): boolean {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.canCook) {
      return orderService.canCook(order);
    }
    return (order.contextStatusCode < UnifiedOrderContextStatusCode.COOKED);
  }

  public async cook(order: UnifiedOrderDoc): Promise<boolean> {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.cook) {
      return orderService.cook(order);
    }

    this.logService.logOrder(order, '조리 완료 처리 불가한 주문입니다.', 'error', true);
    return false;
  }

  public async pickup(order: UnifiedOrderDoc): Promise<boolean> {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.pickup) {
      return orderService.pickup(order);
    }

    this.logService.logOrder(order, '픽업 확인 처리 불가한 주문입니다.', 'error', true);
    return false;
  }

  public async complete(order: UnifiedOrderDoc): Promise<boolean> {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.complete) {
      return orderService.complete(order);
    }

    this.logService.logOrder(order, '배송 완료 처리 불가한 주문입니다.', 'error', true);
    return false;
  }

  public async cancel(order: UnifiedOrderDoc, cancelReasonCode): Promise<boolean> {
    const orderService = this.typeOfOrderService(order);

    if (this.canCancel(order)) {
      if (orderService?.cancel) {
        return orderService.cancel(order, cancelReasonCode);
      } else {
        this.logService.logOrder(order, `주문을 취소하지 못 했습니다. 현상이 지속될 경우 고객센터에 알려주세요.`, 'error', true);
      }
    } else {
      this.logService.logOrder(order, `취소할 수 없는 주문인데 취소하려고 했습니다. ${JSON.stringify(order)}`, 'error');
    }

    return false;
  }

  public async modify(order: UnifiedOrderDoc): Promise<boolean> {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.modify) {
      return orderService.modify(order);
    }

    this.logService.logOrder(order, '주문 변경이 불가한 주문입니다.', 'error', true);
    return false;
  }

  // 설정
  public cookMinutesOptionsFor(order: UnifiedOrderDoc): string[] {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.cookMinutesOptionsFor) {
      return orderService?.cookMinutesOptionsFor(order);
    }

    // default
    return ['10', '15', '20', '25', '30', '40', '50', '60'];
  }

  public canCancel(order: UnifiedOrderDoc): boolean {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.canCancel) {
      return orderService?.canCancel(order);
    }

    // Default
    return false;
  }

  /**
   * 캔슬 코드 매핑
   */
  public cancelDescs(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.cancelDescs) {
      return orderService.cancelDescs(order);
    }

    return [{ cancelCode: '0', cancelReason: '기타' }];
  }

  public venderWithDeliveryType(order: UnifiedOrderDoc): string {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.venderWithDeliveryType) {
      return orderService?.venderWithDeliveryType(order);
    }

    // 주문 확인 창은 공간에 여유가 있으므로 다 보여준다.
    return `${unifiedOrderChannelMappings[order.orderChannel]}/${unifiedOrderVendorMappings[order.orderVendor]}/${unifiedOrderDeliveryTypeMappings[order.deliveryType]}`;
  }

  /**
   * 업주의 취소주문 확인 여부를 반영한다.
   */
  public async updatePosCancelConfirmed(order: UnifiedOrderDoc) {
    try {
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        posCancelConfirmed: true,
      });
      this.logService.logOrder(order, '취소주문 확인 완료');
    } catch (error) {
      this.logService.logOrder(order, `취소주문 확인 실패 : ${error}. 고객센터에 알려주세요`, 'error', true);
    }
  }

  /**
   * orderAddress 고객 주소
   * 노출하지 않으려면 undefined를 반환한다.
   */
  public orderAddress(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.orderAddress) {
      return orderService.orderAddress(order);
    }

    if (order.deliveryType === 'DELIVERY') {
      return `${order.address_key} ${order.address_detail}`;
    }

    if (['BAERA', 'COUPANG'].includes(order.deliveryType)) {
      return order.address_dong;
    }

    if (['HERE', 'TAKEOUT', 'UBER', 'DDINGDONG', 'SHUTTLE', 'YOGIYO'].includes(order.deliveryType)) {
      return undefined;
    }

    this.logService.logOrder(order, `deliveryType에 새로운 유형이 나타났습니다. ${order._id} ${order.deliveryType}`, 'error');
    return '신규유형???';
  }

  /**
   * roadAddress 고객 도로명 주소
   * 노출하지 않으려면 undefined를 반환한다.
   */
  public roadAddress(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.roadAddress) {
      return orderService.roadAddress(order);
    }

    if (['HERE', 'BAERA', 'TAKEOUT', 'UBER', 'DDINGDONG', 'COUPANG', 'SHUTTLE', 'YOGIYO'].includes(order.deliveryType)) {
      return undefined;
    }

    return order.address_road;
  }

  /**
   * 행정동
   * 노출하지 않으려면 undefined를 반환한다.
   */
  public addressDongH(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.addressDongH) {
      return orderService.addressDongH(order);
    }

    if (['HERE', 'TAKEOUT', 'UBER', 'DDINGDONG', 'SHUTTLE'].includes(order.deliveryType)) {
      return undefined;
    }

    if (order.address_dongH && order.address_dongH !== '') {
      return order.address_dongH;
    }

    this.logService.logOrder(order, 'address_dongH가 채워지지 않았습니다.');
    return undefined;
  }

  /**
   * orderNo 접수 번호
   * 노출하지 않으려면 undefined를 반환한다.
   */
  public orderNo(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.orderNo) {
      return orderService.orderNo(order);
    }

    return undefined;
  }

  /**
   * 고객 전화번호 표시 여부
   * 필요할 때만 보인다.
   */
  public showUserTel(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    const isManual = order.orderVendor === 'ghostkitchen' ||
      order.createdBy === 'manual' ||
      order.createdBy === 'face';

    if (orderService?.showUserTel) {
      return orderService.showUserTel(order);
    }

    if (isManual || order?.deliveryType === 'TAKEOUT') {
      return true;
    }

    return false;
  }

  /**
   * 예상 배달 시간 표시 여부
   * 필요에 따라 안보인다.
   */
  public showDeliveryMinutes(order: UnifiedOrderDoc) {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.showDeliveryMinutes) {
      return orderService.showDeliveryMinutes(order);
    }

    return true;
  }

  public onDemandUserTel(order: UnifiedOrderDoc): boolean {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.onDemandUserTel) {
      return orderService?.onDemandUserTel(order);
    }

    return false;
  }

  /**
   * 쿠팡이츠 주문 고객 또는 쿠리어의 안심번호를 요청한다.
   */
  public async getSafeNumber(order: UnifiedOrderDoc, target: 'customer' | 'rider'): Promise<{ result: 'success' | 'error' | 'warning', reason?: string, data?: string }> {
    const orderService = this.typeOfOrderService(order);

    if (orderService?.getSafeNumber) {
      return orderService?.getSafeNumber(order, target);
    }

    // Default
    return {
      result: 'error',
      reason: '미지원'
    };
  }
}
