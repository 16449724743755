/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogSearchAddressData } from '../dialog-search-address/dialog-search-address.component';
import { DialogSearchAddressService } from '../dialog-search-address/dialog-search-address.service';
import { DialogTelService } from '../dialog-tel/dialog-tel.service';

import { AugmentedAddress } from '../../schema/1/schema-common';

import { normalizeTel } from '../../core/1/util';
import { UtilService } from '../../core/2/util.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {
  private destroySignal = new Subject<boolean>();
  private address = {
    address_key: '',
    address_road: '',
    address_detail: '',
    address_location: { lat: 0, lon: 0 },
    address_sido: '',
    address_sigungu: '',
    address_dong: '',
    address_dongH: '',
    address_jibun: ''
  };
  public displayAddress = {
    addressKey: '',
    addressRoad: '',
    addressDetail: '',
  };
  @Input('address') set _address(value: {
    address_key: string;
    address_road: string | null;
    address_detail: string;
    address_location: {
      lat: number;
      lon: number;
    };
    address_sido: string;
    address_sigungu: string;
    address_dong: string;
    address_dongH: string;
    address_jibun: string;
  }) {
    this.address = value;

    this.displayAddress.addressKey = value.address_key;
    this.displayAddress.addressDetail = value.address_detail;
    this.displayAddress.addressRoad = value.address_road;
  }

  public userTel = '';
  @Input('userTel') set _userTel(value: string) {
    this.userTel = normalizeTel(value);
  }

  public formControlOrdMsg = new FormControl();
  @Input('orderMsg') set _orderMsg(value: string) {
    this.formControlOrdMsg.setValue(value, {onlySelf: true, emitEvent: false });
  }

  public prefixMsg = '';
  @Input('prefixMsg') set _prefixMsg(value: string) {
    this.prefixMsg = value;
  }

  @Output() addressEmitter = new EventEmitter<{
    augmentedAddress: AugmentedAddress
    addressDetail: any
  }>();
  @Output() userTelEmitter = new EventEmitter<string>();
  @Output() orderMsgEmitter = new EventEmitter<string>();

  constructor(
    private dialogSearchAddressService: DialogSearchAddressService,
    private dialogTelService: DialogTelService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.observeOrderMsg(this.formControlOrdMsg);
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  public openDialogTel() {
    this.dialogTelService.openNumberPadDialog(this.userTel).afterClosed().subscribe(telNo => {
      if (telNo === '') {
        this.utilService.toastrWarning('전화번호가 올바르지 않습니다.');
      } else if (telNo === undefined) {
        // 취소
      } else if (telNo !== undefined) {
        this.userTel = normalizeTel(telNo);
        this.userTelEmitter.emit(this.userTel);
      }
    });
  }

  public openDialogSearchAddress() {
    const searchAddressData: DialogSearchAddressData = this.address.address_key === ''
      ? undefined
      : {
        address_key: this.address.address_key,
        address_detail: this.address.address_detail
      };

    if (searchAddressData && this.address.address_road) {
      searchAddressData.address_road = this.address.address_road;
    }

    this.dialogSearchAddressService.openDialog(searchAddressData).afterClosed()
    .subscribe((result: {
      augmentedAddress: AugmentedAddress
      addressDetail: any
    }) => {
      if (result) {
        this.displayAddress.addressKey = result.augmentedAddress.key;
        this.displayAddress.addressDetail = result.addressDetail;
        this.displayAddress.addressRoad = result.augmentedAddress.road;

        this.addressEmitter.emit(result);
      }
    });
  }

  private observeOrderMsg(formControl: AbstractControl) {
    formControl.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        this.orderMsgEmitter.emit(value);
      });
  }
}
