<h2>프린터 설정</h2>

<div class="container">
  <div *ngIf="printer === undefined">
    <p>설정된 프린터 정보가 없습니다.</p>
  </div>

  <div *ngIf="printer">
    <p class="info-item">
      연결 유형 :
      <span>{{ printer.connectionType
        ? printer.connectionType === 'direct' 
          ? '인터넷' 
          : printer.connectionType 
        : 'N/A' }}</span>
    </p>
    <p class="info-item" *ngIf="printer.connectionType === 'proxy'">
      proxy 연결 :
      <span>{{ printer.proxyInstanceNo }}/{{ printer.printerAddress }}</span>
    </p>

    <form *ngIf="printer.connectionType === 'direct'">
      <p class="info-item">
        네트워크 주소 :
        <ng-container *ngIf="!isFormEnabled">
          <span class="input-width">{{ printer.printerAddress ? printer.printerAddress : 'N/A' }}</span>
          <button mat-stroked-button [disableRipple]="true" color="primary" (click)="enableForm()" type="button">수정</button>
        </ng-container>
        <ng-container *ngIf="isFormEnabled">
          <input type="tel" [formControl]="printerAddressForm" [class.invalid]="printerAddressForm.invalid">
          <button mat-stroked-button [disableRipple]="true" *ngIf="isFormEnabled" [disabled]="printerAddressForm.invalid" (click)="updatePrinterAddress()" type="button">완료</button>
          <button mat-stroked-button [disableRipple]="true" color="accent" *ngIf="isFormEnabled" (click)="disableForm()" type="button">취소</button>
        </ng-container>
      </p>
      <p class="invalid" *ngIf="isFormEnabled && printerAddressForm.invalid">{{ printerAddressForm.errors['reason'] }}</p>
      <p class="info-item alram">
        <mat-checkbox [checked]="printAlarmSetting" (change)="togglePrintAlarm($event.checked)">현재 기기로 알림 인쇄(주문 취소, 리뷰 등)를 수행합니다.</mat-checkbox>
      </p>
    </form>
  </div>
</div>

<div class="buttons">
  <button [disableRipple]="true" mat-flat-button class="with-margin" color="primary" (click)="printerTest()" type="button">시험인쇄</button>
  <button cdkFocusInitial [disableRipple]="true" mat-flat-button class="left cancel" (click)="closeDialog()">닫기</button>
</div>