/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';

import { DeliveryVendor } from '../../schema/1/schema-common';
import { VroongPosOptionsPickupItem } from '../../schema/1/schema-vroong-api';
import { UnifiedOrder } from '../../schema/3/schema';

import { RoomService } from '../1/room.service';
import { calcGeoDistance } from '../1/util';
import { SiteService } from '../1/site.service';
import { VroongPosOptionsPickupService } from '../2/vroong-pos-options-pickup.service';
import { LogService } from '../4/log.service';

export interface DeliveryVendorParams {
  /** 배달 거리: order에 따라서 달라진다. */
  deliveryDistance: number;
  /** 최소 픽업 시간: 지점설정과 동일, 당시의 지점 설정 기록용 */
  deliveryParamsMinPickupMinutes: number;
  /** km당 이동 시간: 지점설정과 동일, 당시의 지점 설정 기록용 */
  deliveryParamsMinutesPerKm: number;
  /** 기본 소요 시간: 지점설정과 동일, 당시의 지점 설정 기록용 */
  deliveryParamsBaseDeliveryMinutes: number;

  /** 요청 픽업 시간 옵션들 */
  pickups: number[];
  /** 추천 픽업 시간 */
  pickupMinutes: number;

  /** 예상 배달 시간 옵션들 */
  deliveryMinutesOptions: number[];
  /** 추천 예상 배달 시간 */
  deliveryMinutes: number;

  /** 거리와 지점설정만으로 계산한 값: 계산 과정의 중간 상태값. 리턴할 필요는 없지만 디버깅에 활용 */
  calculatedDeliveryMinutes: number;
  /** 요기요는 배민과 달리 자유로운 선택을 할 수 없다: 계산 과정의 중간 상태값. 리턴할 필요는 없지만 디버깅에 활용 */
  // calculatedDeliveryMinutesYogiyo: number;
  /** 요청 픽업 시간: 계산 과정의 중간 상태값. 리턴할 필요는 없지만 디버깅에 활용 */
  calculatedPickupMinutes: number;
}

export interface DeliveryParams {
  /** 배달 거리: order에 따라서 달라진다. */
  deliveryDistance: number;
  /** 최소 픽업 시간: 지점설정과 동일 */
  deliveryParamsMinPickupMinutes: number;
  /** km당 이동 시간: 지점설정과 동일 */
  deliveryParamsMinutesPerKm: number;
  /** 기본 소요 시간: 지점설정과 동일 */
  deliveryParamsBaseDeliveryMinutes: number;

  /** 거리와 지점설정만으로 계산한 값: 계산 과정의 중간 상태값. 리턴할 필요는 없지만 디버깅에 활용 */
  calculatedDeliveryMinutes: number;
  /** 요기요는 배민과 달리 자유로운 선택을 할 수 없다: 계산 과정의 중간 상태값. 리턴할 필요는 없지만 디버깅에 활용 */
  calculatedDeliveryMinutesYogiyo: number;

  /** 예상 배달 시간 옵션들 */
  deliveryMinutesOptions: number[];
  /** 예상 배달 시간 */
  deliveryMinutes: number;

  /** 요청 픽업 시간: 계산 과정의 중간 상태값. 리턴할 필요는 없지만 디버깅에 활용 */
  calculatedPickupMinutes: number;

  /** 컴바인넷 픽업 옵션들: 서버와 동기화하지는 않고 있다. */
  combinenetPickups: number[];
  /** 컴바인넷 요청 픽업 시간 */
  pickupMinutesCombinenet: number;

  /** 부릉 instanceNo: 강남역점은 order에 따라서 2개중 하나를 선택한다. */
  vroongInstanceNo: string;
  /** 부릉 픽업 옵션들: 서버와 동기화되는 상태 */
  vroongPickups: VroongPosOptionsPickupItem[];
  /** 부릉 요청 픽업 시간 */
  pickupMinutesVroong: number;

  /** 컴바인넷도 부릉도 아닌 경우 */
  defaultPickups: number[];
  /** 컴바인넷도 부릉도 아닌 경우 */
  defaultPickupMinutes: number;
}

const deliveryMinutesOptionsMappings = {
  /** refer: https://ceo.baemin.com/notice/5787?category=ALL&page=3 */
  baemin: [20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90],
  yogiyo: [30, 40, 50, 60, 75, 90],
  /** 오랫동안 사용해 온 값. 벤더별 확인이 필요하다. */
  other: [30, 40, 50, 60, 70, 80, 90, 100]
};

@Injectable({
  providedIn: 'root'
})
export class DeliveryUtilService {
  constructor(
    private logService: LogService,
    private siteService: SiteService,
    private roomService: RoomService,
    private vroongPosOptionsPickupService: VroongPosOptionsPickupService,
  ) { }

  /**
   * 배달 시간과 관련된 값을 계산한다.
   *
   * - 지점 설정에서 3가지 인자를 가져온다.
   * - order로부터 거리를 계산한다.
   *
   * @param fixedPickupMinutes 사용자가 값을 정한 경우에는 이 값을 이용해서 deliveryMinutes를 계산한다.
   */
  calculateDeliveryVendorParams(order: UnifiedOrder, deliveryVendor: DeliveryVendor, fixedPickupMinutes?: number): DeliveryVendorParams {
    //
    // 1.
    const deliveryParams = this.siteService.siteDoc.deliveryParams;
    // 초를 분으로 변환한다.
    // tslint:disable: max-line-length
    const deliveryParamsMinPickupMinutes = Math.floor((deliveryParams.deliveryVendorParams?.[deliveryVendor]?.minPickupSeconds ?? deliveryParams.minPickupSeconds) / 60);
    const deliveryParamsMinutesPerKm = Math.floor((deliveryParams.deliveryVendorParams?.[deliveryVendor]?.secondsPerKm ?? deliveryParams.secondsPerKm) / 60);
    const deliveryParamsBaseDeliveryMinutes = Math.floor((deliveryParams.deliveryVendorParams?.[deliveryVendor]?.baseDeliverySeconds ?? deliveryParams.baseDeliverySeconds) / 60);
    const deliveryParamsPickups = deliveryParams.deliveryVendorParams?.[deliveryVendor]?.pickups ?? deliveryParams.pickups ?? [10, 15, 20, 25, 30, 35, 40, 50, 60];

    let deliveryDistance = 2000;

    if (order.address_location && order.address_location.lat > 30 && order.address_location.lon > 120) {
      deliveryDistance = this.calcDeliveryDistance(order.address_location.lat, order.address_location.lon);
    }

    //
    // 2. pickupMinutes 관련
    const minPickupMinuts = Math.max(order.cookMinutes ?? 20, deliveryParamsMinPickupMinutes);
    const { pickups, calculatedPickupMinutes } = (() => {
      if (deliveryVendor === 'vroong') {
        const vroongInstanceNo = this.findVroongInstanceNo(order);
        const vroongPickupItems = vroongInstanceNo ? this.vroongPosOptionsPickupService.vroongPosOptionsPickupItemsForInstanceNo(vroongInstanceNo) : [{ enabled: true, time: 20 }];
        const vroongPickups = vroongPickupItems.filter(pickup => pickup.enabled).map(pickup => pickup.time);

        const foundValue = vroongPickups.find(v => v >= minPickupMinuts);
        const vroongPickupMinutes = foundValue ? foundValue : vroongPickups[vroongPickups.length - 1];

        return {
          pickups: vroongPickups,
          calculatedPickupMinutes: vroongPickupMinutes
        };
      } else {
        const vendorPickups = deliveryParamsPickups.filter(minutes => minutes >= deliveryParamsMinPickupMinutes);

        const foundValue = vendorPickups.find(pickup => pickup >= minPickupMinuts);
        const vendorPickupMinutes = foundValue ?? vendorPickups[vendorPickups.length - 1];

        return {
          pickups: vendorPickups,
          calculatedPickupMinutes: vendorPickupMinutes
        };
      }
    })();

    const pickupMinutes = fixedPickupMinutes ?? calculatedPickupMinutes;
    //
    // 3. deliveryMinutes 관련
    let calculatedDeliveryMinutes = Math.floor(pickupMinutes + deliveryParamsBaseDeliveryMinutes + deliveryParamsMinutesPerKm * deliveryDistance / 1000);
    // 5 반올림 (1 => 5, 5 => 5, 9 => 10, ...)
    calculatedDeliveryMinutes = Math.floor((calculatedDeliveryMinutes + 4) / 5) * 5;
    const defaultDeliveryMinutesOptions: number[] = deliveryMinutesOptionsMappings[order.orderVendor] ?? deliveryMinutesOptionsMappings.other;

    const foundOption = defaultDeliveryMinutesOptions.find(option => calculatedDeliveryMinutes <= option);
    const deliveryMinutes = foundOption ?? defaultDeliveryMinutesOptions[defaultDeliveryMinutesOptions.length - 1];
    const deliveryMinutesOptions = defaultDeliveryMinutesOptions;

    // 2021-05-27
    // - 요기요를 아닌 경우에는 deliveryMinutesOptions에 필요시 1개를 동적으로 추가하도록 했으나 UI가 밀려서 막는다.
    // 요기요
    // const yogiyoOption = defaultDeliveryMinutesOptions.find(option => calculatedDeliveryMinutes <= option);
    // const calculatedDeliveryMinutesYogiyo = yogiyoOption ?? defaultDeliveryMinutesOptions[defaultDeliveryMinutesOptions.length - 1];

    // const pickupSet = new Set(defaultDeliveryMinutesOptions);
    // // 기본 목록에 계산된 값을 끼워넣는다.
    // if (order.orderVendor !== 'yogiyo') {
    //   pickupSet.add(calculatedDeliveryMinutes);
    // }
    // const deliveryMinutesOptions = Array.from(pickupSet).sort((a, b) => a - b);

    // const deliveryMinutes = order.orderVendor === 'yogiyo' ? calculatedDeliveryMinutesYogiyo : calculatedDeliveryMinutes;

    return {
      deliveryDistance,
      deliveryParamsMinPickupMinutes,
      deliveryParamsMinutesPerKm,
      deliveryParamsBaseDeliveryMinutes,

      pickups,
      pickupMinutes,

      deliveryMinutesOptions,
      deliveryMinutes,

      calculatedPickupMinutes,
      calculatedDeliveryMinutes,
      // calculatedDeliveryMinutesYogiyo,
    };
  }

  /**
   * 주문에서 room에 대한 정보를 넣은 후에
   * 해당 room에 속한 vroong의 instanceNo를 알려준다.
   *
   * 강남역점의 경우에는 room에 2개의 부릉 계정이 속하게 되고
   * 주소를 기준으로 분배해야 한다.
   */
  findVroongInstanceNo(order: UnifiedOrder) {
    const instanceNos = this.roomService.room?.account.vroong;

    if (instanceNos === undefined || instanceNos?.length === 0) {
      return undefined;
    }

    if (order.site === 'gk-kangnam') {
      if (instanceNos.length !== 2) {
        this.logService.logRoom(`${order.room}에 해당하는 부릉 인스턴스는 2개여야 합니다. ${instanceNos.length}개`, 'error');
        return undefined;
      }

      if (order.address_sigungu === '서초구') {
        return instanceNos[0];
      } else if (order.address_sigungu === '강남구') {
        return instanceNos[1];
      } else {
        this.logService.logRoom(`예상하지 않은 시군구입니다.: ${order.address_sigungu}`, 'error');
        return undefined;
      }
    } else {
      if (instanceNos.length !== 1) {
        this.logService.logRoom(`${order.room}에 해당하는 부릉 인스턴스가 1개야야 합니다. ${instanceNos.length}개`, 'error');
        return undefined;
      }

      return instanceNos[0];
    }
  }

  public calcDeliveryDistance(lat: number, lng: number) {
    const roomDoc = this.roomService.room;
    const siteDoc = this.siteService.siteDoc;

    const roomlat = roomDoc.lat ? roomDoc.lat : siteDoc.lat;
    const roomlng = roomDoc.lng ? roomDoc.lng : siteDoc.lng;

    if (roomlat && roomlng) {
      return calcGeoDistance(roomlat, roomlng, lat, lng);
    } else {
      this.logService.logRoomWithToastrError(`${roomDoc.name}에 대한 lat, lng를 찾을 수 없습니다. 고객센터에 알려주세요.`);
      return 0;
    }
  }
}
