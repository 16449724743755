/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-full-screen-dialog-header',
  templateUrl: './full-screen-dialog-header.component.html',
  styleUrls: ['./full-screen-dialog-header.component.scss']
})
export class FullScreenDialogHeaderComponent implements OnInit {
  @Input() title: string;
  @Output() closed = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  public closeDialog() {
    this.closed.emit(true);
  }
}
