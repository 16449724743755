/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { combineLatest, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate, Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild, CanLoad
} from '@angular/router';

import { debugLog } from './core/1/common';
import { SiteService } from './core/1/site.service';
import { RoomService } from './core/1/room.service';
import { UserService } from './core/3/user.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - conf/organization
 */
@Injectable({
  providedIn: 'root'
})
export class InitGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private userService: UserService,
    private roomService: RoomService,
    private siteService: SiteService,
  ) { }

  promiseForInit() {
    return new Promise<boolean>((resolve, reject) => {
      // 3가지 조건이 모두 충족해야 하기 때문에 combineLatest를 이용했다.
      let subscription: Subscription;
      subscription = combineLatest([
        this.userService.latestUserSubject,
        this.roomService.latestSubject,
        this.siteService.latestSubject
      ]).subscribe(([user, room, site]) => {
        // console.dir(conf, user);
        if (user && Object.keys(room).length > 0 && site.roomCount > 0) {
          debugLog(`PASS init-guard`);
          resolve(true);
          // resolve는 한 번만 하면 되므로 unsubscritbe 한다.
          subscription?.unsubscribe();
        }
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    debugLog(`canActivate: '${route.url}'`);

    return this.promiseForInit();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    debugLog(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForInit();
  }

  canLoad(route: Route) {
    debugLog(`canLoad: '${route.path}'`);

    return this.promiseForInit();
  }
}
