/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/functions';

import { DeliveryVendor, WHERE } from './schema/1/schema-common';
import { UnifiedDeliveryShopDoc } from './schema/3/schema';
import { CallInputModifyUser, CallInputRequestDeliveryInformation, CallOutputModifyUser, CallOutputRequestDeliveryInformation } from './schema/4/schema-functions-call';

import { debugLog } from './core/1/common';
import { iNoBounce } from './core/1/inobounce';
import { IpService } from './core/1/ip.service';
import { deliveryVendorMappings } from './core/1/string-map';
import { sleep, isiPad, trimOrganization, isiPadDevice, textToSpeech, enableTTS } from './core/1/util';
import { VersionService } from './core/1/version.service';
import { SimpleNoticeService } from './core/1/simple-notice.service';
import { UnifiedDeliveryShopService } from './core/1/unified-delivery-shop.service';
import { RoomService } from './core/1/room.service';
import { SiteService } from './core/1/site.service';
import { UtilService } from './core/2/util.service';
import { AuthService } from './core/2/auth.service';
import { UnifiedMenuService } from './core/3/unified-menu.service';
import { UserService } from './core/3/user.service';
import { LogService } from './core/4/log.service';
import { InAppBrowserMessageService } from './core/5/in-app-browser-message.service';
import { SoundService } from './core/5/sound.service';
// import { HealthService } from './core/5/health.service';
import { AddressService } from './core/5/address.service';
import { DeliveryService } from './core/5/delivery.service';
import { MessageService } from './core/7/message.service';

import { DialogBaeminBlockService } from './shared/dialog-baemin-block/dialog-baemin-block.service';
import { DialogNoticeService } from './shared/dialog-notice/dialog-notice.service';
import { DialogConfigService } from './shared/dialog-config/dialog-config.service';
import { DialogOrderStatsService } from './shared/dialog-order-stats/dialog-order-stats.service';
import { DialogOrderMenuService } from './shared/dialog-order-menu/dialog-order-menu.service';
import { DialogSelectShopService } from './shared/dialog-select-shop/dialog-select-shop.service';
import { DialogCreateOrderService } from './shared/dialog-create-order/dialog-create-order.service';
import { DialogIframeService } from './shared/dialog-iframe/dialog-iframe.service';
import { DialogPrinterService } from './shared/dialog-printer/dialog-printer.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isDev = environment.production !== true;

  public loggedIn = false;

  public myVersion: string;
  public latestVersion: string;

  public title = 'POS';
  public navItems = [
    { name: '주문 상태', route: '/operating' },
  ];

  public showStoreCash = false; // siteDoc 설정과 연계
  public showNoPrint = false; // 자동 프린트를 하지 않음 표시

  public coupangeatsAutoPrint = false;

  public printReview = false;

  public unifiedDeliveryShops: UnifiedDeliveryShopDoc[] = [];

  public mainDeliveryVendor: DeliveryVendor;

  public deliveryInfo: CallOutputRequestDeliveryInformation['deliveryInfo'] & { updateTime: Date, reason: string };

  private email = '';
  private roomKey: string; // 'gk-samsung-14'
  private roomName: string; // '삼성점 14호'

  private destroySignal = new Subject<boolean>();

  constructor(
    public userService: UserService,
    public inAppBrowserMessageService: InAppBrowserMessageService,
    private router: Router,
    private fns: AngularFireFunctions,
    private authService: AuthService,
    private utilService: UtilService,
    private messageService: MessageService,
    private soundService: SoundService,
    private versionService: VersionService,
    private ipService: IpService,
    private siteService: SiteService,
    private logService: LogService,
    private roomService: RoomService,
    private addressService: AddressService,
    private simpleNoticeService: SimpleNoticeService,
    private unifiedDeliveryShopService: UnifiedDeliveryShopService,
    private deliveryService: DeliveryService,
    private dialogNoticeService: DialogNoticeService,
    private dialogBaeminBlockService: DialogBaeminBlockService,
    private dialogConfigService: DialogConfigService,
    private dialogOrderStatsService: DialogOrderStatsService,
    private dialogSelectShopService: DialogSelectShopService,
    private dialogOrderMenuService: DialogOrderMenuService,
    private dialogCreateorderService: DialogCreateOrderService,
    private unifiedMenuService: UnifiedMenuService,
    private dialogIframeService: DialogIframeService,
    private dialogPrinterService: DialogPrinterService,
    // private healthService: HealthService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.dir(router.routerState);
        // debugLog(`urlAfterRedirects = ${event.urlAfterRedirects}`);

        for (const navItem of this.navItems) {
          if (navItem.route === event.urlAfterRedirects) {
            this.updateTitle();
            break;
          }
        }
      }
    });
  }

  updateTitle() {
    const prefix = environment.firebase?.projectId === 'toe-prod' ? '' : '[개발]';
    this.title = prefix + `${this.loggedIn ? '[' + this.roomName + '] ' : ''}`;
    document.title = `${this.loggedIn ? '[' + this.roomName + '] ' : 'POS'}`;
  }

  ngOnInit() {
    this.authService.observeLoggedIn().pipe(
      // 안정화되지 않은 상태인 null 을 제거하기 위함이다.
      filter(value => {
        return value === true || value === false;
      }),
      takeUntil(this.destroySignal)
    ).subscribe(async value => {
      debugLog(`@AppComponent loggedIn = ${value}`);

      this.loggedIn = value;
      if (value === true) {

        this.utilService.toastrInfo('로그인 성공!', null, 3000);
        this.email = this.authService.user.email;

        let localAddresses = 'unknown';
        try {
          localAddresses = (await this.ipService.findLocalAddress()).join();
        } catch (err) {
          console.error('Fail to local IP address');
        }
        const publicAddress = await this.ipService.findPublicAddress();

        // debugLog(`localAddress = ${localAddresses}`);
        // debugLog(`publicAddress = ${publicAddress.ip}`);
        // tslint:disable-next-line: max-line-length
        this.userService.observe(this.email);
        // 다음의 순서로 필요한 정보를 읽어온다.
        // email => /user/email => message/doc (to.instanceId == user.room)
        this.userService.latestUserSubject
          .pipe(
            takeUntil(this.destroySignal)
          )
          .subscribe(async user => {

            if (user) {
              if (!user.rolePos && !['admin', 'operator', 'ceo'].includes(user.role)) {
                this.logService.logRoomWithToastrError(`현재 사용자(${user.role})는 권한이 없습니다. 로그아웃합니다.`);
                this.logout();
              }
              if (user.room) {
                this.roomKey = user.room;
              } else {
                this.logService.withToastrError(`등록된 호실이 없습니다. 로그아웃합니다.`);
                this.logout();
              }

              // if (!isiPadDevice() && user.role === 'ceo' && user.email.split('@')[1] === 'toe.cloud') {
              //   const message = `현재 계정(${user.email})은 전용 발가락 POS 기기에서만 사용해야 합니다. 사장님 사이트 계정(xxx@ceo.stat)을 이용해서 다시 로그인해 주세요.`;
              //   const { isConfirmed } = await Swal.fire({
              //     titleText: '알림',
              //     text: message,
              //     confirmButtonText: '확인',
              //     backdrop: false,
              //   });

              //   if (isConfirmed) {
              //     this.logout();
              //   }
              //   this.logService.logRoom(message, 'error');
              //   return;
              // }

              // 'ceo'인 경우에만 접수시 자동 출력한다.
              // if (user.role !== 'ceo') {
              //   this.showNoPrint = true;
              // }
            }
          });

        // await promiseForInit()를 실행한 경우에 UI의 반응이 떨어지는 듯하여 아래와 같이 변경
        // 정밀한 분석 필요
        while (this.userService.user === undefined) {
          debugLog('Waiting for the first sync with Firestore user');
          await sleep(200);
        }

        // message에 기록을 남긴다.
        this.messageService.notificationLogin(this.email, {
          version: environment.version,
          localIPs: localAddresses,
          publicIP: publicAddress ?? ''
        });

        const roomKey = this.userService.user.room;
        this.messageService.observeMessage(roomKey);
        this.roomService.observe(roomKey);

        while (Object.keys(this.roomService.room).length === 0) {
          debugLog('Waiting for the first sync with Firestore room');
          await sleep(200);
        }

        // this.userService와 this.roomService가 완성된 시점까지 기다린다.
        const viewportDesc = window.visualViewport ? ('해상도 = '
          + window.visualViewport.width + 'x' + window.visualViewport.height
          + ' (확대비율 ' + Math.round(window.visualViewport.scale * 100) + '%)') :
          '해상도 모름';

        // inApp 환경인 경우 App정보를 같이 보낸다.
        if (this.inAppBrowserMessageService.webkit !== undefined) {
          const ionicInfo = this.inAppBrowserMessageService.IonicInfo;
          const appVersion = ionicInfo ? `${ionicInfo.binaryVersionName}/${ionicInfo.binaryVersionCode}` : '알 수 없음';
          this.logService.logRoom(`앱에서 로그인 성공. version=${environment.version}, appVersion=${appVersion}, id=${this.email}, ${viewportDesc}, userAgent=${navigator?.userAgent}, remote=${publicAddress}, local=${localAddresses}`);
          this.triggerEnableTTSWithClickEvent();
        } else {
          this.logService.logRoom(`브라우저에서 로그인 성공. version=${environment.version}, id=${this.email}, ${viewportDesc}, userAgent=${navigator?.userAgent}, remote=${publicAddress}, local=${localAddresses}`);
        }

        this.roomService.latestSubject.subscribe(roomDoc => {
          this.printReview = roomDoc.printReview;

          this.roomName = trimOrganization(roomDoc.name);
          this.updateTitle();

          this.coupangeatsAutoPrint = roomDoc.autoPrint?.coupangeats ?? false;

          if (this.mainDeliveryVendor !== roomDoc.deliveryVendors[0]) {
            this.mainDeliveryVendor = roomDoc.deliveryVendors[0];
            // 호실 설정이 변경되었다니 다시 조회하자.
            debugLog('호실 설정 변경으로 인한 updateDeliveryInfo() 호출');
            this.updateDeliveryInfo();
          }
        }, error => console.error(error));

        const room = this.roomService.room;

        this.siteService.observe(room.site);

        this.simpleNoticeService.startObservingSimpleNotice([
          ['site', '==', room.site],
          ['state', '==', 'show'],
        ], 6, 0, -7, 'desc');

        // 최소 픽업 시간을 모니터링한다.
        this.observeSiteDoc();
        // 픽업 시간을 동기화한다.
        this.observePickupTime();

        this.observeUnifiedDeliveryShop(roomKey);

        // 업소 목록 및 메뉴를 최신으로 유지한다.
        this.observeUnifiedMenu();
      }

      // subscribe로 변경하면서 아래의 문제가 저절로 해결된다.
      // refer: https://stackoverflow.com/questions/35105374/how-to-force-a-components-re-rendering-in-angular-2
      // LoginComponent가 붙을 경우에 별다른 일을 하지 않으니 AppComponent의 뷰가 갱신되지 않았다.
      // this.changeDetectorRef.detectChanges();
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionService.latestVersionSubject
      .pipe(
        takeUntil(this.destroySignal)
      )
      .subscribe(lastesVersion => {
        if (environment.production) {
          // 개발 환경에서는 자동 업데이트 금지
          this.latestVersion = lastesVersion;
          if (this.latestVersion !== this.myVersion) {
            if (isiPad()) {
              this.soundService.playRestart();
            }

            // 1분 뒤에 자동 리로드
            setTimeout(() => {
              this.logService.logRoom('1분 경과되어 자동 리로드');
              window.location.reload();
            }, 60000);
          }
        }
      }, error => console.error(error));

    // safari의 scroll bouncing을 막는다.
    iNoBounce.enable();

    // this.healthService.observe();
  }

  ngOnDestroy() {
    this.destroySignal.next(true);
    this.destroySignal.unsubscribe();
  }

  public logout() {
    this.logService.logRoom('로그아웃');

    // 로그아웃 과정의 일시적 permission 에러 메시지를 표시하지 않는다.
    this.utilService.ignore = true;
    this.logService.ignore = true;

    this.authService.signOut().then(value => {
      this.messageService.notificationLogout();
      // this.router.navigate(['/auth/login']);

      // 로그아웃을 하면 기존 상태도 초기화해야 한다.
      // 여러 서비스가 갖고 있는 상태를 초기화하기 위해서 일단 reload()한다.
      // TODO : 나중에는 상태를 정리한다 리로드 할 경우에 토스트 메시지가 사라지는 문제가 있다.
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  }

  public openUnifiedDeliveryShop() {
    this.logService.logRoom('충전잔액 클릭');

    const msg = this.unifiedDeliveryShops.map(shop => {
      const timestamp = shop._timeUpdate ?? shop._timeCreate;
      const updateTime = timestamp ? format(timestamp.toDate(), 'yyyy-MM-dd HH:mm:ss') : '';
      const numberFormatDeposit = new Intl.NumberFormat().format(shop.deposit);

      const title = `${deliveryVendorMappings[shop.deliveryVendor]} 충전정보\n\n`;
      const desc = `가맹점 잔액: ${numberFormatDeposit}원\n충전계좌 은행명: ${shop.bankName}\n충전계좌 번호: ${shop.bankAccount}\n충전계좌 예금주: ${shop.bankOwner}\n${updateTime ? '최근 변경 시각: ' + updateTime : ''}\n\n`;

      return title + desc;
    }).join('');

    this.dialogNoticeService.openSimpleNoticeDialog('', msg);
  }

  public openPickupTime() {
    // 눌렀다는 것은 답답한 사정이 있다는 것이므로 다시 최신 정보를 가져오도록 한다.
    // await로 기다리지는 않는다.
    debugLog('사용자 클릭으로 인한 updateDeliveryInfo() 호출');
    this.updateDeliveryInfo();

    let msg = '';
    if (this.deliveryInfo) {
      const { pickups, updateTime, reason } = this.deliveryInfo;
      const formatUpdateTime = updateTime ? format(updateTime, 'yyyy-MM-dd HH:mm:ss') : '';

      msg = `${deliveryVendorMappings[this.mainDeliveryVendor]} 배달 정보\n\n`;
      if (pickups) {
        msg += `최소 픽업: ${pickups[0]}분\n픽업 가능 시간(분): ${pickups.join(', ')}\n`;
      }
      if (reason) {
        msg += `최근 에러 발생: ${reason}\n`;
      }
      msg += `${updateTime ? '최근 변경 시각: ' + formatUpdateTime : ''}\n\n`;
    } else {
      // mainDeliveryVendor는 표시하자.
      msg = `${deliveryVendorMappings[this.mainDeliveryVendor]} 배달 정보\n\n아직 상세 정보를 받지 못 했습니다. 잠시 후에 다시 확인해 보세요.`;
    }
    this.dialogNoticeService.openSimpleNoticeDialog('', msg);
  }

  public openBaeminBlockDialog() {
    // 주의: agInit()에서 order를 저장한 후에 사용하면 최신 order를 추적하지 않는다.
    this.dialogBaeminBlockService.openDialog(this.roomKey);
  }

  public playSound() {
    this.logService.logRoom('소리테스트 클릭');
    this.soundService.playTest();
    enableTTS();
  }

  public stopSound() {
    this.soundService.stopNewOrder();
  }

  public manualUpdate() {
    this.logService.logRoom('수동업데이트 클릭');
    window.location.reload();
  }

  public manualReload() {
    this.logService.logRoom('리로드 클릭');
    window.location.reload();
  }

  public info() {
    const ionicInfo = this.inAppBrowserMessageService.IonicInfo;

    this.dialogNoticeService.openSimpleNoticeDialog('정보',
      `버전 : ${environment.version}\n` +
      `사용자 : ${this.userService.user.email}\n` +
      `${window.visualViewport ? ('해상도: ' + window.visualViewport.width + 'x' + window.visualViewport.height + ' (확대비율 ' + Math.round(window.visualViewport.scale * 100) + '%)') : ''}` +
      `${ionicInfo ? `\n앱 버전 : ${ionicInfo.binaryVersionName}/${ionicInfo.binaryVersionCode}` : ''}`
    );
  }

  public printerInfo() {
    this.dialogPrinterService.openDialog();
  }

  public onNoPrint() {
    this.dialogNoticeService.openSimpleNoticeDialog('접수 시 자동으로 주문서를 인쇄하지 않습니다.', '전용 POS 태블릿인 경우에는 POS 전용 계정으로 로그인해야 합니다.');
  }

  public openConfigDialog() {
    this.dialogConfigService.openConfigDialog();
  }

  public openOrderStatsDialog() {
    this.dialogOrderStatsService.openOrderStatsDialog();
  }

  public manualOrder() {
    this.dialogSelectShopService.openDialogSelectShop().afterClosed().subscribe(unifiedMenu => {
      this.dialogOrderMenuService.openDialogOrderMenu(unifiedMenu);
    });
  }

  public createOrder() {
    this.dialogCreateorderService.openDialogCreateOrder();
  }

  /**
   * 등록된 호실을 변경한다.
   * user.role = admin, operator 본인 계정만 변경 가능
   */
  public async updateRoom(roomKey: string) {
    if (this.roomKey === roomKey) {
      Swal.fire('기존과 동일한 호실입니다.');
      return;
    }

    const loading = Swal.mixin({
      title: '변경 중...',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    loading.fire();
    const callInput: CallInputModifyUser = {
      room: roomKey
    };
    const callbable = this.fns.httpsCallable<CallInputModifyUser, CallOutputModifyUser>('callModifyUser');

    try {
      const response = await callbable(callInput).toPromise();
      loading.close();
      if (response.result !== 'success') {
        this.logService.logRoomWithToastrError(`호실 변경 오류: ${response.reason}`);
        return;
      }

      const message = '3초 후, 재시작 합니다.';
      // 예외가 발생하는 경우가 있어서 감싼다.
      try {
        textToSpeech(message);
      } catch (error) {
        this.logService.logRoom(`textToSpeech(${message}) 예외: ${error}`, 'error');
      }

      Swal.fire({
        icon: 'success',
        title: message,
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        didDestroy: () => {
          window.location.reload();
        }
      });
    } catch (error) {
      this.logService.logRoomWithToastrCatch(error, `호실 변경 중 예외 발생`);
    }
  }

  public openSwalPlatformTel() {
    const html = `
    <div class="swal-platform">
      <div class="swal-platform-name">◉ 배달의민족, 배민1</div>
      <div class="swal-platform-content">
        <span class="swal-tel">고객센터 (1600-0987) 연락</span>
        <span>→ 1번 (업소를 운영 중인 사장님)</span>
        <span>→ 4번 (주문 확인 및 취소 문의)</span>
        <span>→ 상담원 연결 후 ‘주문 건 정보 (주문 일자, 주문 시간, 배송 주소, 금액, 메뉴명 등)’ 확인 후 문의 사항 언급</span>
      </div>
      <div class="swal-platform-notice">※ 배달의민족과 배민1 주문 건을 처리할 수 있는 고객센터는 상이하므로 상담원 연결 시, ‘배달의민족 고객센터’, ‘배민1 고객센터’ 여부 확인 필요</div>
    </div>

    <div class="swal-platform">
      <div class="swal-platform-name">◉ 쿠팡이츠</div>
      <div class="swal-platform-content">
        <span class="swal-tel">쿠팡이츠 스토어 서포트 센터 (1600-9827) 연락</span>
        <span>→ 1번 (업소를 운영 중인 업주님)</span>
        <span>→ 사업자 번호 10자리 입력</span>
        <span>→ 1번 (입력한 사업자 번호 일치)</span>
        <span>→ 상담원 연결 후  ‘주문 건 정보 (주문 번호, 주문 시간 등)’ 확인 후 원하는 요청 사항 언급</span>
      </div>
    </div>

    <div class="swal-platform">
      <div class="swal-platform-name">◉ 요기요</div>
      <div class="swal-platform-content">
        <span class="swal-tel">요기요 고객센터 (1661-5270) 연락</span>
        <span>→ 2번 (가맹점)</span>
        <span>→ 2번 (주문 취소 관련 문의)</span>
        <span>→ 주문한 고객 전화번호+#</span>
        <span>→ 1번 (입력한 고객 전화번호 일치)</span>
        <span>→ 취소 사유 선택</span>
      </div>
      <div class="swal-platform-notice">※ 24시간 이내 주문 건은 ‘요기요 사장님’ 앱 통해 직접 주문 취소 가능</div>
    </div>

    <div class="swal-platform">
      <div class="swal-platform-name">◉ 고스트키친</div>
      <div class="swal-platform-content">
        <span class="swal-tel">고스트키친 고객센터 (1522-6385) 연락</span>
      </div>
      <div class="swal-platform-notice">※ 평일 09:00~18:00, 점심시간 12:00~13:00, 주말·공휴일 휴무</div>
    </div>

    `;

    Swal.fire({
      title: '플랫폼별 고객센터 상담원 연결 방법',
      html,
      // 스타일의 스코프를 지정하기 위한 target
      target: 'app-root',
      customClass: {
        popup: 'swal-wide-80vw'
      }
    });
  }

  public openPrinterManual() {
    this.dialogIframeService.openDialog();
  }

  /**
   * 운영팀에서 지점별로 관리하는 배달 최소픽업시간을 보여준다.
   */
  private observeSiteDoc() {
    const ob1 = this.siteService.latestSubject;

    ob1.subscribe(siteDoc => {
      debugLog('site 변경으로로 인한 updateDeliveryInfo() 호출');
      this.updateDeliveryInfo();
      this.showStoreCash = siteDoc.showStoreCash === true;
    }, error => console.error(error));
  }

  private observeUnifiedDeliveryShop(roomKey: string) {
    this.unifiedDeliveryShopService.observe(roomKey)
      .pipe(
        takeUntil(this.destroySignal),
      )
      .subscribe(shops => {
        const activeShops = shops.filter(shop => {
          // roomDoc이 존재하는 것은 앞에서 보장해 주고 있다.
          const roomDoc = this.roomService.room;

          return roomDoc.account[shop.deliveryVendor]?.includes(shop.instanceNo);
        });
        if (activeShops.length !== shops.length) {
          this.logService.logRoom(`${roomKey}로 검색되는 unifiedDeliveryShop에서 연동이 해제된 업소 ${shops.length - activeShops.length}개는 제외합니다.`, 'warn');
        }
        if (activeShops.length === 0) {
          this.logService.logRoom(`${roomKey}로 검색되는 연동중인 unifiedDeliveryShop이 없습니다.`, 'warn');
          return;
        }

        const deliveryVendors = this.roomService.room.deliveryVendors;
        // 시각에 대해 내림차순 정렬을 한다.
        activeShops.sort((a, b) => {
          const [timestampA, timestampB] = [a, b].map(doc => (doc._timeUpdate ?? doc._timeCreate ?? firestore.Timestamp.now()).toDate().getTime());
          return timestampB - timestampA;
        });
        const filteredShops = deliveryVendors
          .filter(deliveryVendor => activeShops.find(shop => shop.deliveryVendor === deliveryVendor))
          .map(deliveryVendor => {
            // 가장 앞에 있는 것이 가장 최근 것이다.
            const found = activeShops.find(shop => shop.deliveryVendor === deliveryVendor);
            if (found) {
              return found;
            }
            throw new Error('어라~~ filter 조건이 바뀌었나?');
          });

        this.unifiedDeliveryShops = filteredShops;
      }, error => console.error(error));
  }

  private observeUnifiedMenu() {
    const unifiedMenuWheres: WHERE[] = [
      ['room', '==', this.userService.user.room]
    ];

    this.unifiedMenuService.observeMenuDocs(unifiedMenuWheres);
  }

  /**
   * 10분(600,000ms) 간격으로 배차 전 정보 API를 호출해 픽업 시간을 동기화한다.
   */
  private async observePickupTime() {
    const callTimer = timer(30000, 600000);

    callTimer.pipe(takeUntil(this.destroySignal)).subscribe(_ => {
      debugLog('timer에 의한 updateDeliveryInfo() 호출');
      this.updateDeliveryInfo();
    }, error => console.error(error));
  }

  private async updateDeliveryInfo() {
    const site = this.siteService.siteDoc;
    const room = this.roomService.room;

    if (!room._id) {
      debugLog('room이 아직 준비되지 않았다.');
      return;
    }
    if (!site._id) {
      debugLog('site가 아직 준비되지 않았다.');
      return;
    }

    // 화면이 보이지 않는 상태일 때엔 실행하지 않는다.
    if (!this.mainDeliveryVendor || document?.visibilityState === 'hidden') {
      return;
    }

    if (this.loggedIn === true) {
      // tslint:disable: variable-name
      const address_key = room.address_key ? room.address_key : site.address_key;
      const address_road = room.address_road ? room.address_road : site.address_road;
      const address_detail = room.address_detail ? room.address_detail : site.address_detail;
      const lat = room.lat ? room.lat : site.lat;
      const lng = room.lng ? room.lng : site.lng;

      if (!(address_key && address_road && address_detail && lat && lng)) {
        this.logService.logRoom(`호실의 주소 혹은 위경도 설정이 없는 것 같아요.`, 'error');
        return;
      }

      const callInput: CallInputRequestDeliveryInformation = {
        organization: room.organization,
        site: room.site,
        room: room.room,
        deliveryVendor: this.mainDeliveryVendor,
        instanceNo: this.mainDeliveryVendor === 'ghokirun' ? 'N/A' : room.account[this.mainDeliveryVendor][0],
        address_key,
        address_road,
        address_detail,
        address_location: { lat, lon: lng },
        initialPaymentAmount: 0,
      };

      const { result, augmentedAddress } = await this.addressService.augmentAddress(address_key, address_road, true);
      if (result === 'success') {
        callInput.address_dongH = augmentedAddress.dongH;
        callInput.address_dong = augmentedAddress.dong;
        callInput.address_sido = augmentedAddress.sido;
        callInput.address_sigungu = augmentedAddress.sigungu;
        callInput.address_jibun = augmentedAddress.jibun;
      }

      try {
        const response = await this.deliveryService.requestDeliveryInformation(callInput);

        if (response.result === 'success') {
          this.deliveryInfo = {
            ...response.deliveryInfo,
            updateTime: new Date(),
            reason: ''
          };
        } else {
          // const hours = new Date().getHours();
          // // 2:00 ~ 08:59 까지는 자주 발생하므로 error 처리하지 않는다.
          // const logLevel = ((1 < hours && hours < 9) || response.reason?.includes('영업 종료')) ? 'debug' : 'warn';

          this.deliveryInfo = {
            ...response.deliveryInfo, // 대부분 undefined가 된다. type을 맞추기 위해 추가했다.
            updateTime: new Date(),
            reason: response.reason
          };

          this.logService.logRoom(`${this.mainDeliveryVendor} 픽업 시간 동기화 중 배차 전 정보 조회 실패: ${response.reason}`, 'warn');
        }
      } catch (error) {
        this.logService.logRoom(`${this.mainDeliveryVendor} 픽업 시간 동기화 중 배차 전 정보 조회 예외: ${error.message}`, 'warn');
      }
    }
  }

  /**
   * inAppBrowser환경에서는 TTS를 제외한 사운드 재생이 사용자 인터렉션없이 가능하지만
   * TTS는 최초 실행시에 반드시 사용자의 인터렉션을 필요로한다.
   */
  private triggerEnableTTSWithClickEvent() {
    const triggerEnableTTS = () => {
      enableTTS();
      document.removeEventListener('click', triggerEnableTTS);
    };
    document.addEventListener('click', triggerEnableTTS);
  }
}
