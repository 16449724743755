/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UnifiedDeliveryDoc, UnifiedOrderDoc } from '../../schema/3/schema';

import { DialogCreateOrderComponent } from './dialog-create-order.component';

@Injectable({
  providedIn: 'root'
})
export class DialogCreateOrderService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogCreateOrder(order?: UnifiedOrderDoc, deliveries?: UnifiedDeliveryDoc[]) {
    this.dialog.open(DialogCreateOrderComponent, {
      disableClose: true,
      panelClass: 'full-screen-dialog',
      data: {
        order,
        deliveries
      }
    });
  }
}
