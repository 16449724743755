/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { UnifiedDeliveryShopDoc } from '../../schema/3/schema';

@Injectable({
  providedIn: 'root'
})
export class UnifiedDeliveryShopService {

  constructor(
    private db: AngularFirestore
  ) { }

  observe(roomKey: string) {
    const collectionPath = 'unifiedDeliveryShop';
    const queryFn: QueryFn = ref => ref.where('room', '==', roomKey);

    const collection = this.db.collection<UnifiedDeliveryShopDoc>(collectionPath, queryFn);

    const observable = collection.valueChanges();

    return observable;
  }

}
