/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { format } from 'date-fns';

import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

import { UnifiedOrderContextStatusCode, UnifiedOrderDoc, UnifiedOrderStatusCode } from '../../schema/3/schema';
import { OrderProtocol } from '../../schema/5/schema-protocol';

import { diffTime } from '../1/util';
import { LogService } from '../4/log.service';
import { UnifiedOrderService } from '../5/unified-order.service';
import { MessageService } from '../7/message.service';

@Injectable({
  providedIn: 'root'
})
export class CoupangeatsOrderService implements OrderProtocol {

  constructor(
    private unifiedOrderService: UnifiedOrderService,
    private messageService: MessageService,
    private logService: LogService,
    private dialogNoticeService: DialogNoticeService,
  ) { }

  /**
   * @returns 접수 성공 여부
   */
  public async accept(order: UnifiedOrderDoc): Promise<boolean> {
    const cookMinutes = order.cookMinutes;

    if (!cookMinutes) {
      this.logService.logOrder(order, 'cookMinutes이 없습니다. 고객센터에 알려주세요.', 'error', true);
      return false;
    }

    try {
      // 1. OMC에 알리고
      const nowDateString = format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`);
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        // cookMinutes는 설정하지 않아도 되지만 일부 의존적인 코드가 있을 수 있어서 유지한다.
        cookMinutes: cookMinutes ?? 20,
        withDelivery: false,
        time: {
          // coupangeats는 여기에서 한꺼번에 기록한다.
          onTryACCEPT: nowDateString,
          onCEOACCEPTED: nowDateString,
        },
      });
      this.logService.logOrder(order, '쿠팡이츠 접수 명령을 보냅니다.');

      // 2. 직접 접수 요청을 한다.
      const response = await this.messageService.requestAcceptCoupangeatsOrder(order.instanceNo, order.orderNo, String(cookMinutes));
      if (response?.result === 'success') {
        this.logService.logOrder(order, '쿠팡이츠 접수 성공했습니다.');
      } else {
        this.logService.logOrder(order, `쿠팡이츠 접수 실패했습니다: ${response?.reason}`);
        return false;
      }
    } catch (error) {
      this.logService.logOrder(order, `쿠팡이츠 접수 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  public async cook(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 1. OMC에 알리고
      try {
        // 시간을 기록한다.
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.COOKED,
          time: {
            onCOOKED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          }
        });
        this.logService.logOrder(order, `쿠팡이츠 주문의 주문상태를 '조리완료'로 변경 완료했습니다.`);
      } catch (error) {
        this.logService.logOrder(order, `쿠팡이츠 주문의 주문상태를 '조리완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
        this.dialogNoticeService.openSimpleNoticeDialog(error.message);
        return false;
      }

      // 2. 준비완료 요청을 한다.
      await this.messageService.requestReadyCoupangeatsOrder(order.instanceNo, order.orderNo);
      this.logService.logOrder(order, '쿠팡이츠 주문 준비완료 처리. (쿠팡이츠에 준비완료 접수)');
    } catch (error) {
      this.logService.logOrder(order, `쿠팡이츠에 준비완료로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  /**
   * 외부 서비스와 동기화하는 주문에 대한 픽업확인
   * 추가적인 연동이 없이 내부 상태만 변경한다.
   */
  public async pickup(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      if (order.deliveryType === 'COUPANG') {
        // 시간을 기록한다.
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.PICKEDUP,
          time: {
            onPICKEDUP: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          }
        });
        this.logService.logOrder(order, `쿠팡이츠 주문(${order.deliveryType})의 주문상태를 '배송중'으로 변경 완료했습니다.`);
      } else if (order.deliveryType === 'TAKEOUT') {
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          // TODO: 쿠팡이츠 완료 처리를 해야 한다.
          contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED,
        });
        this.logService.logOrder(order, `쿠팡이츠 주문(${order.deliveryType})의 주문상태를 '배송완료'로 변경 완료했습니다.`);
      } else {
        this.logService.logOrder(order, `예기치 않는 쿠팡이츠 주문의 배송 유형입니다. 고객센터에 알려주세요. (현재 배송유형: ${order._id} ${order.deliveryType})`, 'error', true);
      }
    } catch (error) {
      this.logService.logOrder(order, `쿠팡이츠 주문의 주문상태 변경 시도 중에 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }
    return true;
  }

  public async cancel(order: UnifiedOrderDoc, cancelReasonCode): Promise<boolean> {
    try {
      await this.messageService.requestCancelCoupangeatsOrder(
        order.instanceNo,
        order.orderNo,
        cancelReasonCode,
        order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'DECLINE' : 'CANCEL'
      );
      this.logService.logOrder(order, `쿠팡이츠 주문 취소 요청 성공`);

      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        posCancelConfirmed: true // 직접 취소를 하고 있기 때문에 바로 확인 상태로 변경한다.
      });
      this.logService.logOrder(order, `쿠팡이츠 취소 주문을 취소 확인상태로 변경 성공`);
    } catch (error) {
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      this.logService.logOrder(order, `[${error.message}]의 이유로 취소(${cancelReasonCode})를 실패했다`);
      return false;
    }

    return true;
  }

  public canCancel?(order: UnifiedOrderDoc): boolean {
    return (order.createdBy !== 'manual' && order.contextStatusCode < 60);
  }

  public cancelDescs(order: UnifiedOrderDoc) {
    const cancelCodesMap = {
      // COUPANG: {
      REJECT: [
        { cancelCode: '1', cancelReason: '품절' },
        { cancelCode: '2', cancelReason: '주문 폭주' },
        { cancelCode: '17', cancelReason: '고객 요청' },
        { cancelCode: '19', cancelReason: '금일 영업 마감' },
        { cancelCode: '4', cancelReason: '금일 휴업' },
        { cancelCode: '5', cancelReason: '메뉴 상이' },
        // { code: '3', name: '기타 사유' },
        // 3의 자식
        { cancelCode: '6', cancelReason: '기타 (주문금액)' },
        { cancelCode: '16', cancelReason: '기타 (폐업·사업자 변경)' },
        { cancelCode: '15', cancelReason: '기타 (사용법 모름)' },
        { cancelCode: '7', cancelReason: '기타 (테스트 주문)' },
        { cancelCode: '18', cancelReason: '기타 (상점 귀책)' },
      ],
      CANCEL: [
        { cancelCode: '1', cancelReason: '품절' },
        { cancelCode: '2', cancelReason: '주문 폭주' },
        { cancelCode: '17', cancelReason: '고객 요청' },
        { cancelCode: '19', cancelReason: '금일 영업 마감' },
        { cancelCode: '4', cancelReason: '금일 휴업' },
        { cancelCode: '5', cancelReason: '메뉴 상이' },
        // { code: '3', name: '기타 사유' },
        // 3의 자식
        { cancelCode: '6', cancelReason: '기타 (주문금액)' },
        { cancelCode: '16', cancelReason: '기타 (폐업·사업자 변경)' },
        { cancelCode: '15', cancelReason: '기타 (사용법 모름)' },
        { cancelCode: '7', cancelReason: '기타 (테스트 주문)' },
        { cancelCode: '18', cancelReason: '기타 (상점 귀책)' },
      ]
      // }
    };

    // TODO: 'COUPANG'인 경우와 'TAKEOUT'인 경우 동일하게 처리. TAKEOUT인 경우에 확인이 필요하다.
    return cancelCodesMap[order.orderStatusCode === UnifiedOrderStatusCode.NEW ? 'REJECT' : 'CANCEL'];
  }

  public cookMinutesOptionsFor(order: UnifiedOrderDoc): string[] {
    return ['5', '10', '15', '20', '25', '30', '40', '50', '60'];
  }

  public orderNo(order: UnifiedOrderDoc): string {
    const displayNo = order.displayNo ? ` [접수번호: ${order.displayNo}]` : '';
    return `${order.orderNo}${displayNo}`;
  }

  public showUserTel(order: UnifiedOrderDoc) {
    return true;
  }

  public onDemandUserTel?(order: UnifiedOrderDoc): boolean {
    return true;
  }

  /**
   * 쿠팡이츠 주문 고객 또는 쿠리어의 안심번호를 요청한다.
   */
  public async getSafeNumber(order: UnifiedOrderDoc, target: 'customer' | 'rider'): Promise<{ result: 'success' | 'error' | 'warning', reason?: string, data?: string }> {
    const diff = diffTime(order.orderDate, Date.now());
    // 쿠팡이츠의 안심번호는 2시간이 지나면 얻을 수 없다.(통신 시간을 고려하여 2분의 마진을 둔다.)
    if (diff.m > 119) {
      return { result: 'warning', reason: '기간 만료' };
    }

    try {
      const msgResponse = await this.messageService.requestGetSafeNumberCoupangeatsOrder(order.instanceNo, order.orderNo, target === 'rider' ? 'courier' : target);

      if (msgResponse.result === 'success') {
        this.logService.logOrder(order, `쿠팡이츠 주문 ${target} 안심번호 요청 성공`);
        return { result: 'success', data: msgResponse.body.safeNumber };
      } else {
        this.logService.logOrder(order, `쿠팡이츠 주문의 ${target} 안심번호 요청 중 에러 응답: ${msgResponse.reason}`, 'error');
        return { result: 'error', reason: '요청 에러' };
      }
    } catch (error) {
      this.logService.logOrder(order, `쿠팡이츠 주문의 ${target} 안심번호 요청 중 예외 발생. error: ${error.message}`, 'error');
      return { result: 'error', reason: '예외 발생' };
    }
  }
}
