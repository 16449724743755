/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

export function currencyFormatter(params: any) {
  if (params.value == null) {
    return null;
  }

  const value = parseInt(params.value, 10);
  return new Intl.NumberFormat().format(value);
}

/**
 * Safari의 new Date()는 융통성이 없다.
 *
 * 다음의 형식을 인식하지 못한다.
 * 2019-01-01 11:11:12
 * 2019-01-01 11:11:12.0
 * 2019-01-01 11:11:12.123
 * 2019-01-01T11;11:12+0900
 *
 * 사파리를 지원하기 위한 코드가 추가되었다.
 */
export function dateFormatter(date: string, dateFormat: string) {
  const mask = dateFormat;

  if (typeof date !== 'string') {
    return date;
  }

  // 2019/07/20 23:23:11.333 의 경우에 적용
  date = date.replace(/\//g, '-');

  // 1561459027
  let matches = date.match(/^(\d{10})$/);
  if (matches) {
    return format(parseInt(matches[0], 10) * 1000, mask, { locale: ko });
  }

  matches = date.match(/^(\d{13})$/);
  if (matches) {
    return format(parseInt(matches[0], 10), mask, { locale: ko });
  }

  // 2019-07-01T14:13:40+0900
  // 2019-07-01T14:13:40+09:00
  matches = date.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})\+(\d{2}):{0,1}(\d{2})$/);
  if (matches) {
    return format(new Date(`${matches[1]}T${matches[2]}+${matches[3]}:${matches[4]}`), mask, { locale: ko });
  }

  // 소수점은 무시. +0900으로 가정
  // 2019-07-01 14:13:40
  // 2019-07-01 14:13:40.0
  // 2019-07-01 14:13:40.123
  matches = date.match(/^(\d{4}-\d{2}-\d{2}).(\d{2}:\d{2}:\d{2})/);
  if (matches) {
    return format(new Date(`${matches[1]}T${matches[2]}+09:00`), mask, { locale: ko });
  }

  return date;
}

/**
 * sido(서울특별시, 경기도, 광주광역시)를 제거한다.
 */
export function addressFormatter(params: any) {
  const addr = params.value;

  if (addr == null) {
    return addr;
  }
  const match = addr.match(/^(?:서울|인천|대전|부산|대구|광주|울산|세종|경기|강원|충청|충남|충북|전라|전북|전남|경상|경북|경남|제주)\S*\s+(.+)$/);
  if (match) {
    return match[1];
  }

  return addr;
}

/**
 * - 를 삽입한다.
 * this 이슈를 피하기 위해서 util.service.ts에서 가져왔다.
 */
export function telFormatter(telNo: string) {
  if (telNo == null) {
    return '번호없음';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17]0)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}
