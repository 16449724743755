/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';

// message, log에서 사용한다.
export const instanceId = uuidv4();

export const autoOpenedOrders: Set<string> = new Set();
// 신규 주문이 자동 오픈 할수 있을지에 대한 상태 관리
export const canOpenOrder = { canOpen: true };

// 소리테스트 화면이 보이는지 여부
export const testSound = { show: false };

export function debugLog(msg: string) {
  // 디버깅용
  if (environment.production === false) {
    console.log(msg);
  }
}

export function debugDir(msg: string) {
  // 디버깅용
  if (environment.production === false) {
    console.dir(msg);
  }
}

export function stressedLog(msg: string) {
  // 디버깅용
  if (environment.production === false) {
    console.log(`%c ${msg}`, 'background: #222; color: #bada55');
  }
}

export function lineClampSwalContent(title: string, content: string) {
  Swal.fire(title, `<div class="text-wrapping">${content}</div>`);
}
