/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfigComponent } from './dialog-config.component';

@Injectable({
  providedIn: 'root'
})
export class DialogConfigService {

  constructor(
    private dialog: MatDialog
  ) { }

  openConfigDialog() {
    this.dialog.open(DialogConfigComponent, {
      maxWidth: '90vw'
    });
  }
}
