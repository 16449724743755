/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';

import { testSound } from '../../core/1/common';
import { enableTTS, isiPad } from '../../core/1/util';
import { SoundService } from '../../core/5/sound.service';

@Component({
  selector: 'app-just-touch',
  templateUrl: './just-touch.component.html',
  styleUrls: ['./just-touch.component.scss']
})
export class JustTouchComponent implements OnInit {
  private isPressed = false;
  public show = true;

  constructor(
    private soundService: SoundService
  ) {
  }

  ngOnInit() {
    // 아이패드는 터치하기 전에는 소리가 나지 않으므로 버퍼에 축적되는 것을 막는다.
    if (isiPad()) {
      this.soundService.disabled = true;
    }

    this.show = isiPad();
    testSound.show = this.show;
  }

  testSound(): void {
    this.soundService.disabled = false;

    // 버튼을 반복적으로 눌렀을 때에도 한 번만 재생하도록 한다.
    if (this.isPressed === false) {
      enableTTS();
      this.soundService.playTest();
    }
    this.isPressed = true;

    setTimeout(() => {
      this.show = false;
      testSound.show = false;
    }, 1000);
  }
}
