/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { SiteDoc } from '../../schema/3/schema';

const collectionPath = 'site';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  // minPickupOption의 기본값인 '20'에서 시작하자.
  public siteDoc: SiteDoc = {
    _id: '',
    organization: '',
    organizationName: '',
    siteName: '',
    sortKey: 0,
    deliveryParams: {
      minPickupSeconds: 1200,
      secondsPerKm: 600,
      baseDeliverySeconds: 300
    },
    deliveryBaseFee: 3800,
    showStoreCash: false,
    siteNo: 1,
    autoPilot: false,
    lat: 0,
    lng: 0,
    roomKeys: [],
    roomCount: 0
  } as SiteDoc; // 관심없는 필드는 초기화가 하지 않는다.

  latestSubject = new BehaviorSubject<SiteDoc>(this.siteDoc);
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe(site: string) {
    const doc = this.db.doc<SiteDoc>(`${collectionPath}/${site}`);

    doc.valueChanges().subscribe(siteDoc => {
      this.siteDoc = siteDoc;

      this.latestSubject.next(this.siteDoc);
    });
  }
}
