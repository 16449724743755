/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogRequestDeliveryComponent } from './dialog-request-delivery.component';

import { UnifiedDeliveryDoc, UnifiedOrderDoc } from '../../schema/3/schema';

@Injectable({
  providedIn: 'root'
})
export class DialogRequestDeliveryService {

  constructor(
    private dialog: MatDialog
  ) { }

  /**
   * @param order 관련 주문
   * @param mainDelivery deliveryVendor 필드가 반드시 있어야한다.
   */
  openDialogRequestDelivery(order: UnifiedOrderDoc, mainDelivery: Partial<UnifiedDeliveryDoc>) {
    return this.dialog.open(DialogRequestDeliveryComponent, {
      disableClose: true,
      maxWidth: '100vw',
      data: {
        order,
        mainDelivery
      }
    });
  }
}
