/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { NEVER, race, Subject } from 'rxjs';
import { filter, take, timeout } from 'rxjs/operators';

import { InAppBrowserMessageRequestBody, InAppBrowserMessageResponse, IonicInfo } from '../../schema/3/schema-ops';

import { debugLog } from '../1/common';
import { LogService } from '../4/log.service';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserMessageService {
  public get webkit() {
    return (window as any).webkit;
  }
  public IonicInfo: IonicInfo;

  private messageResultSubject: Subject<{id: string} & InAppBrowserMessageResponse>;
  private showPercentDialog = false;

  constructor(
    private logService: LogService
  ) {
    // toe-pos에서 요청에 대한 응답을 toe-app-ceo로 전달할 때 사용한다.
    this.messageResultSubject = new Subject();
    (window as any).inAppBrowserMessageResult = (id: string, result: 'success' | 'error', reason = 'OK' ) => {
      this.messageResultSubject.next({ id, result, reason });
    };

    // toe-pos에서 자신의 web빌드 버전을 toe-app-ceo로 전달할 때 사용한다.
    (window as any).setIonicInfo = (info: string) => {
      try {
        const ionicInfo = JSON.parse(info) as IonicInfo;
        this.IonicInfo = ionicInfo;
      } catch (error) {
        this.logService.logRoom(`setIonicInfo의 인자로 받은 값이 object가 아닌것 같습니다. error: ${error}`, 'error');
      }
    };

    // toe-pos에서 ceo 사용자에게 업데이트 상황을 알려줄 때 사용한다.
    (window as any).updateMessage = (isUpdate: boolean, percentDone?: number) => {
      if (isUpdate) {
        // 업데이트 중이며 dialog를 아직 띄우지 않았다면 새로 만들어준다.
        if (this.showPercentDialog === false) {
          Swal.fire({
            title: '앱을 업데이트하는 중입니다.',
            html: '진행중: <b></b>%',
            didOpen: () => {
              Swal.showLoading();
            },
            backdrop: false
          });
          this.showPercentDialog = true;
        } else {
          // 이미 dialog가 있다면 현재 다운로드 진행률을 화면에 표시해준다.
          const b = Swal.getHtmlContainer().querySelector('b');
          b.textContent = String(percentDone);
        }
      } else {
        // isUpdate false인 호출의 경우는 에러 상황이다.
        Swal.close();
        this.showPercentDialog = false;
      }
    };
  }

  /**
   * toe-pos로 요청을 보낸다. ex) printOrder
   */
  public async postMessage<N extends keyof InAppBrowserMessageRequestBody>(body: { [key: string]: InAppBrowserMessageRequestBody[N] }): Promise<string> {
    if (this.webkit === undefined) {
      return '출력가능한 환경(InAppBrowser)이 아닙니다.';
    }

    const uuid = uuidv4();
    const message = {id: uuid, body};
    this.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));

    return this.observeMessageResultWithTimeout(uuid);
  }

  public observeMessageResultWithTimeout = async (id: string, msec = 10000) => {
    return new Promise<string>((resolve, reject) => {
      const resultOb = this.messageResultSubject.pipe(
        /** 같은 id를 가진 요청과 응답만을 연결한다. */
        filter(result => id === result.id),
        take(1)
      );
      const timeoutOb = NEVER.pipe(timeout(msec));
      race(resultOb, timeoutOb).subscribe(result => {
        resolve(result.reason);
      }, error => {
        // timeout인 경우에는 다음의 형식을 리턴
        // {
        //   message: "Timeout has occurred"
        //   name: "TimeoutError"
        //   stack: "
        // }
        if (error.name === 'TimeoutError') {
          error.message = `응답 대기 시간이 ${msec / 1000}초를 초과했습니다.`;
        } else {
          console.error(error);
        }
        reject(error);
      }, () => {
        debugLog('observeMessageResultWithTimeout complete');
      });
    });
  }
}
