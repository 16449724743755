/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Observable, BehaviorSubject } from 'rxjs';
import firebase from 'firebase/app';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import { debugLog } from '../1/common';
import { DebugAuthService } from '../1/debug-auth.service';


/*
  아래는 console에 표시된 경고
  It looks like you're using the development build of the Firebase JS SDK.
  When deploying Firebase apps to production, it is advisable to only import
  the individual SDK components you intend to use.
  For the module builds, these are available in the following manner
  (replace <PACKAGE> with the name of a component - i.e. auth, database, etc):

  CommonJS Modules:  (이것도 typescript에서 가능)
  const firebase = require('firebase/app');
  require('firebase/<PACKAGE>');

  ES Modules:
  import firebase from 'firebase/app';
  import 'firebase/<PACKAGE>';

  typescript에서는 다음과 같이 하면 되는 듯 한데....
  import * as firebase from 'firebase/app');
*/

/**
 * refer: https://firebase.google.com/docs/reference/js/firebase.auth
 *
 * @export
 */

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedInSubject = new BehaviorSubject<boolean>(null);
  // loggedInSubject = new Subject<boolean>();
  user: firebase.User = null;

  constructor(
    private afAuth: AngularFireAuth,
    private debugAuthService: DebugAuthService
  ) {
    // debugLog(firebase.auth.EmailAuthProvider.PROVIDER_ID);                   // password
    // debugLog(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD); // password
    // debugLog(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD);     // emailLink

    this.afAuth.onAuthStateChanged(user => {
      this.user = user;
      if (user) {
        debugLog(`onAuthStateChanged logged in`);
        debugLog(`@AuthService email = ${user.email}`);
        this.loggedInSubject.next(true);

        // debugFirebaseService.logUser(user);
      } else {
        debugLog('onAuthStateChanged not logged in');

        this.loggedInSubject.next(false);
      }
    });
  }

  signupWithEmailAndPassword(email: string, password: string ) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
    .then((userCredential: firebase.auth.UserCredential) => {
      debugLog('signup OK:');
      this.debugAuthService.logUserCredential(userCredential);
      return true;
    }, error => {
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
      console.error('signup FAIL:');
      console.dir(error);
      return Promise.reject(error);
    });
  }

  signinWithEmailAndPassword(email: string, password: string ) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((userCredential: firebase.auth.UserCredential) => {
      debugLog('signin OK:');
      this.debugAuthService.logUserCredential(userCredential);
      return true;
    }, error => {
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
      console.error('signin FAIL:');
      console.dir(error);
      return Promise.reject(error);
    });
  }

  signOut() {
    return this.afAuth.signOut();
  }

  async updatePassword(password: string) {
    // 성공하기 위해서는 최근 로그인을 해야 한다.
    // 로그인을 한 상태에서 오래 이용하다가 비밀번호 변경을 할 수는 없다.
    // 재 로그인을 유도해야 한다.
    return (await this.afAuth.currentUser).updatePassword(password);
  }

  observeLoggedIn(): Observable<boolean> {
    return this.loggedInSubject.asObservable();
  }
}
