<h3 *ngIf="myVersion != latestVersion"><span class="red">1분 후 새 버전으로 자동 업데이트됩니다.</span><span class="version">(현재 버전:{{myVersion}}, 최신 버전:{{latestVersion}})</span></h3>
<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <div *ngFor="let navItem of navItems">
        <a *ngIf="navItem.route != '/auth/signup' || loggedIn == false"
          mat-list-item
          (click)="sidenav.close()"
          routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [attr.tabindex]="routerLinkActiveInstance.isActive ? 0 : -1"
          [routerLink]="[navItem.route]"
          disabled="true">
          {{navItem.name}}
        </a>
      </div>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="sidenav.close(); openSwalPlatformTel()">고객센터 연결방법</a>
      <a mat-list-item (click)="sidenav.close(); openPrinterManual()">빌프린터 장애 매뉴얼</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="manualReload()">재시작</a>
      <mat-divider *ngIf="unifiedDeliveryShops.length > 0"></mat-divider>
      <a *ngIf="unifiedDeliveryShops.length > 0" mat-list-item (click)="sidenav.close(); openUnifiedDeliveryShop()">충전잔액</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="sidenav.close(); openConfigDialog()">설정 변경</a>
      <a mat-list-item (click)="sidenav.close(); printerInfo()">프린터 설정</a>
      <a mat-list-item (click)="sidenav.close(); openBaeminBlockDialog()">배민 임시중지...</a>
      <a *ngIf="userService.user && ['admin', 'operator'].includes(userService.user.role)" mat-list-item (click)="sidenav.close(); roomSelector.show()">호실 변경</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="sidenav.close(); openOrderStatsDialog()">통계 인쇄</a>
      <a mat-list-item *ngIf="myVersion != latestVersion" (click)="manualUpdate()">수동 업데이트</a>
      <a mat-list-item (click)="playSound()">소리테스트</a>
      <a mat-list-item (click)="stopSound()">소리끄기</a>
      <mat-divider></mat-divider>
      <a mat-list-item (click)="sidenav.close(); info()">정보</a>

      <div [ngClass]="userService.user && ['admin', 'operator'].includes(userService.user.role) ? 'faraway-update-room' : 'faraway'"></div>

      <mat-divider></mat-divider>
      <a mat-list-item (click)="logout()">로그아웃</a>
      <mat-divider></mat-divider>
    </mat-nav-list>
  </mat-sidenav>
  <div>
    <mat-toolbar color="primary">
      <button [disableRipple]="true" mat-icon-button (click)="sidenav.open('mouse')">
        <mat-icon>menu</mat-icon>
      </button>
      <!-- <span class="version">(현재 버전:{{myVersion}}<span *ngIf="myVersion != latestVersion">, 최신 버전:{{latestVersion}}</span>) -->
      <h1>
        <span class="title">{{title}}</span>

        <!-- 1. 충전잔액 -->
        <ng-container *ngIf="showStoreCash">
          <span *ngFor="let shop of unifiedDeliveryShops" class="storeCash"
            [class.pickupGreen]="shop.deposit >= 100000"
            [class.pickupYellow]="shop.deposit > 10000 && shop.deposit < 100000"
            [class.pickupRed]="shop.deposit <= 10000"
            (click)="openUnifiedDeliveryShop()"
            >충전 : {{ shop.deposit | number }}원
          </span>
        </ng-container>

        <!-- 2. 최소 픽업 시간 -->
        <span *ngIf="mainDeliveryVendor" class="minPickup"
          [class.pickupRed]="deliveryInfo?.pickups === undefined || deliveryInfo?.pickups[0] > 30"
          [class.pickupYellow]="deliveryInfo?.pickups && deliveryInfo?.pickups[0] > 20 && deliveryInfo?.pickups[0] <= 30"
          [class.pickupGreen]="deliveryInfo?.pickups && deliveryInfo?.pickups[0] <= 20"
          (click)="openPickupTime()"
          >픽업 : {{ (deliveryInfo?.pickups && deliveryInfo?.pickups[0] !== undefined) ? deliveryInfo.pickups[0] : '-' }}분
        </span>

        <!-- 3. 리뷰인쇄 -->
        <span class="printReview"  [class.noPrintReview]="!printReview" (click)="openConfigDialog()">리뷰인쇄{{ printReview ? '' : 'X'}}</span>

        <!-- 4. 쿠팡 자동 인쇄 -->
        <span *ngIf="coupangeatsAutoPrint" class="autoPrint" (click)="openConfigDialog()">쿠팡자동인쇄</span>

        <!-- 5. 수동 인쇄 -->
        <span *ngIf="showNoPrint" class="noPrint" (click)="onNoPrint()">수동인쇄</span>
      </h1>
      <div class="right">
        <button [disableRipple]="true" mat-raised-button color="accent" (click)="openBaeminBlockDialog()">배민 임시중지...</button>
        <button [disableRipple]="true" mat-stroked-button color="primary" style="background-color: white;" (click)="createOrder()">주문 입력</button>
        <!-- <button mat-raised-button (click)="playSound()">소리테스트</button> -->
        <!-- <button mat-raised-button (click)="stopSound()">소리끄기</button> -->
        <!-- <button *ngIf="loggedIn == true" mat-raised-button (click)="logout()">로그아웃 </button> -->
        <!-- <button *ngIf="loggedIn == false" mat-raised-button (click)="login()">로그인 </button> -->
        <button mat-raised-button *ngIf="myVersion != latestVersion" color="accent" (click)="manualUpdate()">수동 업데이트</button>
      </div>
    </mat-toolbar>

    <!-- 인앱 환경에서는 표시하지 않는다. -->
    <app-just-touch *ngIf="loggedIn && inAppBrowserMessageService.webkit === undefined"></app-just-touch>
    <app-simple-notice></app-simple-notice>

    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>

<app-swal-room-select #roomSelector (confirm)="updateRoom($event)"></app-swal-room-select>
