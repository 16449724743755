/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dialog-iframe',
  templateUrl: './dialog-iframe.component.html',
  styleUrls: ['./dialog-iframe.component.scss']
})
export class DialogIframeComponent implements OnInit {

  public url: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<DialogIframeComponent>,
    private sanitizer: DomSanitizer,
  ) {
    const project = environment.production ? '' : '-dev';
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://toe-page${project}.web.app/gk/printer/?ts=${Date.now()}`);
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef?.close();
  }
}
