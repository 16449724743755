/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { environment } from '../../../environments/environment';

const urlPrefix = `//dapi.kakao.com/v2/local/search/keyword.json`;
/**
 * Kakao 키워드로 주소 검색
 * @link https://developers.kakao.com/docs/latest/ko/local/dev-guide#search-by-keyword
 */
export async function kakao_keyword_address_search(
  keyword: string,
  option: {
    /** lng */
    x: number,
    /** lat */
    y: number,
    /** 검색 반경 - 유효값: 0 ~ 20000(20km) */
    radius: number
  },
  page?: number
) {
  const { x, y, radius } = option;
  const requestInit: RequestInit = {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      Authorization: `KakaoAK ${environment.kakaoAPIKey}`
    }
  };

  return fetch(`${urlPrefix}?y=${y}&x=${x}&radius=${radius}&query=${keyword}${page ? `&page=${page}` : '' }`, requestInit);
}
