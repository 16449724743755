<h1>배차 요청</h1>

<div class="container">
  <div class="delivery-info">
    <div class="left section">
      <div class="section-title">배달 대행사</div>
      <div>
        <mat-form-field>
          <mat-select [(value)]="deliveryVendor" (selectionChange)="onDeliveryVendorChanged($event)" >
            <mat-option *ngIf="deliveryVendors.length === 0" [value]="'none'">---</mat-option>
            <mat-option [value]="deliveryVendor" *ngFor="let deliveryVendor of deliveryVendors">{{ deliveryVendorMappings[deliveryVendor] }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="section-title">배달비</div>
      <div class="delivery-fee">
        <!-- 위치가 살짝 바뀌는 것을 방지하기 위해서 visibility를 이용해서 일단 자리는 차지한다. -->
        <span [class.hide]="deliveryInfo.totalFee === 0" *ngIf="deliveryInfo.extraAvailable">기본 {{ deliveryInfo.baseFee | number }}원 + 할증 {{ (deliveryInfo.totalFee - deliveryInfo.baseFee) | number }}원 
        <span class="vat">(VAT 별도)</span> / {{ deliveryInfo.distance | number:'1.0-0' }}m</span>
        <span [class.hide]="deliveryInfo.totalFee === 0" *ngIf="!deliveryInfo.extraAvailable">전체 {{ deliveryInfo.totalFee | number }}원 
        <span class="vat">(할증 포함, VAT 별도)</span> / {{ deliveryInfo.distance | number:'1.0-0' }}m</span>
      </div>

    </div>
    <div class="right section">
      <div class="section-title">결제 방법</div>
      <mat-button-toggle-group (change)="onChangePaymentMethod($event)" [value]="paymentMethod">
        <mat-button-toggle value="선불">선불</mat-button-toggle>
        <mat-button-toggle value="후불현금">후불현금</mat-button-toggle>
        <mat-button-toggle value="후불카드">후불카드</mat-button-toggle>
      </mat-button-toggle-group>

      <div class="section-title">결제 금액</div>
      <div class="payment-amount-input">
        <input matInput class="input" inputmode="decimal" [formControl]="formControlPaymentAmount" required>
        <button class="button-prepaid-zero" mat-stroked-button (click)="onClickPrePaidZero()">선불 0원</button> 
      </div>
    </div>
  </div>
  <div class="section">
    <!-- [order]는 가장 앞에 위치해야 한다. -->
    <app-select-minute [order]="data.order" [showItems]="['requestedPickupMinutes']" [requestDeliveryInformationPickups]="deliveryInfo.pickups"
    (changeRequestedPickupMinutes)="onChangeRequestedPickupMinutes($event)"></app-select-minute>
  </div>
  <div class="customer-info section">
    <app-customer [address]="address" [userTel]="userTel" [orderMsg]="orderMsg" [prefixMsg]="prefixMsg"
    (addressEmitter)="onChangeAddress($event)" (userTelEmitter)="onChangeUserTel($event)" (orderMsgEmitter)="onChangeOrderMsg($event)"
    ></app-customer>

    <div class="section-title">라이더에게 전달하는 메시지<span class="accent-text"> (상세주소 앞에 메시지가 추가됩니다.)</span></div>
    <div class="rider-message">
      <mat-selection-list (selectionChange)="onChangePrefix($event)" [disableRipple]="true">
        <mat-list-option *ngIf="this.riderName !== ''" [checkboxPosition]="'before'" [value]="'과적['+deliveryVendorMappings[data?.mainDelivery?.deliveryVendor]+'-'+this.riderName+']'">과적[{{ deliveryVendorMappings[data?.mainDelivery?.deliveryVendor] }}-{{this.riderName}}]</mat-list-option>
        <mat-list-option [checkboxPosition]="'before'" [value]="'과적'">과적</mat-list-option>
        <mat-list-option [checkboxPosition]="'before'" [value]="'긴급누락건'">긴급누락건</mat-list-option>
        <mat-list-option [checkboxPosition]="'before'" [value]="'조리완료'">조리완료</mat-list-option>
      </mat-selection-list>
      <input class="input" matInput [formControl]="formControlPrefixMsg" placeholder="기타" required>
    </div>
  </div>

</div>

<div class="buttons">
  <button class="button-close" mat-stroked-button (click)="onClose()">닫기</button>
  <button class="button-request" mat-flat-button (click)="submitDelivery()" [disabled]="buttonPressed.REQUEST">배차 요청</button>
</div>
