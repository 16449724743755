/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogNoticeComponent } from './dialog-notice.component';

@Injectable({
  providedIn: 'root'
})
export class DialogNoticeService {

  constructor(
    private dialog: MatDialog
  ) { }

  openSimpleNoticeDialog(msg: string, msg2: string = null, timeout?: number, closeAll: boolean = true) {
    if (closeAll) {
      // 다른 다이얼로그는 닫는다.
      this.dialog.closeAll();
    }

    const dialogRef = this.dialog.open(DialogNoticeComponent, {
      data: {
        msg,
        msg2
      }
    });

    if (timeout !== undefined) {
      setTimeout(() => {
        dialogRef.close();
      }, timeout);
    }
  }

  /**
   * 다이얼로그를 닫으면 지정한 goto URL로 페이지를 이동한다.
   */
  openGotoNoticeDialog(goto, msg) {
    // 다른 다이얼로그는 닫는다.
    this.dialog.closeAll();
    this.dialog.open(DialogNoticeComponent, {
      data: {
        goto,
        msg
      }
    });
  }
}
