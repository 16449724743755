/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import Swal from 'sweetalert2';

import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { KakaoKeywordAddressDocument, KakaoKeywordSearchAddress } from '../../schema/1/schema-kakao-api';
import { AugmentedAddress } from '../../schema/1/schema-common';
import { SiteDoc } from '../../schema/3/schema';

import { kakao_keyword_address_search } from '../../core/1/kakao-address-search-api';
import { RoomService } from '../../core/1/room.service';
import { SiteService } from '../../core/1/site.service';
import { LogService } from '../../core/4/log.service';
import { AddressService } from '../../core/5/address.service';
import { DeliveryUtilService } from '../../core/5/delivery-util.service';

import { DialogSpinnerComponent } from '../dialog-spinner/dialog-spinner.component';
import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';

const kakao = (window as any).kakao;

export interface DialogSearchAddressData {
  address_key: string;
  address_road?: string;

  // 아래의 내용이 존재한다면 augmentedAddress의 성공으로 볼 수 있다.
  address_sido?: string;
  address_sigungu?: string;
  address_dong?: string;
  address_jibun?: string;
  address_detail?: string;
}
@Component({
  selector: 'app-dialog-search-address',
  templateUrl: './dialog-search-address.component.html',
  styleUrls: ['./dialog-search-address.component.scss']
})
export class DialogSearchAddressComponent implements OnInit {
  public title = '주소 입력';
  public rawAddress: string;
  /** augmentedAddress에서 '시군구 + 동 + 지번'을 합쳐놓은 주소(질의 전 내용인 rawAdress와 구분된다.) */
  public augmentedAddressJibun: string;
  /** 모달의 최종 response */
  public augmentedAddress: AugmentedAddress;
  /** 상세 주소 */
  public addressDetailForm: FormControl;
  public showMap = false;

  /** 번지 주소 탭 */
  public numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  public bunjiSearchForm: FormGroup;
  public dongDB: SiteDoc['dongDB'];
  public deliveryDistance: number;

  /** 검색으로 찾기 탭 */
  public keywordSearchForm: FormGroup;
  public documents: KakaoKeywordAddressDocument[];
  public pagination = {
    pageNumber: 1,
    pageLength: 1,
    pageableCount: 0,
    /** 한 페이지에 표시할 주소의 최대 갯수 */
    size: 15,
    isEnd: false
  };
  /** 키워드 주소검색 검색 반경 - 유효값: 0 ~ 20000(20km) */
  public radius = 20 * 1000;

  @ViewChild('keywordSearchResult') private keywordSearchResultRef: ElementRef<HTMLDivElement>;
  @ViewChild('kakaoMap') private kakaoMapRef: ElementRef<HTMLDivElement>;
  private selectedIndex = 0;
  private dialogSpinnerRef: MatDialogRef<DialogSpinnerComponent>;

  constructor(
    private dialogRef: MatDialogRef<DialogSearchAddressComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { addressData: DialogSearchAddressData },
    private fb: FormBuilder,
    private logService: LogService,
    private siteService: SiteService,
    private roomService: RoomService,
    private addressService: AddressService,
    private deliveryUtilService: DeliveryUtilService,
    private dialogSpinnerService: DialogSpinnerService,
  ) { }

  ngOnInit(): void {
    this.dongDB = this.siteService.siteDoc.dongDB;
    this.initAddressForm();
    this.initKeywordSearchAddressForm();
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public onSelectedIndexChange(index: number) {
    this.selectedIndex = index;
  }

  /** 입력한 위치를 지도로 보여준다. */
  public showKakaoMap(lat: number, lng: number) {
    this.showMap = true;

    // 지도 팝업창이 'display: block'으로 dom에 붙은 후 맵을 그리도록한다.
    setTimeout(() => {
      const options = {
        center: new kakao.maps.LatLng(lat, lng),
        level: 3
      };
      const map = new kakao.maps.Map(this.kakaoMapRef.nativeElement, options);

      // 지도에 확대 축소 컨트롤을 생성한다
      const zoomControl = new kakao.maps.ZoomControl();
      // 지도의 우측에 확대 축소 컨트롤을 추가한다
      map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

      // 마커를 생성한다
      const markerPosition = new kakao.maps.LatLng(lat, lng);
      const marker = new kakao.maps.Marker({
        position: markerPosition
      });

      // 마커가 지도 위에 표시되도록 설정
      marker.setMap(map);
    }, 0);
  }

  public closeKaKaoMap() {
    this.showMap = false;
  }

  /***************************************************************
   * 번지 주소 입력 탭
   ***************************************************************/
  public onSelectDong(sido: string, sigungu: string, dong: string) {
    this.bunjiSearchForm.get('address_sido').setValue(sido);
    this.bunjiSearchForm.get('address_sigungu').setValue(sigungu);
    this.bunjiSearchForm.get('address_dong').setValue(dong);
  }

  public async bunjiAugmentAddress() {
    this.showMap = false;

    this.dialogSpinnerRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');

    const values = this.bunjiSearchForm.value;
    const { address_sido, address_sigungu, address_dong, address_jibun } = values;
    const rawAddress = `${address_sido} ${address_sigungu} ${address_dong} ${address_jibun}`;

    try {
      const { result, augmentedAddress, usedRawAddress } = await this.addressService.augmentAddress(rawAddress);
      this.rawAddress = usedRawAddress;

      if (result === 'success') {
        this.augmentedAddress = augmentedAddress;
        this.augmentedAddressJibun = `${augmentedAddress.sigungu ?? ''} ${augmentedAddress.dong ?? ''} ${augmentedAddress.jibun ?? ''}`;
        this.deliveryDistance = (augmentedAddress.location?.lat > 30 && augmentedAddress.location.lon > 120)
          ? this.deliveryUtilService.calcDeliveryDistance(augmentedAddress.location.lat, augmentedAddress.location.lon)
          : undefined;
      } else {
        this.alertAddressError();
      }
    } catch (error) {
      this.logService.withToastrError(`주소 확인에 실패했습니다. 계속 발생할 경우 문의주세요. error: ${error}`);
    }

    this.dialogSpinnerRef?.close(false);
    this.dialogSpinnerRef = undefined;
  }

  /***************************************************************
   * 검색으로 찾기 탭
   ***************************************************************/
  public initKeywordSearchAddressForm() {
    this.documents = undefined;
    this.pagination = {
      pageNumber: 1,
      pageLength: 1,
      pageableCount: 0,
      size: 15,
      isEnd: false
    };

    this.keywordSearchForm = this.fb.group({
      keyword: ''
    }, { validators: this.formValidator() });
  }

  /** 모달을 닫고 입력된 주소를 반환한다.  */
  public submitAugmentedAddress() {
    // 아무것도 입력하지 않고 'enter'를 눌러서 submit하는 경우를 방지한다.
    if (this.addressDetailForm.value.length > 0) {
      this.dialogRef.close({
        augmentedAddress: this.augmentedAddress,
        addressDetail: this.addressDetailForm.value
      });
    }
  }

  /** 카카오 키워드 검색 요청 */
  public async keywordSearch(page?: number) {
    const fnName = 'keywordSearch';
    const roomDoc = this.roomService.room;
    const siteDoc = this.siteService.siteDoc;

    const roomLat = roomDoc.lat ? roomDoc.lat : siteDoc.lat;
    const roomLng = roomDoc.lng ? roomDoc.lng : siteDoc.lng;

    const keyword = this.keywordSearchForm.get('keyword').value;
    if (keyword.length < 1) {
      return;
    }

    if (page === undefined) {
      this.pagination.pageNumber = 1;
    }

    this.dialogSpinnerRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');

    try {
      const response = await kakao_keyword_address_search(keyword, {
        x: roomLng,
        y: roomLat,
        radius: this.radius
      }, page);
      const result = await response.json() as KakaoKeywordSearchAddress;
      this.documents = result.documents;

      // pagination에 필요한 정보를 설정한다.
      this.pagination.pageableCount = result.meta.pageable_count;
      this.pagination.pageLength = Math.ceil(this.pagination.pageableCount > this.pagination.size
        ? this.pagination.pageableCount / this.pagination.size
        : 1
      );
      this.pagination.isEnd = result.meta.is_end;

      // dom 생성후 스크롤을 해야한다.
      setTimeout(() => this.keywordSearchResultRef?.nativeElement.scrollTo(0, 0), 100);
    } catch (error) {
      // 네트워크 에러인 경우
      this.logService.withToastrError(`[${fnName}] 주소 찾기 실패 : ${error}`);
    }

    this.dialogSpinnerRef?.close(false);
    this.dialogSpinnerRef = undefined;
  }

  /** 키워드 검색 결과내 페이지 이동 */
  public async paginate(direction: 'prev' | 'next') {
    let pageNumber = this.pagination.pageNumber;
    if (direction === 'prev' && pageNumber > 1) {
      --pageNumber;
    } else if (pageNumber < this.pagination.pageLength) {
      ++pageNumber;
    }

    if (pageNumber === this.pagination.pageNumber) {
      return;
    } else {
      this.pagination.pageNumber = pageNumber;
      await this.keywordSearch(pageNumber);
      this.keywordSearchResultRef.nativeElement.scrollTo(0, 0);
    }
  }

  /** 카카오 keyword search의 결과 리스트에서 사용자가 선택한 주소로 agumentAddress를 요청한다 */
  public async pickAddress(document: KakaoKeywordAddressDocument) {
    const fnName = 'pickAddress';

    // UI
    this.documents.forEach(doc => doc._selected = false);
    document._selected = true;

    const { road_address_name, address_name, place_name = '', distance = '0' } = document;

    try {
      this.dialogSpinnerRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');

      const { result, augmentedAddress, usedRawAddress } = await this.addressService.augmentAddress(address_name, road_address_name);
      this.rawAddress = usedRawAddress;

      if (result === 'success') {
        this.augmentedAddress = augmentedAddress;
        this.augmentedAddressJibun = `${augmentedAddress.sigungu ?? ''} ${augmentedAddress.dong ?? ''} ${augmentedAddress.jibun ?? ''}`;
        this.addressDetailForm.setValue(place_name);
        this.deliveryDistance = Number(distance);
      } else {
        this.alertAddressError();
      }
    } catch (error) {
      // 카카오 keyword search의 결과로 augmentAddress에 실패한 경우
      this.logService.withToastrCatch(error, `[${fnName}] 주소 변환 실패 : 사용할 수 없는 주소입니다. rawAddress: ${this.rawAddress}`);
    }

    this.dialogSpinnerRef?.close(false);
    this.dialogSpinnerRef = undefined;
  }

  /** 커스텀 다이얼로그의 이벤트를 다룬다 */
  public onChangeDials(value: string, event: any) {
    event.preventDefault();

    let bunji = this.bunjiSearchForm.get('address_jibun').value;
    bunji = value === 'backspace' ? bunji.slice(0, -1) : (bunji + value);

    this.bunjiSearchForm.get('address_jibun').setValue(bunji);
  }

  public clearBunji() {
    this.bunjiSearchForm.get('address_jibun').setValue('');
  }

  /***************************************************************
   * Private
   ***************************************************************/
  private async initAddressForm() {
    const { addressData } = this.data;

    // '서울특별시'를 초기값의 우선순위로 둔다.
    const addressSido = Object.keys(this.dongDB).includes('서울특별시') ? '서울특별시' : Object.keys(this.dongDB)[0];
    // 나머지 '시군구'와 '동'은 가나다 순의 첫 번째 값을 초기값으로 둔다.
    const addressSigungu = Object.keys(this.dongDB[addressSido]).sort((a, b) => a < b ? -1 : a > b ? 1 : 0)[0];
    const addressDong = Object.keys(this.dongDB[addressSido][addressSigungu]).sort((a, b) => a < b ? -1 : a > b ? 1 : 0)[0];

    this.bunjiSearchForm = this.fb.group({
      address_sido: addressSido,
      address_sigungu: addressSigungu,
      address_dong: addressDong,
      address_jibun: ['', this.formGroupControlValidator('address_jibun')],
    }, { validators: this.formValidator() });

    // 넘겨받은 주소 정보가 있다면 반영한다. (이미 있는 주소를 변경해야하는 경우)
    this.addressDetailForm = new FormControl(
      { value: addressData?.address_detail ?? '', disabled: false },
      this.formControlValidator()
    );

    if (addressData) {
      // UI에 표기되는 지번 정보를 넘겨받은 주소로 초기화한다.
      if (addressData.address_sigungu && addressData.address_dong && addressData.address_jibun) {
        this.augmentedAddressJibun = `${addressData.address_sigungu} ${addressData.address_dong} ${addressData.address_jibun}`;
      } else {
        this.augmentedAddressJibun = addressData.address_key;
      }

      this.dialogSpinnerRef = this.dialogSpinnerService.openSpinnerDialog('응답 대기 중');
      const rawAddress = addressData.address_key;
      const { augmentedAddress, result, usedRawAddress } = await this.addressService.augmentAddress(rawAddress, addressData.address_road);
      this.rawAddress = usedRawAddress;

      if (result === 'success') {
        this.augmentedAddress = augmentedAddress;
        const { sido, sigungu, dong, jibun } = this.augmentedAddress;

        this.bunjiSearchForm.get('address_sido').setValue(sido);
        this.bunjiSearchForm.get('address_sigungu').setValue(sigungu);
        this.bunjiSearchForm.get('address_dong').setValue(dong);
        this.bunjiSearchForm.get('address_jibun').setValue(jibun);

        this.augmentedAddressJibun = `${augmentedAddress.sigungu ?? ''} ${augmentedAddress.dong ?? ''} ${augmentedAddress.jibun ?? ''}`;
        this.deliveryDistance = (augmentedAddress.location?.lat > 30 && augmentedAddress.location.lon > 120)
          ? this.deliveryUtilService.calcDeliveryDistance(augmentedAddress.location.lat, augmentedAddress.location.lon)
          : undefined;
      } else {
        this.alertAddressError();
      }

      this.dialogSpinnerRef?.close(false);
      this.dialogSpinnerRef = undefined;
    }
  }

  private formControlValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const { value } = control;

      return (value.length === 0) ? { reason: '필수 항목입니다.' } : null;
    };
  }

  private formGroupControlValidator(field?: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // 초기에 undefined로 되는 경우가 있다.
      if (control.parent === null) {
        return null;
      }

      const { value } = control;
      const form = control.parent;
      if (value.length === 0) {
        return { reason: '필수 항목입니다.' };
      } else {
        if (field && form.get(field).errors?.pattern) {
          return { reason: '형식이 맞지 않습니다!' };
        }
      }

      return null;
    };
  }

  /** 모든 control이 변경될 때마다 호출된다. */
  private formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const addressForm = control;
      if (addressForm && this.selectedIndex === 0) {
        // onlySelf를 false로 하면 무한반복한다.
        addressForm.get('address_jibun')?.updateValueAndValidity({ onlySelf: true });
      }

      return null;
    };
  }

  private async alertAddressError() {
    await Swal.fire({
      icon: 'error',
      title: '유효한 주소가 아닙니다.',
      text: this.rawAddress,
      footer: `
        <p>
          - '번지 주소 입력'에서 번지를 입력하거나<br>
          - '검색으로 찾기'에서 도로명 등으로 검색을 해 보세요.
        </p>
      `
    });
  }
}
