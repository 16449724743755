/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';

const prefix = 'toePos';

interface LocalStorage {
  printAlarm: 'on' | 'off';
  instanceId: string;
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public storage: Storage;

  public available = false;

  constructor(
  ) {
    this.storage = window.localStorage;
    this.available = this.checkAvailable();
  }

  public isExist(key: keyof LocalStorage) {
    return this.storage.getItem(`${prefix}${key}`)?.length > 0;
  }

  public getValue(key: keyof LocalStorage) {
    const value = this.storage.getItem(`${prefix}${key}`);
    if (value === null) {
      return undefined;
    }

    return JSON.parse(value);
  }

  public setItem(key: keyof LocalStorage, value: LocalStorage[keyof LocalStorage]) {
    try {
      this.storage.setItem(`${prefix}${key}`, JSON.stringify(value));
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  }

  public removeItem(key: keyof LocalStorage) {
    this.storage.removeItem(`${prefix}${key}`);
  }

  public clearStorage() {
    this.storage.clear();
  }

  private checkAvailable() {
    try {
      const test = '__storage_test__';
      this.storage.setItem(test, test);
      this.storage.removeItem(test);

      return true;
    } catch (error) {
      console.error(error);
      // error instanceof DOMException && (
      //   // everything except Firefox
      //   error.code === 22 ||
      //   // Firefox
      //   error.code === 1014 ||
      //   // test name field too, because code might not be present
      //   // everything except Firefox
      //   error.name === 'QuotaExceededError' ||
      //   // Firefox
      //   error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      //   (this.storage && this.storage.length !== 0);
    }

    return false;
  }
}
