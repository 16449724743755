/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input } from '@angular/core';

import { UnifiedOrderDoc } from '../../schema/3/schema';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', './menu.component.print.scss']
})
export class MenuComponent implements OnInit {
  @Input() isPrint = false;
  public order: UnifiedOrderDoc;
  @Input('order') set _order(value: UnifiedOrderDoc) {
    this.order = value;

    const eventDiscount = this.order.eventDiscount ?? 0;
    const discount = this.order.discount ?? 0;
    this.paymentAmount = this.order.orderAmount + this.order.deliveryTip - eventDiscount - discount;
  }

  @Input() showPrice = true; // 주방용에서는 제외한다.

  paymentAmount = 0;

  constructor(
  ) { }

  ngOnInit() {
  }

  postProcessFoodName(foodName: string) {
    // 2020-02-27 쿠팡이츠 주문 처리 과정에서 null인 경우를 발견했다.
    if (!foodName) {
      // console.error(`[postProcessFoodName] foodName is ${foodName}`);
      return '';
    }
    // (사이드)잡채_사이드 같은 경우는 _사이드를 제거한다.
    const neatName = foodName.replace(/(\(사이드\).+)_사이드$/, '$1');

    return neatName;
  }
}
