/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SimpleNoticeDoc } from '../../schema/3/schema';

import { SimpleNoticeService } from '../../core/1/simple-notice.service';
import { LogService } from '../../core/4/log.service';
import { SoundService } from '../../core/5/sound.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-simple-notice',
  templateUrl: './simple-notice.component.html',
  styleUrls: ['./simple-notice.component.scss']
})
export class SimpleNoticeComponent implements OnInit, OnDestroy {
  simpleNotice: SimpleNoticeDoc;
  subscription: Subscription;

  title = '';

  constructor(
    private dialogNoticeService: DialogNoticeService,
    private simpleNoticeService: SimpleNoticeService,
    private soundService: SoundService,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.subscription = this.simpleNoticeService.latestSimpleNoticesSubject.subscribe(docs => {
      if (docs.length > 0) {
        const simpleNotice = docs[0];

        this.title = `[${format(simpleNotice._timeUpdate.toDate(), 'HH:mm')}] ${simpleNotice.title}`;

        if (this.simpleNotice === undefined ||
          this.simpleNotice.title !== simpleNotice.title ||
          this.simpleNotice.desc !== simpleNotice.desc) {

          this.soundService.playNotice();
        }

        this.simpleNotice = simpleNotice;
      } else {
        this.simpleNotice = undefined;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openDesc() {
    this.logService.logRoom('공지 자세히 클릭');
    this.dialogNoticeService.openSimpleNoticeDialog(this.title, this.simpleNotice.desc);
  }
}
