/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { normalizingTel } from '../../core/1/util';

export interface DialogData {
  telNo: string;
  subTitle?: string;
}

@Component({
  selector: 'app-dialog-tel',
  templateUrl: './dialog-tel.component.html',
  styleUrls: ['./dialog-tel.component.scss']
})
export class DialogTelComponent implements OnInit {
  public telNoForm = new FormControl({ value: '', disabled: false }, this.userTelValidator());
  // public defaultTelNo = '1522-6385';
  public numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  constructor(
    public dialogRef: MatDialogRef<DialogTelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
    const telNo = this.data?.telNo ?? '';
    this.telNoForm.setValue(telNo);
  }

  // 직접 디비를 변경하지 않고 결과만 반환한다.
  // 일단 주석처리 -> 머지 후 사용안함이 확정되면 제거 예정
  public async updateTelNo() {
    const telNo = this.telNoForm.value.replace(/-/g, '');
    this.dialogRef.close(telNo);
  }

  public closeDialog() {
    // 취소시에만 불리므로 값을 주지 않는다.
    this.dialogRef.close();
  }

  public onChangeDials(value: string, event: any) {
    let telNo = this.telNoForm.value;
    event.preventDefault();

    switch (value) {
      case '010':
        telNo += '010'; break;
      case 'backspace':
        telNo = telNo.slice(0, -1); break;
      default:
        telNo += value;
        break;
    }

    this.telNoForm.setValue(telNo);
  }

  public clearTelNo() {
    this.telNoForm.setValue('');
  }

  private userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const { value } = control;

      const normalizedTel = normalizingTel(value);
      if (value !== normalizedTel) {
        this.telNoForm.setValue(normalizedTel);
      }

      if (normalizedTel && normalizedTel.length > 0) {
        // 010-1234-5678
        const pattern01 = normalizedTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        // 1522-6485
        const pattern02 = normalizedTel.match(/^(1[568][0-9][0-9]?-[0-9]{4})$/);

        if (pattern01 === null && pattern02 === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.'};
        }
      }

      return null;
    };
  }
}
