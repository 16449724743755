/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { debugLog } from '../../../core/1/common';
import { normalizeCurrency } from '../../../core/1/util';

@Component({
  selector: 'app-dialog-order-form',
  templateUrl: './dialog-order-form.component.html',
  styleUrls: ['./dialog-order-form.component.scss']
})
export class DialogOrderFormComponent implements OnInit, OnDestroy {
  public orderForm: FormGroup;
  private destroySignal: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<DialogOrderFormComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.observeCurrency(this.orderForm.get('foodPrice'));
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  initForm() {
    this.orderForm = this.fb.group({
      foodName: '',
      foodPrice: ['', this.valueValidator(1000000)],
    });
  }

  public onSubmit() {
    const value = this.orderForm.value;

    // number에서 , 제거
    value.foodPrice = parseInt(String(value.foodPrice).replace(/,/g, ''), 10);
    // 수량은 1로 생성한다. 수량 조정은 메뉴 목록의 +,-를 이용
    value.qty = 1;

    debugLog(value);

    this.dialogRef?.close(this.orderForm.value);
  }

  public onCancel() {
    this.closeDialog();
  }

  private closeDialog() {
    this.dialogRef?.close(false);
    this.dialogRef = undefined;
  }

  /**
   * 금액에 변화가 있으면 포맷을 자동 적용한다.
   */
  private observeCurrency(formControl: AbstractControl) {
    formControl.valueChanges
      .pipe(takeUntil(this.destroySignal))
      .subscribe(value => {
        const normalizedCurrency = normalizeCurrency(String(value));
        if (value !== normalizedCurrency) {
          formControl.setValue(normalizedCurrency);
        }
      });
  }

  private valueValidator(max: number): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // 초기에 null 되는 경우가 있다.
      if (control.parent === null) {
        return null;
      }

      const inputValue = control.value;
      // Input 너비가 짧아서 최대한 간결하게 적는다.
      if (inputValue.replace(/,/g, '') === '') {
        return { reason: `가격을 입력해 주세요.` };
      }

      if (parseInt(inputValue.replace(/,/g, ''), 10) > max) {
        return { reason: `최대: ${normalizeCurrency(String(max))}` };
      }

      if (parseInt(inputValue.replace(/,/g, ''), 10) < 0) {
        return { reason: '최소: 0' };
      }

      return null;
    };
  }

}
