<header>
  <section>
    <h3>고객 주문 이력</h3>
    <button mat-icon-button>
      <mat-icon (click)="onClose()">close</mat-icon>
    </button>
  </section>
  <section class="summary">
    <p>결제액: {{ summary.totalAmount| number }}원 ({{ summary.countOrders }}건 + 취소 {{ summary.countCanceledOrders }}건)</p>
    <div>
      <mat-checkbox [checked]="showCancelOrderOnly" (change)="showCancelOrderOnlyChange($event.checked)">취소 주문만 보기</mat-checkbox>
    </div>
  </section>
</header>
<div class="container">
  <table mat-table [dataSource]="rows">
    <ng-container matColumnDef="{{ column.field }}" *ngFor="let column of columnDefs" [sticky]>
      <th mat-header-cell *matHeaderCellDef>{{ column.headerName }}</th>
      <td mat-cell *matCellDef="let row">{{ row[column.field] ? row[column.field] : '' }}</td>
    </ng-container>

    <!-- columns 배열의 요소 => matColumnDef -->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" [class.canceled]="row.orderHistory.contextStatusCode === 80"></tr>
  </table>  
</div>
