/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogOrderFormComponent } from './dialog-order-form.component';

@Injectable({
  providedIn: 'root'
})
export class DialogOrderFormService {

  constructor(
    private dialog: MatDialog
  ) { }

  openDialogOptionMenu() {
    return this.dialog.open(DialogOrderFormComponent, {
    });
  }
}
