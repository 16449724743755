/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import get from 'lodash/get';
import { encode } from 'iconv-lite';
import { format } from 'date-fns';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Buffer } from 'safer-buffer';

import { WHERE } from '../../schema/1/schema-common';
import { Message, MessageBodyMap, MessagePeer } from '../../schema/2/schema-message';
import { SiteDoc, PrinterDoc, UnifiedOrderContextStatusCode, UnifiedOrderDoc } from '../../schema/3/schema';
import { BaeminUserReviewDoc } from '../../schema/3/schema-baemin';

import { EP } from '../1/escpos';
import { logger } from '../1/logger';
import { LocalStorageService } from '../1/local-storage.service';
import { RoomService } from '../1/room.service';
import { renderFoods, renderMessage, renderWhat, renderReview, renderBillToon, renderQRCode } from '../2/printer-agent';
import { UserService } from '../3/user.service';
import { LogService } from '../4/log.service';
import { InAppBrowserMessageService } from '../5/in-app-browser-message.service';
import { FirebaseManagerService } from '../5/firebase-manager.service';

import { environment } from '../../../environments/environment';

const messageCollectionPath = 'message';
const unifiedOrderCollectionPath = 'unifiedOrder';
const baeminUserReviewCollectionPath = 'baeminUserReview';

const partialCut = '\n' + EP.FEED_PARTIAL_CUT_N + '\x10'; // EPSON은 행의 시작에서만 cut 이 된다.
const prefixQRcode = environment.firebase?.projectId === 'toe-prod' ? '' : 'dev.';

@Injectable({
  providedIn: 'root'
})
export class PrinterAgentMessageService {
  public printerMessageSubscription: Subscription;

  constructor(
    private logService: LogService,
    private userService: UserService,
    private roomService: RoomService,
    private firebaseManagerService: FirebaseManagerService,
    private inAppBrowserMessageService: InAppBrowserMessageService,
    private localStorageService: LocalStorageService
  ) { }

  public async handlePrinterMessageRequest(message: Message<any, any>) {
    if (this.inAppBrowserMessageService.webkit === undefined) {
      this.logService.logRoom(`브라우저로 실행중이라서 ${message.name} 요청을 무시합니다.`, 'warn');
      return;
    }

    // printer기능이 안정적인지 모니터링하기 위함
    this.logService.logRoom(`직접출력(direct) printer message(${message.name})를 받았습니다.`);

    switch (message.name) {
      case 'printOrder':
        await this.handleMessage_printOrder(message);
        break;
      case 'printStat':
        await this.handleMessage_printStat(message);
        break;
      case 'printReview':
        if (this.localStorageService.available && this.localStorageService.getValue('printAlarm') === 'on') {
          await this.handleMessage_printReview(message);
        } else {
          this.logService.logRoom(`localStorage 설정이 off로 되어 있어서 무시합니다.`);
        }
        break;
      case 'printMessage':
        // pos에서 보낸 출력 명령(테스트 출력)은 로컬 설정에 관계없이 출력한다.
        // 그 외 functions에서 받은 출력 명령은 설정에 따른다.
        if (message.from.class === 'pos' || (this.localStorageService.available && this.localStorageService.getValue('printAlarm') === 'on')) {
          await this.handleMessage_printMessage(message);
        } else {
          this.logService.logRoom(`localStorage 설정이 off로 되어 있어서 무시합니다.`);
        }
        break;
      default:
        this.logService.logRoom(`예상하지 못한 message가 printerMessageRequest로 들어왔습니다. message.name = ${message.name}`, 'error');
        break;
    }
  }

  public requestPrintTestMessage(printer: PrinterDoc, to: MessagePeer) {
    const { room } = this.roomService;
    // 홀더 마진
    const start = EP.INIT + '\n\n\n';

    let body = '          --- 프린터 테스트 ---\n\n';
    // 소리출력
    body += '\x07\x07';
    body += `room: ${room._id}\n`;
    body += `organization: ${printer.organization}\n`;
    body += `printerKey: ${printer._id}\n`;
    body += `printFormat: ${printer.printFormat}\n`;
    body += `connectionType: ${printer.connectionType}\n`;
    body += `proxyInstanceNo: ${printer.proxyInstanceNo ?? 'N/A'}\n`;
    body += `printerAddress: ${printer.printerAddress ?? 'N/A'}\n`;

    const end = '\x10';

    const textRaw = start + body + end;

    const resMessage: Message<'request', 'printMessage'> = {
      organization: this.userService.organization,
      channel: 'message',
      from: {
        class: 'pos',
        instanceNo: room._id,
      },
      to,
      type: 'request',
      name: 'printMessage',
      body: {
        printerKey: this.userService.printer._id,
        textTitle: '테스트 출력',
        textRaw,
        beep: true,
        autoPrint: true
      }
    };

    return this.firebaseManagerService.setDoc(messageCollectionPath, undefined, resMessage, {
      addMetadata: true,
      isCreate: true
    });
  }

  /**
   * [toe-printer-agent에서 복사한 코드]
   */
  private async handleMessage_printOrder(message: Message<'request', 'printOrder'>) {
    const fnName = 'handleMessage_printOrder';

    const m = message;
    // body
    const orderId = get(m, ['body', 'orderId'], 'No orderId');
    const printerKey = get(m, ['body', 'printerKey'], 'No printerKey');
    const what = get(m, ['body', 'what'], 'No what') as any;
    const beep = get(m, ['body', 'beep'], false);
    const autoPrint = get(m, ['body', 'autoPrint'], false);
    const printCookOption = get(m, ['body', 'printCookOption'], 'normal');

    const resMessage: Partial<Message<'response', 'printOrder'>> = {
      type: 'response',
      name: message.name,
      organization: message.organization,
      channel: 'message',
      // from은 sendResponse에서 채워준다.
      to: m.from,
      requestId: m._id,
      body: {
        orderId,
        printerKey,
        what,
        printCookOption
      }
    };

    try {
      const printer = this.userService.printer;
      if (printer?.printerAddress === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : ${printerKey}에 해당하는 printer/${printerKey} 또는 printer/${printerKey}/printerAddress가 없네요`;
        await this.sendResponse(resMessage);
        return;
      }

      const order = await this.firebaseManagerService.getDoc<UnifiedOrderDoc>(`${unifiedOrderCollectionPath}/${orderId}`);
      if (order === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : unifiedOrder/${orderId}를 찾을 수 없습니다.`;
        await this.sendResponse(resMessage);
        return;
      }

      const room = this.roomService.room;
      if (room === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : 호실 정보가 없습니다.`;
        await this.sendResponse(resMessage);
        return;
      }

      // 빌툰 출력 여부 체크
      let billToonPrint = false;
      let billToonUrl = '';
      if (room.direct?.enable === true) {
        // 빌툰 URL 확인
        const siteKey = order.site;
        const site = await this.firebaseManagerService.getDoc<SiteDoc>(`site/${siteKey}`);
        if (site?.direct?.billToonUrl) {
          // https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/billtoon/billtoon_211215.pbm
          logger.info(`[${fnName} 빌툰 출력 확인 ${site.direct.billToonUrl}`);
          billToonPrint = true;
          billToonUrl = site.direct.billToonUrl;
        } else {
          logger.warn(`[${fnName}] site/${siteKey} 에서 빌툰 출력에 필요한 billToonUrl을 찾을 수 없습니다.`);
        }
      }

      logger.info(`DO print for ${orderId} (beep:${beep}, autoPrint:${autoPrint}, printCookOption:${printCookOption}, billToon:${billToonPrint})`);

      let whats: ('cook' | 'customer' | 'qrcode' | 'billtoon' | 'cut')[] = [];
      {
        // 1. what, printOption을 printOption으로 통일한다.
        const printOption = what === 'default' ? room.printOption : what === 'all' ? 'cookFirst' : what === 'customer' ? 'customerOnly' : what === 'cook' ? 'cookOnly' : 'UNEXPECTED';

        // 2. 예외 처리
        // 출력하지 말라니 응답만 한다.
        // 그런데 어떤 경우에 메시지를 보내는 것일까?
        if (printOption === 'noPrint') {
          resMessage.result = 'success';
          resMessage.reason = 'noPrint';
          console.log(`noPrint인데 굳이 메시지를 보내고 있는 너는 누구냐? ${m.from.class}`);
          await this.sendResponse(resMessage);
          return;
        }
        if (!['cookFirst', 'customerFirst', 'cookOnly', 'customerOnly'].includes(printOption)) {
          resMessage.result = 'error';
          resMessage.reason = `잘못된 printOption 값입니다. ${printOption}`;
          await this.sendResponse(resMessage);
          return;
        }

        // 3. 주방용, 고객용 subBlock 구성
        const customerBlock: ('customer' | 'qrcode' | 'billtoon' | 'cut')[] = billToonPrint !== false ? ['customer', 'qrcode', 'billtoon', 'cut'] : ['customer', 'cut'];
        const cookBlock: ('cook' | 'cut')[] = ['cook', 'cut'];

        // 4. subBlock 조합
        if (printOption === 'cookFirst') {
          whats = [...cookBlock, ...customerBlock];
        } else if (printOption === 'customerFirst') {
          whats = [...customerBlock, ...cookBlock];
        } else if (printOption === 'cookOnly') {
          whats = [...cookBlock];
        } else if (printOption === 'customerOnly') {
          whats = [...customerBlock];
        }
      }

      const whichBodies: Uint8Array[] = [];
      for (const [index, which] of whats.entries()) {
        if (which === 'cook' || which === 'customer') {
          whichBodies[index] = await renderWhat(which, order, room, autoPrint, printCookOption === 'double');
        } else if (which === 'billtoon') {
          whichBodies[index] = await renderBillToon(billToonUrl);
        } else if (which === 'qrcode') {
          // whichBodies[index] = await renderQRImage(billToonUrl, `https://${prefixQRcode}direct.ghostaurant.co/${order.site}?order=${order._id}`);
          whichBodies[index] = renderQRCode(`https://${prefixQRcode}direct.ghostaurant.co/${order.site}?order=${order._id}`);
        } else if (which === 'cut') {
          whichBodies[index] = encode(partialCut, 'euc-kr');
        }
      }

      /// 출력 첫 부분은 여백과 출력 비프음 추가한다.
      const beepSound = EP.INIT + '\x07\x07\x07\x07';
      const bufferHead = encode(beep ? beepSound : '', 'euc-kr');
      const lengthToPrint = bufferHead.length + whichBodies.reduce((acc, cur) => (acc += cur.length), 0);
      const arrayToPrint = new Uint8Array(lengthToPrint);

      arrayToPrint.set(bufferHead, 0);
      let bufferIndex = bufferHead.length;
      for (const body of whichBodies) {
        arrayToPrint.set(body, bufferIndex);
        bufferIndex += body.length;
      }
      const bufferToPrint = Buffer.from(arrayToPrint);

      const errMessage = await this.printBin(bufferToPrint, printer.printerAddress);
      if (errMessage !== 'OK') {
        const reasonMessage = `[${fnName}] ${printer.printerAddress} error on printBin : ${errMessage}`;
        this.logService.logOrder(order, reasonMessage, 'error');
        resMessage.result = 'error';
        resMessage.reason = reasonMessage;
        await this.sendResponse(resMessage);
        return;
      } else {
        // 실제로 빌툰 출력 했는지 기록 (통계 분석에 활용)
        this.logService.logOrder(order, `${printer.printerAddress} ${ whats.includes('qrcode') ? '빌툰' : '' }영수증 출력 성공`);
        logger.info(`[${fnName}] ${printer.printerAddress} 영수증 출력 성공`);
      }

      resMessage.result = 'success';
    } catch (error) {
      const reason = error instanceof Error ? error.message : String(error);
      resMessage.result = 'error';
      resMessage.reason = `[${fnName}] ${orderId} 출력 실패 : ${reason}`;
      await this.sendResponse(resMessage);
      return;
    }

    await this.sendResponse(resMessage);
  }

  /**
   * [toe-printer-agent에서 복사한 코드]
   */
  private async handleMessage_printStat(message: Message<'request', 'printStat'>) {
    const fnName = 'handleMessage_printStat';

    const m = message;
    // body
    const roomKey: string = get(m, ['body', 'roomKey'], '');
    const printerKey: string = get(m, ['body', 'printerKey'], '');
    const ignoreZeroOption: boolean = get(m, ['body', 'ignoreZeroOption'], true);

    const resMessage: Partial<Message<'response', 'printStat'>> = {
      type: 'response',
      name: message.name,
      organization: message.organization,
      channel: 'message',
      // from은 sendResponse에서 채워준다.
      to: m.from,
      requestId: m._id,
      body: {
        roomKey,
        printerKey,
        ignoreZeroOption
      }
    };

    try {
      // 1. 프린터 정보를 가져온다.
      const printer = this.userService.printer;
      if (printer?.printerAddress === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : ${printerKey}에 해당하는 printer/${printerKey} 또는 printer/${printerKey}/printerAddress가 없네요`;
        await this.sendResponse(resMessage);
        return;
      }

      // 같은 호실에 취소는 제외한 주문에 대해서 출력한다.
      const orders = await this.getUncanceledOrdersFor(roomKey);
      const room = this.roomService.room;
      if (room === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : 호실 정보가 없습니다.`;
      } else {
        logger.info(`DO print stats for ${roomKey}`);

        resMessage.result = 'success';
        const utf8 = renderFoods('customer', orders, room, ignoreZeroOption);
        const euckr = encode(utf8, 'euc-kr');
        const errMessage = await this.printBin(euckr, printer.printerAddress);
        if (errMessage !== 'OK') {
          resMessage.result = 'error';
          resMessage.reason = `[${fnName}] 출력 실패 : printBin - ${errMessage}`;
        }
      }
    } catch (error) {
      const reason = error instanceof Error ? error.message : String(error);
      resMessage.result = 'error';
      resMessage.reason = `[${fnName}] 출력 실패 : ${reason}`;
    }

    await this.sendResponse(resMessage);
  }

  /**
   * [toe-printer-agent에서 복사한 코드]
   * 배민 리뷰를 출력한다.
   */
  private async handleMessage_printReview(message: Message<'request', 'printReview'>) {
    const fnName = 'handleMessage_printReview';

    const collectionPath = 'baeminUserReview';
    const m = message;
    // body
    const printerKey = get(m, ['body', 'printerKey'], 'No PrinterKey');
    const reviewId = get(m, ['body', 'reviewId'], 'No rewviewId');
    const type = get(m, ['body', 'type'], 'removed');
    const oldRating = get(m, ['body', 'oldRating']) as any;
    const oldContents = get(m, ['body', 'oldContents'], 'No old contents');

    const resMessage: Partial<Message<'response', 'printReview'>> = {
      type: 'response',
      name: message.name,
      organization: message.organization,
      channel: 'message',
      // from은 sendResponse에서 채워준다.
      to: m.from,
      requestId: m._id,
      body: {
        printerKey,
        reviewId,
        type
      }
    };

    try {
      // 1. 프린터 정보를 가져온다.
      const printer = this.userService.printer;
      if (printer?.printerAddress === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : ${printerKey}에 해당하는 printer/${printerKey} 또는 printer/${printerKey}/printerAddress가 없네요`;
        await this.sendResponse(resMessage);
        return;
      }

      // 리뷰 내용을 가져온다.
      const reviewDoc = await this.firebaseManagerService.getDoc<BaeminUserReviewDoc>(`${baeminUserReviewCollectionPath}/${reviewId}`);
      if (reviewDoc === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : ${collectionPath}/${reviewId}에 해당하는 리뷰를 찾지 못했습니다.`;
        await this.sendResponse(resMessage);
        return;
      }

      // 리뷰를 출력할 수 있게 변환한다.
      const oldReview = { rating: oldRating, contents: oldContents };
      const utf8 = renderReview(type, reviewDoc, type === 'modified' ? oldReview : undefined);
      const euckr = encode(utf8, 'euc-kr');
      // logger.debug(euckr);

      logger.info(`DO print review ${reviewId} to ${printerKey}`);
      resMessage.result = 'success';
      const errMessage = await this.printBin(euckr, printer.printerAddress);
      if (errMessage !== 'OK') {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : error on printBin - ${errMessage}`;
      }
    } catch (error) {
      const reason = error instanceof Error ? error.message : String(error);
      resMessage.result = 'error';
      resMessage.reason = `[${fnName}] 출력 실패 : ${reason}`;
    }

    await this.sendResponse(resMessage);
  }

  /**
   * [toe-printer-agent에서 복사한 코드]
   */
  private async handleMessage_printMessage(message: Message<'request', 'printMessage'>) {
    const fnName = 'handleMessage_printMessage';

    const m = message;
    // body
    const printerKey = get(m, ['body', 'printerKey'], 'No PrinterKey');
    const textTitle = get(m, ['body', 'textTitle'], '');
    const textRaw = get(m, ['body', 'textRaw'], '');
    const orderId = get(m, ['body', 'orderId'], 'No orderId');
    const beep = get(m, ['body', 'beep'], false);
    const autoPrint = get(m, ['body', 'autoPrint'], false);

    const resMessage: Partial<Message<'response', 'printMessage'>> = {
      type: 'response',
      name: message.name,
      organization: message.organization,
      channel: 'message',
      // from은 sendResponse에서 채워준다.
      to: m.from,
      requestId: m._id,
      body: {
        printerKey,
        orderId
      }
    };

    try {
      // 1. 프린터 정보를 가져온다.
      const printer = this.userService.printer;
      if (printer?.printerAddress === undefined) {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] 출력 실패 : ${printerKey}에 해당하는 printer/${printerKey} 또는 printer/${printerKey}/printerAddress가 없네요`;
        await this.sendResponse(resMessage);
        return;
      }

      let utf8: string;
      // 2. 주문정보가 존재하면 메시지에 반영한다.
      if (orderId === 'No orderId') {
        utf8 = renderMessage(textTitle, textRaw, beep, autoPrint);
      } else {
        const order = await this.firebaseManagerService.getDoc<UnifiedOrderDoc>(`${unifiedOrderCollectionPath}/${orderId}`);
        if (order === undefined) {
          resMessage.result = 'error';
          resMessage.reason = `[${fnName}] 출력 실패 : 주문(${orderId})을 찾을 수 없습니다.`;
          await this.sendResponse(resMessage);
          return;
        }

        // 주문정보를 표시해야하는 경우 roomDoc을 필요로 한다.
        const room = this.roomService.room;

        utf8 = renderMessage(textTitle, textRaw, beep, autoPrint, order, room);
      }

      // 3. 출력 메세지를 변환한다.
      const euckr = encode(utf8, 'euc-kr');
      logger.info(`DO print Message to ${printerKey} (orderId:${orderId}, beep:${beep}, autoPrint:${autoPrint})`);
      resMessage.result = 'success';
      const errMessage = await this.printBin(euckr, printer.printerAddress);
      if (errMessage !== 'OK') {
        resMessage.result = 'error';
        resMessage.reason = `[${fnName}] error on printBin : ${errMessage}`;
      }
    } catch (error) {
      const reason = error instanceof Error ? error.message : String(error);
      resMessage.result = 'error';
      resMessage.reason = `[${fnName}] 출력 실패 : ${reason}`;
    }

    await this.sendResponse(resMessage);
  }

  /**
   * '오늘의 주문'을 알기위한 '오늘'이 시작하는 시간을 알려준다.
   */
  private getStatStartDate() {
    // POS가 06시 기준으로 하루를 시작하기 때문에 맞춘다.
    let atDate = 0;
    const openHours = 6;
    const openMinutes = 0;
    const now = new Date();
    const nowHours = now.getHours();
    const nowMinutes = now.getMinutes();

    // 현재 시각이 오픈 이전이라면 이전 날짜에 대한 주문부터 가져온다.
    if (openHours * 60 + openMinutes > nowHours * 60 + nowMinutes) {
      atDate -= 1;
    }
    const openHHMM = String(openHours).padStart(2, '0') + ':' + String(openMinutes).padStart(2, '0');
    // orderDate: string; // '2019-04-09T18:56:20+0900'
    return format(now.getTime() + atDate * 24 * 3600 * 1000, `yyyy-MM-dd'T'${openHHMM}:00+0900`);
  }

  private sendResponse<N extends keyof MessageBodyMap['response']>(resMessage: Partial<Message<'response', N>>) {
    if (resMessage.result === 'error') {
      this.logService.logRoom(resMessage.reason, 'error');
    }

    if (!resMessage.organization) {
      this.logService.logRoom(`message 에 organization 필드 누락.\n${JSON.stringify(resMessage)}`, 'error');
      resMessage.organization = this.userService.organization;
    }

    resMessage.from = {
      class: 'pos',
      instanceNo: this.roomService.room._id
    };

    return this.firebaseManagerService.setDoc(messageCollectionPath, undefined, resMessage, {
      addMetadata: true,
      isCreate: true
    });
  }

  private printBin(buffer: Buffer, host: string) {
    return this.inAppBrowserMessageService.postMessage<'printRequest'>({
      printRequest: {
        buffer,
        host
      }
    });
  }

  private async getUncanceledOrdersFor(roomKey: string) {
    const atDateStringFrom = this.getStatStartDate();
    const wheres: WHERE[] = [
      ['room', '==', roomKey],
      ['orderDate', '>', atDateStringFrom]
    ];
    // POS에 맞추어 printStat 출력시 주문시작, 주문끝이 시간순으로 표시되게 하기 위함
    return (await this.firebaseManagerService.getDocsArrayWithWhere<UnifiedOrderDoc>(
      // limit 200은 현재 시작 오류 등으로 인한 안전장치
      unifiedOrderCollectionPath, wheres, { sortKey: 'orderDate', orderBy: 'asc', limit: 200 }))
      // '취소' 주문은 제외한다.
      .filter((order) => order.contextStatusCode < UnifiedOrderContextStatusCode.CANCELED);
  }
}

