/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import set from 'lodash-es/set';

import { RoomDoc } from '../../schema/3/schema';

import { RoomService } from '../../core/1/room.service';
import { normalizeTel } from '../../core/1/util';
import { debugLog } from '../../core/1/common';
import { UtilService } from '../../core/2/util.service';
import { UserService } from '../../core/3/user.service';
import { LogService } from '../../core/4/log.service';

import { DialogSpinnerService } from '../dialog-spinner/dialog-spinner.service';
import { DialogTelService } from '../dialog-tel/dialog-tel.service';

@Component({
  selector: 'app-dialog-config',
  templateUrl: './dialog-config.component.html',
  styleUrls: ['./dialog-config.component.scss']
})
export class DialogConfigComponent implements OnInit {
  public roomDoc: RoomDoc;
  public configForm: FormGroup;
  public normalizedTel = '';
  public defaultTel = '15226385';

  constructor(
    public dialogRef: MatDialogRef<DialogConfigComponent>,
    private roomService: RoomService,
    private userService: UserService,
    private fb: FormBuilder,
    private dialogTelService: DialogTelService,
    private logService: LogService,
    private utilService: UtilService,
    private dialogSpinnerService: DialogSpinnerService
  ) { }

  ngOnInit(): void {
    // 초기값과 비교해야하므로 복사한다.
    this.roomDoc = cloneDeep(this.roomService.room);

    this.initForm();
    const telNo = this.configForm.get('telNo').value;
    this.normalizedTel = normalizeTel(telNo);
  }

  initForm() {
    this.configForm = this.fb.group({
      printReview: this.roomDoc.printReview ?? false,
      printOption: this.roomDoc.printOption ?? false,
      printCookOption: this.roomDoc.printCookOption ?? 'double',
      canceledOrderAutoPrint: this.roomDoc.autoPrint?.canceledOrder ?? false,
      coupangeatsAutoPrint: this.roomDoc.autoPrint?.coupangeats ?? false,
      // 값이 없을 경우에는 빈칸 대신에 대표 번호 표시
      telNo: (!this.roomDoc.telNo || this.roomDoc.telNo === '') ? this.defaultTel : this.roomDoc.telNo,
      cookMinutes: this.roomDoc.cookMinutes ?? 20,
    }, { validators: this.formValidator() });
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public async update() {
    const dialogSpinner = this.dialogSpinnerService.openSpinnerDialog('변경 중...');
    const updateData = this.extractUpdateValueFromForm();

    try {
      await this.roomService.mergeRoomDoc(this.userService.user.room, updateData);
      this.utilService.toastrInfo('설정이 반영되었습니다.');
      this.closeDialog();
    } catch (error) {
      this.logService.logRoomWithToastrCatch(error.message);
    }

    dialogSpinner.close();
  }

  public extractUpdateValueFromForm() {
    const formValue = this.configForm.value;
    // roomDoc에서 update해야할 것만 추린다.
    const updateRoomDocData: any = {};

    if (this.roomDoc.printReview !== formValue.printReview) {
      updateRoomDocData.printReview = formValue.printReview;
    }

    if (this.roomDoc.printOption !== formValue.printOption) {
      updateRoomDocData.printOption = formValue.printOption;
    }

    if (this.roomDoc.printCookOption !== formValue.printCookOption) {
      updateRoomDocData.printCookOption = formValue.printCookOption;
    }

    if (this.roomDoc.cookMinutes !== formValue.cookMinutes) {
      updateRoomDocData.cookMinutes = formValue.cookMinutes;
    }

    if (this.roomDoc.autoPrint?.coupangeats !== formValue.coupangeatsAutoPrint) {
      set(updateRoomDocData, 'autoPrint.coupangeats', formValue.coupangeatsAutoPrint);
    }

    if (this.roomDoc.autoPrint?.canceledOrder !== formValue.canceledOrderAutoPrint) {
      set(updateRoomDocData, 'autoPrint.canceledOrder', formValue.canceledOrderAutoPrint);
    }

    let telNo = formValue.telNo;
    // ''일 경우에는 대표번호인데 화면에 표시하기 위해 formValue가 ''이 아니다. 그렇지만 비교는 ''로 해야한다.
    if (formValue.telNo === this.defaultTel) {
      telNo = '';
    }
    if (this.roomDoc.telNo !== telNo) {
      debugLog(`roomDoc.telNo: ${this.roomDoc.telNo}, formValue.telNo: ${formValue.telNo}, telNo: ${telNo}`);

      updateRoomDocData.telNo = telNo;
    }

    return updateRoomDocData;
  }

  public openTelNoDialog() {
    const formTelNo = this.configForm.get('telNo').value;
    this.dialogTelService.openNumberPadDialog(formTelNo, '값이 없으면 1522-6385(고스트키친)을 출력합니다.').afterClosed().subscribe(telNo => {
      if (telNo === '') {
        this.configForm.get('telNo').setValue(this.defaultTel);
        this.normalizedTel = normalizeTel(this.defaultTel);
      } else if (telNo !== undefined) {
        this.configForm.get('telNo').setValue(telNo);
        this.normalizedTel = normalizeTel(telNo);
      }
    });
  }

  private formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const configForm = control;
      // 변경된 사항이 없으면 invalid로 취급한다.
      if (this.configForm) {
        const configFormValue = this.extractUpdateValueFromForm();
        if (Object.keys(configFormValue).length === 0) {
          return { reason: '변경 사항이 없습니다.' };
        }
      }

      return null;
    };
  }

}
