/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { format } from 'date-fns';

import { lineClampSwalContent } from '../1/common';
import { UnifiedOrderContextStatusCode, UnifiedOrderDoc } from '../../schema/3/schema';
import { CallInputAcceptWithDelivery, CallInputCancelYogiyoOrder, CallOutputAcceptWithDelivery, CallOutputCancelYogiyoOrder } from '../../schema/4/schema-functions-call';

import { yogiyoOrderCancelTypeMapping } from '../1/string-map';
import { LogService } from '../4/log.service';
import { UnifiedOrderService } from '../5/unified-order.service';
import { MessageService } from '../7/message.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Injectable({
  providedIn: 'root'
})
export class YogiyoOrderService {

  constructor(
    private unifiedOrderService: UnifiedOrderService,
    private messageService: MessageService,
    private logService: LogService,
    private dialogNoticeService: DialogNoticeService,
    private fns: AngularFireFunctions
  ) { }

  /**
   * 배달의 경우에는 accept callable을 호출한다.
   * 포장 또는 익스프레스 주문인 경우에는 직접 명령을 보낸다.
   */
  public async accept(order: UnifiedOrderDoc, withDelivery = false): Promise<boolean> {
    const cookMinutes = order.cookMinutes;

    if (order.createdBy !== undefined) {
      this.logService.logOrder(order, `createdBy(${order.createdBy})에 값이 있습니다. 고객센터에 알려주세요.`, 'error', true);
      return false;
    }

    try {
      if (order.deliveryType === 'DELIVERY') {
          const callInputAcceptWithDelivery: CallInputAcceptWithDelivery = {
            orderId: order._id,
            withDelivery,
            posRequestedPickupMinutes: order.posRequestedPickupMinutes,
            posDeliveryMinutes: order.posDeliveryMinutes,
            recommendedRequestedPickupMinutes: order.recommendedRequestedPickupMinutes,
            recommendedDeliveryMinutes: order.recommendedDeliveryMinutes,
          };
          const callable = this.fns.httpsCallable<CallInputAcceptWithDelivery, CallOutputAcceptWithDelivery>('callAcceptWithDelivery');
          const response = await callable(callInputAcceptWithDelivery).toPromise();

          if (response.result === 'success') {
            this.logService.logOrder(order, '주문 접수 성공', 'info', true);
          } else {
            if (response.acceptSuccess) {
              this.logService.logOrder(order, `주문 접수에 성공했지만 배차에 실패했습니다. ${response.reason} / withDelivery: ${withDelivery}`, 'error');
              lineClampSwalContent('배차 실패', '주문 접수에 성공했지만 배차에 실패했습니다. 접수된 주문 상세화면에서 배차 추가를 다시 진행해 주세요.');
            } else {
              this.logService.logOrder(order, `주문 접수 시도했지만 실패했습니다. ${response.reason} / withDelivery: ${withDelivery}`, 'error');
              lineClampSwalContent('주문을 접수하지 못 했습니다.', response.reason);

              return false;
            }
          }
      } else if (order.deliveryType === 'TAKEOUT') {
        if (!cookMinutes) {
          this.logService.logOrder(order, 'cookMinutes이 없습니다. 고객센터에 알려주세요.', 'error', true);
          return false;
        }
        // 포장인 경우에는 바로 이어서 주문 접수한다.
        // '5'분 배달 요청이 곧 포장 접수이다.
        const response = await this.messageService.requestAcceptYogiyoOrder(order.orderNo, '5');
        if (response?.result === 'success') {
          this.logService.logOrder(order, '요기요 포장 주문을 접수 했습니다. (실제로 접수)');
        } else {
          this.logService.logOrder(order, `요기요 포장 주문을 접수 실패했습니다: ${response?.reason}`);
          return false;
        }
      } else if (order.deliveryType === 'YOGIYO') {
        // 익스프레스 주문의 경우에는 바로 이어서 주문 접수한다.
        // '0'분 배달 요청이 곧 익스프레스 접수이다.
        const response = await this.messageService.requestAcceptYogiyoOrder(order.orderNo, '0');
        if (response?.result === 'success') {
          this.logService.logOrder(order, '요기요 익스프레스 주문을 접수 했습니다. (실제로 접수)');
        } else {
          this.logService.logOrder(order, `요기요 익스프레스 주문을 접수 실패했습니다: ${response?.reason}`);
          return false;
        }
      }
    } catch (error) {
      this.logService.logOrder(order, `요기요 접수 시도했지만 실패했습니다. ${error.message} / yogiyoActionAccept(withDelivery: ${withDelivery})`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  /**
   * 추가적인 연동이 없이 내부 상태만 변경한다.
   */
  public async cook(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 시간을 기록한다.
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        contextStatusCode: UnifiedOrderContextStatusCode.COOKED,
        time: {
          onCOOKED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
        }
      });
      this.logService.logOrder(order, `요기요 주문의 주문상태를 '조리완료'로 변경 완료했습니다.`);
    } catch (error) {
      this.logService.logOrder(order, `요기요 주문의 주문상태를 '조리완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  /**
   * 추가적인 연동이 없이 내부 상태만 변경한다.
   */
  public async pickup(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 포장이나 매장식사의 경우에는 픽업이 곧 배송완료이다.
      if (order.deliveryType === 'HERE' || order.deliveryType === 'TAKEOUT') {
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED,
        });
        this.logService.logOrder(order, `요기요 주문(${order.deliveryType})의 주문상태를 '배송완료'로 변경 완료했습니다.`);
      } else {
        // 시간을 기록한다.
        await this.unifiedOrderService.mergeOrder({
          _id: order._id,
          contextStatusCode: UnifiedOrderContextStatusCode.PICKEDUP,
          time: {
            onPICKEDUP: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
          }
        });
        this.logService.logOrder(order, `요기요 주문(${order.deliveryType})의 주문상태를 '배송중'으로 변경 완료했습니다.`);
      }
    } catch (error) {
      this.logService.logOrder(order, `요기요 주문의 주문상태를 '배송중'(포장이나 매장식사)이나 '배송완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
      return false;
    }

    return true;
  }

  public async complete(order: UnifiedOrderDoc): Promise<boolean> {
    try {
      // 배송완료로 상태를 바꾸고 시간 기록
      await this.unifiedOrderService.mergeOrder({
        _id: order._id,
        contextStatusCode: UnifiedOrderContextStatusCode.COMPLETED,
        time: {
          onCOMPLETED: format(new Date(), `yyyy-MM-dd'T'HH:mm:ss+0900`)
        }
      });

      this.logService.logOrder(order, `주문상태를 '배송완료'로 변경 완료했습니다.`);
      return true;
    } catch (error) {
      this.logService.logOrder(order, `주문상태를 '배송완료'로 변경 시도했지만 실패했습니다. ${error.message}`, 'error');
      this.dialogNoticeService.openSimpleNoticeDialog(error.message);
    }

    return false;
  }

  // 취소 사유를 선택받는다.
  public cancelDescs(order: UnifiedOrderDoc) {
    return Object.entries(yogiyoOrderCancelTypeMapping)
    .map(([key, value]) => {
      return { cancelCode: parseInt(key, 10), cancelReason: value };
    });
  }

  public async cancel(order: UnifiedOrderDoc, declineReasonCode): Promise<boolean> {
    const callInputCancelYogiyoOrder: CallInputCancelYogiyoOrder = {
      orderId: order._id,
      declineReasonCode
    };

    try {
      const callable = this.fns.httpsCallable<CallInputCancelYogiyoOrder, CallOutputCancelYogiyoOrder>('callCancelYogiyoOrder');
      const response = await callable(callInputCancelYogiyoOrder).toPromise();

      if (response.result === 'success') {
        this.logService.logOrder(order, '요기요 주문 취소 성공', 'info', true);
      } else {
        this.logService.logOrder(order, `요기요 주문을 취소 시도했지만 실패했습니다. ${response.reason}`, 'error');
        lineClampSwalContent('요기요 주문을 취소하지 못 했습니다.', response.reason);
        return false;
      }

    } catch (error) {
      lineClampSwalContent('요기요 주문을 취소하지 못 했습니다.', error.message);
      this.logService.logOrder(order, `[${error.message}]의 이유로 취소(${callInputCancelYogiyoOrder.declineReasonCode})를 실패했다`);
      return false;
    }
    return true;
  }

  canCancel(order: UnifiedOrderDoc) {
    // 요기요는 주문 접수 후 바로 완료가 되기 때문에 이미 취소된 주문을 제외하고 모두 거부/취소가 가능하다.
    return order.contextStatusCode !== UnifiedOrderContextStatusCode.CANCELED;
  }

  public showUserTel(order: UnifiedOrderDoc): boolean {
    return true;
  }

  public showDeliveryMinutes(order: UnifiedOrderDoc): boolean {
    return order.deliveryType !== 'TAKEOUT';
  }

  public orderNo(order: UnifiedOrderDoc): string {
    const displayNo = order.displayNo ? ` [접수번호: ${order.displayNo}]` : '';
    return `${order.orderNo}${displayNo}`;
  }

  public cookMinutesOptionsFor(order: UnifiedOrderDoc): string[] {
    // 요기요 익스프레스인 경우에는 UI가 표시되지 않기 때문에 '0'으로 설정하는 부분이 필요없을 수도 있지만
    // UI 독립적인 코드가 원칙이므로 구분해서 응답한다.
    return order.deliveryType === 'YOGIYO' ? ['0'] : ['10', '15', '20', '25', '30', '40', '50', '60'];
  }
}
