<h1>배달의민족 영업 임시{{ blocked === null ? '??' : ''}}{{ blocked === true ? '해제' : ''}}{{ blocked === false ? '중지' : ''}}</h1>
<h2>ID: {{ memId }}</h2>
<h2 class="notice">모든 배민 업소에 대해서 적용됩니다.</h2>

<p *ngIf="blocked === null">조회 중...</p>
<p *ngIf="blockedInfo">[현재 영업중지 中] {{ blockedInfo }}</p>

<p *ngIf="blocked === false">임시영업중지 시간을 선택하세요.</p>
<div *ngIf="blocked === false" class="buttons">
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(30)">30분</button>
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(60)">1시간</button>
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(90)">1시간 30분</button>
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(120)">2시간</button>
</div>
<div *ngIf="blocked === false" class="buttons">
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(150)">2시간 30분</button>
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(180)">3시간</button>
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(210)">3시간 30분</button>
  <button [disableRipple]="true" mat-raised-button color="warn" (click)="block(240)">4시간</button>
</div>

<div class="buttons">
  <button [disableRipple]="true" mat-raised-button type="submit" (click)="onSubmit()">닫기</button>
  <button [disableRipple]="true" *ngIf="blocked === true" mat-raised-button color="primary" (click)="unblock()" >임시중지 해제</button>
</div>


