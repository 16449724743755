<div class="number-pad-container">
  <div class="title">
    <h3>전화번호 입력</h3>
    <span *ngIf="this.data?.subTitle">{{ this.data?.subTitle }}</span>
  </div>
  <form>
    <div class="tel-number">
      <input [class.invalid]="telNoForm.invalid" readonly="readonly" type="tel" [formControl]="telNoForm">
      <mat-icon class="cancel-icon-button" *ngIf="telNoForm.value.length > 0" (click)="clearTelNo()">cancel</mat-icon>
      <mat-error *ngIf="telNoForm.invalid">{{ telNoForm.errors['reason'] }}</mat-error>
    </div>
  </form>
  <div class="dials">
    <button *ngFor="let num of numbers" (pointerdown)="onChangeDials(num.toString(), $event)">{{ num }}</button>
    <button class="special" (pointerdown)="onChangeDials('010', $event)">010</button>
    <button (pointerdown)="onChangeDials('0', $event)">0</button>
    <button (pointerdown)="onChangeDials('backspace', $event)">
      <mat-icon color="accent">backspace</mat-icon>
    </button>
  </div>
  <div class="buttons">
    <button class="left cancel" (pointerdown)="closeDialog()">취소</button>
    <button [disableRipple]="true" mat-flat-button color="accent" (click)="updateTelNo()" [disabled]="telNoForm.invalid">입력</button>
  </div>
</div>
